import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import Spinner from "../../components/helpers/Spinner";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { useAppSelector } from "../../hooks/hooks";
import { set_landing_house } from "../../redux/reducers/landingReducer";

interface Props {
    tenant: number;
}

const ComprobarPago = ({ tenant }: Props) => {
    const dispatch = useDispatch();
    const { banner } = useAppSelector((state) => state.landing_house.house);

    const { banner: banner_corredor } = useAppSelector(
        (state) => state.corredor
    );

    const getHouseFromTenant = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/dominio`,
            });

            dispatch(set_landing_house(data));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (tenant === 3 || tenant === 8) {
            getHouseFromTenant();
        }
    }, [tenant]);

    const backgroundImageUrl =
        tenant === 6
            ? `${process.env.REACT_APP_PROXY}/usuario/getImage/${banner_corredor}`
            : `${process.env.REACT_APP_PROXY}/casa/getImage/${banner}`;

    return (
        <div className="flex">
            {(tenant === 3 && banner) ||
            (tenant === 6 && banner_corredor) ||
            (tenant === 8 && banner) ? (
                <div
                    style={{
                        backgroundImage: `url(${backgroundImageUrl})`,
                        filter: "brightness(100%) contrast(113%) saturate(100%) blur(0px) hue-rotate(0deg)",
                    }}
                    className={`w-full bg-blue-500 h-screen bg-center bg-no-repeat bg-cover flex flex-col text-center`}>
                    <div className="h-screen bg-[rgba(5,52,101,0.60)]">
                        <h1 className="p-4 mt-20 text-4xl font-bold text-white lg:text-5xl">
                            ¡Gracias por tu pago!
                        </h1>
                        <h1 className="mt-5 text-xl font-bold text-white lg:text-3xl">
                            Estamos revisando tu comprobante
                        </h1>
                        <div className="px-10">
                            <div className="flex flex-col max-w-lg py-10 mx-auto mt-10 rounded-lg bg-neutral-200 lg:mt-28 opacity-95">
                                <h1 className="px-10 py-2 mb-10 text-2xl text-gray-600">
                                    Muy pronto nos pondremos en contacto
                                </h1>
                                <Link to="/">
                                    <span
                                        className={`font-semibold hover:border hover:underline border-gray-400 bg-blue-500 text-white p-3 min-w-max mx-auto cursor-pointer rounded-md text-lg`}>
                                        Volver al sitio
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mx-auto mt-20">
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default ComprobarPago;
