import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BurguerMenuMobile } from "./BurguerMenuMobile";
import { useAppSelector } from "../../hooks/hooks";

const TITLES = [
    {
        id: 1,
        title: "Casas",
        url: "casas",
        textInfo:
            "Aca podras ver los pedidos de reservas que te haran los clientes!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 99.54 100">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m49.75,73.36c-13.18,0-26.36,0-39.54,0-2.33,0-3.81-1.47-3.7-3.65.07-1.4.92-2.61,2.33-2.98.88-.23,1-.68,1.02-1.46.13-4.61.59-9.18,1.81-13.66,2.56-9.41,8.55-15.56,17.67-18.73,4.8-1.67,9.77-2.49,14.84-2.72,2.23-.1,2.67-.68,2.18-2.91-.13-.59-.6-.49-.95-.62-1.55-.58-2.41-1.8-2.36-3.42.04-1.46.94-2.68,2.33-3.1.36-.11.75-.15,1.12-.15,2.18-.01,4.36-.02,6.54,0,1.77.02,3.13,1.14,3.4,2.76.3,1.81-.65,3.41-2.46,3.91-.71.19-.83.53-.84,1.16-.01,2.3-.03,2.24,2.32,2.37,6.05.32,11.94,1.35,17.52,3.84,8.03,3.58,12.86,9.79,15.02,18.23,1.09,4.28,1.53,8.65,1.64,13.06.02.8.2,1.2,1.04,1.44,1.67.47,2.56,2.08,2.29,3.82-.25,1.63-1.67,2.79-3.48,2.79-9.72,0-19.44,0-29.16,0-3.53,0-7.06,0-10.59,0Zm6.41-29.71c-1.32-.17-2.31.99-2.74,2.1-.84,2.14.65,4.19,3.11,4.39,2.28.18,4.53.52,6.71,1.24,2.81.94,4.81,2.67,5.55,5.65.11.43.28.86.5,1.25.79,1.35,2.4,1.9,3.86,1.36,1.42-.53,2.34-2.12,1.99-3.59-1.04-4.33-3.3-7.73-7.41-9.73-3.36-1.64-6.95-2.23-11.58-2.66Z"
                        />
                        <path
                            fill="white"
                            d="m49.65,99.99c-15.95,0-31.9,0-47.84,0-.31,0-.62-.02-.93,0-.84.08-1.02-.26-.76-1.04.94-2.75,1.86-5.51,2.76-8.28.19-.57.51-.84,1.09-.93,3.27-.5,5.22-2.38,5.72-5.66.15-.98.78-.88,1.44-.88,11.21,0,22.42,0,33.63,0q1.62,0,1.62-1.64t1.69-1.69c1.38,0,2.77.03,4.15,0,.73-.02.94.28.96.98.06,2.36.08,2.36,2.45,2.36,10.9,0,21.79.01,32.69-.02,1.02,0,1.45.26,1.6,1.32.39,2.68,2.54,4.77,5.25,5.16.92.13,1.36.53,1.63,1.41.78,2.55,1.66,5.07,2.5,7.6.43,1.3.42,1.31-.97,1.31-16.22,0-32.45,0-48.67,0Z"
                        />
                        <path
                            fill="white"
                            d="m46.37,6.67c0-1.14-.03-2.29,0-3.43C46.43,1.36,47.86,0,49.73,0c1.87,0,3.37,1.35,3.4,3.19.05,2.35.05,4.71,0,7.07-.04,1.85-1.53,3.2-3.4,3.19-1.83,0-3.3-1.42-3.36-3.25-.03-1.18,0-2.36,0-3.53Z"
                        />
                        <path
                            fill="white"
                            d="m19.93,16.77c-.04,1.36-.63,2.41-1.86,2.99-1.25.6-2.54.54-3.61-.36-1.43-1.2-2.76-2.52-3.95-3.96-1.07-1.31-.9-3.29.25-4.47,1.18-1.21,3.2-1.4,4.54-.3,1.43,1.19,2.73,2.55,3.96,3.95.51.59.72,1.33.67,2.13Z"
                        />
                        <path
                            fill="white"
                            d="m79.57,16.21c-.01-.5.36-1.3,1.01-1.97,1.06-1.09,2.12-2.18,3.23-3.23,1.47-1.38,3.63-1.38,4.94-.03,1.28,1.32,1.29,3.44-.06,4.88-1.09,1.16-2.21,2.29-3.38,3.37-1.13,1.03-2.48,1.2-3.86.55-1.28-.6-1.87-1.69-1.88-3.56Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 2,
        title: "Calendario",
        url: "calendario",

        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 99.99">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m50.06,35.65h48.35c1.58,0,1.59,0,1.59,1.59,0,17.23,0,34.46,0,51.69,0,6.41-4.61,11.05-11,11.05-25.99,0-51.99,0-77.99,0C4.67,99.98.01,95.35.01,89.04,0,71.73,0,54.43,0,37.12c0-1.46,0-1.47,1.48-1.47h48.57m-25.01,17.99c-1.11,0-2.23-.03-3.34,0-2.16.07-3.73,1.58-3.71,3.51.03,1.85,1.62,3.39,3.68,3.44,2.22.05,4.45.05,6.67,0,2.1-.04,3.75-1.64,3.71-3.51-.03-1.85-1.63-3.37-3.68-3.44-1.11-.04-2.23,0-3.34,0m24.9,6.97c1.11,0,2.23.03,3.34,0,2.11-.06,3.75-1.61,3.74-3.49-.01-1.88-1.65-3.43-3.76-3.46-2.19-.04-4.38-.04-6.57,0-2.17.04-3.74,1.54-3.73,3.48,0,1.88,1.64,3.41,3.76,3.47,1.07.03,2.15,0,3.23,0m25.11-6.97c-1.15,0-2.3-.03-3.45,0-2.16.07-3.73,1.6-3.69,3.52.04,1.86,1.63,3.38,3.69,3.43,2.22.05,4.45.05,6.67,0,2.1-.04,3.74-1.66,3.7-3.53-.05-1.86-1.64-3.36-3.69-3.42-1.07-.04-2.15,0-3.23,0m-50.03,21.39c-1.11,0-2.23-.03-3.34,0-2.15.07-3.73,1.59-3.7,3.52.04,1.86,1.63,3.39,3.69,3.43,2.22.05,4.45.05,6.67,0,2.1-.04,3.75-1.65,3.7-3.52-.04-1.85-1.64-3.36-3.69-3.43-1.11-.03-2.22,0-3.34,0m25.01,6.97c1.07,0,2.15.03,3.23,0,2.11-.06,3.75-1.59,3.75-3.48,0-1.88-1.64-3.44-3.75-3.48-2.19-.04-4.38-.04-6.57,0-2.18.04-3.74,1.52-3.75,3.47,0,1.88,1.63,3.43,3.74,3.49,1.11.03,2.23,0,3.34,0m24.86,0c1.11,0,2.23.03,3.34,0,2.11-.06,3.75-1.61,3.74-3.49-.01-1.89-1.65-3.43-3.77-3.46-2.19-.04-4.38-.04-6.57,0-2.17.04-3.74,1.54-3.73,3.48,0,1.89,1.64,3.41,3.76,3.47,1.07.03,2.15,0,3.23,0"
                        />
                        <path
                            fill="white"
                            d="m49.92,28.68H1.79Q0,28.68,0,26.84C0,23.76-.01,20.68.02,17.6.06,11.93,4.81,7.18,10.48,7.14c4.38-.03,8.76-.04,13.15.01,1.1.01,1.46-.34,1.35-1.4-.07-.7-.02-1.41,0-2.12C25.02,1.48,26.52-.01,28.64,0c2.04.01,3.56,1.52,3.61,3.59.02.85.04,1.71,0,2.56-.03.72.24,1.01.97.98,1.11-.04,2.23,0,3.34,0h29.74q1.45,0,1.45-1.48c0-.63,0-1.26,0-1.89C67.79,1.5,69.26,0,71.42,0c2.12,0,3.59,1.56,3.62,3.81,0,.7.05,1.41,0,2.11-.08.92.26,1.23,1.19,1.22,4.23-.04,8.46-.02,12.7-.02,6.47.01,11.06,4.61,11.08,11.09,0,2.93,0,5.86,0,8.8q0,1.66-1.63,1.66h-48.45"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 3,
        title: "Crm",
        url: "crm",
        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 87.85">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m0,27.24c.32-.65.78-1.19,1.29-1.7,7.96-7.95,15.91-15.9,23.86-23.85,1.87-1.87,3.38-1.86,5.26.03,2.13,2.14,4.24,4.31,6.4,6.42.55.54.55.87,0,1.41-3.12,3.08-6.21,6.2-9.32,9.3-2.04,2.03-3.12,4.42-2.88,7.36.19,2.22,1.15,4.05,2.71,5.57,5.4,5.25,13.98,5.35,19.76.26,2.12-1.86,3.18-1.87,5.32-.02,5.02,4.33,11.26,4.98,17.11,1.75.56-.31.91-.26,1.35.18,5.69,5.71,11.41,11.4,17.09,17.11,1.89,1.9,1.95,4.57.23,6.39-1.78,1.88-4.43,1.97-6.44.07-2.36-2.23-4.62-4.57-6.92-6.87-.54-.54-1.12-.99-1.9-1.1-1.17-.17-2.3.4-2.86,1.44-.6,1.11-.46,2.35.49,3.32,2.32,2.36,4.68,4.69,7.02,7.05,1.2,1.21,1.77,2.65,1.39,4.35-.7,3.13-4.27,4.52-6.91,2.71-.43-.29-.81-.66-1.17-1.02-2.17-2.15-4.32-4.33-6.49-6.47-1.35-1.33-3.07-1.39-4.23-.19-1.14,1.18-1.04,2.83.27,4.16,2.24,2.26,4.51,4.5,6.75,6.77,1.85,1.88,2.07,4.35.61,6.17-1.76,2.18-4.65,2.36-6.78.36-1.19-1.11-2.34-2.26-3.45-3.45-1.92-2.06-4.23-3.33-7.06-3.51-.76-.05-.95-.37-1.02-1.08-.43-4.2-2.59-7.13-6.56-8.64-.72-.27-.65-.61-.43-1.18,1.73-4.46.69-8.79-2.98-11.76-2.97-2.4-6.36-2.81-9.91-1.47-.85.32-1.2.28-1.54-.65-2.44-6.47-10.97-8.42-16.09-3.72-1.56,1.43-1.59,1.44-2.23-.61-.9-2.89-2.43-5.37-4.6-7.47-1.24-1.2-2.42-2.45-3.64-3.66-.56-.55-1.07-1.14-1.46-1.82v-1.95Z"
                        />
                        <path
                            fill="white"
                            d="m55.02,8.7c1.46,0,2.93-.03,4.39,0,1.87.05,3.4-.6,4.7-1.95,1.69-1.76,3.44-3.46,5.17-5.18,2.11-2.1,3.57-2.11,5.65-.03,7.86,7.86,15.73,15.72,23.59,23.59,1.99,1.99,1.99,3.5,0,5.51-1.99,2.01-4,4-6,6-1.62,1.62-2.19,3.55-1.78,5.79.14.8.28,1.59.1,2.42-.16.74-.43.7-.87.24-.18-.19-.37-.37-.55-.55-6.02-6.02-12.06-12.04-18.06-18.08-.67-.67-1.05-.75-1.79-.06-3.54,3.3-8.91,3.75-13.22,1.18-.78-.47-1.55-.96-2.34-1.41-3.16-1.8-7.09-1.45-9.83.94-1.8,1.57-3.72,2.85-6.19,3-2.79.17-5.19-.76-7.09-2.79-1.08-1.16-.96-2.84.2-4.05,1.33-1.38,2.7-2.72,4.06-4.08,2.39-2.39,4.84-4.73,7.16-7.19,2.18-2.31,4.81-3.33,7.93-3.31,1.59.01,3.19,0,4.78,0Z"
                        />
                        <path
                            fill="white"
                            d="m37.7,56.36c-.03,1.4-.53,2.47-1.41,3.36-3.37,3.39-6.74,6.77-10.13,10.13-1.36,1.34-2.99,1.82-4.83,1.15-1.78-.66-2.76-2.01-2.94-3.89-.13-1.33.35-2.51,1.28-3.45,3.43-3.46,6.86-6.92,10.34-10.33,1.43-1.4,3.18-1.78,5.03-.93,1.72.79,2.56,2.22,2.66,3.97Z"
                        />
                        <path
                            fill="white"
                            d="m12.22,60.93c-1.51-.08-2.92-.89-3.73-2.56-.82-1.68-.61-3.34.59-4.74,1.18-1.38,2.52-2.62,3.8-3.91.91-.92,1.81-1.87,2.78-2.74,1.34-1.19,2.89-1.59,4.61-.94,1.7.65,2.67,1.92,2.91,3.71.18,1.33-.23,2.55-1.15,3.5-2.12,2.2-4.3,4.34-6.48,6.48-.82.81-1.86,1.17-3.33,1.2Z"
                        />
                        <path
                            fill="white"
                            d="m33.25,81.65c-1.82-.13-3.23-.88-4.04-2.52-.81-1.63-.73-3.29.45-4.66,2.16-2.51,4.52-4.85,7.01-7.03,1.74-1.53,4.34-1.27,5.93.36,1.58,1.61,1.82,4.13.32,5.86-2.17,2.5-4.57,4.79-7.01,7.04-.74.68-1.72.88-2.67.96Z"
                        />
                        <path
                            fill="white"
                            d="m43.25,83.28c0-.1,0-.19,0-.29.13-2.99,4.64-6.68,7.42-6.09,3.17.68,4.63,4.3,2.74,6.94-.87,1.22-1.95,2.28-3.16,3.16-1.44,1.05-3.03,1.09-4.61.32-1.56-.76-2.41-2.27-2.39-4.04Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
];

export const NavbarMobileHotel = () => {
    const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleNavigate = (title: string) => {
        navigate(`/dashboard-hotel/${title}`);
    };
    const hotel = useAppSelector((state) => state.landing_house.house);

    return (
        <>
            <div className="fixed bottom-0 w-full h-12 bg-[#029BFC] text-white sm:hidden ">
                <div className="flex items-center w-full my-auto mt-2 overflow-auto justify-evenly">
                    {TITLES.map((title) => (
                        <div
                            className="transition ease-in cursor-pointer"
                            key={title.id}
                            onClick={() => handleNavigate(title.url)}>
                            {title.icono}
                        </div>
                    ))}
                    <svg
                        onClick={() => setShowBurgerMenu((prev) => !prev)}
                        className="w-8 h-8 text-white cursor-pointer"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </div>
            </div>
            <BurguerMenuMobile
                setShowBurgerMenu={setShowBurgerMenu}
                showBurgerMenu={showBurgerMenu}
            />
        </>
    );
};
