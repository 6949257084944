import React, { useEffect, useState } from "react";
import MainNavbar from "../../MainNavbar/MainNavbar";
import { Footer } from "../../Footer";
import { useNavigate } from "react-router-dom";
import { ModalPlan } from "../../../designs/modalEdit/ModalPlan";

let DATA = {
    starter: {
        una_casa: 19,
        casa_adicional: 2,
    },

    standard: {
        una_casa: 39,
        casa_adicional: 3,
    },
    premium: {
        una_casa: 79,
        casa_adicional: 5,
    },
};

const Planes = () => {
    const [Plan, setPlan] = useState("Mensual");
    const [meses, setMeses] = useState<number>(1);
    const [numberHouse, setNumberHouse] = useState<number>(1);
    const [moneda, setMoneda] = useState("$");
    const [tipoPlan, setTipoPlan] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const [activeButton, setActiveButton] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handlePlan = (planString: string) => {
        setPlan(planString);
    };

    const handleButtonClick = (button: any) => {
        setActiveButton(button);
        if (button === "+") {
            setNumberHouse(Number(numberHouse) + 1);
        }

        if (button === "-") {
            if (numberHouse === 1) return;
            setNumberHouse(Number(numberHouse) - 1);
        }
    };

    const handleOutsideClick = () => {
        setActiveButton(null);
    };

    const handleChange = (e: any) => {
        const value = e.target.value;

        // Verifica que el valor sea un número y que sea al menos 1
        if (/^\d*$/.test(value) && (value === "" || parseInt(value) >= 1)) {
            setNumberHouse(value); // Solo actualiza si pasa las validaciones
        }
    };

    useEffect(() => {
        // Agrega el evento de clic al documento
        document.addEventListener("click", handleOutsideClick);
        return () => {
            // Limpia el evento al desmontar el componente
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const handleComprarPlan = () => {
        navigate(`/registro-corredor/${!Plan ? "1" : "2"}/${numberHouse}`);
    };

    const getStarter = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi Anual") {
            porcentaje = 0.7;
        }
        let price =
            numberHouse < 2
                ? DATA.starter.una_casa
                : Number(DATA.starter.una_casa) +
                  Number(DATA.starter.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (descuento) {
            price = Math.ceil(price * porcentaje);
        }

        return price * 12;
    };

    const getStandard = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi Anual") {
            porcentaje = 0.7;
        }
        let price =
            numberHouse < 2
                ? DATA.standard.una_casa
                : Number(DATA.standard.una_casa) +
                  Number(DATA.standard.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (descuento) {
            price = Math.ceil(price * porcentaje);
        }

        return price * 12;
    };

    const getPremium = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi Anual") {
            porcentaje = 0.7;
        }

        let price =
            numberHouse < 2
                ? DATA.premium.una_casa
                : Number(DATA.premium.una_casa) +
                  Number(DATA.premium.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (descuento) {
            price = Math.ceil(price * porcentaje);
        }

        return price * 12;
    };

    return (
        <div className="overflow-hidden bg-[#ffffff]">
            <MainNavbar />
            {showModal && tipoPlan && (
                <ModalPlan
                    setModalVisibleComodidades={setShowModal}
                    modalVisibleComodidades={showModal}
                    comprar={true}
                    unidades={numberHouse}
                    tipo_pago={
                        Plan === "Mensual"
                            ? 0
                            : Plan === "Semestral"
                            ? 1
                            : Plan === "Anual"
                            ? 2
                            : 3
                    }
                    plan={tipoPlan}
                />
            )}
            <div className="md818:mt-14 mt-8 pt-28 bg-[#ffffff] ">
                <div className="flex flex-col justify-center h-auto mb-5 md818:mb-10 lg:mx-32 ">
                    <div className="text-[#179BD8] text-center text-[30px] md818:text-[35px] lg:text-[45px] xl:text-[50px] 2xl:text-[55px] font-bold mb-5 ">
                        Nuestros Planes
                    </div>

                    <div className="text-[#179BD8] text-center text-[18px]  2xl:text-[20px] font-bold w-2/3 mx-auto mb-5 ">
                        Si tienes una propiedad, eres corredor o tienes un
                        hotel, estas herramientas son ideales para ti
                    </div>

                    <div className="text-[#179BD8] text-center text-[18px]  2xl:text-[20px] font-bold ">
                        ¡Elije el plan adecuado para ti!
                    </div>
                </div>

                <div className="flex items-center justify-between w-3/4 mx-auto 2xl:w-1/2 ">
                    <div className="flex flex-col items-center justify-center gap-4">
                        <div className="text-lg font-semibold">
                            Elije el número de unidades *
                        </div>
                        <div className="flex items-center justify-center gap-2 p-2 border-2 border-gray-300 rounded-full">
                            <div
                                className={`border-2 ${
                                    activeButton === "-"
                                        ? "border-gray-500 bg-gray-200"
                                        : "border-gray-100"
                                }  rounded-full h-10 w-10 flex justify-center items-center cursor-pointer`}
                                onClick={(e) => {
                                    e.stopPropagation(); // Evita que el clic en este botón cierre el estado activo
                                    handleButtonClick("-");
                                    // Aquí puedes manejar la lógica para restar
                                }}
                            >
                                <span className="mb-1 text-2xl font-semibold leading-none">
                                    -
                                </span>
                            </div>
                            <div className="flex items-center justify-center h-10 border-2 border-gray-300 rounded-md">
                                <input
                                    type="text"
                                    className="w-16 text-xl text-center "
                                    value={numberHouse}
                                    onChange={handleChange}
                                />
                            </div>
                            <div
                                className={`border-2 ${
                                    activeButton === "+"
                                        ? "border-gray-500 bg-gray-200"
                                        : "border-gray-100"
                                }  rounded-full h-10 w-10 flex justify-center items-center cursor-pointer`}
                                onClick={(e) => {
                                    e.stopPropagation(); // Evita que el clic en este botón cierre el estado activo
                                    handleButtonClick("+");
                                    // Aquí puedes manejar la lógica para restar
                                }}
                            >
                                <span className="mb-1 text-2xl font-semibold leading-none">
                                    +
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center gap-4">
                        <div className="text-lg font-semibold">
                            Elije como quieres pagar
                        </div>
                        <div className="flex items-center justify-center px-1 py-1 border-2 w-96 rounded-3xl">
                            <div
                                className={`${
                                    Plan === "Bi Anual"
                                        ? "bg-[#0E1928] text-[#C0D345]"
                                        : " text-[#0E1928]"
                                }   text-center  rounded-l-3xl cursor-pointer w-1/2 h-full `}
                                onClick={() => {
                                    handlePlan("Bi Anual");
                                    setMeses(24);
                                }}
                            >
                                <p className="text-lg ">Bi Anual</p>
                                <p
                                    className={`text-[8px] w-full  mb-1 ${
                                        Plan === "Bi Anual"
                                            ? "text-white"
                                            : " text-[#0E1928]"
                                    }`}
                                >
                                    - 30%
                                </p>
                            </div>
                            <div
                                className={`${
                                    Plan === "Anual"
                                        ? "bg-[#0E1928] text-[#C0D345]"
                                        : " text-[#0E1928]"
                                }   text-center  cursor-pointer w-1/2 h-full `}
                                onClick={() => {
                                    handlePlan("Anual");
                                    setMeses(12);
                                }}
                            >
                                <p className="text-lg ">Anual</p>
                                <p
                                    className={`text-[8px] w-full  mb-1 ${
                                        Plan === "Anual"
                                            ? "text-white"
                                            : " text-[#0E1928]"
                                    }`}
                                >
                                    - 20%
                                </p>
                            </div>

                            <div
                                className={`${
                                    Plan === "Semestral"
                                        ? "bg-[#0E1928] text-[#C0D345]"
                                        : " text-[#0E1928]"
                                }   text-center  cursor-pointer w-1/2 h-full `}
                                onClick={() => {
                                    handlePlan("Semestral");
                                    setMeses(6);
                                }}
                            >
                                <p className="text-lg ">Semestral</p>
                                <p
                                    className={`text-[8px] w-full  mb-1 ${
                                        Plan === "Semestral"
                                            ? "text-white"
                                            : " text-[#0E1928]"
                                    }`}
                                >
                                    - 10%
                                </p>
                            </div>
                            <div
                                className={` ${
                                    Plan === "Mensual"
                                        ? "bg-[#0E1928] text-[#C0D345]"
                                        : " text-[#0E1928]"
                                }   px-4 text-lg   rounded-r-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                                onClick={() => {
                                    handlePlan("Mensual");
                                    setMeses(1);
                                }}
                            >
                                <p className="text-lg ">Mensual</p>
                                <p
                                    className={`text-[8px] w-full  mb-1 ${
                                        Plan === "Mensual"
                                            ? "text-white"
                                            : " text-[#0E1928]"
                                    }`}
                                ></p>
                            </div>
                        </div>
                    </div>

                    <div className="w-60"></div>
                </div>

                <div className="flex flex-col items-center justify-center w-full gap-6 px-5 mx-auto mt-5 lg:flex-row ">
                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                        <div
                            className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                        >
                            Starter
                        </div>

                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                    {Plan === "Mensual" ? (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl font-bold">
                                                {numberHouse < 2
                                                    ? DATA.starter.una_casa
                                                    : Number(
                                                          DATA.starter.una_casa
                                                      ) +
                                                      Number(
                                                          DATA.starter
                                                              .casa_adicional
                                                      ) *
                                                          (Number(numberHouse) -
                                                              1)}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl text-[#179BD8] font-bold">
                                                {" "}
                                                {getStarter({
                                                    tipo: Plan,
                                                    descuento: true,
                                                }) / 12}
                                            </p>
                                        </div>
                                    )}

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                    {moneda}
                                    {Plan === "Mensual"
                                        ? getStarter({
                                              tipo: Plan,
                                          })
                                        : getStarter({
                                              tipo: Plan,
                                              descuento: true,
                                          })}{" "}
                                    al año.
                                    {Plan === "Semestral" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 10%
                                        </div>
                                    )}
                                    {Plan === "Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 20%
                                        </div>
                                    )}
                                    {Plan === "Bi Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 30%
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                                >
                                    <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                        <li className="mb-1">
                                            Landing personalizada
                                        </li>
                                        <li className="mb-1">
                                            Dominio para tu página
                                        </li>
                                        <li className="mb-1">
                                            Reseñas de clientes
                                        </li>
                                        <li className="mb-1">
                                            Historial de reservas
                                        </li>
                                        <li className="mb-1">
                                            Pago por transferencia
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                onClick={() => (
                                    setTipoPlan(1), setShowModal(true)
                                )}
                                className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                            >
                                <p className="w-full text-center">COMPRAR</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928] text-white border-solid px-5   h-auto w-[330px] mb-4 ">
                        <div
                            className={` text-lg md:text-xl xl:text-2xl flex items-center justify-between w-full  ${"mt-5"} `}
                        >
                            <p>Estándar</p>{" "}
                            <p className="text-xs text-[#C0D345] bg-[#C0D345]/10 rounded-3xl px-3 py-1">
                                El más seleccionado
                            </p>
                        </div>

                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end mt-2 text-2xl md:text-3xl xl:text-5xl">
                                    {Plan === "Mensual" ? (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl font-bold text-[#C0D345]">
                                                {" "}
                                                {numberHouse < 2
                                                    ? DATA.standard.una_casa
                                                    : Number(
                                                          DATA.standard.una_casa
                                                      ) +
                                                      Number(
                                                          DATA.standard
                                                              .casa_adicional
                                                      ) *
                                                          (Number(numberHouse) -
                                                              1)}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-5xl text-[#C0D345] font-bold">
                                                {getStandard({
                                                    tipo: Plan,
                                                    descuento: true,
                                                }) / 12}
                                            </p>
                                        </div>
                                    )}

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    {moneda}
                                    {Plan === "Mensual"
                                        ? getStandard({
                                              tipo: Plan,
                                          })
                                        : getStandard({
                                              tipo: Plan,
                                              descuento: true,
                                          })}{" "}
                                    al año.
                                    {Plan === "Semestral" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 10%
                                        </div>
                                    )}
                                    {Plan === "Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 20%
                                        </div>
                                    )}
                                    {Plan === "Bi Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 10%
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col ">
                                <div className={` text-sm  mt-4 mb-0`}>
                                    <ul className="ml-6 text-xs list-disc list-outside marker:text-white md:text-base">
                                        <li className="mb-1">
                                            Módulo de CRM (Casa, whatsapp)
                                        </li>
                                        <li className="mb-1">
                                            Modificación precios / noche casas
                                        </li>
                                        <li className="mb-1">
                                            Creación de cupones
                                        </li>
                                        <li className="mb-1">
                                            Chat y gestión de clientes
                                        </li>
                                        <li className="mb-1">
                                            Resumen de estadísticas
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                onClick={() => (
                                    setTipoPlan(2), setShowModal(true)
                                )}
                                className="bg-[#C0D345] py-1 px-8 my-8 text-base md:text-xl text-[#0E1928] font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                            >
                                <p className="w-full text-center">COMPRAR</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                        <div
                            className={`text-[#0E1928] text-lg md:text-xl xl:text-2xl  ${"mt-5"} `}
                        >
                            Premium
                        </div>

                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                    {Plan === "Mensual" ? (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl font-bold">
                                                {" "}
                                                {numberHouse < 2
                                                    ? DATA.premium.una_casa
                                                    : Number(
                                                          DATA.premium.una_casa
                                                      ) +
                                                      Number(
                                                          DATA.premium
                                                              .casa_adicional
                                                      ) *
                                                          (Number(numberHouse) -
                                                              1)}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl text-[#179BD8] font-bold">
                                                {" "}
                                                {getPremium({
                                                    tipo: Plan,
                                                    descuento: true,
                                                }) / 12}
                                            </p>
                                        </div>
                                    )}

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                    {moneda}
                                    {Plan === "Mensual"
                                        ? getPremium({
                                              tipo: Plan,
                                          })
                                        : getPremium({
                                              tipo: Plan,
                                              descuento: true,
                                          })}{" "}
                                    al año.
                                    {Plan === "Semestral" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 10%
                                        </div>
                                    )}
                                    {Plan === "Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 20%
                                        </div>
                                    )}
                                    {Plan === "Bi Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 10%
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col ">
                                <div
                                    className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                                >
                                    <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                        <li className="mb-1">
                                            Chatbot interno
                                        </li>
                                        <li className="mb-1">
                                            Integración google calendar
                                        </li>
                                        <li className="mb-1">
                                            Plantillas para contratos por
                                            Alquiler
                                        </li>
                                        <li className="mb-1">
                                            Chatroom Whatsapp
                                        </li>
                                        <li className="mb-1">
                                            Integración Airbnb / Booking -
                                            Calendario
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                onClick={() => (
                                    setTipoPlan(3), setShowModal(true)
                                )}
                                className="bg-[#179BD8] py-1 px-8 my-8 text-base md:text-xl text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-2/3 text-center mx-auto"
                            >
                                <p className="w-full text-center">COMPRAR</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-start justify-center w-3/4 mx-auto text-sm 2xl:w-1/2">
                    <div>* Corredores unidades refiere a casas</div>
                    <div>* Hoteles unidades refiere a habitaciones</div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Planes;
