import React, { useState, useEffect } from "react";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";

import { MicuentaBancaria } from "../MicuentaBancaria";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../../dashboardHotel/NavbarMobileHotel";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const MediosDePago = () => {
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const casa = useAppSelector((state) => state.landing_house);
    console.log(casa);
    const dispatch = useAppDispatch();

    const [toggleButton, setToggleButton] = useState<boolean | null>(null);
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        id_casa: "",
        publica: "",
        privada: "",
    });
    const getHouseFromTenant = async () => {
        try {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/dominio`,
            });
            console.log(data);
            setToggleButton(data.is_mercado_pago === "Si" ? true : false);
            setUserInfo({
                id_casa: data.id,
                publica: data.llave_publica_mp ? data.llave_publica_mp : "",
                privada: data.llave_privada_mp ? data.llave_privada_mp : "",
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getHouseFromTenant();
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Cuenta para Transferir">
                    <div className="w-full">
                        <div>
                            <MicuentaBancaria />
                        </div>
                    </div>
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Cuenta para Transferir">
                    <div className="w-full">
                        <div>
                            <MicuentaBancaria />
                        </div>
                    </div>
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : tipo_usuario === 8 ? (
                <NavbarHotel titulo="Cuenta para Transferir">
                    <div className="w-full">
                        <div>
                            <MicuentaBancaria />
                        </div>
                    </div>
                    <NavbarMobileHotel />
                </NavbarHotel>
            ) : null}
            {/*  <Navbar titulo="Cuenta para Transferir">
                <div className="w-full">
                    <div>
                        <MicuentaBancaria />
                    </div>
                </div>
            </Navbar>
            <NavbarMobile /> */}
        </div>
    );
};
