import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";
import { useAppSelector } from "../../hooks/hooks";
import { Navbar } from "../dashboardPropietarios/Navbar";
import BotonInformacion from "../../components/helpers/BotonInformacion";
import { NavbarMobile } from "../dashboardPropietarios/NavbarMobile";
import pictureDesign1 from "../../img/onboarding/desingnew1.webp";
import pictureDesign2 from "../../img/onboarding/desingnew2.webp";
import pictureDesign3 from "../../img/onboarding/desingnew3.webp";
import pictureDesign4 from "../../img/onboarding/desingnew4.webp";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { NavbarBroker } from "../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../dashboardHotel/NavbarMobileHotel";

const TEMAS = [
    {
        id: 1,
        title: "Plantilla 1",
        image: pictureDesign1,
        type: "Básico",
    },
    {
        id: 2,
        title: "Plantilla 2",
        image: pictureDesign2,
        type: "Básico",
    },
    {
        id: 3,
        title: "Plantilla 3",
        image: pictureDesign3,
        type: "Stándar",
    },
    {
        id: 4,
        title: "Plantilla 4",
        image: pictureDesign4,
        type: "Stándar",
    },
];

export const TemaLanding = () => {
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const [temaSelected, setTemaSelected] = useState<number>(0);
    const navigate = useNavigate();

    const casa = useAppSelector((state) => state.landing_house);

    console.log(casa.house.plan);
    useEffect(() => {
        setTemaSelected(Number(casa.house.tema));
    }, [casa.house.tema]);

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    const [saving, setSaving] = useState<boolean>(false);

    const handleSubmit = async () => {
        setSaving(true);
        const data = await fetchAxiosToken({
            url: `/casa/putTema/${casa.house.id}`,
            method: "put",
            body: {
                tema: temaSelected,
            },
        });

        if (data.message === "actualizacion exitoso") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Tema cambiado con exito",
                icon: "success",
                confirmButtonText: "Ok",
            });
            const dataCasa = await fetchAxiosNoToken({
                url: `/casa/getOne/id/${casa.house.id}`,
            });

            dispatchDominio();
            setSaving(false);
        }
    };

    const render = () => {
        return (
            <div>
                <div className="w-full mt-8">
                    <ButtonRegresar
                        // url={`/dashboard/onboarding/alojamiento`}
                        className="ml-6 "
                        arrowColor="black"
                        textColor="black"
                    />
                    <div className="flex flex-wrap justify-center gap-8 mt-4">
                        {TEMAS.map((tema) => (
                            <div
                                className={`  rounded-lg flex flex-col min-w-[275px]  relative`}
                                key={tema.id}
                            >
                                <img
                                    onClick={() =>
                                        /* (casa.house.plan > 1 ||
                                            tema.type === "Básico") && */
                                        setTemaSelected(tema.id)
                                    }
                                    className={`2xl:h-96 h-72 cursor-pointer object-cover object-left-top ${
                                        temaSelected === tema.id
                                            ? "scale-110 border-4 border-[#029BFC] rounded-lg"
                                            : ""
                                    }  ${
                                        ""
                                        /* casa.house.plan < 2 &&
                                        tema.type === "Stándar" &&
                                        "opacity-30" */
                                    } `}
                                    src={tema.image}
                                    alt={tema.title}
                                />

                                <div className="flex items-center justify-around mt-6">
                                    <p
                                        className={`font-bold text-[#4D4D4D] text-sm`}
                                    >
                                        {tema.title} - {tema.type}
                                    </p>

                                    <a
                                        target={"_blank"}
                                        href={`/dashboard/onboarding/temaEjemplo/${tema.id}`}
                                        className={` text-[#4D4D4D] text-sm bg-[#E6E6E6] px-4 py-1 rounded-md cursor-pointer hover:scale-105`}
                                        rel="noreferrer"
                                    >
                                        Vista Previa
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex justify-center mt-3">
                        <button
                            disabled={saving}
                            onClick={() => handleSubmit()}
                            className="py-3 px-6 my-6 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm"
                        >
                            Aplicar plantilla
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Temas para mi landing">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Temas para mi landing">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : tipo_usuario === 8 ? (
        <NavbarHotel titulo="Temas para mi landing">
            {render()}
            <NavbarMobileHotel />
        </NavbarHotel>
    ) : null;
};
