import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../components/helpers/Spinner";

import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { Navbar } from "../Navbar";
import { NavbarCliente } from "../../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../../dashboardClientes/NavbarMobileCliente";
import { NavbarMobile } from "../NavbarMobile";
import { Paginado } from "../../../components/Paginado";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
import StarsRating from "../reservas/StarsRating";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { set_landing_house } from "../../../redux/reducers/landingReducer";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../../dashboardHotel/NavbarMobileHotel";
interface Propietario {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface casa {
    banner: string;
    id: number;
    nombre: string;
    Propietario: Propietario;
}

interface getComfort {
    pagina: number;
    habilitado: boolean;
    query: string;
}

export interface resenas {
    id: number;
    id_pedidos: number;
    id_pedidos_habitaciones: number;
    id_casa: number;
    puntuacion: string;
    resena: string;
    cliente_resena: any;
    avatar_resena: any;
    fecha_registro: string;
    habilitado: string;
    pedidos_habitacione: PedidosHabitacione;
    pedido: Pedido;
}

export interface PedidosHabitacione {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    Cliente: Cliente;
}

export interface Cliente {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export interface Pedido {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    Cliente: Cliente;
}

export const Resenas = () => {
    const { headers } = useHeaders();
    const [resenas, setResenas] = useState<resenas[]>([]);
    const [resenasTotal, setResenasTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);
    const { id_casa } = useParams();
    const [input, setInput] = useState<string>("");
    const debounceInput = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [cargando, setCargando] = useState(true);
    const dispatch = useDispatch();
    const [toggleButton, setToggleButton] = useState(true);
    const navigate = useNavigate();
    const RESERVAS_PER_PAGE = 20;
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const { plan, id: id_casa_redux } = useAppSelector(
        (state) => state.landing_house.house
    );
    const corredor = useAppSelector((state) => state.corredor);

    useEffect(() => {
        getCasa();
    }, [id_casa]);

    const getCasa = async () => {
        if (id_casa) {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/id/${id_casa}`,
            });

            dispatch(set_landing_house(data));
        }
    };

    const getComforts = async ({ pagina, habilitado, query }: getComfort) => {
        setCargando(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/${
                    tipo_usuario === 8 ? "resenas_hoteles" : "resenas"
                }/getAllbyOwner/${pagina}/${habilitado ? "Si" : "No"}/${
                    toggleButton ? "cliente" : "propietario"
                }?cliente=${query}&id_casa=${id_casa_redux}`,
                { headers }
            );
            console.log(data);
            setResenas(data.rows);
            setResenasTotal(data.count);
            setCargando(false);
        } catch (error) {
            console.log(error);
            setCargando(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);

        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            getComforts({
                pagina: pageNumber,
                habilitado: enabled,
                query: "",
            });

            return;
        }
        debounceInput.current = setTimeout(() => {
            getComforts({
                pagina: pageNumber,
                habilitado: enabled,
                query: e.target.value,
            });
        }, 1000);
    };

    useEffect(() => {
        getComforts({ pagina: pageNumber, habilitado: enabled, query: "" });
    }, [enabled, create, toggleButton, id_casa_redux]);

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará la reseña a habilitados!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.put(
                        `${process.env.REACT_APP_PROXY}/${
                            tipo_usuario === 8 ? "resenas_hoteles" : "resenas"
                        }/putState/${id}`,
                        {
                            habilitado: "Si",
                        },
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "reseña restaurada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDelete = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará la reseña a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.put(
                        `${process.env.REACT_APP_PROXY}/${
                            tipo_usuario === 8 ? "resenas_hoteles" : "resenas"
                        }/putState/${id}`,
                        {
                            habilitado: "No",
                        },
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "success",
                        title: "Reseña elimidada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDetalles = async (id: number) => {
        console.log(id);

        if (tipo_usuario === 8) {
            navigate(`/dashboard-hotel/reviews/${id}`, { replace: false });
        } else {
            navigate(`/dashboard/reviews/${id}`, { replace: false });
        }
    };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getComforts({ pagina: pageNumber, habilitado: enabled, query: "" });
    };
    const avatar = (resena: resenas) => {
        if (resena.avatar_resena) {
            return resena.avatar_resena;
        } else {
            return resena.pedido?.Cliente.avatar;
        }
    };

    const handleReviewPropietario = () => {
        if (tipo_usuario === 3 || tipo_usuario === 4) {
            navigate(`/dashboard/reviews-propietario/0`, { replace: false });
        }

        if (tipo_usuario === 6) {
            navigate(`/dashboard-corredor/reviews-propietario/0`, {
                replace: false,
            });
        }

        if (tipo_usuario === 8) {
            navigate(`/dashboard-hotel/reviews-propietario/0`, {
                replace: false,
            });
        }
    };

    const render = () => {
        return (
            <div className="w-full mt-12">
                <div className="flex items-center justify-between w-full px-6 mx-auto "></div>
                <div className="flex flex-col items-center justify-between px-2 mx-auto xs:pr-6 md818:flex-row md818:justify-start sm:px-6">
                    <div className="flex items-center">
                        {tipo_usuario === 3 ||
                        tipo_usuario === 4 ||
                        tipo_usuario === 6 ||
                        tipo_usuario === 8 ? (
                            <div className="flex  p-1 mr-2 border-2 border-[#CCCCCC] rounded-lg appearance-none w-40 xs:w-52 lg:w-72 left-2 text-stone-900">
                                <button className="flex items-center justify-center px-2">
                                    <svg
                                        className="w-6 h-6 text-[#808080]"
                                        fill="currentColor"
                                        viewBox="0 0 24 24">
                                        <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                                    </svg>
                                </button>{" "}
                                <input
                                    onBlur={() => setInput("")}
                                    value={input}
                                    type="text"
                                    onChange={handleInputChange}
                                    className="w-full py-2 bg-transparent outline-none appearance-none"
                                    placeholder="Buscar"
                                />
                            </div>
                        ) : null}
                        <div
                            onMouseOut={() => setModalEnabled(false)}
                            onMouseOver={() => setModalEnabled(true)}
                            className="relative">
                            <button className="w-[51px] h-[51px] flex items-center justify-center px-1 py-1  border-2 border-[#CCCCCC] rounded-lg group hover:bg-[#CCCCCC] hover:text-white">
                                <svg
                                    className="w-6 h-6 text-[#808080] group-hover:text-white"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <circle cx="14" cy="6" r="2" />{" "}
                                    <line x1="4" y1="6" x2="12" y2="6" />{" "}
                                    <line x1="16" y1="6" x2="20" y2="6" />{" "}
                                    <circle cx="8" cy="12" r="2" />{" "}
                                    <line x1="4" y1="12" x2="6" y2="12" />{" "}
                                    <line x1="10" y1="12" x2="20" y2="12" />{" "}
                                    <circle cx="17" cy="18" r="2" />{" "}
                                    <line x1="4" y1="18" x2="15" y2="18" />{" "}
                                    <line x1="19" y1="18" x2="20" y2="18" />
                                </svg>
                            </button>
                            <div
                                className={
                                    modalEnabled
                                        ? "absolute top-8 xs:top-[50px]  w-36 z-10  pt-2 right-0 "
                                        : "hidden"
                                }>
                                <div
                                    className={
                                        modalEnabled
                                            ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                            : "hidden"
                                    }>
                                    <div
                                        onClick={() => (
                                            setModalEnabled(false),
                                            setenabled(!enabled)
                                        )}
                                        className=" w-32 h-11 mx-auto cursor-pointer rounded-lg hover:bg-[#053465]/30 hover:text-white flex items-center justify-start pl-4 ">
                                        {!enabled
                                            ? `Habilitados`
                                            : `Deshabilitadas`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {(plan > 1 || corredor.plan > 1) &&
                                !toggleButton && (
                                    <div className="relative z-20 flex items-center justify-center ">
                                        <button
                                            onClick={() =>
                                                handleReviewPropietario()
                                            }
                                            className="flex items-center gap-1 p-2  ml-2 border-2 rounded-lg border-[#CCCCCC] text-[#808080] hover:scale-105 h-[51px]">
                                            <svg
                                                className="w-6 h-6 text-[#808080] group-hover:text-white"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round">
                                                {" "}
                                                <line
                                                    x1="12"
                                                    y1="5"
                                                    x2="12"
                                                    y2="19"
                                                />{" "}
                                                <line
                                                    x1="5"
                                                    y1="12"
                                                    x2="19"
                                                    y2="12"
                                                />
                                            </svg>
                                            <span>Crear reseña</span>
                                        </button>
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className="flex justify-center w-full mt-4 md818:mt-0 md818:justify-end hid">
                        {plan > 1 || corredor.plan > 1 ? (
                            <div className="flex items-center gap-2 ">
                                <p>Reseñas de :</p>
                                <p
                                    onClick={() => setToggleButton(false)}
                                    className={`${
                                        !toggleButton
                                            ? " bg-[#029BFC]"
                                            : "border-2 border-[#029BFC] text-[#029BFC]"
                                    }  px-4 py-2 text-white rounded-lg hover:scale-105 cursor-pointer`}>
                                    <span className="mx-2 text-sm font-medium xs:text-base">
                                        Propietario
                                    </span>
                                </p>

                                <p
                                    onClick={() => setToggleButton(true)}
                                    className={`${
                                        toggleButton
                                            ? " bg-[#029BFC]"
                                            : "border-2 border-[#029BFC] text-[#029BFC]"
                                    } px-4 py-2 text-white rounded-lg hover:scale-105 cursor-pointer`}>
                                    <span className="mx-2 text-sm font-medium xs:text-base">
                                        Clientes
                                    </span>
                                </p>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
                {cargando ? (
                    <div className="flex items-center justify-center w-full mt-10">
                        <Spinner width={20} />
                    </div>
                ) : !cargando && resenas.length === 0 ? (
                    <div className="flex items-center justify-center w-full mt-10">
                        <p className="text-xl font-semibold">
                            Aun no tienes reseñas
                        </p>
                    </div>
                ) : (
                    <section className="flex justify-center items-center lg:py-[10px] ">
                        <div className="w-full px-11">
                            <div className="flex flex-wrap -mx-8">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {true ? (
                                            <div className="w-full ">
                                                <table className="w-full mt-8 overflow-x-auto 2xl:mt-14">
                                                    <thead className="text-left border-b-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                        <tr>
                                                            <th className="w-full min-w-[10rem] md:w-4/12 pb-4  ">
                                                                {toggleButton
                                                                    ? "Cliente"
                                                                    : " Por el propietario"}
                                                            </th>
                                                            <th className="w-full min-w-[10rem] md:w-2/12 hidden lg:table-cell pb-4 text-center  ">
                                                                Fecha
                                                            </th>

                                                            <th className="w-full min-w-[10rem] md:w-2/12 hidden xs:table-cell pb-4 text-center ">
                                                                Puntuacion
                                                            </th>
                                                            <th className="w-1/12 text-right min-w-[8rem] pr-10 pb-4">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <div className="my-2 2xl:my-4"></div>
                                                    <tbody className="text-sm 2xl:text-base">
                                                        {resenas?.map(
                                                            (reserva) => (
                                                                <tr
                                                                    key={
                                                                        reserva.id
                                                                    }
                                                                    className="w-full cursor-pointer hover:bg-gray-200"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        handleDetalles(
                                                                            reserva?.id
                                                                        );
                                                                    }}>
                                                                    <td className="border-r-[3px] border-[#CCCCCC]">
                                                                        <div className="flex items-center ">
                                                                            <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                                                    avatar(
                                                                                        reserva
                                                                                    )
                                                                                }
                                                                                alt="icono"
                                                                                className="object-cover w-10 h-10 rounded-full"
                                                                            />
                                                                            <h1 className="mx-3 text-[#3C3C3C] ">
                                                                                {reserva.id_pedidos_habitaciones >
                                                                                0
                                                                                    ? `${reserva.pedidos_habitacione?.Cliente.nombre} ${reserva.pedidos_habitacione?.Cliente.apellido}`
                                                                                    : reserva.id_pedidos >
                                                                                      0
                                                                                    ? `${reserva.pedido?.Cliente.nombre} ${reserva.pedido?.Cliente.apellido}`
                                                                                    : reserva.cliente_resena}
                                                                            </h1>
                                                                        </div>
                                                                    </td>
                                                                    <td className="hidden text-center lg:table-cell border-r-[3px] border-[#CCCCCC]">
                                                                        <ShowDateToUser
                                                                            date={
                                                                                reserva
                                                                                    .pedido
                                                                                    ?.fecha_inicio ||
                                                                                reserva.fecha_registro
                                                                            }
                                                                        />
                                                                    </td>

                                                                    <td className="hidden text-center xs:table-cell border-r-[3px] border-[#CCCCCC] px-4">
                                                                        {
                                                                            <StarsRating
                                                                                starsQuantity={
                                                                                    5
                                                                                }
                                                                                value={Number(
                                                                                    reserva.puntuacion
                                                                                )}
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        className={
                                                                            enabled
                                                                                ? " md:flex justify-end 2xl:py-5 py-3 px-2"
                                                                                : " md:flex  justify-center pl-7 md:justify-end 2xl:py-5 py-3 md:pr-10 "
                                                                        }>
                                                                        {enabled ? (
                                                                            <div className="flex">
                                                                                <button
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        handleDetalles(
                                                                                            reserva?.id
                                                                                        );
                                                                                    }}
                                                                                    title="Ver detalles"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mr-3 border rounded bg-[#029BFC] text-blue-blueFrom border-[#029BFC]   group hover:bg-blue-blueFrom hover:white">
                                                                                    <svg
                                                                                        className="w-5 h-5 "
                                                                                        id="Capa_2"
                                                                                        data-name="Capa 2"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 87.07 100">
                                                                                        <g id="Testimonios">
                                                                                            <g>
                                                                                                <path
                                                                                                    fill="white"
                                                                                                    d="m.04,54.11c0-7.86-.1-15.72.03-23.57.14-8.34,3.89-14.75,10.99-19.13,3.44-2.12,6.37-.48,6.58,3.58.32,6.3,4.67,11.61,10.77,13.11,1.1.27,2.21.4,3.35.4,7.82-.01,15.64.01,23.46-.01,7.7-.02,13.8-5.86,14.2-13.53.15-2.97,1.71-4.6,4.24-4.46.68.04,1.29.29,1.86.63,7.26,4.3,11.34,10.66,11.44,19.09.18,16.02.1,32.05.03,48.08-.05,10.94-9.36,20.72-20.26,21.54-1.59.12-3.18.15-4.78.15-12.84,0-25.67-.01-38.51,0-6.84.01-12.74-2.23-17.44-7.28C2.14,88.56.1,83.6.06,77.92c-.07-7.94-.02-15.87-.02-23.81Zm60.99-2.84c.04-2.35-1.44-3.81-3.63-3.79-1.19.01-2.04.62-2.83,1.41-5.24,5.26-10.5,10.5-15.75,15.75q-1.19,1.19-2.41-.04c-1.21-1.21-2.39-2.45-3.65-3.61-1.6-1.48-3.72-1.48-5.14-.08-1.42,1.41-1.48,3.57.02,5.13,2.4,2.5,4.84,4.94,7.34,7.34,1.66,1.59,3.7,1.56,5.31-.05,6.56-6.52,13.09-13.07,19.63-19.62.71-.71,1.17-1.55,1.11-2.45Z"
                                                                                                />
                                                                                                <path
                                                                                                    fill="white"
                                                                                                    d="m43.45,23.72c-3.81,0-7.62,0-11.44,0-5.31-.01-9.29-3.6-9.72-8.9-.17-2.09-.24-4.21.07-6.29C23.13,3.47,26.84.13,31.95.07c7.7-.09,15.41-.09,23.11,0,5.61.06,9.46,3.99,9.69,9.71.07,1.82.08,3.65-.11,5.48-.51,4.8-4.43,8.4-9.28,8.46-.08,0-.16,0-.23,0-3.89,0-7.78,0-11.67,0Z"
                                                                                                />
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                                {!toggleButton && (
                                                                                    <button
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation();
                                                                                            navigate(
                                                                                                `/dashboard${
                                                                                                    tipo_usuario ===
                                                                                                    8
                                                                                                        ? "-hotel"
                                                                                                        : ""
                                                                                                }/reviews-propietario/${
                                                                                                    reserva.id
                                                                                                }`,
                                                                                                {
                                                                                                    replace:
                                                                                                        false,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        title="Editar"
                                                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded bg-[#029BFC] text-blue-blueFrom border-[#029BFC] group hover:bg-blue-blueFrom hover:text-white">
                                                                                        <svg
                                                                                            className="w-5 h-5"
                                                                                            id="Capa_2"
                                                                                            data-name="Capa 2"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 100 99.97">
                                                                                            <g id="Testimonios">
                                                                                                <g>
                                                                                                    <path
                                                                                                        fill="white"
                                                                                                        d="m.01,95.86c1.03-2.98,1.79-6.04,2.66-9.06,1.65-5.68,3.25-11.36,4.88-17.05.35-1.21.41-1.22,1.3-.33,7.26,7.26,14.53,14.52,21.79,21.79.86.86.85.88-.32,1.21-8.47,2.41-16.93,4.82-25.39,7.23-.28.08-.54.21-.82.31-1.24,0-2.47-.02-3.71.01-.34,0-.41-.06-.4-.4.03-1.23.01-2.47.01-3.71Z"
                                                                                                    />
                                                                                                    <path
                                                                                                        fill="white"
                                                                                                        d="m36.11,88.77c-.31-.02-.47-.25-.65-.43-7.95-7.95-15.9-15.91-23.87-23.84-.72-.72-.2-1.05.23-1.48,8.48-8.48,16.96-16.96,25.44-25.44,7.95-7.95,15.9-15.9,23.86-23.85q.95-.95,1.88-.02c7.77,7.77,15.53,15.55,23.32,23.29.73.73.64,1.1-.03,1.77-16.45,16.42-32.87,32.86-49.3,49.29-.27.27-.49.61-.89.71Z"
                                                                                                    />
                                                                                                    <path
                                                                                                        fill="white"
                                                                                                        d="m78.52,0c2.53-.02,4.61.81,6.3,2.48,4.23,4.17,8.46,8.36,12.61,12.61,3.32,3.4,3.45,8.57.29,12.11-1.94,2.18-4.09,4.17-6.13,6.27-.61.63-.95.17-1.34-.22-4.55-4.55-9.1-9.1-13.65-13.65-3.29-3.29-6.56-6.58-9.87-9.84-.63-.62-.66-1.02,0-1.64,1.85-1.78,3.63-3.62,5.46-5.43C73.97.92,76.1-.04,78.52,0Z"
                                                                                                    />
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </button>
                                                                                )}

                                                                                <button
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded bg-[#029BFC] text-blue-blueFrom border-[#029BFC] group hover:bg-blue-blueFrom hover:text-white"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        handleDelete(
                                                                                            reserva.id
                                                                                        );
                                                                                    }}
                                                                                    title="Eliminar">
                                                                                    <svg
                                                                                        className="w-5 h-5 "
                                                                                        id="Capa_2"
                                                                                        data-name="Capa 2"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 91.81 100">
                                                                                        <g id="Testimonios">
                                                                                            <path
                                                                                                fill="white"
                                                                                                d="m69.32,100H22.49c-.28-.09-.55-.19-.83-.27-5.22-1.49-8.55-4.86-9.37-10.22-.74-4.81-.65-9.72-.96-14.58-.26-3.99-.41-7.98-.63-11.97-.43-7.56-.88-15.11-1.32-22.67-.27-4.74-.54-9.47-.78-14.21-.04-.73-.35-.96-1.03-.94-1.2.03-2.41.04-3.61,0-2.56-.11-4.44-2.66-3.83-5.15.52-2.1,2.14-3.35,4.44-3.36,5.04-.01,10.08-.02,15.12,0,.89,0,1.24-.28,1.19-1.18-.06-1-.02-2.02-.01-3.02C20.88,5.58,26.42.03,33.28.02c8.39-.01,16.78-.04,25.17,0,5.22.03,8.92,2.59,11.31,7.15,1.24,2.37,1.24,4.94,1.2,7.52-.04,1.92,0,1.92,1.88,1.92,4.88,0,9.76-.02,14.63.01,2.49.02,4.38,1.96,4.34,4.34-.04,2.37-1.91,4.15-4.38,4.17-.81,0-1.63,0-2.44,0-1.82,0-1.75-.08-1.8,1.65-.02.49-.11.97-.13,1.46-.33,5.84-.65,11.68-.97,17.52-.39,7.1-.78,14.21-1.17,21.31-.39,7.04-.78,14.08-1.17,21.12-.2,3.61-1.72,6.55-4.42,8.92-1.73,1.52-3.81,2.33-6,2.88ZM45.9,16.62c5.13,0,10.27-.01,15.4.01.84,0,1.18-.27,1.14-1.13-.05-1,0-2.02-.02-3.02-.07-2.18-1.75-3.91-3.93-3.92-8.38-.03-16.77-.03-25.15,0-2.19,0-3.87,1.73-3.94,3.91-.03.94-.01,1.88,0,2.83,0,1.29.03,1.32,1.31,1.32,5.07,0,10.14,0,15.21,0Zm4.04,41.72c0-4,0-8,0-12,0-.45,0-.92-.11-1.35-.49-2.09-2.32-3.32-4.55-3.07-1.9.21-3.4,1.94-3.41,4.01-.01,8.19-.01,16.39,0,24.58,0,2.58,1.75,4.46,4.07,4.42,2.32-.04,3.99-1.86,4-4.4,0-4.06,0-8.13,0-12.19Zm16.61.07s0,0,0,0c0-4.26.03-8.52-.01-12.78-.02-1.92-1.53-3.52-3.36-3.72-2.16-.23-3.98.93-4.5,2.89-.16.6-.21,1.21-.21,1.83,0,4.62,0,9.23,0,13.85,0,3.35-.03,6.7,0,10.05.03,2.96,2.41,4.91,5.09,4.22,1.77-.45,2.96-1.94,2.97-3.86.03-4.16,0-8.32,0-12.48Zm-33.21.01c0-3.93,0-7.87,0-11.8,0-.62-.06-1.24-.22-1.83-.52-1.93-2.27-3.07-4.41-2.88-1.76.16-3.41,1.68-3.42,3.45-.05,8.68-.06,17.36,0,26.04.02,2.19,2.43,3.87,4.58,3.44,2.19-.44,3.43-2,3.45-4.42.02-4,0-8,0-12Z"
                                                                                            />
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <button
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        handleEnable(
                                                                                            reserva.id
                                                                                        );
                                                                                    }}
                                                                                    title="Habilitar"
                                                                                    className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-3 border rounded text-blue-blueFrom border-blue-blueFrom group hover:bg-blue-blueFrom hover:white">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-blue-blueFrom group-hover:text-white"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        stroke="currentColor"
                                                                                        strokeWidth="2"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <polyline points="17 1 21 5 17 9" />{" "}
                                                                                        <path d="M3 11V9a4 4 0 0 1 4-4h14" />{" "}
                                                                                        <polyline points="7 23 3 19 7 15" />{" "}
                                                                                        <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>

                                    <div className="container flex items-center justify-between p-1 mx-auto">
                                        <Paginado
                                            elementsPerPage={RESERVAS_PER_PAGE}
                                            cantTotalElements={resenasTotal}
                                            pagination={pagination}
                                            actualPage={pageNumber}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        );
    };

    return (
        <div>
            <div className="absolute z-[999] flex items-center justify-center w-full text-center"></div>

            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Reseñas">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 5 || tipo_usuario === 7 ? (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Reseñas">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : tipo_usuario === 8 ? (
                <NavbarHotel titulo="Reseñas">
                    {render()}
                    <NavbarMobileHotel />
                </NavbarHotel>
            ) : null}
        </div>
    );
};
