import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import { NavbarHotel } from "./NavbarHotel";
import { NavbarMobileHotel } from "./NavbarMobileHotel";
import { useAppSelector } from "../../hooks/hooks";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { useDispatch } from "react-redux";
import { set_landing_house } from "../../redux/reducers/landingReducer";
import Spinner from "../../components/helpers/Spinner";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import Swal from "sweetalert2";

interface Cliente {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface Propietario {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface casa {
    banner: string;
    id: number;
    nombre: string;
    Propietario: Propietario;
}

interface contacto {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    costo_total: number;
    avatar: string;
    estado: string;
    habilitado: string;
    fecha_registro: string;
    Cliente: Cliente;
    casa: casa;
}

interface getComfort {
    pagina: number;
    habilitado: string;
    query: string;
}

export interface Contactos {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    celular: string;
    avatar: string;
    tipo_contacto: string;
    fecha_ultima_reserva: string;
    id_pedido: number;
    etapa_crm: string;
    id_crm: number;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
    terminado: string;
    precio_base: string;
    tipo_moneda: string;
    publicado: string;
    porcentaje: number;
    pagina: string;
}

export const HotelHabitaciones = () => {
    const [casas, setCasas] = useState<Casa[]>([]);
    const [input, setInput] = useState<string>("Todos");
    const [loading, setLoading] = useState<boolean>(false);
    const [showButtons, setShowButtons] = useState(false);

    const navigate = useNavigate();
    const contactoS_PER_PAGE = 20;
    const casa = useAppSelector((state) => state.landing_house.house);

    console.log("casa id", casa.id);

    const dispatch = useDispatch();

    const handleCalendario = async (id: number) => {
        try {
            if (id) {
                navigate(`/dashboard-hotel/calendario/${id}`);
            }
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    const handleReserva = async (id: number) => {
        try {
            if (id) {
                navigate(`/dashboard-corredor/reservas/${id}`);
            }
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    const continuarConOnboarding = async (pagina: string, casa: number) => {
        switch (pagina) {
            case "nombre":
                navigate(
                    `/dashboard-hotel/onboarding/capacidad-habitacion/${casa}`
                );
                break;
            case "capacidades":
                navigate(
                    `/dashboard-hotel/onboarding/imagenes-habitacion/${casa}`
                );
                break;
            case "banner":
                navigate(
                    `/dashboard-hotel/onboarding/precio-habitacion/${casa}`
                );
                break;
            default:
                break;
        }
    };

    const handleReviews = async (id: number) => {
        try {
            if (id) {
                navigate(`/dashboard-corredor/reviews/${id}`);
            }
        } catch (error) {
            console.log(error);
            dispatch(set_landing_house(null));
        }
    };

    const handleConfiguraciones = async (id: number) => {
        try {
            if (id) {
                navigate(`/dashboard-hotel/configuraciones-habitacion/${id}`);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAllCasas = async (query: string) => {
        setLoading(true);

        fetchAxiosNoToken({
            url: `/hotel_habitaciones/getAllByHotel/${casa.id}${
                query === "Todos" ? "" : `?query=${query}`
            }`,
        })
            .then((res) => {
                console.log(res);
                setCasas(res);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const updatePublicado = async (id: number, publicado: string) => {
        setLoading(true);
        fetchAxiosToken({
            url: `/hotel_habitaciones/updatePublicado/${id}`,
            body: {
                publicado,
            },
            method: "put",
        })
            .then((res) => {
                console.log(res);
                getAllCasas(input);
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    // position: "top-end",
                    icon: "success",
                    title: `${
                        publicado === "Si" ? "Casa Publicada" : "Casa Oculta"
                    }`,
                    showConfirmButton: false,
                    timer: 2000,
                });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const deleteHabitacion = async (id: number) => {
        setLoading(true);
        fetchAxiosToken({
            url: `/hotel_habitaciones/delete/${id}`,

            method: "delete",
        })
            .then((res) => {
                console.log(res);
                getAllCasas(input);
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    // position: "top-end",
                    icon: "success",
                    title: `habitación eliminada con éxito`,
                    showConfirmButton: false,
                    timer: 2000,
                });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    useEffect(() => {
        getAllCasas(input);
    }, [input, casa]);

    const render = () => {
        return (
            <div className="w-full">
                {loading ? (
                    <div className="flex-col items-center justify-center">
                        <div className="flex items-center justify-center mx-auto w-28 h-28">
                            <Spinner width={28} />
                        </div>
                        <div className="mt-5 text-xl text-center">
                            Por favor, aguarde mientras cargamos los datos.
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="flex items-center px-3 mx-auto mt-4 sm:px-6">
                            <div className="flex flex-col items-center justify-center w-full gap-3 md818:flex-row md818:justify-end">
                                <div className="flex"></div>

                                <div className="flex md:ml-4">
                                    <button
                                        onClick={() =>
                                            navigate(
                                                "/dashboard-hotel/onboarding/habitacion/0"
                                            )
                                        }
                                        className="flex items-center gap-1 p-2 border-2 rounded-lg h-[51px] border-[#CCCCCC] text-[#808080] hover:scale-105 mt-2">
                                        <svg
                                            className="h-6 w-6 mt-0.5 text-[#808080]"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                            />
                                            <line
                                                x1="12"
                                                y1="5"
                                                x2="12"
                                                y2="19"
                                            />
                                            <line
                                                x1="5"
                                                y1="12"
                                                x2="19"
                                                y2="12"
                                            />
                                        </svg>
                                        <span>Crear Habitación</span>
                                    </button>
                                    <button className="relative flex items-center justify-center h-[51px] w-[51px] ml-5 border-2  border-[#CCCCCC] text-[#808080] rounded-lg mt-2 ">
                                        <svg
                                            onClick={() => {
                                                setShowButtons((prev) => !prev);
                                            }}
                                            className="text-[#808080] h-7 w-7"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                            />
                                        </svg>
                                        {showButtons && (
                                            <div className="border rounded-lg absolute -left-24 top-[35px] z-10 bg-white">
                                                <div
                                                    onClick={() => {
                                                        setShowButtons(
                                                            (prev) => !prev
                                                        );
                                                        setInput("Todos");
                                                    }}
                                                    className="flex items-center justify-start w-32 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                                    <p
                                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                        Todos
                                                    </p>
                                                </div>

                                                <hr className="w-28 mx-auto border-[#053465] my-1"></hr>
                                                <div
                                                    onClick={() => {
                                                        setShowButtons(
                                                            (prev) => !prev
                                                        );
                                                        setInput("Completo");
                                                    }}
                                                    className={
                                                        " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1"
                                                    }>
                                                    <p
                                                        className={
                                                            "p-2 hover:bg-[#053465]/30 hover:text-white w-full rounded-lg"
                                                        }>
                                                        Terminados
                                                    </p>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setShowButtons(
                                                            (prev) => !prev
                                                        );
                                                        setInput("Incompleto");
                                                    }}
                                                    className={
                                                        " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1 "
                                                    }>
                                                    <p
                                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                        Incompletos
                                                    </p>
                                                </div>

                                                <hr className="w-28 mx-auto border-[#053465] my-1"></hr>
                                                <div
                                                    onClick={() => {
                                                        setShowButtons(
                                                            (prev) => !prev
                                                        );
                                                        setInput("Publicado");
                                                    }}
                                                    className={
                                                        " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1 "
                                                    }>
                                                    <p
                                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                        Publicados
                                                    </p>
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setShowButtons(
                                                            (prev) => !prev
                                                        );
                                                        setInput("Revision");
                                                    }}
                                                    className={
                                                        " w-32 h-11 mx-auto cursor-pointer rounded-lg flex items-center justify-start p-1 "
                                                    }>
                                                    <p
                                                        className="p-2 hover:bg-[#053465]/30
                                hover:text-white w-full rounded-lg">
                                                        Ocultos
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <section className="flex justify-center items-center lg:pb-[10px] ">
                            <div className="w-full px-11">
                                <div className="flex flex-wrap -mx-8">
                                    <div className="w-full ">
                                        <div className="flex flex-col">
                                            {true ? (
                                                <div className="w-full ">
                                                    <table className="w-full mt-6 overflow-x-auto ">
                                                        <thead className="text-left border-b-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                            <tr className="">
                                                                <th className="w-full min-w-[10rem] md:w-3/12 pb-4 ">
                                                                    Nombre
                                                                </th>
                                                                <th className="w-full min-w-[5rem] md:w-2/12 pb-4 text-center hidden xs:table-cell">
                                                                    Estado
                                                                </th>
                                                                <th className="w-full min-w-[10rem] md:w-2/12 hidden lg1170:table-cell pb-4 text-center">
                                                                    Terminado
                                                                </th>
                                                                <th className="w-full min-w-[8rem] md:w-2/12 pb-4 text-center hidden lg:table-cell">
                                                                    Precio
                                                                </th>
                                                                <th className="w-full min-w-[8rem] md:w-2/12 pb-4 text-center">
                                                                    Acciones
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <div className="my-2 2xl:my-4"></div>
                                                        <tbody className="">
                                                            {casas?.map(
                                                                (casa, i) => (
                                                                    <tr
                                                                        key={i}
                                                                        className="w-full text-sm hover:bg-gray-200 2xl:text-base">
                                                                        <td className=" border-r-[3px] border-[#CCCCCC]">
                                                                            <div className="flex items-center 2xl:py-3 py-1.5 ">
                                                                                <img
                                                                                    src={
                                                                                        `${process.env.REACT_APP_PROXY}/hotel_habitaciones/getImage/` +
                                                                                        casa?.banner
                                                                                    }
                                                                                    alt="icono"
                                                                                    className="object-cover w-10 h-10 rounded"
                                                                                />
                                                                                <p className="mx-3 text-[#3C3C3C] 2xl:text-base text-sm">
                                                                                    {`${casa.nombre}`}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td className=" border-r-[3px] border-[#CCCCCC] text-center hidden xs:table-cell px-1">
                                                                            {casa.publicado ===
                                                                            "Si"
                                                                                ? "Publicado"
                                                                                : "Oculto"}
                                                                        </td>
                                                                        <td className="hidden lg1170:table-cell border-r-[3px] border-[#CCCCCC] text-center">
                                                                            <div
                                                                                onClick={() => {
                                                                                    casa.porcentaje !==
                                                                                        100 &&
                                                                                        continuarConOnboarding(
                                                                                            casa.pagina,
                                                                                            casa.id
                                                                                        );
                                                                                }}
                                                                                className={`w-10 h-10 border-4 border-white  mx-auto rounded-full flex items-center justify-center ${
                                                                                    casa.porcentaje ===
                                                                                    100
                                                                                        ? "border-[#053465]"
                                                                                        : casa.porcentaje <
                                                                                          25
                                                                                        ? "border-t-[#053465] cursor-pointer"
                                                                                        : casa.porcentaje <
                                                                                          50
                                                                                        ? "border-t-[#053465] border-r-[#053465]  cursor-pointer"
                                                                                        : "border-t-[#053465] border-r-[#053465] border-b-[#053465] cursor-pointer"
                                                                                }  `}>
                                                                                <div className="w-8 h-8 bg-white rounded-full  text-xs text-[#053465] flex items-center justify-center">
                                                                                    <span>
                                                                                        {
                                                                                            casa.porcentaje
                                                                                        }
                                                                                    </span>{" "}
                                                                                    <span>
                                                                                        %
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="border-r-[3px] border-[#CCCCCC] hidden text-center lg:table-cell">
                                                                            {
                                                                                casa.precio_base
                                                                            }{" "}
                                                                            {
                                                                                casa.tipo_moneda
                                                                            }
                                                                        </td>
                                                                        <td className="gap-2 border-r-[3px] border-[#CCCCCC] text-center ">
                                                                            <div className="flex items-center justify-center gap-2">
                                                                                {casa.porcentaje ===
                                                                                100 ? (
                                                                                    <button
                                                                                        title={`${
                                                                                            casa.publicado ===
                                                                                            "Si"
                                                                                                ? "Ocultar"
                                                                                                : "Publicar"
                                                                                        }`}
                                                                                        onClick={() =>
                                                                                            updatePublicado(
                                                                                                casa.id,
                                                                                                casa.publicado ===
                                                                                                    "Si"
                                                                                                    ? "No"
                                                                                                    : "Si"
                                                                                            )
                                                                                        }
                                                                                        className="h-8 w-8 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                        <svg
                                                                                            className="w-6 h-6 text-white"
                                                                                            width="24"
                                                                                            height="24"
                                                                                            viewBox="0 0 24 24"
                                                                                            strokeWidth="2"
                                                                                            stroke="currentColor"
                                                                                            fill="none"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round">
                                                                                            {" "}
                                                                                            <path
                                                                                                stroke="none"
                                                                                                d="M0 0h24v24H0z"
                                                                                            />{" "}
                                                                                            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -5v5h5" />{" "}
                                                                                            <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 5v-5h-5" />
                                                                                        </svg>
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        title={`${
                                                                                            casa.publicado ===
                                                                                            "Si"
                                                                                                ? "Ocultar"
                                                                                                : "Publicar"
                                                                                        }`}
                                                                                        onClick={() =>
                                                                                            continuarConOnboarding(
                                                                                                casa.pagina,
                                                                                                casa.id
                                                                                            )
                                                                                        }
                                                                                        className="h-8 w-8 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                        <svg
                                                                                            className="w-6 h-6 text-white"
                                                                                            viewBox="0 0 24 24"
                                                                                            strokeWidth="2"
                                                                                            stroke="currentColor"
                                                                                            fill="none"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round">
                                                                                            {" "}
                                                                                            <path
                                                                                                stroke="none"
                                                                                                d="M0 0h24v24H0z"
                                                                                            />{" "}
                                                                                            <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />{" "}
                                                                                            <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />{" "}
                                                                                            <line
                                                                                                x1="16"
                                                                                                y1="5"
                                                                                                x2="19"
                                                                                                y2="8"
                                                                                            />
                                                                                        </svg>
                                                                                    </button>
                                                                                )}

                                                                                <button
                                                                                    title={`Eliminar`}
                                                                                    onClick={() =>
                                                                                        deleteHabitacion(
                                                                                            casa.id
                                                                                        )
                                                                                    }
                                                                                    className="h-8 w-8 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-white"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke="currentColor">
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            strokeWidth="2"
                                                                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                                {casa.porcentaje ===
                                                                                    100 && (
                                                                                    <>
                                                                                        <button
                                                                                            onClick={() =>
                                                                                                handleCalendario(
                                                                                                    casa.id
                                                                                                )
                                                                                            }
                                                                                            className="h-8 w-8 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                            <svg
                                                                                                className="w-6 h-6 text-white"
                                                                                                width="24"
                                                                                                height="24"
                                                                                                viewBox="0 0 24 24"
                                                                                                strokeWidth="2"
                                                                                                stroke="currentColor"
                                                                                                fill="none"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round">
                                                                                                {" "}
                                                                                                <path
                                                                                                    stroke="none"
                                                                                                    d="M0 0h24v24H0z"
                                                                                                />{" "}
                                                                                                <rect
                                                                                                    x="4"
                                                                                                    y="5"
                                                                                                    width="16"
                                                                                                    height="16"
                                                                                                    rx="2"
                                                                                                />{" "}
                                                                                                <line
                                                                                                    x1="16"
                                                                                                    y1="3"
                                                                                                    x2="16"
                                                                                                    y2="7"
                                                                                                />{" "}
                                                                                                <line
                                                                                                    x1="8"
                                                                                                    y1="3"
                                                                                                    x2="8"
                                                                                                    y2="7"
                                                                                                />{" "}
                                                                                                <line
                                                                                                    x1="4"
                                                                                                    y1="11"
                                                                                                    x2="20"
                                                                                                    y2="11"
                                                                                                />{" "}
                                                                                                <line
                                                                                                    x1="11"
                                                                                                    y1="15"
                                                                                                    x2="12"
                                                                                                    y2="15"
                                                                                                />{" "}
                                                                                                <line
                                                                                                    x1="12"
                                                                                                    y1="15"
                                                                                                    x2="12"
                                                                                                    y2="18"
                                                                                                />
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button
                                                                                            onClick={() =>
                                                                                                handleConfiguraciones(
                                                                                                    casa.id
                                                                                                )
                                                                                            }
                                                                                            className="h-8 w-8 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                            <svg
                                                                                                className="w-6 h-6 text-white"
                                                                                                width="24"
                                                                                                height="24"
                                                                                                viewBox="0 0 24 24"
                                                                                                strokeWidth="2"
                                                                                                stroke="currentColor"
                                                                                                fill="none"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round">
                                                                                                {" "}
                                                                                                <path
                                                                                                    stroke="none"
                                                                                                    d="M0 0h24v24H0z"
                                                                                                />{" "}
                                                                                                <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />{" "}
                                                                                                <circle
                                                                                                    cx="12"
                                                                                                    cy="12"
                                                                                                    r="3"
                                                                                                />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </>
                                                                                )}
                                                                                {/* <button
                                                                                    onClick={() =>
                                                                                        handleReserva(
                                                                                            casa.id
                                                                                        )
                                                                                    }
                                                                                    className="h-8 w-8 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-white"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        stroke="currentColor">
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            strokeWidth="2"
                                                                                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                                                                                        />
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleReviews(
                                                                                            casa.id
                                                                                        )
                                                                                    }
                                                                                    className="h-8 w-8 rounded-md bg-[#029BFC] hover:scale-105 flex justify-center items-center">
                                                                                    <svg
                                                                                        className="w-6 h-6 text-white"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth="2"
                                                                                        stroke="currentColor"
                                                                                        fill="none"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round">
                                                                                        {" "}
                                                                                        <path
                                                                                            stroke="none"
                                                                                            d="M0 0h24v24H0z"
                                                                                        />{" "}
                                                                                        <path d="M12 17.75l-6.172 3.245 1.179-6.873-4.993-4.867 6.9-1.002L12 2l3.086 6.253 6.9 1.002-4.993 4.867 1.179 6.873z" />
                                                                                    </svg>
                                                                                </button>
                                                                                */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                "Aun no tiene Casas"
                                            )}
                                        </div>

                                        {/* <div className="container flex items-center justify-between p-1 mx-auto">
                                    <Paginado
                                        elementsPerPage={contactoS_PER_PAGE}
                                        cantTotalElements={contactosTotal}
                                        pagination={pagination}
                                        actualPage={pageNumber}
                                    />
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <NavbarHotel titulo="Habitaciones">
                {render()}
                <NavbarMobileHotel />
            </NavbarHotel>
        </div>
    );
};
