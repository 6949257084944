import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";

import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import BotonInformacion from "../../../components/helpers/BotonInformacion";

export interface PagosPendiente {
    id: number;
    id_pedidos: number;
    monto: string;
    metodo: string;
    etapa_pago: string;
    fecha_registro: string;
    imagen: string;
    estado: string;
    observacion: string;
}

interface Props {
    setModalVisible: (modalVisible: boolean) => void;
    modalVisible: boolean;
    idPedido: number;
    moneda: string;
}

export const ModalDetalleDePagos = ({
    idPedido,
    modalVisible,
    setModalVisible,
    moneda,
}: Props) => {
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const [showPagosRealizados, setShowPagosRealizados] = useState(true);
    const [pagoAceptado, setPagoAceptado] = useState("Aceptado");
    const [input, setInput] = useState({
        motivo_rechazado: "",
        checking: false,
    });
    const [pagosPendientes, setPagosPendientes] = useState<PagosPendiente[]>(
        []
    );
    const [pagosRealizados, setPagosRealizados] = useState<PagosPendiente[]>(
        []
    );

    const [saving, setSaving] = useState<boolean>(false);

    const getPagos = async () => {
        const data = await fetchAxiosToken({
            url: `/pedidos_pagos/getAll/${idPedido}`,
            method: "get",
        });

        setPagosPendientes(data.pagos_pendientes);
        setPagosRealizados(data.pagos_validados);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        getPagos();
    }, []);

    const handleSubmit = async (id: number) => {
        if (pagoAceptado !== "Aceptado") {
            if (input.motivo_rechazado === "") {
                return Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    icon: "warning",
                    text: "Debes especificar el motivo de rechazo",
                    confirmButtonText: "Aceptar",
                });
            }
        }
        setInput({
            ...input,
            checking: true,
        });
        console.log({
            estado: pagoAceptado,
            observacion: input.motivo_rechazado,
        });
        const data = await fetchAxiosToken({
            url: `/pedidos_pagos/putPay/${id}`,
            method: "put",
            body: {
                estado: pagoAceptado,
                observacion: input.motivo_rechazado,
            },
        });

        getPagos();

        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            icon: "success",
            text: "Pago actualizado",
            confirmButtonText: "Aceptar",
        });
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
        >
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-sm font-medium 2xl:text-base">
                    Detalle de pagos
                </h2>
                <hr className="w-full bg-gray-300" />
                <div className="flex w-full mx-auto ">
                    <div className="flex justify-center w-full">
                        <button
                            className={`${
                                !showPagosRealizados
                                    ? "2xl:p-3 p-2 text-blue-500 border border-blue-blueFrom w-full max-w-[156px] rounded-lg 2xl:text-base text-sm"
                                    : "2xl:p-3 p-2 max-w-[156px]  text-white bg-blue-blueFrom w-full border rounded-lg  2xl:text-base text-sm"
                            }`}
                            onClick={() => setShowPagosRealizados(true)}
                        >
                            Realizados
                        </button>
                        {true && (
                            <button
                                className={`${
                                    showPagosRealizados
                                        ? "2xl:p-3 p-2 text-blue-500 border border-blue-blueFrom w-full max-w-[156px] rounded-lg 2xl:text-base text-sm"
                                        : "2xl:p-3 p-2 max-w-[156px]  text-white bg-blue-blueFrom w-full border rounded-lg  2xl:text-base text-sm"
                                }`}
                                onClick={() => setShowPagosRealizados(false)}
                            >
                                Pendientes
                            </button>
                        )}
                    </div>
                </div>
                {showPagosRealizados ? (
                    pagosRealizados?.length > 0 ? (
                        <div className="w-full p-4 ">
                            <div className="relative flex items-center justify-center ">
                                <h1 className="2xl:text-xl text-lg  text-center text-[#3C3C3C] mr-1">
                                    Pagos realizados
                                </h1>
                                <BotonInformacion text="Aca podras ver los pagos que te hizo el cliente por esta reserva!" />
                            </div>
                            {pagosRealizados.map((pagoRealizado, index) => (
                                <div
                                    key={pagoRealizado.id}
                                    className="w-full mt-2 text-sm 2xl:mt-4 2xl:text-base"
                                >
                                    <h1 className="text-base font-medium ">
                                        Pago {index + 1}
                                    </h1>
                                    <hr />
                                    <div className="flex justify-between mt-2">
                                        <label>Método de pago</label>
                                        <span>
                                            {pagoRealizado.metodo === "Stripe"
                                                ? "Stripe"
                                                : "Transferencia bancaria"}
                                        </span>
                                    </div>
                                    <div className="flex justify-between ">
                                        <label>Etapa de pago</label>
                                        <span>{pagoRealizado.etapa_pago}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <label>Estado de pago</label>
                                        <span
                                            className={`${
                                                pagoRealizado.estado ===
                                                "Aceptado"
                                                    ? "text-green-600"
                                                    : "text-red-500"
                                            } uppercase font-medium`}
                                        >
                                            {pagoRealizado.estado}
                                        </span>
                                    </div>
                                    {pagoRealizado.estado !== "Aceptado" &&
                                        pagoRealizado.observacion && (
                                            <div className="flex flex-col justify-between ">
                                                <label>Motivo de rechazo</label>
                                                <span className="p-1 my-1 border-y">
                                                    {pagoRealizado.observacion}
                                                </span>
                                            </div>
                                        )}

                                    <div className="flex justify-between ">
                                        <label>Pagado</label>
                                        <span>
                                            {moneda} {pagoRealizado.monto}{" "}
                                        </span>
                                    </div>
                                    {pagoRealizado.metodo === "Stripe" ? (
                                        <div className="flex justify-between w-full">
                                            <label>Fecha de registro</label>
                                            <span>
                                                {pagoRealizado.fecha_registro}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="flex justify-between w-full">
                                            <label>
                                                Fecha de carga de imagen
                                            </label>
                                            <span>
                                                {pagoRealizado.fecha_registro}
                                            </span>
                                        </div>
                                    )}

                                    {pagoRealizado.imagen && (
                                        <div className="flex flex-col font-medium">
                                            Imagen de comprobante
                                            <img
                                                className="mt-3"
                                                src={`${process.env.REACT_APP_PROXY}/pedidos_pagos/getImage/${pagoRealizado.imagen}`}
                                                alt="imagen"
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <h1 className="mt-10 font-medium text-center">
                            No posee pagos realizados
                        </h1>
                    )
                ) : pagosPendientes?.length > 0 ? (
                    <div className="w-full p-4 2xl:mt-3 ">
                        <div className="relative flex items-center justify-center ">
                            <h1 className="2xl:text-xl text-lg  text-center text-[#3C3C3C] mr-1">
                                Pagos pendientes
                            </h1>
                            <BotonInformacion text="Aca podrás ver los pagos pendientes, deberás rechazarlos en caso este algo mal, o aceptar el pago para completar el proceso de alquiler" />
                        </div>
                        {pagosPendientes.map((pagoPendiente) => (
                            <div
                                key={pagoPendiente.id}
                                className="w-full mt-3 text-sm 2xl:text-base"
                            >
                                <div className="flex justify-between ">
                                    <label>Método de pago</label>
                                    <span>Transferencia bancaria</span>
                                </div>
                                <div className="flex justify-between ">
                                    <label>Etapa de pago</label>
                                    <span>{pagoPendiente.etapa_pago}</span>
                                </div>
                                <div className="flex justify-between">
                                    <label>Estado de pago</label>
                                    <span>{pagoPendiente.estado}</span>
                                </div>
                                <div className="flex justify-between ">
                                    <label>Pagado</label>
                                    <span>
                                        {moneda} {pagoPendiente.monto}
                                    </span>
                                </div>
                                {pagoPendiente.metodo === "Stripe" ? (
                                    <div className="flex justify-between w-full">
                                        <label>Fecha de registro</label>
                                        <span>
                                            {pagoPendiente.fecha_registro}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="flex justify-between w-full">
                                        <label>Fecha de carga de imagen</label>
                                        <span>
                                            {pagoPendiente.fecha_registro}
                                        </span>
                                    </div>
                                )}
                                {
                                    <div className="flex flex-col font-medium">
                                        Imagen de comprobante
                                        {pagoPendiente.imagen ? (
                                            <img
                                                className="mt-3"
                                                src={`${process.env.REACT_APP_PROXY}/pedidos_pagos/getImage/${pagoPendiente.imagen}`}
                                                alt="imagen"
                                            />
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>
                                }
                                <select
                                    value={pagoAceptado}
                                    onChange={(e) => {
                                        setInput({
                                            ...input,
                                            motivo_rechazado: "",
                                        });
                                        setPagoAceptado(e.target.value);
                                    }}
                                    className={
                                        tipo_usuario === 3 || tipo_usuario === 6
                                            ? "w-full p-2 mt-4 border rounded-lg"
                                            : "hidden"
                                    }
                                >
                                    <option value={"Aceptado"}>
                                        Pago aceptado
                                    </option>
                                    <option value={"Rechazado"}>
                                        Pago rechazado
                                    </option>
                                </select>
                                {pagoAceptado === "Rechazado" && (
                                    <div className="flex flex-col w-full mt-4">
                                        <h1>
                                            Detalle la razón de rechazo a
                                            continuación
                                        </h1>
                                        <textarea
                                            value={input.motivo_rechazado}
                                            className="w-full h-32 p-2 mt-4 border"
                                            name="motivo_rechazado"
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                        />
                                    </div>
                                )}
                                {saving ? (
                                    <Spinner />
                                ) : (
                                    <button
                                        disabled={input.checking}
                                        onClick={() =>
                                            handleSubmit(pagoPendiente.id)
                                        }
                                        className={
                                            tipo_usuario === 3 ||
                                            tipo_usuario === 6
                                                ? "w-full disabled:bg-gray-400 rounded-lg bg-blue-blueFrom py-3 text-white  active:translate-y-[0.125rem] mt-5 2xl:mt-10"
                                                : "hidden"
                                        }
                                    >
                                        Guardar
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <h1 className="mt-10 font-medium text-center">
                        No posee pagos pendientes
                    </h1>
                )}
                <div className="flex flex-col mt-1  text-[#3C3C3C]"></div>

                {/* <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                            Guardar cambios
                        </button>
                    )}
                </div> */}
            </div>
        </ResponsiveModalAbsolute>
    );
};
