import beneficio1 from "../../img/landing_hotel/beneficio1.png";
import beneficio2 from "../../img/landing_hotel/beneficio2.png";
import beneficio3 from "../../img/landing_hotel/beneficio3.png";
import beneficio4 from "../../img/landing_hotel/beneficio4.png";

const Beneficios = () => {
    const beneficios = [
        {
            titulo: "Optimización operativa",
            descripcion:
                "Automatiza procesos diarios, reduciendo errores y aumentando la eficiencia.",
            imagen: beneficio1,
        },
        {
            titulo: "Mejora en la experiencia del huésped",
            descripcion:
                "Crea una experiencia más personalizada y profesional, incrementando la satisfacción y lealtad.",
            imagen: beneficio2,
        },
        {
            titulo: "Aumento de ingresos",
            descripcion:
                "Maximiza la ocupación y gestión de manera dinámica para mejorar la rentabilidad.",
            imagen: beneficio3,
        },
        {
            titulo: "Accesibilidad remota",
            descripcion:
                "Administra tu hotel desde cualquier lugar, manteniendo el control total en todo momento.",
            imagen: beneficio4,
        },
    ];

    const ancho = window.innerWidth;

    return (
        <div className="md723:px-12 px-5 py-6 mx-auto text-center">
            <h2 className="text-2xl font-bold md723:mb-12 mb-5 text-center text-[#029DE0]">
                Beneficios
            </h2>

            <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
                {beneficios.map((beneficio, index) => (
                    <div
                        key={index}
                        className={`flex ${
                            ancho < 723 ? "flex-col-reverse" : "flex-row"
                        } ${
                            index === 0 || index === 1
                                ? "md:flex-row"
                                : "md:flex-row-reverse"
                        } gap-6 items-center rounded-lg  bg-white shadow-lg md:shadow-none md:bg-gray-100 w-full md:h-[180px]`}
                    >
                        <div className="w-full md:w-[55%]  p-4 text-left ">
                            <h3 className="xl:text-lg font-bold mb-2 xl:mb-4 text-[#029DE0]">
                                {beneficio.titulo}
                            </h3>
                            <p className="text-[#071829] xl:text-base text-base">
                                {beneficio.descripcion}
                            </p>
                        </div>

                        <div className="md:w-[45%] w-full bg-gray-100 rounded-lg h-[200px] md:h-full ">
                            <img
                                src={beneficio.imagen}
                                // src={beneficio1}
                                alt={beneficio.titulo}
                                className="object-cover w-full h-full rounded-lg"
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Beneficios;
