import React, { useEffect, useMemo, useRef, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { CapacidadesHotel } from "../capacidades/CapacidadesHotel";
import { formatearNumero } from "../../components/helpers/formatearNumero";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";
import Habitaciones from "../design1/Habitaciones";
import Spinner from "../../components/helpers/Spinner";
import Swal from "sweetalert2";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Lazy } from "swiper";
import "swiper/css/lazy";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Calendar from "react-calendar";

import getEveryDayBetween2Dates from "../../helpers/getEveryDayBetween2Dates";
import { getEachDayPrice } from "../../helpers/getEachDayPrice";
import { transformDatesToSlash } from "../../helpers/transformDatesToSlash";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";
import { calcularMinimaDisponibilidad } from "../calendario-reserva/calcularMinimaDisponibilidad";
import Servicios from "../design1/Servicios";
import { getPriceAditionalServicePerDay } from "../calendario-reserva/getPriceAditionalServicePerDay";
import { DateIsBetween2Dates } from "../../helpers/DateIsBetween2Dates";
import { ModalCupon } from "./ModalCupon";

import { useNavigate } from "react-router-dom";

interface Props {
    modalVisible: boolean;
    setModalVisible: (b: boolean) => void;
    id_habitacion: number;
    fecha_ingreso: string;
    fecha_salida: string;
    total_huespedes: number;
}

interface Habitacion {
    id: number;
    nombre: string;
    descripcion: string;
    banner: string;
    precio_base: string;
    plan: number;
    personas: string;
    mascotas: string;
    estadia_minima: number;
    banos: string;
    camas: string;
    landing: number;
    check_in: string;
    check_out: string;
    id_hotel: number;
    tipo_garantia: string;
    garantia: string;
    casa: Casa;
    hotel_habitaciones_imagenes: HabitacionImagen[];
    disponibilidades: Disponibilidades;
    precios_variables: Precios_variables[];
    hotel_estadias_minimas: Estadia_minima[];
    servicios_adicionales: Servicios_adicionales[];
    descuentos_tiempos: Descuentos_tiempos[];
    personas_adicionales: Personas_adicionales[];
    pedidos: Pedidos;
}

interface Casa {
    id: number;
    nombre: string;
}

interface HabitacionImagen {
    id: number;
    id_habitacion_hotel: number;
    imagen: string;
    orden: number;
}

interface Disponibilidades {
    arrayDias: DiaDisponible[];
    arrayFechas: FechaDisponible[];
}

interface Precios_variables {
    id: number;
    id_habitacion: number;
    fecha: Date;
    precio: number;
}

interface Pedidos {
    arrayFechasPedido: Reservas[];
}

interface Reservas {
    id_pedido: number;
    fecha: string;
}

interface Estadia_minima {
    fecha_inicio: string;
    fecha_final: string;
    estadia_minima: number;
}

interface Servicios_adicionales {
    id: number;
    nombre: string;
    opcional: string;
    precio: number;
}

interface Personas_adicionales {
    id: number;
    rango_inicio: string;
    rango_final: string;
    precio: number;
    tipo: string;
    fecha_inicio: string;
    fecha_final: string;
}

interface DiaDisponible {
    dia: number;
    fecha_inicio: string;
    fecha_final: string;
    motivo: string;
}

interface Descuentos_tiempos {
    dia_inicio: number;
    dia_final: number;
    descuento: number;
    tipo: string;
    fecha_inicio: string;
    fecha_final: string;
}

interface FechaDisponible {
    fecha: string;
    motivo: string;
}

interface huespedesProps {
    name: "mascotas" | "ninos" | "adultos";
    action: "sumar" | "restar";
}

interface reserva {
    precio_alquiler: number;
    garantia: number;
    descuento_estadia: number;
    descuento_cupon: number;
    total_servicios_adicionales: number;
    servicios_adicionales: Servicios_adicionales[];
    precio_total_reserva: number;
}

export const ModalHabitacion = ({
    modalVisible,
    setModalVisible,
    id_habitacion,
    fecha_ingreso,
    fecha_salida,
    total_huespedes,
}: Props) => {
    const [index, setIndex] = useState(-1);

    const navigate = useNavigate();

    const dispatchDominio = useDispatchDominio();
    const { tipo_moneda, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );

    const [modalCalendar, setModalCalendar] = useState<boolean>(false);
    const [modalHuespedes, setModalHuespedes] = useState<boolean>(false);
    const [reserveVisible, setReserveVisible] = useState<boolean>(false);
    const [allDatesSelected, setAllDatesSelected] = useState<Date[]>([]);
    const [errorReserve, setErrorReserve] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [cupon, setCupon] = useState(0);
    const { SELECTED_CURRENCY } = useCurrencyPrices();
    const [checkedInputs, setCheckedInputs] = useState<any[]>([]);
    const [reserva, setReserva] = useState<reserva>({
        precio_alquiler: 0,
        garantia: 0,
        descuento_estadia: 0,
        descuento_cupon: 0,
        total_servicios_adicionales: 0,
        servicios_adicionales: [],
        precio_total_reserva: 0,
    });

    const [habitacion, setHabitacion] = useState<Habitacion>({
        id: 0,
        nombre: "",
        descripcion: "",
        banner: "",
        precio_base: "",
        personas: "",
        plan: 2,
        mascotas: "",
        estadia_minima: 0,
        banos: "",
        camas: "",
        landing: 0,
        check_in: "",
        check_out: "",
        id_hotel: 0,
        tipo_garantia: "",
        garantia: "",
        casa: {
            id: 0,
            nombre: "",
        },
        hotel_habitaciones_imagenes: [],
        disponibilidades: {
            arrayDias: [],
            arrayFechas: [],
        },
        precios_variables: [],
        hotel_estadias_minimas: [],
        servicios_adicionales: [],
        descuentos_tiempos: [],
        personas_adicionales: [],
        pedidos: {
            arrayFechasPedido: [],
        },
    });

    const modalRef = useRef<HTMLDivElement>(null);
    const modalRefCalendar = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (modalVisible) {
            getOneHabitacion();
        }
    }, [modalVisible]);

    const getOneHabitacion = async () => {
        setLoading(true);
        const response = await fetchAxiosToken({
            url: `/hotel_habitaciones/detalle/${id_habitacion}`,
            method: "get",
        });

        setHabitacion(response);
        setLoading(false);
    };

    // Función para manejar clics fuera del modal
    const handleClickOutside = (event: MouseEvent) => {
        if (
            modalRef.current &&
            !modalRef.current.contains(event.target as Node)
        ) {
            setModalCalendar(false); // Cierra el modal
        }
    };

    const handleClickOutsideHuespedes = (event: MouseEvent) => {
        if (
            modalRefCalendar.current &&
            !modalRefCalendar.current.contains(event.target as Node)
        ) {
            setModalHuespedes(false); // Cierra el modal
        }
    };

    const [dataReseve, setDataReserve] = useState<{
        fecha_inicial: string;
        fecha_final: string;
        huespedes: number;
    }>({
        fecha_inicial: "Selecciona una fecha",
        fecha_final: "Selecciona una fecha",
        huespedes: 1,
    });

    const parseDateFromString = (
        dateStr: string,
        isEndDate = false
    ): Date | null => {
        if (!dateStr) return null;
        const [day, month, year] = dateStr.split("/").map(Number);
        if (
            !day ||
            !month ||
            !year ||
            day < 1 ||
            day > 31 ||
            month < 1 ||
            month > 12
        ) {
            console.error("Fecha inválida:", dateStr);
            return null;
        }

        const date = new Date(year, month - 1, day);

        if (isEndDate) {
            date.setHours(23, 59, 59, 999); // Establecer hora a 23:59:59.999
        }
        return date;
    };

    useEffect(() => {
        if (!loading) {
            let fecha_inicio_str = "Selecciona una fecha";
            let fecha_final_str = "Selecciona una fecha";
            if (fecha_ingreso !== "" && fecha_salida !== "") {
                fecha_inicio_str = fecha_ingreso;
                fecha_final_str = fecha_salida;
                let startDate: Date | null = null;
                let endDate: Date | null = null;
                if (fecha_ingreso) {
                    startDate = parseDateFromString(fecha_ingreso);
                }
                if (fecha_salida) {
                    endDate = parseDateFromString(fecha_salida, true); // Pasar true para ajustar la fecha final
                }

                if (startDate && endDate) {
                    setDatesSelected({
                        fecha_inicial: startDate,
                        fecha_final: endDate,
                    });

                    onChangeCalendar([startDate, endDate]);
                }
            }
            setDataReserve({
                fecha_inicial: fecha_inicio_str,
                fecha_final: fecha_final_str,
                huespedes: total_huespedes,
            });
        }
    }, [loading]);

    useEffect(() => {
        // Agregar el evento cuando el modal está activo
        if (modalCalendar) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Eliminar el evento al desmontar o cuando el modal se cierra
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalCalendar]);

    useEffect(() => {
        // Agregar el evento cuando el modal está activo
        if (modalHuespedes) {
            document.addEventListener("mousedown", handleClickOutsideHuespedes);
        }

        // Eliminar el evento al desmontar o cuando el modal se cierra
        return () => {
            document.removeEventListener(
                "mousedown",
                handleClickOutsideHuespedes
            );
        };
    }, [modalHuespedes]);

    const [datesSelected, setDatesSelected] = useState<{
        fecha_inicial: Date;
        fecha_final: Date;
    } | null>(null);

    const [saving, setSaving] = useState<boolean>(false);

    const { price_to_currency } = usePriceToSelectedCurrency();

    const formatDate = (date: Date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Mes empieza desde 0
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [huespedes, setHuespedes] = useState({
        adultos: 1,
        ninos: 0,
        mascotas: 0,
    });

    const onChangeCalendar = (twoDates: Date[]) => {
        const fecha_inicial = formatDate(twoDates[0]);
        const fecha_final = formatDate(twoDates[1]);

        // Actualizar estados
        setDatesSelected({
            fecha_inicial: twoDates[0],
            fecha_final: twoDates[1],
        });

        setDataReserve((prev) => ({
            ...prev,
            fecha_inicial,
            fecha_final,
        }));

        setModalCalendar(false);
        setReserveVisible(true);
        const all_dates_selected = getEveryDayBetween2Dates({
            array_fechas: twoDates,
        });

        const all_dates_selectedTotal = getEveryDayBetween2Dates({
            array_fechas: twoDates,
        });
        const allDaysSelected = getEveryDayBetween2Dates({
            array_fechas: twoDates,
        }).map((date) => date.getDay());

        let precioVariblePorSemana = 0;

        // Precios variables por semana

        // console.log(all_dates_selectedTotal)

        if (habitacion?.precio_base) {
            let total_alquiler = all_dates_selected.reduce(
                (acc, currentDate) => {
                    return (acc += getEachDayPrice({
                        current_day: currentDate,
                        house_variable_prices:
                            2 > 1 ? habitacion.precios_variables : [],
                        base_price: Number(habitacion.precio_base),
                    }));
                },
                0
            );

            const isAvailable = calcularMinimaDisponibilidad({
                minimaDisponibilidad: habitacion.hotel_estadias_minimas,
                //Tomo el primer y el ultimo día reservado
                selected_dates: [
                    all_dates_selected[0],
                    all_dates_selected[all_dates_selected.length - 1],
                ],
                estadia_maxima: 0,
                estadia_minima: habitacion.estadia_minima,
            });

            if (all_dates_selected.length > 1) {
                all_dates_selected.pop();
            }

            setAllDatesSelected(all_dates_selected);

            const arrayFechas = habitacion.disponibilidades.arrayFechas.map(
                (fecha) => {
                    const transformFecha = transformDatesToSlash({
                        date: fecha.fecha,
                    });

                    return new Date(transformFecha).getTime();
                }
            );

            /* const fechasBluecorner = arrayFechasBluecorner.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });


            const fechasAirBnb = arrayFechasAirBnb.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });

            const fechasTripadvisor = arrayFechasTripadvisor.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });

            const fechasBooking = arrayFechasBooking.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });

            const pedidosBluecorner = arrayPedidosBluecorner.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });

            const arrayFechasPedidos = infoCasa.pedidos.arrayFechasPedido.map(
                (fecha: Reservas) => {
                    const transformFecha = transformDatesToSlash({
                        date: fecha.fecha,
                    });

                    return new Date(transformFecha).getTime();
                }
            ); */

            const all_days_selected = all_dates_selected.map((date) =>
                date.getDay()
            );

            const { arrayDias } = habitacion.disponibilidades;
            // if (infoCasaRedux.plan > 1) {
            if (
                /*  allDaysSelected.some((day) =>
                    arrayDiasBluecorner.includes(day)
                ) ||
                all_dates_selected.some((date) =>
                    pedidosBluecorner.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    fechasBluecorner.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    fechasAirBnb.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    fechasBooking.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    fechasTripadvisor.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    arrayFechasPedidos.includes(date.getTime())
                ) || */
                all_dates_selected.some((date) =>
                    arrayFechas.includes(date.getTime())
                ) ||
                all_days_selected.some((day) =>
                    arrayDias.some(
                        (dia) =>
                            dia.dia === day &&
                            twoDates[1] <= new Date(dia.fecha_final) &&
                            twoDates[0] >= new Date(dia.fecha_inicio)
                    )
                )
            ) {
                setErrorReserve("No puedes seleccionar fechas no disponibles");
            } else {
                if (isAvailable.some(({ available }) => available === false)) {
                    const response = isAvailable.find(
                        ({ available }) => available === false
                    );
                    setErrorReserve(response?.message || null);
                } else {
                    setErrorReserve(null);
                }
            }
            // }

            setReserva({
                ...reserva,
                garantia:
                    habitacion.tipo_garantia === "efectivo"
                        ? 0
                        : Number(habitacion?.garantia),
                precio_alquiler:
                    precioVariblePorSemana > 0
                        ? precioVariblePorSemana
                        : total_alquiler,
            });

            const newCheckedInputs = habitacion.servicios_adicionales.filter(
                (service: any) => service.opcional === "No"
            );

            setCheckedInputs(
                newCheckedInputs.map((input: any) => Number(input.id))
            );

            let servicios_adicionales: any = [];
            let total_servicios_adicionales = 0;
            habitacion.servicios_adicionales.forEach((currentService) => {
                const service_final_price = getPriceAditionalServicePerDay({
                    dia_inicial: new Date(fecha_inicial),
                    dia_final: new Date(fecha_final),
                    servicio_adicional: currentService,
                });
                if (currentService.opcional === "No") {
                    total_servicios_adicionales += service_final_price;
                }

                servicios_adicionales.push({
                    id: currentService.id,
                    nombre: currentService.nombre,
                    precio: service_final_price,
                    opcional: currentService.opcional,
                });
            });

            const total_nights = all_dates_selected.length;
            let descuento_estadia = 0;
            const matchDiscountFecha = habitacion.descuentos_tiempos.find(
                (descuento) => {
                    if (
                        total_nights >= descuento.dia_inicio - 1 &&
                        total_nights <= descuento.dia_final - 1 &&
                        descuento.tipo === "Fecha"
                    ) {
                        //Transformo las fechas de la base de datos a objetos new Date()
                        const nueva_fecha_inicio = new Date(
                            transformDatesToSlash({
                                date: descuento.fecha_inicio,
                            })
                        );
                        const nueva_fecha_final = new Date(
                            transformDatesToSlash({
                                date: descuento.fecha_final,
                            })
                        );

                        if (
                            all_dates_selected.some((dateSelected) => {
                                if (
                                    DateIsBetween2Dates({
                                        fecha_evaluada: dateSelected,
                                        fecha_inicio: nueva_fecha_inicio,
                                        fecha_final: nueva_fecha_final,
                                    })
                                )
                                    return true;
                            })
                        ) {
                            return descuento;
                        }
                    }
                }
            );

            //Si en matchNights encuentro uno por fechas lo uso, sino intento encontrar uno por tipo "Siempre"
            if (matchDiscountFecha) {
                descuento_estadia = Number(matchDiscountFecha.descuento);
            } else {
                const matchDiscountSiempre = habitacion.descuentos_tiempos.find(
                    (descuento) =>
                        total_nights >= descuento.dia_inicio - 1 &&
                        total_nights <= descuento.dia_final - 1 &&
                        descuento.tipo === "Siempre"
                );

                if (matchDiscountSiempre) {
                    descuento_estadia = Number(matchDiscountSiempre.descuento);
                } else {
                    descuento_estadia = 0;
                }
            }
            //Encontrar precio personas adicionales
            const matchPersonasAdicionalesFecha =
                habitacion.personas_adicionales.find((rango_persona) => {
                    if (
                        Number(dataReseve.huespedes) >=
                            Number(rango_persona.rango_inicio) &&
                        Number(dataReseve.huespedes) <=
                            Number(rango_persona.rango_final) &&
                        rango_persona.tipo === "Fecha"
                    ) {
                        //Transformo las fechas de la base de datos a objetos new Date()

                        const nueva_fecha_inicio = new Date(
                            transformDatesToSlash({
                                date: rango_persona.fecha_inicio,
                            })
                        );
                        const nueva_fecha_final = new Date(
                            transformDatesToSlash({
                                date: rango_persona.fecha_final,
                            })
                        );

                        if (
                            all_dates_selected.some((dateSelected) => {
                                if (
                                    DateIsBetween2Dates({
                                        fecha_evaluada: dateSelected,
                                        fecha_inicio: nueva_fecha_inicio,
                                        fecha_final: nueva_fecha_final,
                                    })
                                ) {
                                    return true;
                                }
                            })
                        ) {
                            return rango_persona;
                        }
                    }
                });
            let precio_personas_adicionales = 0;
            //Si en matchNights encuentro uno por fechas lo uso, sino intento encontrar uno por tipo "Siempre"
            if (matchPersonasAdicionalesFecha) {
                precio_personas_adicionales =
                    total_nights * Number(matchPersonasAdicionalesFecha.precio);
            } else {
                const matchPersonasAdicionalesSiempre =
                    habitacion.personas_adicionales.find(
                        (rango_persona) =>
                            Number(dataReseve.huespedes) >=
                                Number(rango_persona.rango_inicio) &&
                            Number(dataReseve.huespedes) <=
                                Number(rango_persona.rango_final) &&
                            rango_persona.tipo === "Siempre"
                    );

                if (matchPersonasAdicionalesSiempre) {
                    precio_personas_adicionales =
                        total_nights *
                        Number(matchPersonasAdicionalesSiempre.precio);
                } else {
                    precio_personas_adicionales = 0;
                }
            }
            total_alquiler += precio_personas_adicionales;
            const garantia =
                habitacion.tipo_garantia === "efectivo"
                    ? 0
                    : Number(habitacion?.garantia);

            let reservaFinal =
                price_to_currency(total_alquiler) +
                price_to_currency(garantia) +
                price_to_currency(total_servicios_adicionales) -
                price_to_currency(descuento_estadia);

            setReserva({
                ...reserva,
                garantia,
                precio_alquiler:
                    precioVariblePorSemana > 0
                        ? precioVariblePorSemana
                        : total_alquiler,
                servicios_adicionales,
                total_servicios_adicionales,
                descuento_estadia,
                precio_total_reserva: reservaFinal,
            });
        }
    };

    useEffect(() => {
        const services = habitacion.servicios_adicionales.filter((service) =>
            checkedInputs.includes(service.id)
        );

        const final_price = services.reduce((acc, currentService, idx) => {
            const service_final_price = getPriceAditionalServicePerDay({
                dia_inicial: datesSelected?.fecha_inicial || new Date(),
                dia_final: datesSelected?.fecha_final || new Date(),
                servicio_adicional: currentService,
            });

            return (acc += service_final_price);
        }, 0);

        let reservaFinal =
            price_to_currency(reserva.precio_alquiler) +
            price_to_currency(reserva.garantia) +
            price_to_currency(final_price) -
            price_to_currency(reserva.descuento_estadia);

        setReserva({
            ...reserva,
            total_servicios_adicionales: final_price,
            precio_total_reserva: reservaFinal,
        });
    }, [checkedInputs]);

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked)
            setCheckedInputs((prev) => [...prev, Number(e.target.value)]);
        else
            setCheckedInputs((prev) =>
                prev.filter((input) => input !== Number(e.target.value))
            );
    };

    const handleDeleteFechas = () => {
        setDataReserve((prev) => ({
            ...prev,
            fecha_inicial: "Selecciona una fecha",
            fecha_final: "Selecciona una fecha",
        }));
        setReserveVisible(false);
        setDatesSelected(null);
    };

    const handleModalHuespedes = () => {
        if (modalHuespedes) {
            setModalHuespedes(false); // Cierra el modal si ya está abierto
        } else {
            setModalHuespedes(true); // Abre el modal si está cerrado
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        const newValue = inputValue !== "" ? Number(inputValue) : "";

        setHuespedes((prevHuespedes) => {
            const updatedHuespedes = {
                ...prevHuespedes,
                [event.target.name]: newValue,
            };

            // Calcular el total de huéspedes
            const totalHuespedes =
                (updatedHuespedes.adultos || 0) + (updatedHuespedes.ninos || 0);

            // Actualizar dataReseve con el total de huéspedes
            setDataReserve((prevData) => ({
                ...prevData,
                huespedes: totalHuespedes,
            }));

            return updatedHuespedes;
        });
    };

    useEffect(() => {
        let total_nights = allDatesSelected.length;

        if (total_nights > 0) {
            let total_alquiler = allDatesSelected.reduce((acc, currentDate) => {
                return (acc += getEachDayPrice({
                    current_day: currentDate,
                    house_variable_prices:
                        2 > 1 ? habitacion.precios_variables : [],
                    base_price: Number(habitacion.precio_base),
                }));
            }, 0);

            const matchPersonasAdicionalesFecha =
                habitacion.personas_adicionales.find((rango_persona) => {
                    if (
                        Number(dataReseve.huespedes) >=
                            Number(rango_persona.rango_inicio) &&
                        Number(dataReseve.huespedes) <=
                            Number(rango_persona.rango_final) &&
                        rango_persona.tipo === "Fecha"
                    ) {
                        //Transformo las fechas de la base de datos a objetos new Date()

                        const nueva_fecha_inicio = new Date(
                            transformDatesToSlash({
                                date: rango_persona.fecha_inicio,
                            })
                        );
                        const nueva_fecha_final = new Date(
                            transformDatesToSlash({
                                date: rango_persona.fecha_final,
                            })
                        );

                        if (
                            allDatesSelected.some((dateSelected) => {
                                if (
                                    DateIsBetween2Dates({
                                        fecha_evaluada: dateSelected,
                                        fecha_inicio: nueva_fecha_inicio,
                                        fecha_final: nueva_fecha_final,
                                    })
                                ) {
                                    return true;
                                }
                            })
                        ) {
                            return rango_persona;
                        }
                    }
                });
            let precio_personas_adicionales = 0;
            //Si en matchNights encuentro uno por fechas lo uso, sino intento encontrar uno por tipo "Siempre"
            if (matchPersonasAdicionalesFecha) {
                precio_personas_adicionales =
                    total_nights * Number(matchPersonasAdicionalesFecha.precio);
            } else {
                const matchPersonasAdicionalesSiempre =
                    habitacion.personas_adicionales.find(
                        (rango_persona) =>
                            Number(dataReseve.huespedes) >=
                                Number(rango_persona.rango_inicio) &&
                            Number(dataReseve.huespedes) <=
                                Number(rango_persona.rango_final) &&
                            rango_persona.tipo === "Siempre"
                    );

                if (matchPersonasAdicionalesSiempre) {
                    precio_personas_adicionales =
                        total_nights *
                        Number(matchPersonasAdicionalesSiempre.precio);
                } else {
                    precio_personas_adicionales = 0;
                }
            }

            total_alquiler = total_alquiler + precio_personas_adicionales;

            let reservaFinal =
                price_to_currency(total_alquiler) +
                price_to_currency(reserva.garantia) +
                price_to_currency(reserva.total_servicios_adicionales) -
                price_to_currency(reserva.descuento_estadia);

            setReserva({
                ...reserva,
                precio_alquiler: total_alquiler,
                precio_total_reserva: reservaFinal,
            });
        }
    }, [dataReseve.huespedes]);

    useEffect(() => {
        console.log(cupon);
    }, [cupon]);

    const handleInputClick = ({ name, action }: huespedesProps) => {
        setHuespedes((prevHuespedes) => {
            const updatedHuespedes = { ...prevHuespedes };

            // Calcular el total actual de huéspedes antes de realizar cambios
            const totalHuespedesActual =
                (updatedHuespedes.adultos || 0) + (updatedHuespedes.ninos || 0);

            if (action === "sumar") {
                // Solo sumar si no excede el límite
                if (totalHuespedesActual < Number(habitacion.personas)) {
                    switch (name) {
                        case "adultos":
                            updatedHuespedes[name] += 1;
                            break;
                        case "ninos":
                            updatedHuespedes[name] += 1;
                            break;
                        case "mascotas":
                            updatedHuespedes[name] += 1;
                            break;
                        default:
                            break;
                    }
                }
            } else {
                // Restar sin restricciones pero respetando los mínimos
                switch (name) {
                    case "adultos":
                        updatedHuespedes[name] = Math.max(
                            1,
                            updatedHuespedes[name] - 1
                        );
                        break;
                    case "ninos":
                        updatedHuespedes[name] = Math.max(
                            0,
                            updatedHuespedes[name] - 1
                        );
                        break;
                    case "mascotas":
                        updatedHuespedes[name] = Math.max(
                            0,
                            updatedHuespedes[name] - 1
                        );
                        break;
                    default:
                        break;
                }
            }

            // Calcular el nuevo total de huéspedes
            const totalHuespedes =
                (updatedHuespedes.adultos || 0) + (updatedHuespedes.ninos || 0);

            setDataReserve((prevData) => ({
                ...prevData,
                huespedes: totalHuespedes,
            }));

            return updatedHuespedes;
        });
    };

    const handleSubmit = () => {
        const TOTAL_HUESPEDES =
            Number(huespedes.adultos) + Number(huespedes.ninos);
        if (Number(huespedes.adultos) < 1) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Por favor primero indica la cantidad de huéspedes",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        if (TOTAL_HUESPEDES > Number(habitacion.personas)) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `Solo se permiten hasta ${habitacion.personas} personas incluido niños y adultos`,
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        }
        /*  if (Number(huespedes.mascotas) > habt.mascotas) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `Solo se permiten hasta ${
                    infoCasa.mascotas === 1 ? "una" : infoCasa.mascotas
                } ${infoCasa.mascotas === 1 ? "mascota" : "mascotas"}`,
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        } */
        const servicios_adicionales_seleccionados =
            reserva.servicios_adicionales.filter((servicio) =>
                checkedInputs.includes(servicio.id)
            );

        localStorage.setItem(
            "reserva",
            JSON.stringify({
                id_casa: habitacion.id_hotel,
                precio_alquiler: price_to_currency(reserva.precio_alquiler),
                garantia: price_to_currency(Number(reserva.garantia)),
                tipo_garantia: habitacion.tipo_garantia,

                descuento_estadia: reserva.descuento_estadia,
                precio_servicios_adicionales:
                    reserva.total_servicios_adicionales,
                precio_total: reserva.precio_total_reserva - cupon,
                huespedes: {
                    adultos: Number(huespedes.adultos),
                    mascotas: Number(huespedes.mascotas),
                    ninos: Number(huespedes.ninos),
                },
                dates_selected: {
                    final_date: datesSelected?.fecha_final,
                    initial_date: datesSelected?.fecha_inicial,
                },
                servicios_adicionales: servicios_adicionales_seleccionados.map(
                    (service) => {
                        return {
                            ...service,
                            precio_final: price_to_currency(service.precio),
                        };
                    }
                ),
                datos_alojamiento: {
                    id_habitacion: habitacion.id,
                    habitacion: habitacion.nombre,
                    casa: habitacion.casa.nombre,
                    id_casa: habitacion.casa.id,
                    moneda: SELECTED_CURRENCY,
                },
                cupon: cupon,
            })
        );

        navigate("/checkout-hotel/");
    };

    const calculateDisabledTiles = React.useMemo(
        () =>
            (date: Date): boolean => {
                if (habitacion) {
                    // Paso las fechas a formato Date
                    const mesActual = date.getMonth();

                    const disponibilidadesFechas =
                        habitacion.disponibilidades.arrayFechas.some(
                            (fecha) => {
                                return (
                                    date.getTime() ===
                                    new Date(
                                        fecha.fecha + "T00:00:00"
                                    ).getTime()
                                );
                            }
                        );

                    if (disponibilidadesFechas) return disponibilidadesFechas;

                    /*   const disponibilidadesFechasBluecorner =
                        arrayFechasBluecorner.some((fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        });
                    if (disponibilidadesFechasBluecorner)
                        return disponibilidadesFechasBluecorner;

                    const disponibilidadesFechasAirBnb = arrayFechasAirBnb.some(
                        (fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        }
                    );

                    if (disponibilidadesFechasAirBnb)
                        return disponibilidadesFechasAirBnb;

                    const disponibilidadesFechasTripadvisor =
                        arrayFechasTripadvisor.some((fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        });
                    if (disponibilidadesFechasTripadvisor)
                        return disponibilidadesFechasTripadvisor;

                    const disponibilidadesFechasBooking =
                        arrayFechasBooking.some((fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        });
                    if (disponibilidadesFechasBooking)
                        return disponibilidadesFechasBooking; */

                    /*   const pedidosBluecorner = arrayPedidosBluecorner.some(
                        (fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        }
                    );
                    if (pedidosBluecorner) return pedidosBluecorner;

                    const diasBluecorner = arrayDiasBluecorner.some((day) => {
                        return date.getDay() === day;
                    });

                    if (diasBluecorner) return diasBluecorner;

                    const mesBluecorner = arrayMesesBluecorner.some((mes) => {
                        return mesActual === mes;
                    });

                    if (mesBluecorner) return mesBluecorner; */

                    const disponibilidadesFechasPedidos =
                        habitacion.pedidos.arrayFechasPedido.some(
                            (fecha: Reservas) => {
                                return (
                                    date.getTime() ===
                                    new Date(
                                        fecha.fecha + "T00:00:00"
                                    ).getTime()
                                );
                            }
                        );

                    if (disponibilidadesFechasPedidos)
                        return disponibilidadesFechasPedidos;

                    const disponibilidadesDias =
                        habitacion.disponibilidades.arrayDias;

                    if (
                        disponibilidadesDias.some(
                            (dia) =>
                                dia.dia === date.getDay() &&
                                date <= new Date(dia.fecha_final) &&
                                date >= new Date(dia.fecha_inicio)
                        )
                    )
                        return true;
                }
                return false;
            },
        [habitacion]
    );

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            max_width={90}
        >
            <div className="flex flex-col items-center justify-center gap-4 p-4 mx-auto w-full relative ">
                {modalCalendar && (
                    <div
                        ref={modalRef}
                        className="w-1/3 absolute flex flex-col top-20 h-96 p-1 items-center bg-white justify-center gap-4 z-50"
                    >
                        <Calendar
                            selectRange={true}
                            onChange={onChangeCalendar}
                            minDate={new Date()}
                            tileDisabled={({ activeStartDate, date, view }) =>
                                calculateDisabledTiles(date)
                            }
                            value={
                                datesSelected
                                    ? [
                                          datesSelected.fecha_inicial,
                                          datesSelected.fecha_final,
                                      ]
                                    : undefined
                            }
                        />

                        <div
                            onClick={handleDeleteFechas}
                            className="underline cursor-pointer text-[#1C4CBB] justify-end w-full text-right px-5"
                        >
                            Borrar fechas
                        </div>
                    </div>
                )}

                <h2 className="2xl:text-4xl text-3xl text-[#1C4CBB] text-center font-medium">
                    Habitación {habitacion?.nombre}
                </h2>
                <hr className="w-full bg-gray-300" />
                {loading ? (
                    <>
                        <Spinner />
                    </>
                ) : (
                    <div className="w-full h-full flex flex-col items-center justify-center gap-4 p-4 mx-auto ">
                        {habitacion && (
                            <div
                                key={`habitacion-${habitacion.id}`}
                                className={` w-full flex justify-start px-5 items-start gap-10`}
                            >
                                <div className="w-1/2">
                                    <Swiper
                                        key={habitacion.banner}
                                        modules={[Navigation, Lazy]}
                                        slidesPerView={1}
                                        watchSlidesProgress
                                        lazy={true}
                                        navigation
                                        rewind={true}
                                        loop={true}
                                    >
                                        <SwiperSlide
                                            key={habitacion.banner}
                                            className="cursor-pointer"
                                        >
                                            <img
                                                loading="lazy"
                                                className=" h-[50vh] rounded-md swiper-lazy mx-auto"
                                                alt="Imagen del producto"
                                                data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${habitacion.banner}`}
                                                data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${habitacion.banner}`}
                                            />

                                            <div className="swiper-lazy-preloader">
                                                <div className="flex flex-col w-full h-64 gap-2 p-3 bg-gray-200">
                                                    <div className="bg-slate-50 w-full h-[80%] rounded-xl"></div>
                                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        {habitacion?.hotel_habitaciones_imagenes?.map(
                                            (imagen) => (
                                                <SwiperSlide key={imagen.id}>
                                                    <img
                                                        loading="lazy"
                                                        className=" h-[50vh] rounded-md swiper-lazy mx-auto"
                                                        alt="Imagen del producto"
                                                        data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${imagen.imagen}`}
                                                        data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${imagen.imagen}`}
                                                    />
                                                    <div className="swiper-lazy-preloader">
                                                        <div className="flex flex-col w-auto h-[65vh] gap-2 p-3 bg-gray-200">
                                                            <div className="bg-slate-50 w-full h-[80%] rounded-xl"></div>
                                                            <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                            <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        )}
                                    </Swiper>
                                </div>

                                <div className="w-1/2 flex flex-col items-center justify-center gap-4">
                                    {" "}
                                    <div className=" flex gap-10  items-center justify-center  ">
                                        <div className=" text-lg text-center text-[#1C4CBB] align-middle ">
                                            <p className="font-light select-none">
                                                <span className="font-semibold">{`${SELECTED_CURRENCY} ${formatearNumero(
                                                    price_to_currency(
                                                        Number(
                                                            habitacion?.precio_base
                                                        )
                                                    )
                                                )} `}</span>{" "}
                                                /por noche
                                            </p>
                                        </div>

                                        <div className=" h-20  border-l-2 border-[#1C4CBB]"></div>

                                        <div className="flex flex-col items-center justify-center  ">
                                            <CapacidadesHotel
                                                personas={
                                                    habitacion?.personas || ""
                                                }
                                                mascotas={
                                                    habitacion?.mascotas || ""
                                                }
                                                banos={habitacion?.banos || ""}
                                                camas={habitacion?.camas || ""}
                                                id_habitacion={
                                                    habitacion?.id || 0
                                                }
                                                toggleButton={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="py-5 px-10 shadow-lg border border-gray-200 rounded-lg flex flex-col justify-center items-center w-11/12  ">
                                        <div className="text-xl text-center font-semibold text-[#029BFC]">
                                            {!reserveVisible &&
                                                "Elije tus fechas para ver el precio de tu reserva"}
                                        </div>
                                        <div className=" w-5/6 mx-auto border-2 border-gray-500 rounded-lg  mt-4 ">
                                            <div className="flex divide-x-2 divide-gray-500 border-b-2 border-gray-500">
                                                <div
                                                    onClick={() =>
                                                        setModalCalendar(
                                                            !modalCalendar
                                                        )
                                                    }
                                                    className="flex-1 p-4 cursor-pointer"
                                                >
                                                    <p className="text-xs font-medium text-gray-800 uppercase">
                                                        LLEGADA
                                                    </p>
                                                    <p
                                                        className={`mt-1 text-sm font-semibold ${
                                                            dataReseve.fecha_inicial ===
                                                            "Selecciona una fecha"
                                                                ? "text-gray-500"
                                                                : "text-gray-800"
                                                        }`}
                                                    >
                                                        {
                                                            dataReseve.fecha_inicial
                                                        }
                                                    </p>
                                                </div>

                                                <div
                                                    onClick={() =>
                                                        setModalCalendar(
                                                            !modalCalendar
                                                        )
                                                    }
                                                    className="flex-1 p-4 cursor-pointer"
                                                >
                                                    <p className="text-xs font-medium text-gray-800 uppercase">
                                                        SALIDA
                                                    </p>
                                                    <p
                                                        className={`mt-1 text-sm font-semibold ${
                                                            dataReseve.fecha_final ===
                                                            "Selecciona una fecha"
                                                                ? "text-gray-500"
                                                                : "text-gray-800"
                                                        } `}
                                                    >
                                                        {dataReseve.fecha_final}
                                                    </p>
                                                </div>
                                            </div>

                                            <div
                                                onClick={() =>
                                                    handleModalHuespedes()
                                                }
                                                className="p-4 flex items-center justify-between cursor-pointer relative "
                                            >
                                                {modalHuespedes && (
                                                    <div
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        ref={modalRefCalendar}
                                                        className="absolute top-16 right-0 w-full p-5 border border-gray-400 shadow-lg bg-white rounded-lg z-50"
                                                    >
                                                        <div className="flex flex-col items-center justify-between">
                                                            <div className="flex flex-col w-2/3 mx-auto gap-1 text-left">
                                                                <label className="text-sm text-[#1C4CBB] mb-2 font-bold">
                                                                    Adultos
                                                                </label>
                                                                <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                                                    <button
                                                                        onClick={() =>
                                                                            handleInputClick(
                                                                                {
                                                                                    action: "restar",
                                                                                    name: "adultos",
                                                                                }
                                                                            )
                                                                        }
                                                                        className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                                                    >
                                                                        <svg
                                                                            className="w-6 h-6 text-[#1C4CBB]"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                            <line
                                                                                x1="5"
                                                                                y1="12"
                                                                                x2="19"
                                                                                y2="12"
                                                                            />
                                                                        </svg>
                                                                    </button>

                                                                    <input
                                                                        className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                                                        type="number"
                                                                        name="adultos"
                                                                        value={
                                                                            huespedes.adultos
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                    />
                                                                    <button
                                                                        onClick={() =>
                                                                            handleInputClick(
                                                                                {
                                                                                    action: "sumar",
                                                                                    name: "adultos",
                                                                                }
                                                                            )
                                                                        }
                                                                        className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                                                    >
                                                                        <svg
                                                                            className="w-6 h-6 text-[#1C4CBB]"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />
                                                                            <line
                                                                                x1="12"
                                                                                y1="5"
                                                                                x2="12"
                                                                                y2="19"
                                                                            />
                                                                            <line
                                                                                x1="5"
                                                                                y1="12"
                                                                                x2="19"
                                                                                y2="12"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col w-2/3 mx-auto gap-1 text-left">
                                                                <label className="text-sm text-[#1C4CBB] mb-2 font-bold">
                                                                    Niños
                                                                </label>
                                                                <div className="grid grid-cols-[20%_1fr_20%] w-full grid-flow-col gap-2">
                                                                    <button
                                                                        onClick={() =>
                                                                            handleInputClick(
                                                                                {
                                                                                    action: "restar",
                                                                                    name: "ninos",
                                                                                }
                                                                            )
                                                                        }
                                                                        className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                                                    >
                                                                        <svg
                                                                            className="w-6 h-6 text-[#1C4CBB]"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                            <line
                                                                                x1="5"
                                                                                y1="12"
                                                                                x2="19"
                                                                                y2="12"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                    <input
                                                                        className="w-full p-2 font-medium text-center border border-[#1C4CBB] rounded-lg"
                                                                        type="number"
                                                                        name="ninos"
                                                                        value={
                                                                            huespedes.ninos
                                                                        }
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                    />
                                                                    <button
                                                                        onClick={() =>
                                                                            handleInputClick(
                                                                                {
                                                                                    action: "sumar",
                                                                                    name: "ninos",
                                                                                }
                                                                            )
                                                                        }
                                                                        className="flex items-center justify-center w-full border border-[#1C4CBB] rounded-lg hover:scale-105"
                                                                    >
                                                                        <svg
                                                                            className="w-6 h-6 text-[#1C4CBB]"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="2"
                                                                            stroke="currentColor"
                                                                            fill="none"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        >
                                                                            <path
                                                                                stroke="none"
                                                                                d="M0 0h24v24H0z"
                                                                            />
                                                                            <line
                                                                                x1="12"
                                                                                y1="5"
                                                                                x2="12"
                                                                                y2="19"
                                                                            />
                                                                            <line
                                                                                x1="5"
                                                                                y1="12"
                                                                                x2="19"
                                                                                y2="12"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                <div>
                                                    <p className="text-xs font-medium text-gray-800 uppercase">
                                                        HUÉSPEDES
                                                    </p>
                                                    <p className="mt-1 text-sm font-semibold text-gray-800">
                                                        {dataReseve.huespedes}{" "}
                                                        huésped
                                                    </p>
                                                </div>
                                                <div className="text-gray-500">
                                                    {modalHuespedes ? (
                                                        <svg
                                                            className="h-5 w-5 "
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M5 15l7-7 7 7"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            className="h-5 w-5 "
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="2"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            {" "}
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                            />{" "}
                                                            <polyline points="6 9 12 15 18 9" />
                                                        </svg>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {errorReserve ? (
                                            <div className="font-medium text-center text-red-500">
                                                {errorReserve}
                                            </div>
                                        ) : (
                                            <>
                                                {reserveVisible && (
                                                    <div
                                                        onClick={handleSubmit}
                                                        className="w-5/6 p-3 bg-[#029BFC] text-white rounded-lg mt-4 cursor-pointer font-bold text-center hover:scale-105 transition-transform duration-300 mb-5"
                                                    >
                                                        {reserveVisible
                                                            ? "Reservar"
                                                            : "Comprobar disponibilidad"}
                                                    </div>
                                                )}

                                                {reserveVisible && (
                                                    <>
                                                        <div className="flex justify-between w-full py-2 border-y border-[#1C4CBB] text-[#1C4CBB] ">
                                                            <span className="mt-2 font-semibold">
                                                                Precio de
                                                                alquiler{" "}
                                                            </span>
                                                            <span className="mt-2 font-semibold">
                                                                {formatearNumero(
                                                                    price_to_currency(
                                                                        Number(
                                                                            reserva.precio_alquiler
                                                                        )
                                                                    )
                                                                )}{" "}
                                                                {
                                                                    SELECTED_CURRENCY
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="flex w-full mt-4 ">
                                                            {
                                                                <ModalCupon
                                                                    setCupon={
                                                                        setCupon
                                                                    }
                                                                    cupon={
                                                                        cupon
                                                                    }
                                                                />
                                                            }
                                                        </div>
                                                        {reserva.descuento_estadia ? (
                                                            <div className="flex justify-between w-full mt-4 font-semibold text-green-600">
                                                                <span className="">
                                                                    Descuento
                                                                    por larga
                                                                    estadía{" "}
                                                                </span>
                                                                <span className="font-medium text-end min-w-max">
                                                                    -{" "}
                                                                    {formatearNumero(
                                                                        Number(
                                                                            price_to_currency(
                                                                                reserva.descuento_estadia
                                                                            )
                                                                        )
                                                                    )}{" "}
                                                                    {
                                                                        SELECTED_CURRENCY
                                                                    }
                                                                </span>
                                                            </div>
                                                        ) : null}

                                                        {Number(
                                                            reserva.garantia
                                                        ) !== 0 &&
                                                            habitacion.tipo_garantia ===
                                                                "Alquiler" && (
                                                                <div className="flex justify-between w-full py-2 border-b border-[#1C4CBB] text-[#1C4CBB] font-semibold">
                                                                    <span className="mt-2 ">
                                                                        Garantia{" "}
                                                                    </span>
                                                                    <span className="mt-2 ">
                                                                        {formatearNumero(
                                                                            price_to_currency(
                                                                                Number(
                                                                                    reserva.garantia
                                                                                )
                                                                            )
                                                                        )}{" "}
                                                                        {
                                                                            SELECTED_CURRENCY
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}

                                                        <div className="flex flex-col w-full gap-1 mt-4 text-left text-[#1C4CBB] font-semibold">
                                                            <span className="mb-2 font-semibold ">
                                                                Servicios
                                                                adicionales
                                                            </span>
                                                            {reserva.servicios_adicionales?.map(
                                                                (service) => (
                                                                    <div
                                                                        key={
                                                                            service.id
                                                                        }
                                                                        className="flex items-center justify-between "
                                                                    >
                                                                        <div className="flex items-center gap-2">
                                                                            <input
                                                                                className="bg-[#1C4CBB] w-4 h-4 "
                                                                                type={
                                                                                    "checkbox"
                                                                                }
                                                                                disabled={
                                                                                    service.opcional ===
                                                                                    "No"
                                                                                }
                                                                                checked={checkedInputs.includes(
                                                                                    service.id
                                                                                )}
                                                                                value={
                                                                                    service.id
                                                                                }
                                                                                onChange={
                                                                                    handleCheckbox
                                                                                }
                                                                            />
                                                                            <span>
                                                                                {
                                                                                    service.nombre
                                                                                }
                                                                            </span>
                                                                        </div>

                                                                        {service.precio ? (
                                                                            <span
                                                                                className={
                                                                                    !checkedInputs.includes(
                                                                                        service.id
                                                                                    )
                                                                                        ? "font-medium text-gray-300"
                                                                                        : "font-medium"
                                                                                }
                                                                            >
                                                                                {formatearNumero(
                                                                                    Number(
                                                                                        price_to_currency(
                                                                                            service.precio
                                                                                        )
                                                                                    )
                                                                                )}{" "}
                                                                                {
                                                                                    SELECTED_CURRENCY
                                                                                }{" "}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="flex justify-between w-full mt-4 text-[#666666] font-bold border-t border-[#1C4CBB] pt-4 ">
                                                            <span className="">
                                                                Precio total de
                                                                la reserva:{" "}
                                                            </span>
                                                            <span className="">
                                                                {formatearNumero(
                                                                    Number(
                                                                        reserva.precio_total_reserva
                                                                    ) -
                                                                        Number(
                                                                            cupon
                                                                        )
                                                                )}{" "}
                                                                {
                                                                    SELECTED_CURRENCY
                                                                }
                                                            </span>
                                                        </div>

                                                        {Number(
                                                            reserva.garantia
                                                        ) !== 0 &&
                                                            habitacion.tipo_garantia ===
                                                                "Efectivo" && (
                                                                <div className="flex w-full px-1 mt-4 bg-blue-200 border-4 border-blue-300 rounded-lg">
                                                                    <span className="underline">
                                                                        Garantía:{" "}
                                                                    </span>
                                                                    <span className="ml-2 italic font-thin ">
                                                                        Deberás
                                                                        pagar{" "}
                                                                        {formatearNumero(
                                                                            price_to_currency(
                                                                                Number(
                                                                                    reserva.garantia
                                                                                )
                                                                            )
                                                                        )}{" "}
                                                                        {
                                                                            SELECTED_CURRENCY
                                                                        }{" "}
                                                                        en
                                                                        efectivo
                                                                        al
                                                                        momento
                                                                        de
                                                                        llegar a
                                                                        la
                                                                        propiedad.
                                                                        (Reembolsable)
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </ResponsiveModalAbsolute>
    );
};
