import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../components/helpers/Spinner";

import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { Navbar } from "../Navbar";
import { NavbarCliente } from "../../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../../dashboardClientes/NavbarMobileCliente";
import { NavbarMobile } from "../NavbarMobile";
import { Paginado } from "../../../components/Paginado";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
import ToggleButton from "../../../components/helpers/ToggleButton";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import ModalContacto from "./ModalContacto";
import { ModalCrearContacto } from "./ModalCrearContacto";
import ModalCargaMasiva from "./ModalCargaMasiva";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface Cliente {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface Propietario {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface casa {
    banner: string;
    id: number;
    nombre: string;
    Propietario: Propietario;
}

interface contacto {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    costo_total: number;
    avatar: string;
    estado: string;
    habilitado: string;
    fecha_registro: string;
    Cliente: Cliente;
    casa: casa;
}

interface getComfort {
    pagina: number;
    habilitado: string;
    query: string;
}

export interface Contactos {
    id: number;
    nombre: string;
    apellido: string;
    correo: string;
    celular: string;
    avatar: string;
    tipo_contacto: string;
    fecha_ultima_reserva: string;
    id_pedido: number;
    etapa_crm: string;
    id_crm: number;
}

export const Contactos = () => {
    const { headers } = useHeaders();

    const [contactos, setContactos] = useState<Contactos[]>([]);
    const [contactoSelect, setContactoSelect] = useState<Contactos | null>(
        null
    );
    const [modalContacto, setModalContacto] = useState(false);

    const [contactosTotal, setcontactosTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<string>("Todos");
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);
    const [comfortEdit, setComfortEdit] = useState<contacto>();
    const [input, setInput] = useState<string>("");
    const debounceInput = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [modalVisibleUsuario, setModalVisibleUsuario] =
        useState<boolean>(false);
    const [modalMasiva, setModalMasiva] = useState(false);

    const navigate = useNavigate();
    const contactoS_PER_PAGE = 20;
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const getComforts = async ({ pagina, habilitado, query }: getComfort) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/clientes/getContacts/${habilitado}?cliente=${query}`,
                { headers }
            );
            console.log(data);
            setContactos(data);
            // setcontactos(data.rows);
            // setcontactosTotal(data.count);
        } catch (error) {
            console.log(error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);

        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            getComforts({
                pagina: pageNumber,
                habilitado: enabled,
                query: "",
            });

            return;
        }
        debounceInput.current = setTimeout(() => {
            getComforts({
                pagina: pageNumber,
                habilitado: enabled,
                query: e.target.value,
            });
        }, 1000);
    };

    useEffect(() => {
        getComforts({ pagina: pageNumber, habilitado: enabled, query: "" });
    }, [enabled, create, comfortEdit, modalVisibleUsuario, modalMasiva]);

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres habilitarlo?",
            text: "Se enviará la comodidad a habilitados!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, habilitar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/comodidad/delete/${id}/Si`,
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Comodidad restaurada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDelete = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará la contacto a deshabilitados!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/pedidos/delete/${id}`,
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "success",
                        title: "contacto elimidada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDetalles = async (id: number) => {
        console.log(id);
        if (tipo_usuario === 3) {
            navigate(`/dashboard/contactos/${id}`, { replace: false });
        } else {
            navigate(`/dashboard-cliente/contactos/${id}`, { replace: false });
        }
    };
    const resenaEnFecha = (fecha: string) => {
        let hoy = new Date().getTime();
        let final_contacto = new Date(fecha + "T00:00:00").getTime();

        return hoy > final_contacto;
    };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getComforts({ pagina: pageNumber, habilitado: enabled, query: "" });
    };

    const navegar = (id: number, tipo: string) => {
        if (tipo_usuario === 3 || tipo_usuario === 4) {
            window.open(
                `/dashboard/${tipo === "pedido" ? "reservas" : "crm"}/${id}`,
                "_blank"
            );
        }

        if (tipo_usuario === 6) {
            window.open(
                `/dashboard-corredor/${
                    tipo === "pedido" ? "reservas" : "crm"
                }/${id}`,
                "_blank"
            );
        }
    };

    const render = () => {
        return (
            <div className="w-full">
                <div className="flex items-center px-3 mx-auto mt-12 sm:px-6">
                    <div className="flex flex-col items-center justify-center w-full gap-3 md818:flex-row md818:justify-start">
                        <div className="flex">
                            {tipo_usuario === 3 ? (
                                <div className="flex w-full p-1 mr-4  border-2 border-[#CCCCCC]  rounded-lg appearance-none md:w-52 lg:w-80 left-2 text-stone-900">
                                    <button className="flex items-center justify-center px-2">
                                        <svg
                                            className="w-6 h-6 text-[#808080]"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                                        </svg>
                                    </button>{" "}
                                    <input
                                        onBlur={() => setInput("")}
                                        value={input}
                                        type="text"
                                        onChange={handleInputChange}
                                        className="w-full py-1 bg-transparent outline-none appearance-none 2xl:py-2"
                                        placeholder="Buscar"
                                    />
                                </div>
                            ) : null}
                            <div
                                onMouseOut={() => setModalEnabled(false)}
                                onMouseOver={() => setModalEnabled(true)}
                                className="relative"
                            >
                                <button className="w-[51px] h-[51px] flex items-center justify-center px-1 py-1  text-blue-blueFrom border-2 border-[#CCCCCC] rounded-lg group hover:bg-[#CCCCCC] hover:text-white">
                                    <svg
                                        className="w-6 h-6 text-[#808080] group-hover:text-white"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <circle cx="14" cy="6" r="2" />{" "}
                                        <line x1="4" y1="6" x2="12" y2="6" />{" "}
                                        <line x1="16" y1="6" x2="20" y2="6" />{" "}
                                        <circle cx="8" cy="12" r="2" />{" "}
                                        <line x1="4" y1="12" x2="6" y2="12" />{" "}
                                        <line x1="10" y1="12" x2="20" y2="12" />{" "}
                                        <circle cx="17" cy="18" r="2" />{" "}
                                        <line x1="4" y1="18" x2="15" y2="18" />{" "}
                                        <line x1="19" y1="18" x2="20" y2="18" />
                                    </svg>
                                </button>
                                <div
                                    className={
                                        modalEnabled
                                            ? "absolute top-8 xs:top-[50px]  w-36 z-10  pt-2 left-0 border rounded-lg "
                                            : "hidden"
                                    }
                                >
                                    <div
                                        className={
                                            modalEnabled
                                                ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md"
                                                : "hidden"
                                        }
                                    >
                                        <div
                                            onClick={() => (
                                                setModalEnabled(false),
                                                setenabled("Todos")
                                            )}
                                            className=" w-32 2xl:h-11 h-9 mx-auto 2xl:text-base text-sm cursor-pointer rounded-lg hover:bg-[#029BFC] hover:text-white flex items-center justify-start pl-4 "
                                        >
                                            Todos
                                        </div>
                                        <div
                                            onClick={() => (
                                                setModalEnabled(false),
                                                setenabled("Prospecto")
                                            )}
                                            className=" w-32 2xl:h-11 h-9 mx-auto 2xl:text-base text-sm cursor-pointer rounded-lg hover:bg-[#029BFC] hover:text-white flex items-center justify-start pl-4 "
                                        >
                                            Prospecto
                                        </div>
                                        <div
                                            onClick={() => (
                                                setModalEnabled(false),
                                                setenabled("Cliente")
                                            )}
                                            className=" w-32 2xl:h-11 h-9 mx-auto cursor-pointer 2xl:text-base text-sm rounded-lg hover:bg-[#029BFC] hover:text-white flex items-center justify-start pl-4 "
                                        >
                                            Cliente
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-4 md:ml-4">
                            <button
                                className="flex items-center gap-1 p-2 border-2 rounded-lg h-[51px] border-[#CCCCCC] text-[#808080] hover:scale-105"
                                onClick={() => setModalMasiva(!modalMasiva)}
                            >
                                <svg
                                    className="w-6 h-6 text-[#808080] group-hover:text-white"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                Carga masiva
                            </button>
                            <button
                                onClick={() => setModalVisibleUsuario(true)}
                                className="flex items-center gap-1 p-2 border-2 rounded-lg h-[51px] border-[#CCCCCC] text-[#808080] hover:scale-105"
                            >
                                <svg
                                    className="h-6 w-6 mt-0.5 text-[#808080]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <line x1="12" y1="5" x2="12" y2="19" />
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <span>Crear contacto</span>
                            </button>
                        </div>
                    </div>
                </div>
                <section className="flex justify-center items-center lg:py-[10px] ">
                    <div className="w-full px-11">
                        <div className="flex flex-wrap -mx-8">
                            <div className="w-full ">
                                <div className="flex flex-col">
                                    {true ? (
                                        <div className="w-full ">
                                            <table className="w-full mt-8 overflow-x-auto 2xl:mt-14">
                                                <thead className="text-left border-b-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                    <tr className="">
                                                        <th className="w-full min-w-[10rem] md:w-4/12 pb-4 ">
                                                            Nombre
                                                        </th>
                                                        <th className="w-full min-w-[5rem] md:w-2/12 pb-4 text-center hidden xs:table-cell">
                                                            Tipo
                                                        </th>
                                                        <th className="w-full min-w-[10rem] md:w-3/12 hidden lg1170:table-cell pb-4 text-center">
                                                            Correo
                                                        </th>
                                                        <th className="w-full min-w-[8rem] md:w-2/12 pb-4 text-center hidden lg:table-cell">
                                                            Celular
                                                        </th>
                                                        <th className="w-full min-w-[8rem] md:w-2/12 hidden sm:table-cell pb-4 text-center">
                                                            Etapa CRM
                                                        </th>
                                                        <th className="w-full min-w-[10rem] md:w-2/12  pb-4 text-center">
                                                            Ultima reserva
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <div className="my-2 2xl:my-4"></div>
                                                <tbody className="">
                                                    {contactos?.map(
                                                        (contacto) => (
                                                            <tr
                                                                key={
                                                                    contacto.id
                                                                }
                                                                onClick={() => (
                                                                    setContactoSelect(
                                                                        contacto
                                                                    ),
                                                                    setModalContacto(
                                                                        true
                                                                    )
                                                                )}
                                                                className="w-full text-sm cursor-pointer hover:bg-gray-200 2xl:text-base"
                                                            >
                                                                <td className=" border-r-[3px] border-[#CCCCCC]">
                                                                    <div className="flex items-center 2xl:py-3 py-1.5 ">
                                                                        <img
                                                                            src={
                                                                                `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                                                contacto?.avatar
                                                                            }
                                                                            alt="icono"
                                                                            className="object-cover w-10 h-10 rounded"
                                                                        />
                                                                        <p className="mx-3 text-[#3C3C3C] 2xl:text-base text-sm">
                                                                            {`${contacto.nombre} ${contacto.apellido}`}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td className=" border-r-[3px] border-[#CCCCCC] text-center hidden xs:table-cell px-1">
                                                                    {
                                                                        contacto.tipo_contacto
                                                                    }
                                                                </td>
                                                                <td className="hidden lg1170:table-cell border-r-[3px] border-[#CCCCCC] text-center">
                                                                    {
                                                                        contacto.correo
                                                                    }
                                                                </td>
                                                                <td className="border-r-[3px] border-[#CCCCCC] hidden text-center lg:table-cell">
                                                                    {
                                                                        contacto.celular
                                                                    }
                                                                </td>
                                                                <td
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        contacto
                                                                            .etapa_crm
                                                                            .length &&
                                                                            navegar(
                                                                                contacto.id_crm,
                                                                                "crm"
                                                                            );
                                                                    }}
                                                                    className={`hidden sm:table-cell text-center border-r-[3px] border-[#CCCCCC] text-blue-500 ${
                                                                        contacto
                                                                            .etapa_crm
                                                                            .length &&
                                                                        "cursor-pointer hover:scale-105"
                                                                    }`}
                                                                >
                                                                    {
                                                                        contacto.etapa_crm
                                                                    }
                                                                </td>
                                                                <td className="text-center ">
                                                                    <div className="flex items-center justify-center">
                                                                        <ShowDateToUser
                                                                            date={
                                                                                contacto.fecha_ultima_reserva
                                                                            }
                                                                        />

                                                                        {contacto.fecha_ultima_reserva && (
                                                                            <svg
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation();
                                                                                    navegar(
                                                                                        contacto.id_pedido,
                                                                                        "pedido"
                                                                                    );
                                                                                }}
                                                                                className="w-5 h-5 ml-2 cursor-pointer hover:scale-110 "
                                                                                id="Capa_2"
                                                                                data-name="Capa 2"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 100 100"
                                                                            >
                                                                                <g id="Testimonios">
                                                                                    <g>
                                                                                        <path
                                                                                            fill="#029BFC"
                                                                                            d="m0,20.57c.41-2.21.92-4.39,2.04-6.37,2.78-4.91,7.13-7.48,12.6-7.88,7.53-.56,15.08-.2,22.63-.17,2.87,0,5.45,1.03,6.69,3.89,1.77,4.07.15,9.8-6.3,9.97-6.53.18-13.07.05-19.61.04-.78,0-1.56.06-2.33.17-1.14.17-1.67.7-1.78,1.85-.07.65-.06,1.3-.06,1.95,0,19.42,0,38.84,0,58.26,0,3.54.3,3.84,3.83,3.84,19.45,0,38.9,0,58.35,0,3.47,0,3.78-.31,3.78-3.78,0-5.98,0-11.97,0-17.95,0-1.3.02-2.6.34-3.88.83-3.31,3.68-5.39,7-5.13,3.79.3,6.23,2.64,6.55,6.24.1,1.14.15,2.27.14,3.41-.07,6.6.16,13.2-.13,19.8-.34,7.72-5.97,13.92-13.65,15.02-.22.03-.44.1-.66.15H14.25c-.22-.05-.44-.11-.66-.14-4.94-.83-8.85-3.2-11.43-7.59-1.18-2.02-1.72-4.25-2.16-6.52V20.57Z"
                                                                                        />
                                                                                        <path
                                                                                            fill="#029BFC"
                                                                                            d="m75.81,14c-.41,0-.82,0-1.24,0-4-.01-8,0-12-.04-2.42-.03-4.58-.77-6.01-2.85-1.61-2.34-1.71-4.89-.53-7.41,1.15-2.44,3.3-3.63,5.9-3.65,10.54-.07,21.07-.06,31.61-.01,3.59.02,6.41,2.83,6.42,6.41.04,10.6.05,21.2,0,31.81-.02,3.63-2.89,6.3-6.73,6.46-3.67.16-6.74-2.43-7.03-6.03-.35-4.45-.11-8.91-.2-13.36,0-.38.08-.77-.09-1.16-.44.06-.63.44-.89.7-11.66,11.66-23.31,23.33-34.95,35-1.14,1.14-2.4,2.09-3.91,2.68-3,1.18-5.46.65-7.53-1.62-1.92-2.1-2.34-5.23-1.08-7.83.48-.98,1.18-1.78,1.94-2.54,11.79-11.76,23.58-23.52,35.37-35.27.34-.34.74-.63,1.1-.95-.05-.11-.11-.23-.16-.34Z"
                                                                                        />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        "Aun no tiene contactos"
                                    )}
                                </div>

                                <div className="container flex items-center justify-between p-1 mx-auto">
                                    <Paginado
                                        elementsPerPage={contactoS_PER_PAGE}
                                        cantTotalElements={contactosTotal}
                                        pagination={pagination}
                                        actualPage={pageNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <div>
            {contactoSelect && modalContacto && (
                <ModalContacto
                    contactoSelect={contactoSelect}
                    setContactoSelect={setContactoSelect}
                    modalVisible={modalContacto}
                    setModalVisible={setModalContacto}
                />
            )}
            {modalVisibleUsuario && (
                <ModalCrearContacto
                    modalVisibleUsuario={modalVisibleUsuario}
                    setModalVisibleUsuario={setModalVisibleUsuario}
                />
            )}
            {modalMasiva && (
                <ModalCargaMasiva
                    modalVisibleUsuario={modalMasiva}
                    setModalVisibleUsuario={setModalMasiva}
                />
            )}

            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Contactos">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Contactos">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            )}
        </div>
    );
};
