import React, { useEffect, useState } from "react";
import ResponsiveModalAbsolute from "../../../../components/ResponsiveModalAbsolute";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../../hooks/hooks";
import { tarifa } from "./TarifasAdicionales";
import Swal from "sweetalert2";

interface tarifaForm {
    nombre?: string;
    opcional?: string;
    precio?: string;
}

interface ModalProps {
    tarifaSelected: tarifa | null;
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    getTarifas: () => void;
}

type errorObject = { [key: string]: string };

export const EditarTarifa = ({
    modalVisible,
    setModalVisible,
    getTarifas,
    tarifaSelected,
}: ModalProps) => {
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<tarifaForm>({
        nombre: tarifaSelected?.nombre || "",
        opcional: tarifaSelected?.opcional || "Si",
        precio: tarifaSelected?.precio || "",
    });
    const [errors, setErrors] = useState<tarifaForm>({});

    useEffect(() => {
        setInput({
            nombre: tarifaSelected?.nombre || "",
            opcional: tarifaSelected?.opcional || "Si",
            precio: tarifaSelected?.precio || "",
        });
    }, [tarifaSelected]);

    const handleInputChange = (e: React.ChangeEvent<any>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    const validateInput = (form: tarifaForm) => {
        let errores: errorObject = {};

        if (!form.nombre) {
            errores.nombre = "Debe ingresar un nombre válido";
        } else if (!form.precio || Number(form.precio) < 0) {
            errores.precio = "Debe ingresar un precio válido";
        }

        return errores;
    };

    const handleSubmit = async () => {
        const errors = validateInput(input);
        setErrors(errors);

        if (Object.entries(errors).length === 0) {
            try {
                setLoading(true);
                if (
                    tipo_usuario === 3 ||
                    tipo_usuario === 4 ||
                    tipo_usuario === 6
                ) {
                    await fetchAxiosToken({
                        url: `/servicios_adicionales/put/${tarifaSelected?.id}`,
                        body: input,
                        method: "put",
                    });
                }

                if (tipo_usuario === 8) {
                    await fetchAxiosToken({
                        url: `/servicios_adicionales_habitaciones/put/${tarifaSelected?.id}`,
                        body: input,
                        method: "put",
                    });
                }
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Actualizado con éxito",

                    icon: "success",
                    confirmButtonText: "Ok",
                });
                setLoading(false);
                setModalVisible(false);
                setErrors({});
                getTarifas();
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            onClose={() => {
                setErrors({});
            }}>
            <div className="flex flex-col items-center gap-2 p-6">
                <h1 className="text-base font-medium 2xl:text-lg">
                    Editar tarifa adicional
                </h1>
                <label className="text-sm font-medium">Nombre</label>
                <input
                    value={input.nombre}
                    onChange={handleInputChange}
                    name="nombre"
                    type={"text"}
                    className="w-full max-w-xs p-2 mt-1 border rounded-lg shadow-md 2xl:p-3 placeholder:italic"
                    placeholder="Servicio de limpieza"
                />
                {errors.nombre && (
                    <p className="my-1 text-sm font-bold text-red-500 text-md">
                        {errors.nombre}
                    </p>
                )}

                <label className="text-sm font-medium">Precio</label>
                <input
                    value={input.precio}
                    onChange={handleInputChange}
                    name="precio"
                    type={"text"}
                    className="w-full max-w-xs p-2 mt-1 border rounded-lg shadow-md 2xl:p-3 placeholder:italic"
                    placeholder="256.00"
                />

                {errors.precio && (
                    <p className="my-1 text-sm font-bold text-red-500 text-md">
                        {errors.precio}
                    </p>
                )}
                <div className="flex items-center w-full max-w-xs gap-6 mt-4">
                    <label className="text-sm font-medium">
                        ¿La tarifa será opcional?
                    </label>
                    <select
                        onChange={handleInputChange}
                        className="2xl:p-2 p-1.5 border rounded-lg"
                        name="opcional"
                        value={input.opcional}>
                        <option>Si</option>
                        <option>No</option>
                    </select>
                </div>
                <button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                    className="p-3 mt-6 font-medium text-white border rounded-lg bg-[#029BFC] disabled:bg-gray-300 active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm">
                    {loading ? "Guardando..." : "Guardar tarifa adicional"}
                </button>
            </div>
        </ResponsiveModalAbsolute>
    );
};
