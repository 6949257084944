import { useEffect, useState } from "react";
import ToggleButton from "../../components/helpers/ToggleButton";

import { useAppSelector } from "../../hooks/hooks";
import { ModalChat } from "../modalEdit/ModalChat";
import Reviews from "./Reviews";

import Carrousel from "./Carrousel";
import Habitaciones from "./Habitaciones";
import Imagenes from "./Imagenes";
import Mapa from "./Mapa";
import Navbar from "./Navbar/Navbar";

import { PrecioBase } from "./PrecioBase";
import { Descripcion } from "./Descripcion";
import Eventos from "./Eventos";
import { FooterLanding } from "../modalEdit/FooterLanding";

import HabitacionesHotel from "../design1New/HabitacionesHotel";

export interface Capacidad {
    personas: number;
    mascotas: number;
    banos: string;
}

const Design2 = (tipo_landing: { tipo_landing: number }) => {
    const [toggleButton, setToggleButton] = useState(false);

    const { id_propietario, plan } = useAppSelector(
        (state) => state.landing_house.house
    );
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    return (
        <div className="2xl:px-[8%]  overflow-clip relative">
            <Navbar />
            <div className="flex flex-col bg-white border shadow-lg ">
                <div id="fotos">
                    <Carrousel
                        tipo_landing={tipo_landing.tipo_landing}
                        toggleButton={toggleButton}
                    />
                </div>

                <div className="flex flex-col gap-8 p-4 bg-white lg:py-10 lg:px-16">
                    {(id_propietario === id || tipo_usuario === 4) && (
                        <div className="flex justify-end w-10/12 mt-3">
                            <p>
                                <span className="text-[#053465] font-medium mr-2">
                                    Activar edicion rapida
                                </span>
                            </p>
                            <ToggleButton
                                isToggled={false}
                                onChangeToggle={(e) => setToggleButton(e)}
                            />
                        </div>
                    )}

                    <PrecioBase
                        toggleButton={toggleButton}
                        tipo_landing={tipo_landing.tipo_landing}
                    />

                    <div id="imagenes">
                        <Imagenes toggleButton={toggleButton} />
                    </div>

                    {tipo_landing.tipo_landing !== 8 && (
                        <div id="habitaciones">
                            <Habitaciones toggleButton={toggleButton} />
                        </div>
                    )}

                    {tipo_landing.tipo_landing === 8 && (
                        <div id="habitaciones">
                            <HabitacionesHotel toggleButton={toggleButton} />
                        </div>
                    )}

                    <div id="servicios">
                        {<Descripcion toggleButton={toggleButton} />}
                    </div>
                </div>
                {plan > 1 && (
                    <div id="actividades">
                        <Eventos toggleButton={toggleButton} />
                    </div>
                )}
                <div id="resenas">
                    <Reviews />
                </div>
                <div className="flex flex-col gap-8 p-4 bg-white lg:py-10 lg:px-16">
                    <div id="ubicacion">
                        <Mapa toggleButton={toggleButton} />
                    </div>
                </div>
                <div>
                    <FooterLanding toggleButton={toggleButton} />
                </div>
            </div>
            <ModalChat bg={"bg-[#e36c4e]"} />
        </div>
    );
};

export default Design2;
