import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hooks";

const TITLES = [
    {
        id: 1,
        title: "Casas",
        url: "casas",

        icono: (
            <svg
                className="text-white w-7 h-7"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                {" "}
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />{" "}
                <polyline points="9 22 9 12 15 12 15 22" />
            </svg>
        ),
    },
    {
        id: 2,
        title: "Chat",
        url: "chat",

        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 95.03">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m100,19.8v32.79c-.11.57-.22,1.15-.33,1.72-1.91,10.16-11.15,17.98-21.49,18.05-10.15.07-20.3.03-30.44,0-.92,0-1.58.26-2.23.92-6.81,6.85-13.65,13.67-20.48,20.5-1.22,1.21-2.43,1.54-3.69.98-1.39-.63-1.83-1.78-1.83-3.25.03-5.92.01-11.84.01-17.76q0-1.49-1.5-1.78C7.76,69.98.03,60.67,0,50.23c-.02-9.33,0-18.67,0-28,0-.91.06-1.81.17-2.72C1.59,8.55,11.17.04,22.24.02,40.75,0,59.25-.02,77.76.03c7.06.02,12.82,2.95,17.33,8.35,2.76,3.31,4.25,7.19,4.91,11.41ZM26.68,31.73c-3.19-.02-5.74,2.44-5.82,5.6-.07,3.12,2.59,5.81,5.75,5.81,3.12,0,5.71-2.55,5.77-5.7.06-3.09-2.55-5.7-5.7-5.72Zm23.43,0c-3.22-.03-5.86,2.54-5.83,5.68.02,3.12,2.62,5.72,5.74,5.73,3.12.01,5.71-2.53,5.79-5.68.07-3.08-2.53-5.71-5.69-5.73Zm23.42,0c-3.22-.02-5.86,2.54-5.83,5.69.02,3.13,2.62,5.72,5.74,5.73,3.12,0,5.71-2.54,5.78-5.69.07-3.08-2.54-5.71-5.69-5.73Z"
                    />
                </g>
            </svg>
        ),
    },

    {
        id: 3,
        title: "Contactos",
        url: "contactos",

        icono: (
            <svg
                className="w-5 h-5 2xl:w-6 2xl:h-6"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 62.39">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m53.4,27.32c1.41.22,2.72.32,4,.64,8.74,2.17,15.03,10.14,15.08,19.14.01,2.64.02,5.28,0,7.92-.04,4.06-3.3,7.34-7.36,7.35-10.06.03-20.12.02-30.18,0-4.06,0-7.38-3.29-7.35-7.34.02-3.68-.31-7.38.23-11.05,1.27-8.59,9.01-15.79,17.68-16.5.34-.03.68-.1,1.36-.2-3.87-1.13-6.71-3.13-8.63-6.34-1.89-3.18-2.51-6.57-1.65-10.19C38.1,4.3,43.7-.08,50.3,0c6.36.07,11.95,4.75,13.24,11.08,1.44,7.03-2.04,12.65-10.14,16.25Z"
                        />
                        <path
                            fill="white"
                            d="m17.38,24.84c-7.04-2.13-9.24-8.37-8.29-13.13,1.15-5.76,5.83-9.33,11.87-9.16,5.08.14,9.72,4.39,10.41,9.66.76,5.81-2.66,11.05-8.79,12.69,3.26,0,6.03.78,8.64,2.2,1.15.62,1.15.7.17,1.57-5.69,5.06-8.55,11.4-8.58,19.02,0,1.86,0,3.73,0,5.59q0,1.64-1.7,1.64c-4.35,0-8.7,0-13.05,0-4.27,0-7.38-2.52-7.8-6.74-.4-4.09-.53-8.26,1.13-12.17,3.01-7.11,8.5-10.69,15.99-11.17Z"
                        />
                        <path
                            fill="white"
                            d="m82.24,24.91c9.43.16,16.59,6.75,17.53,15.9.27,2.59.34,5.2-.03,7.78-.53,3.7-3.61,6.32-7.36,6.33-4.58.02-9.17-.03-13.75.03-1.16.01-1.43-.42-1.43-1.49-.01-3.37.27-6.76-.31-10.11-1.01-5.89-3.8-10.78-8.3-14.71-.92-.8-.91-.91.17-1.5,2.62-1.43,5.41-2.25,8.53-2.22-7.12-2.13-9.48-8.2-8.57-13.09,1.09-5.78,5.76-9.4,11.78-9.27,5.15.11,9.83,4.36,10.53,9.67.76,5.81-2.67,11.04-8.82,12.69Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 6,
        title: "CRM",
        url: "crm",
        textInfo:
            "Aca podras ver los leads que te llegan, desde chats y pedidos!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 87.85">
                <g id="Testimonios">
                    <g>
                        <path
                            fill="white"
                            d="m0,27.24c.32-.65.78-1.19,1.29-1.7,7.96-7.95,15.91-15.9,23.86-23.85,1.87-1.87,3.38-1.86,5.26.03,2.13,2.14,4.24,4.31,6.4,6.42.55.54.55.87,0,1.41-3.12,3.08-6.21,6.2-9.32,9.3-2.04,2.03-3.12,4.42-2.88,7.36.19,2.22,1.15,4.05,2.71,5.57,5.4,5.25,13.98,5.35,19.76.26,2.12-1.86,3.18-1.87,5.32-.02,5.02,4.33,11.26,4.98,17.11,1.75.56-.31.91-.26,1.35.18,5.69,5.71,11.41,11.4,17.09,17.11,1.89,1.9,1.95,4.57.23,6.39-1.78,1.88-4.43,1.97-6.44.07-2.36-2.23-4.62-4.57-6.92-6.87-.54-.54-1.12-.99-1.9-1.1-1.17-.17-2.3.4-2.86,1.44-.6,1.11-.46,2.35.49,3.32,2.32,2.36,4.68,4.69,7.02,7.05,1.2,1.21,1.77,2.65,1.39,4.35-.7,3.13-4.27,4.52-6.91,2.71-.43-.29-.81-.66-1.17-1.02-2.17-2.15-4.32-4.33-6.49-6.47-1.35-1.33-3.07-1.39-4.23-.19-1.14,1.18-1.04,2.83.27,4.16,2.24,2.26,4.51,4.5,6.75,6.77,1.85,1.88,2.07,4.35.61,6.17-1.76,2.18-4.65,2.36-6.78.36-1.19-1.11-2.34-2.26-3.45-3.45-1.92-2.06-4.23-3.33-7.06-3.51-.76-.05-.95-.37-1.02-1.08-.43-4.2-2.59-7.13-6.56-8.64-.72-.27-.65-.61-.43-1.18,1.73-4.46.69-8.79-2.98-11.76-2.97-2.4-6.36-2.81-9.91-1.47-.85.32-1.2.28-1.54-.65-2.44-6.47-10.97-8.42-16.09-3.72-1.56,1.43-1.59,1.44-2.23-.61-.9-2.89-2.43-5.37-4.6-7.47-1.24-1.2-2.42-2.45-3.64-3.66-.56-.55-1.07-1.14-1.46-1.82v-1.95Z"
                        />
                        <path
                            fill="white"
                            d="m55.02,8.7c1.46,0,2.93-.03,4.39,0,1.87.05,3.4-.6,4.7-1.95,1.69-1.76,3.44-3.46,5.17-5.18,2.11-2.1,3.57-2.11,5.65-.03,7.86,7.86,15.73,15.72,23.59,23.59,1.99,1.99,1.99,3.5,0,5.51-1.99,2.01-4,4-6,6-1.62,1.62-2.19,3.55-1.78,5.79.14.8.28,1.59.1,2.42-.16.74-.43.7-.87.24-.18-.19-.37-.37-.55-.55-6.02-6.02-12.06-12.04-18.06-18.08-.67-.67-1.05-.75-1.79-.06-3.54,3.3-8.91,3.75-13.22,1.18-.78-.47-1.55-.96-2.34-1.41-3.16-1.8-7.09-1.45-9.83.94-1.8,1.57-3.72,2.85-6.19,3-2.79.17-5.19-.76-7.09-2.79-1.08-1.16-.96-2.84.2-4.05,1.33-1.38,2.7-2.72,4.06-4.08,2.39-2.39,4.84-4.73,7.16-7.19,2.18-2.31,4.81-3.33,7.93-3.31,1.59.01,3.19,0,4.78,0Z"
                        />
                        <path
                            fill="white"
                            d="m37.7,56.36c-.03,1.4-.53,2.47-1.41,3.36-3.37,3.39-6.74,6.77-10.13,10.13-1.36,1.34-2.99,1.82-4.83,1.15-1.78-.66-2.76-2.01-2.94-3.89-.13-1.33.35-2.51,1.28-3.45,3.43-3.46,6.86-6.92,10.34-10.33,1.43-1.4,3.18-1.78,5.03-.93,1.72.79,2.56,2.22,2.66,3.97Z"
                        />
                        <path
                            fill="white"
                            d="m12.22,60.93c-1.51-.08-2.92-.89-3.73-2.56-.82-1.68-.61-3.34.59-4.74,1.18-1.38,2.52-2.62,3.8-3.91.91-.92,1.81-1.87,2.78-2.74,1.34-1.19,2.89-1.59,4.61-.94,1.7.65,2.67,1.92,2.91,3.71.18,1.33-.23,2.55-1.15,3.5-2.12,2.2-4.3,4.34-6.48,6.48-.82.81-1.86,1.17-3.33,1.2Z"
                        />
                        <path
                            fill="white"
                            d="m33.25,81.65c-1.82-.13-3.23-.88-4.04-2.52-.81-1.63-.73-3.29.45-4.66,2.16-2.51,4.52-4.85,7.01-7.03,1.74-1.53,4.34-1.27,5.93.36,1.58,1.61,1.82,4.13.32,5.86-2.17,2.5-4.57,4.79-7.01,7.04-.74.68-1.72.88-2.67.96Z"
                        />
                        <path
                            fill="white"
                            d="m43.25,83.28c0-.1,0-.19,0-.29.13-2.99,4.64-6.68,7.42-6.09,3.17.68,4.63,4.3,2.74,6.94-.87,1.22-1.95,2.28-3.16,3.16-1.44,1.05-3.03,1.09-4.61.32-1.56-.76-2.41-2.27-2.39-4.04Z"
                        />
                    </g>
                </g>
            </svg>
        ),
    },
    {
        id: 9,
        title: "Medios de pago",
        // url: "medios-de-pago",
        textInfo:
            "Aca podras configurar los diferentes medios de pago que te ofrece BLU!",
        icono: (
            <svg
                className=" w-7 h-7"
                id="Capa_2"
                data-name="Capa 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 99.97">
                <g id="Testimonios">
                    <path
                        fill="white"
                        d="m50.01,0c27.44-.18,49.95,22.47,49.99,49.84.04,27.56-22.48,50.32-50.37,50.12C22.42,99.78.09,77.54,0,50.14-.09,22.41,22.57-.15,50.01,0Zm-4.85,41.39c0-2.29,1.48-4.26,3.65-4.87,2.09-.59,4.24.28,5.47,2.22.19.29.34.6.52.9.97,1.54,2.78,2.03,4.34,1.17,1.5-.82,2.04-2.59,1.27-4.22-1.27-2.68-3.23-4.66-6.02-5.72-.91-.34-1.24-.83-1.15-1.76.07-.79.03-1.59,0-2.39-.06-2.07-1.37-3.49-3.22-3.5-1.89-.02-3.22,1.41-3.28,3.55-.02.83-.05,1.67,0,2.49.06.8-.27,1.15-1,1.42-7.48,2.84-9.69,12.52-4.26,18.4,2.44,2.64,5.47,3.74,8.97,3.95,2.18.13,3.88,1.68,4.4,3.81.54,2.17-.28,4.33-2.12,5.54-2.55,1.68-5.78.74-7.3-2.13-1.02-1.92-2.88-2.61-4.58-1.69-1.65.89-2.12,2.78-1.14,4.69,1.28,2.5,3.22,4.34,5.84,5.36.95.37,1.24.88,1.2,1.84-.05,1.03-.14,2.08.11,3.1.38,1.54,1.62,2.56,3.13,2.55,1.51,0,2.72-1.02,3.11-2.57.21-.82.19-1.67.11-2.48-.15-1.38.29-2.1,1.66-2.7,5-2.21,7.71-7.74,6.46-12.74-1.3-5.19-5.65-8.68-11.33-9.1-2.79-.21-4.86-2.38-4.87-5.11Z"
                    />
                </g>
            </svg>
        ),
        children: [
            {
                id: "transferencia",
                title: "Transferencia",
                url: "transferencia",
                textInfo: "Configurar la cuenta para transferir",
                icono: (
                    <svg
                        className="w-8 h-8 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                    </svg>
                ),
            },
            {
                id: "mercadopago",
                title: "Mercado Pago",
                url: "mercado-pago",
                textInfo: "Configurar la cuenta para transferir",
                icono: (
                    <svg
                        className="w-8 h-8 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z" />
                    </svg>
                ),
            },
        ],
    },
    {
        id: 10,
        title: "Suscripciones",
        url: "suscripciones",
        textInfo: "Aca podras pagar tu suscripcion a BLU!",
        icono: <p>BLU</p>,
    },
];

interface Props {
    setShowBurgerMenu: (visible: boolean) => void;
    showBurgerMenu: boolean;
}

export const BurguerMenuMobile = ({
    setShowBurgerMenu,
    showBurgerMenu,
}: Props) => {
    const { currency } = useAppSelector((state) => state.informacion);
    const [showChildren, setShowChildren] = useState<number>(-1);
    const navigate = useNavigate();

    const { avatar, nombre, apellido, plan } = useAppSelector(
        (state) => state.corredor
    );

    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);

    const handleNavigate = (title: any) => {
        if (title.url) navigate(`/dashboard-corredor/${title.url}`);
        else {
            if (showChildren === title.id) setShowChildren(-1);
            else setShowChildren(title.id);
        }
    };

    const handleChildrenClick = (url: string) => {
        navigate(`/dashboard-corredor/${url}`);
    };

    const ocultar = (titulo: string) => {
        const nombres = [
            "personas",
            "disponibilidad-maxima",
            "descuento-estadia",
            "cupones",
            "Chat",
            "CRM",
            "Estadisticas",
            "mercadopago",
            "Sub usuarios",
        ];

        if (nombres.includes(titulo) && plan < 2) {
            return "hidden";
        } else {
            return "";
        }
    };

    const opcionesPremium = (titulo: string) => {
        const nombres = [
            "personas",
            "disponibilidad-maxima",
            "descuento-estadia",
            "cupones",
            "Chat",
            "CRM",
            "Estadisticas",
            "mercadopago",
            "Sub usuarios",
            "Calendario",
        ];

        if (nombres.includes(titulo) && plan < 2) {
            return true;
        } else if (titulo === "Chat room" && plan < 3) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div
            className={`${
                showBurgerMenu ? "translate-x-0" : "translate-x-full"
            } sm:hidden fixed flex flex-col overflow-auto top-0 right-0 left-0 bottom-0 transition-all duration-500 ease-in-out z-50 bg-[#029BFC] text-white`}>
            <button
                onClick={() => setShowBurgerMenu(false)}
                className="fixed p-1 font-medium right-1 hover:underline w-max">
                Cerrar
            </button>
            <div className="mt-10">
                {TITLES.map((title) => (
                    <div
                        className={`transition ease-in cursor-pointer p-1 `}
                        key={title.id}>
                        <button
                            className="w-full"
                            disabled={opcionesPremium(title.title)}
                            title={
                                opcionesPremium(title.title)
                                    ? "opcion estándar"
                                    : ""
                            }>
                            <div
                                className={` flex flex-col  gap-2  w-full ${
                                    opcionesPremium(title.title)
                                        ? " bg-slate-300 bg-opacity-20 mr-2 py-2 rounded-md pl-1 cursor-not-allowed"
                                        : " cursor-pointer "
                                }`}>
                                <div
                                    className="flex items-center gap-2"
                                    onClick={() => handleNavigate(title)}>
                                    <span className="text-white">
                                        {title.icono}
                                    </span>
                                    <span className="font-medium capitalize ">
                                        {title.title}
                                    </span>
                                    {opcionesPremium(title.title) && (
                                        <span className="text-[10px] border border-yellow-300 text-yellow-300 p-[2px] rounded-md">
                                            Estándar
                                        </span>
                                    )}
                                </div>
                                <div
                                    style={{
                                        height:
                                            showChildren === title.id
                                                ? `${
                                                      title.children &&
                                                      title.children.length *
                                                          2.5
                                                  }rem`
                                                : "0rem",
                                    }}
                                    className={`${
                                        showChildren === title.id
                                            ? `opacity-100 visible`
                                            : "opacity-0 invisible"
                                    } transition-all duration-300 ease-in-out flex items-start flex-col`}>
                                    {title.children &&
                                        title.children.map((children) => (
                                            <button
                                                className={
                                                    currency === "EUR" &&
                                                    children.id ===
                                                        "mercadopago"
                                                        ? "hidden"
                                                        : ""
                                                }
                                                disabled={opcionesPremium(
                                                    children.id
                                                )}
                                                title={
                                                    opcionesPremium(children.id)
                                                        ? "opcion estándar"
                                                        : ""
                                                }
                                                onClick={() =>
                                                    handleChildrenClick(
                                                        children.url
                                                    )
                                                }
                                                key={children.id}>
                                                <div
                                                    className={`  flex items-center p-2 pl-4 lg:pl-10 ${
                                                        opcionesPremium(
                                                            children.id
                                                        )
                                                            ? "bg-slate-300 bg-opacity-20 mr-2 py-2 rounded-md pl-1 cursor-not-allowed"
                                                            : "hover:underline hover:scale-105 "
                                                    }`}>
                                                    <span className="mr-1">
                                                        {children.title}
                                                    </span>
                                                    {/* <BotonInformacion
                                                        text={children.textInfo}
                                                        widthHeight={"w-4 h-4"}
                                                    /> */}
                                                    {opcionesPremium(
                                                        children.id
                                                    ) && (
                                                        <span className="text-[10px] border border-yellow-300 text-yellow-300 p-[2px] rounded-md">
                                                            Estándar
                                                        </span>
                                                    )}
                                                </div>
                                            </button>
                                        ))}
                                </div>
                            </div>
                        </button>
                        <hr className="mt-2" />
                    </div>
                ))}
            </div>
        </div>
    );
};
