const DemoBanner = () => {
    return (
        <div className="md723:px-12 px-5 py-6 mx-auto ">
            <div className=" text-center bg-[#C3EA431A]/10 p-8 md:p-10 rounded-xl">
                <h2 className="mb-6 text-xl md:text-3xl font-bold text-[#029DE0]">
                    Transforma la gestión de tu hotel con BluStay
                </h2>

                <p className="mb-8  md:text-xl text-center text-[#071829]">
                    Solicita tu demo gratuita hoy mismo y descubre cómo podemos
                    ayudarte a optimizar tus operaciones y mejorar la
                    experiencia de tus huéspedes.
                </p>

                <button
                    onClick={() => {
                        document
                            .getElementById("formularioReserva")
                            ?.scrollIntoView({
                                behavior: "smooth",
                            });
                    }}
                    className="px-8 md:px-16 py-3 md:text-lg font-bold text-[#071829] transition duration-300 transform bg-[#C3EA43] rounded-lg shadow-lg hover:bg-blue-50 hover:scale-105"
                >
                    ¡Solicita tu prueba gratuita!
                </button>
            </div>
        </div>
    );
};

export default DemoBanner;
