import React, { useEffect, useState } from "react";

let DATA = {
    starter: {
        una_casa: 19,
        casa_adicional: 2,
    },

    standard: {
        una_casa: 39,
        casa_adicional: 3,
    },
    premium: {
        una_casa: 79,
        casa_adicional: 5,
    },
};

const Planes = () => {
    const [Plan, setPlan] = useState("Mensual");
    const [meses, setMeses] = useState<number>(1);
    const [numberHouse, setNumberHouse] = useState<number>(1);
    const [moneda, setMoneda] = useState("$");
    const [tipoPlan, setTipoPlan] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const [activeButton, setActiveButton] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handlePlan = (planString: string) => {
        setPlan(planString);
    };

    const handleButtonClick = (button: any) => {
        setActiveButton(button);
        if (button === "+") {
            setNumberHouse(Number(numberHouse) + 1);
        }

        if (button === "-") {
            if (numberHouse === 1) return;
            setNumberHouse(Number(numberHouse) - 1);
        }
    };

    const handleOutsideClick = () => {
        setActiveButton(null);
    };

    const handleChange = (e: any) => {
        const value = e.target.value;

        // Verifica que el valor sea un número y que sea al menos 1
        if (/^\d*$/.test(value) && (value === "" || parseInt(value) >= 1)) {
            setNumberHouse(value); // Solo actualiza si pasa las validaciones
        }
    };

    useEffect(() => {
        // Agrega el evento de clic al documento
        document.addEventListener("click", handleOutsideClick);
        return () => {
            // Limpia el evento al desmontar el componente
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    const getStarter = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi Anual") {
            porcentaje = 0.7;
        }
        let price =
            numberHouse < 2
                ? DATA.starter.una_casa
                : Number(DATA.starter.una_casa) +
                  Number(DATA.starter.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (descuento) {
            price = Math.ceil(price * porcentaje);
        }

        return price * 12;
    };

    const getStandard = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi Anual") {
            porcentaje = 0.7;
        }
        let price =
            numberHouse < 2
                ? DATA.standard.una_casa
                : Number(DATA.standard.una_casa) +
                  Number(DATA.standard.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (descuento) {
            price = Math.ceil(price * porcentaje);
        }

        return price * 12;
    };

    const getPremium = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi Anual") {
            porcentaje = 0.7;
        }

        let price =
            numberHouse < 2
                ? DATA.premium.una_casa
                : Number(DATA.premium.una_casa) +
                  Number(DATA.premium.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (descuento) {
            price = Math.ceil(price * porcentaje);
        }

        return price * 12;
    };

    return (
        <div className=" ">
            <div className="lg::mt-10  mt-5  ">
                <div className="flex md723:flex-row flex-col  items-center justify-between w-11/12 xl:w-4/5 mx-auto 2xl:w-2/3 gap-5 lg:gap-0 ">
                    <div className="flex flex-col items-center justify-center gap-4">
                        <div className="2xl:text-base text-sm font-semibold">
                            Elije el número de unidades *
                        </div>
                        <div className="flex items-center justify-center gap-2 p-2 border-2 border-gray-300 rounded-full">
                            <div
                                className={`border-2 ${
                                    activeButton === "-"
                                        ? "border-gray-500 bg-gray-200"
                                        : "border-gray-100"
                                }  rounded-full h-10 w-10 flex justify-center items-center cursor-pointer`}
                                onClick={(e) => {
                                    e.stopPropagation(); // Evita que el clic en este botón cierre el estado activo
                                    handleButtonClick("-");
                                    // Aquí puedes manejar la lógica para restar
                                }}
                            >
                                <span className="mb-1 text-2xl font-semibold leading-none">
                                    -
                                </span>
                            </div>
                            <div className="flex items-center justify-center h-10 border-2 border-gray-300 rounded-md">
                                <input
                                    type="text"
                                    className="w-16 text-xl text-center "
                                    value={numberHouse}
                                    onChange={handleChange}
                                />
                            </div>
                            <div
                                className={`border-2 ${
                                    activeButton === "+"
                                        ? "border-gray-500 bg-gray-200"
                                        : "border-gray-100"
                                }  rounded-full h-10 w-10 flex justify-center items-center cursor-pointer`}
                                onClick={(e) => {
                                    e.stopPropagation(); // Evita que el clic en este botón cierre el estado activo
                                    handleButtonClick("+");
                                    // Aquí puedes manejar la lógica para restar
                                }}
                            >
                                <span className="mb-1 text-2xl font-semibold leading-none">
                                    +
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center gap-4">
                        <div className="2xl:text-base text-sm font-semibold">
                            Elije como quieres pagar
                        </div>
                        <div className="flex items-center justify-center px-1 py-1 border-2 xs:w-96 w-80 rounded-3xl">
                            <div
                                className={`${
                                    Plan === "Bi Anual"
                                        ? "bg-[#0E1928] text-[#C0D345]"
                                        : " text-[#0E1928]"
                                }   text-center  rounded-l-3xl cursor-pointer w-1/2 h-full `}
                                onClick={() => {
                                    handlePlan("Bi Anual");
                                    setMeses(24);
                                }}
                            >
                                <p className="lg:text-lg text-base ">
                                    Bi Anual
                                </p>
                                <p
                                    className={`text-[8px] w-full  mb-1 ${
                                        Plan === "Bi Anual"
                                            ? "text-white"
                                            : " text-[#0E1928]"
                                    }`}
                                >
                                    - 30%
                                </p>
                            </div>
                            <div
                                className={`${
                                    Plan === "Anual"
                                        ? "bg-[#0E1928] text-[#C0D345]"
                                        : " text-[#0E1928]"
                                }   text-center  cursor-pointer w-1/2 h-full `}
                                onClick={() => {
                                    handlePlan("Anual");
                                    setMeses(12);
                                }}
                            >
                                <p className="lg:text-lg text-base ">Anual</p>
                                <p
                                    className={`text-[8px] w-full  mb-1 ${
                                        Plan === "Anual"
                                            ? "text-white"
                                            : " text-[#0E1928]"
                                    }`}
                                >
                                    - 20%
                                </p>
                            </div>

                            <div
                                className={`${
                                    Plan === "Semestral"
                                        ? "bg-[#0E1928] text-[#C0D345]"
                                        : " text-[#0E1928]"
                                }   text-center  cursor-pointer w-1/2 h-full `}
                                onClick={() => {
                                    handlePlan("Semestral");
                                    setMeses(6);
                                }}
                            >
                                <p className="lg:text-lg text-base ">
                                    Semestral
                                </p>
                                <p
                                    className={`text-[8px] w-full  mb-1 ${
                                        Plan === "Semestral"
                                            ? "text-white"
                                            : " text-[#0E1928]"
                                    }`}
                                >
                                    - 10%
                                </p>
                            </div>
                            <div
                                className={` ${
                                    Plan === "Mensual"
                                        ? "bg-[#0E1928] text-[#C0D345]"
                                        : " text-[#0E1928]"
                                }   px-4 text-lg   rounded-r-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                                onClick={() => {
                                    handlePlan("Mensual");
                                    setMeses(1);
                                }}
                            >
                                <p className="lg:text-lg text-base ">Mensual</p>
                                <p
                                    className={`text-[8px] w-full  mb-1 ${
                                        Plan === "Mensual"
                                            ? "text-white"
                                            : " text-[#0E1928]"
                                    }`}
                                ></p>
                            </div>
                        </div>
                    </div>

                    <div className="w-60 hidden lg:flex "></div>
                </div>

                <div className="flex flex-col lg:items-start items-center lg:justify-between justify-center w-full gap-6  mx-auto mt-5 lg:flex-row 2xl:w-2/3 xl:w-4/5 md:w-11/12 ">
                    <div className="flex flex-col  rounded-2xl border border-[#D5D8E2] px-5 pt-10 h-[720px] w-[330px] mb-4">
                        <div
                            className={`text-[#0E1928] text-base md:text-lg xl:text-xl font-bold  ${"mt-5"} `}
                        >
                            Starter
                        </div>

                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col border-b border-[#D5D8E2] border-dashed pb-5  ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                    {Plan === "Mensual" ? (
                                        <div className="flex">
                                            <p className="text-4xl font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl font-bold">
                                                {(numberHouse < 2
                                                    ? DATA.starter.una_casa
                                                    : Number(
                                                          DATA.starter.una_casa
                                                      ) +
                                                      Number(
                                                          DATA.starter
                                                              .casa_adicional
                                                      ) *
                                                          (Number(numberHouse) -
                                                              1)
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl text-[#179BD8] font-bold">
                                                {" "}
                                                {(
                                                    getStarter({
                                                        tipo: Plan,
                                                        descuento: true,
                                                    }) / 12
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                    )}

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                    {moneda}
                                    {Plan === "Mensual"
                                        ? getStarter({
                                              tipo: Plan,
                                          })
                                        : getStarter({
                                              tipo: Plan,
                                              descuento: true,
                                          })}{" "}
                                    al año.
                                    {Plan === "Semestral" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 10%
                                        </div>
                                    )}
                                    {Plan === "Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 20%
                                        </div>
                                    )}
                                    {Plan === "Bi Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 30%
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col mb-8 ">
                                <div
                                    className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                                >
                                    <p className="text-sm md:text-lg">
                                        El plan incluye:{" "}
                                    </p>
                                    <div className="marker:text-[#0E1928] text-xs md:text-base mt-3 flex flex-col justify-center items-start">
                                        {[
                                            "Diseños personalizados de página web.",
                                            "Contar con un subdominio.",
                                            "Calendario de reservas.",
                                            "Módulos de contactos.",
                                            "Tarifas adicionales (Limpieza, Guardia, Mantenimiento, otros).",
                                            "E-mail resumen de alquiler o reserva para el propietario y cliente.",
                                            "Pagos vía transferencia bancaria.",
                                            "Sección de reseñas de los clientes.",
                                        ].map((text, index) => (
                                            <div
                                                key={index}
                                                className="mb-2 flex items-center gap-x-2"
                                            >
                                                <svg
                                                    width="29"
                                                    height="29"
                                                    viewBox="0 0 29 29"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="flex-shrink-0"
                                                >
                                                    <path
                                                        d="M8.43373 15.0943L11.8289 18.4895L19.5894 10.729"
                                                        stroke="#282A37"
                                                        strokeWidth="1.75048"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <p className="flex-1">{text}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl border  bg-[#0E1928] text-white  px-5 pt-10 h-[720px] w-[330px] mb-4 relative">
                        <p className="text-xs text-[#071829] bg-[#C0D345] rounded-lg font-bold px-3 py-1 absolute top-7 left-5">
                            El más seleccionado
                        </p>
                        <div
                            className={`text-white text-base md:text-lg xl:text-xl font-bold  ${"mt-5"} `}
                        >
                            <p className="text-base md:text-lg xl:text-xl font-bold ">
                                Estándar
                            </p>{" "}
                            {/*    */}
                        </div>

                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col border-b border-[#D5D8E2] border-dashed pb-5  ">
                                <div className="flex items-end text-white text-2xl md:text-3xl xl:text-5xl   mt-2">
                                    {Plan === "Mensual" ? (
                                        <div className="flex">
                                            <p className="text-4xl font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl font-bold">
                                                {" "}
                                                {(numberHouse < 2
                                                    ? DATA.standard.una_casa
                                                    : Number(
                                                          DATA.standard.una_casa
                                                      ) +
                                                      Number(
                                                          DATA.standard
                                                              .casa_adicional
                                                      ) *
                                                          (Number(numberHouse) -
                                                              1)
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl  font-bold">
                                                {(
                                                    getStandard({
                                                        tipo: Plan,
                                                        descuento: true,
                                                    }) / 12
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                    )}

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="flex items-center gap-1 mt-2 text-sm ">
                                    {moneda}
                                    {Plan === "Mensual"
                                        ? getStandard({
                                              tipo: Plan,
                                          })
                                        : getStandard({
                                              tipo: Plan,
                                              descuento: true,
                                          })}{" "}
                                    al año.
                                    {Plan === "Semestral" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 10%
                                        </div>
                                    )}
                                    {Plan === "Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 20%
                                        </div>
                                    )}
                                    {Plan === "Bi Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 30%
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col mb-7 text-white ">
                                <div className={` text-sm  mt-4 mb-0`}>
                                    <p className="text-sm md:text-lg">
                                        El plan incluye:{" "}
                                    </p>

                                    <div className=" text-xs md:text-base mt-3 flex flex-col justify-center items-start">
                                        {[
                                            "Incluye el Plan Starter",
                                            "Opción de descuento por estadía por número de noches (ej. 7 días, 15 días, etc).",
                                            "Precios dinámicos por dia de semana y/o fin de semana.",
                                            "Módulo de chat.",
                                            "Módulo de CRM.",
                                            "Módulo de Dashboard con estadísiticas.",
                                            "Creación de cupones de descuento.",
                                        ].map((text, index) => (
                                            <div
                                                key={index}
                                                className="mb-2 flex items-center gap-x-2"
                                            >
                                                <svg
                                                    width="29"
                                                    height="29"
                                                    viewBox="0 0 29 29"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="flex-shrink-0"
                                                >
                                                    <path
                                                        d="M8.43373 15.0943L11.8289 18.4895L19.5894 10.729"
                                                        stroke="#ffffff"
                                                        strokeWidth="1.75048"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <p
                                                    className={`flex-1 ${
                                                        index === 0
                                                            ? "font-bold"
                                                            : ""
                                                    } `}
                                                >
                                                    {text}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col  rounded-2xl border border-[#D5D8E2] px-5 pt-10 h-[720px] w-[330px] mb-4">
                        <div
                            className={`text-[#0E1928] text-base md:text-lg xl:text-xl font-bold  ${"mt-5"} `}
                        >
                            Premium
                        </div>

                        <div className="flex flex-col gap-8 md:gap-0">
                            <div className="flex flex-col border-b border-[#D5D8E2] border-dashed pb-5  ">
                                <div className="flex items-end text-[#0E1928] text-2xl md:text-3xl xl:text-5xl   mt-2">
                                    {Plan === "Mensual" ? (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl font-bold">
                                                {" "}
                                                {(numberHouse < 2
                                                    ? DATA.premium.una_casa
                                                    : Number(
                                                          DATA.premium.una_casa
                                                      ) +
                                                      Number(
                                                          DATA.premium
                                                              .casa_adicional
                                                      ) *
                                                          (Number(numberHouse) -
                                                              1)
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex">
                                            <p className="text-base font-bold">
                                                {moneda}
                                            </p>
                                            <p className="text-4xl text-[#179BD8] font-bold">
                                                {" "}
                                                {(
                                                    getPremium({
                                                        tipo: Plan,
                                                        descuento: true,
                                                    }) / 12
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                    )}

                                    <div className="text-base font-semibold xl:text-lg">
                                        /mes
                                    </div>
                                </div>
                                <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                    {moneda}
                                    {Plan === "Mensual"
                                        ? getPremium({
                                              tipo: Plan,
                                          })
                                        : getPremium({
                                              tipo: Plan,
                                              descuento: true,
                                          })}{" "}
                                    al año.
                                    {Plan === "Semestral" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 10%
                                        </div>
                                    )}
                                    {Plan === "Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 20%
                                        </div>
                                    )}
                                    {Plan === "Bi Anual" && (
                                        <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                            Ahorras 30%
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col mb-8 ">
                                <div
                                    className={`text-[#0E1928] text-sm  mt-4 mb-0`}
                                >
                                    <p className="text-sm md:text-lg">
                                        El plan incluye:{" "}
                                    </p>
                                    <div className="marker:text-[#0E1928] text-xs md:text-base mt-3 flex flex-col justify-center items-start">
                                        {[
                                            "Incluye el Plan Estándar",
                                            "Integración de calendarios de Airbnb, booking, google calendar, entre otros.",
                                            "Chatroom (integración con Whatsapp).",
                                            "Chatbot.",
                                            "Envio de promociones y descuentos via e-mail y chat a tus contactos.",
                                            "Propuesta de alquileres por cliente personalizada.",
                                            "Contratos propietario - huésped.",
                                            "Firma de contratos.",
                                        ].map((text, index) => (
                                            <div
                                                key={index}
                                                className="mb-2 flex items-center gap-x-2"
                                            >
                                                <svg
                                                    width="29"
                                                    height="29"
                                                    viewBox="0 0 29 29"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="flex-shrink-0"
                                                >
                                                    <path
                                                        d="M8.43373 15.0943L11.8289 18.4895L19.5894 10.729"
                                                        stroke="#282A37"
                                                        strokeWidth="1.75048"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <p
                                                    className={`flex-1 ${
                                                        index === 0
                                                            ? "font-bold"
                                                            : ""
                                                    } `}
                                                >
                                                    {text}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Planes;
