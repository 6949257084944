import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { NavbarsAvatar } from "../../components/NavbarsAvatar";
import { ShowDateToUser } from "../../components/ShowDateToUser";
import addDaysToDate from "../../helpers/AddDaysToDate";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import { ModalLogin } from "../ModalLogin";
import Navbar from "../../designs/design1New/Navbar/Navbar";
import { formatearNumero } from "../../components/helpers/formatearNumero";

interface serviceType {
    id: number;
    nombre: string;
    opcional: string;
    precio_final: number;
}

export const CheckoutHotelReserva = () => {
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const reservaString = localStorage.getItem("reserva");
    const reserva = reservaString ? JSON.parse(reservaString) : "";
    console.log(reserva);
    const [modalVisibleLogin, setModalVisibleLogin] = useState(false);
    const navigate = useNavigate();
    const { socket } = useAppSelector((state) => state.activarSocket);
    const casa = useAppSelector((state) => state.landing_house);
    console.log("tipo_usuario", tipo_usuario);
    const [datosUser, setDatosUser] = useState({
        nombre_cliente: "",
        apellido_cliente: "",
        correo_cliente: "",
        celular_cliente: "",
    });

    const comprobarDisponibilidad = async () => {
        if (tipo_usuario === 8) {
            if (
                datosUser.nombre_cliente.trim() === "" ||
                datosUser.apellido_cliente.trim() === "" ||
                datosUser.correo_cliente.trim() === ""
            ) {
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "Error",
                    text: "Por favor complete todos los campos del usuario que son obligatorios.",
                    icon: "error",
                });
                return;
            }
        }
        if (tipo_usuario === null) {
            setModalVisibleLogin(true);
        } else {
            const servicios_adicionales = reserva.servicios_adicionales.map(
                (service: serviceType) => {
                    return {
                        servicio: service.nombre,
                        costo: service.precio_final,
                    };
                }
            );

            const one_day_off_final_date = new Date(
                reserva.dates_selected.final_date
            );
            const convert_final_date =
                one_day_off_final_date.toLocaleDateString("zh-Hans-CN");

            const fecha_inicial =
                reserva.dates_selected.initial_date.split("T")[0];
            const fecha_final = convert_final_date.replaceAll("/", "-");
            const reserva_final = {
                id_habitacion: reserva.datos_alojamiento.id_habitacion,
                id_cliente: tipo_usuario === 8 ? 0 : id,
                costo_alquiler: reserva.precio_alquiler,
                garantia: reserva.garantia,
                descuento_estadia: reserva.descuento_estadia,
                costo_servicios_adicionales:
                    reserva.precio_servicios_adicionales,
                servicios_adicionales: servicios_adicionales,
                costo_total: reserva.precio_total,
                fecha_inicio: fecha_inicial,
                fecha_final: fecha_final,
                tipo_moneda: reserva.datos_alojamiento.moneda,
                adultos: reserva.huespedes.adultos,
                ninos: reserva.huespedes.ninos,
                tipo_garantia: reserva.tipo_garantia,
                descuento_cupon: reserva.cupon,
                ...datosUser,
            };

            try {
                const data = await fetchAxiosToken({
                    url: `/pedidos_habitaciones/`,
                    method: `post`,
                    body: reserva_final,
                });

                /* if (socket) {
                    socket?.emit("send-notificacion-leida", {
                        id_cliente: id,
                        id_casa: casa.house.id,
                    });
                } */

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    title: "¡Tu reserva ha sido enviada!",
                    text: "Te avisaremos cuando la disponibilidad haya sido confirmada, gracias!",
                    icon: "success",
                });
                navigate("/");
            } catch (error) {
                console.log(error);
            }
        }
    };

    if (!reserva) {
        return (
            <div>
                <Navbar />
                <div className="flex items-center justify-between p-3 py-4 font-bold text-center border-b border-[#1C4CBB] text-[#1C4CBB] px-14 md:px-5">
                    <button
                        onClick={() => navigate("/")}
                        className="flex items-center justify-center hover:underline"
                    >
                        <svg
                            className="h-5 w-5 text-[#1C4CBB]"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <polyline points="15 6 9 12 15 18" />
                        </svg>
                        Regresar al inicio
                    </button>
                    {/* <NavbarsAvatar /> */}
                </div>
                <div className="flex items-center justify-center h-screen p-4">
                    <div className="font-medium text-center">
                        Parece que aún no has generado una reserva,
                        <button
                            onClick={() => navigate("/")}
                            className="mx-1 text-blue-600 hover:underline"
                        >
                            regresar al inicio.
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className=" bg-[#029BFC] bg-opacity-10">
            {modalVisibleLogin && (
                <ModalLogin
                    modalVisibleLogin={modalVisibleLogin}
                    setModalVisibleLogin={setModalVisibleLogin}
                />
            )}
            <Navbar />
            <div className="flex items-center justify-between p-3 py-4 font-bold text-center border-b border-[#1C4CBB] text-[#1C4CBB] px-14 md:px-5">
                <button
                    onClick={() => navigate("/")}
                    className="flex items-center justify-center hover:underline"
                >
                    <svg
                        className="h-5 w-5 text-[#1C4CBB]"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        {" "}
                        <path stroke="none" d="M0 0h24v24H0z" />{" "}
                        <polyline points="15 6 9 12 15 18" />
                    </svg>
                    Regresar al inicio
                </button>
                {/* <NavbarsAvatar /> */}
            </div>
            <h2 className="max-w-4xl mx-auto text-[#1C4CBB] font-bold text-xl pl-12 mt-10">
                TU RESERVA
            </h2>
            <div className="grid w-full gap-10 p-4 mx-auto  sm:grid-cols-2 max-w-4xl text-[#333333]">
                <div>
                    <div className="w-full p-6 px-10 mb-6 bg-white rounded-lg ">
                        <p className="mb-4 font-bold">Pago único:</p>
                        <p>Realiza el pago total de tu reserva.</p>
                    </div>

                    <div className="w-full p-6 px-10 mb-6 bg-white rounded-lg sm:h-80">
                        <p className="mb-4 font-bold">Pago en dos partes:</p>
                        <p>
                            Realiza ahora el pago de la mitad del alquiler que
                            representa{" "}
                            <span className="font-medium">
                                {formatearNumero(
                                    (reserva.precio_alquiler - reserva.cupon) /
                                        2
                                )}{" "}
                                {reserva.datos_alojamiento.moneda}
                            </span>{" "}
                            <br></br>
                            <br></br>y 7 días antes del check in (
                            <span className="font-medium">
                                {" "}
                                <ShowDateToUser
                                    className="font-medium"
                                    date={
                                        new Date(
                                            reserva.dates_selected.initial_date
                                        )
                                    }
                                />
                            </span>
                            ) realiza el pago pendiente junto con la garantía y
                            servicios adicionales.
                        </p>
                    </div>

                    <div className="w-full p-4 bg-white rounded-lg">
                        {tipo_usuario === 8 && (
                            <div className="border rounded-lg border-[#1C4CBB] flex flex-col space-y-2 p-2">
                                <div className="flex flex-col space-y-1">
                                    <label
                                        htmlFor="nombre"
                                        className="text-[#333333] font-bold"
                                    >
                                        Nombre:
                                    </label>
                                    <input
                                        className="px-2 py-1 border rounded-lg"
                                        type="text"
                                        id="nombre"
                                        placeholder="Nombre del cliente"
                                        value={datosUser.nombre_cliente}
                                        onChange={(e) =>
                                            setDatosUser({
                                                ...datosUser,
                                                nombre_cliente: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <label
                                        htmlFor="apellido"
                                        className="text-[#333333] font-bold"
                                    >
                                        Apellido:
                                    </label>
                                    <input
                                        className="px-2 py-1 border rounded-lg"
                                        type="text"
                                        id="apellido"
                                        placeholder="Apellido del cliente"
                                        value={datosUser.apellido_cliente}
                                        onChange={(e) =>
                                            setDatosUser({
                                                ...datosUser,
                                                apellido_cliente:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <label
                                        htmlFor="email"
                                        className="text-[#333333] font-bold"
                                    >
                                        Correo electrónico:
                                    </label>
                                    <input
                                        className="px-2 py-1 border rounded-lg"
                                        type="email"
                                        id="email"
                                        placeholder="Correo del cliente"
                                        value={datosUser.correo_cliente}
                                        onChange={(e) =>
                                            setDatosUser({
                                                ...datosUser,
                                                correo_cliente: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="flex flex-col space-y-1">
                                    <label
                                        htmlFor="celular"
                                        className="text-[#333333] font-bold"
                                    >
                                        Celular:
                                        <span className="pl-1 text-sm italic text-gray-500">
                                            (Opcional)
                                        </span>
                                    </label>
                                    <input
                                        className="px-2 py-1 border rounded-lg"
                                        type="text"
                                        id="celular"
                                        placeholder="Celular del cliente (opcional)"
                                        value={datosUser.celular_cliente}
                                        onChange={(e) =>
                                            setDatosUser({
                                                ...datosUser,
                                                celular_cliente: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <p>
                            Pago con tarjeta de crédito/débito cuenta con un
                            costo adicional del 5% del monto a pagar.
                        </p>
                        <button
                            onClick={() => comprobarDisponibilidad()}
                            className="flex justify-center w-full max-w-xs p-2 mx-auto mt-4 border-2 rounded-lg bg-[#1C4CBB] text-white  hover:scale-105"
                        >
                            Comprobar disponibilidad
                        </button>
                    </div>
                </div>
                <div className="flex flex-col w-full p-4  border rounded-lg bg-white border-[#1C4CBB]">
                    <div className="flex flex-col  gap-3 text-lg ">
                        <span className="mx-1 text-xl font-bold">
                            {reserva.datos_alojamiento.casa}
                        </span>

                        <span className="mx-1 text-xl font-bold">
                            {reserva.datos_alojamiento.habitacion}
                        </span>
                        <hr className="my-1 border-[#1C4CBB]" />
                    </div>
                    <span></span>
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between mt-4">
                            <span className="font-bold">Fecha</span>
                            <span className="">
                                <ShowDateToUser
                                    className=""
                                    date={
                                        new Date(
                                            reserva.dates_selected.initial_date
                                        )
                                    }
                                />
                                -{" "}
                                <ShowDateToUser
                                    className=""
                                    date={
                                        new Date(
                                            reserva.dates_selected.final_date
                                        )
                                    }
                                />
                            </span>
                        </div>
                        <div className="flex justify-between mt-4">
                            <span className="font-bold">Huéspedes</span>
                            <span className="">
                                {reserva.huespedes.adultos +
                                    reserva.huespedes.ninos}{" "}
                                huéspedes
                            </span>
                        </div>

                        <hr className="my-4 border-[#1C4CBB]" />
                        <div className="flex justify-between ">
                            <span className="font-bold">Precio alquiler</span>
                            <span className="">
                                {formatearNumero(reserva.precio_alquiler)}{" "}
                                {reserva.datos_alojamiento.moneda}
                            </span>
                        </div>
                        {reserva.cupon > 0 ? (
                            <>
                                <div className="flex justify-between text-green-500">
                                    <span className="font-bold">
                                        Descuento por cupón
                                    </span>
                                    <span className="font-">
                                        {" "}
                                        -{formatearNumero(reserva.cupon)}{" "}
                                        {reserva.datos_alojamiento.moneda}
                                    </span>
                                </div>

                                <hr className="my-4 border-[#1C4CBB]" />
                            </>
                        ) : null}
                        {reserva.descuento_estadia > 0 ? (
                            <>
                                <div className="flex justify-between">
                                    <span className="text-green-600">
                                        Descuento por larga estadía
                                    </span>
                                    <span className="font-medium text-green-600">
                                        -{" "}
                                        {formatearNumero(
                                            reserva.descuento_estadia
                                        )}{" "}
                                        {reserva.datos_alojamiento.moneda}
                                    </span>
                                </div>
                                <hr className="my-1 border-[#1C4CBB]" />
                            </>
                        ) : null}

                        {reserva.garantia !== "0.00" &&
                            reserva.tipo_garantia === "Alquiler" && (
                                <>
                                    <div className="flex justify-between mt-4">
                                        <span className="font-bold">
                                            Garantía
                                        </span>
                                        <span className="">
                                            {formatearNumero(reserva.garantia)}{" "}
                                            {reserva.datos_alojamiento.moneda}
                                        </span>
                                    </div>
                                </>
                            )}

                        {reserva.servicios_adicionales.length > 0 ? (
                            <>
                                <span className="mt-4 font-bold">
                                    Servicios adicionales
                                </span>
                                {reserva.servicios_adicionales.map(
                                    (servicio: any) => (
                                        <div
                                            key={servicio.id}
                                            className="flex justify-between"
                                        >
                                            <span className="">
                                                {servicio.nombre}
                                            </span>
                                            <span className="">
                                                {formatearNumero(
                                                    servicio.precio_final
                                                )}{" "}
                                                {
                                                    reserva.datos_alojamiento
                                                        .moneda
                                                }
                                            </span>
                                        </div>
                                    )
                                )}
                                <hr className="my-4 border-[#1C4CBB]" />
                            </>
                        ) : null}
                    </div>
                    <div className="flex justify-between mt-4">
                        <span className="font-bold">Precio total</span>
                        <span className="">
                            {formatearNumero(reserva.precio_total)}{" "}
                            {reserva.datos_alojamiento.moneda}
                        </span>
                    </div>
                    <div className="flex justify-between mt-4">
                        {reserva.garantia !== "0.00" &&
                            reserva.tipo_garantia ===
                                "Pago al ingreso casa" && (
                                <div className="flex w-full px-1 mt-4 bg-blue-200 border-4 border-blue-300 rounded-lg">
                                    <span className="underline">
                                        Garantía:{" "}
                                    </span>
                                    <span className="ml-2 italic font-thin ">
                                        Deberás pagar{" "}
                                        {formatearNumero(reserva.garantia)}{" "}
                                        {reserva.datos_alojamiento.moneda} en
                                        efectivo al momento de llegar a la
                                        propiedad
                                    </span>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};
