import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "../../components/helpers/Spinner";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
    setModalVisibleComodidades: (modalVisibleComodidades: boolean) => void;
    modalVisibleComodidades: boolean;
    tipo_pago?: number;
    plan?: number;
    comprar: boolean;
    unidades?: number;
}

interface comfort {
    id: number;
    nombre: string;
    icono: string;
    habilitado: string;
    fecha_registro: string;
}

const PLANES = [
    {
        id: 1,
        nombre: "Propietario",
        descripcion: "1 Casa",
        icono: (
            <svg
                className="w-10 h-10 2xl:w-12 2xl:h-12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                {" "}
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />{" "}
                <polyline points="9 22 9 12 15 12 15 22" />
            </svg>
        ),
        url: "/registro-propietario",
    },
    {
        id: 2,
        nombre: "Corredor",
        descripcion: "Multi-Casas",
        icono: (
            <svg
                className="w-10 h-10 2xl:w-12 2xl:h-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
            </svg>
        ),
        url: "/registro-corredor",
    },
    {
        id: 3,
        nombre: "Hotel",
        descripcion: "Habitaciones",
        icono: (
            <svg
                className="w-10 h-10 2xl:w-12 2xl:h-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                />
            </svg>
        ),
        url: "/registro-hotel",
    },
];

export const ModalPlan = ({
    setModalVisibleComodidades,
    modalVisibleComodidades,
    comprar,
    unidades,
    plan,
    tipo_pago,
}: Props) => {
    const navigate = useNavigate();

    const handleNavigate = (url: string) => {
        console.log(
            `${url}-plan?tipo_pago=${tipo_pago}&plan=${plan}&unidades=${unidades}`
        );
        navigate(
            `${
                comprar === true
                    ? `${url}-plan?tipo_pago=${tipo_pago}&plan=${plan}&unidades=${unidades}`
                    : url
            }`,
            {
                replace: false,
            }
        );
    };
    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisibleComodidades}
            setModalVisible={setModalVisibleComodidades}>
            <div className="flex flex-col items-center max-w-lg gap-4 p-4 mx-auto ">
                <h2 className="text-base font-medium">
                    En que servicio deseas registrarte
                </h2>
                <hr className="w-full bg-gray-300" />

                <div className="h-[60vh] ">
                    <div className="max-h-[60vh]  grid grid-cols-1 mx-auto">
                        {PLANES?.map((attribute) => {
                            return (
                                <div
                                    key={attribute.id}
                                    className={`w-full p-3 ${
                                        Number(unidades) > 1 &&
                                        attribute.id === 1
                                            ? "hidden"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        handleNavigate(attribute.url)
                                    }>
                                    <div
                                        className={
                                            " items-center text-center text-base  py-4 px-24 flex flex-col border border-solid border-[#E9E9E9]  rounded-lg   cursor-pointer w-full hover:bg-[#bed3fa] "
                                        }>
                                        <div className="flex justify-center text-[#3C3C3C]">
                                            {attribute.icono}
                                        </div>
                                        <span className=" 2xl:text-sm text-xs text-[#3C3C3C]">
                                            {attribute.nombre}
                                        </span>
                                        <span className=" 2xl:text-sm text-xs text-[#3C3C3C]">
                                            {attribute.descripcion}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* <div className="flex flex-col gap-4 mt-8">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="p-3 px-5 text-white border rounded-lg bg-[#1C4CBB] hover:scale-105">
                            Guardar cambios
                        </button>
                    )}
                </div> */}
            </div>
        </ResponsiveModalAbsolute>
    );
};
