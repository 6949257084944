import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { NavbarCliente } from "../../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../../dashboardClientes/NavbarMobileCliente";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { ModalDetalleDePagos } from "./ModalDetalleDePagos";
import StarsRating from "./StarsRating";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { useDispatch } from "react-redux";
import { set_landing_house } from "../../../redux/reducers/landingReducer";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarClienteHotel } from "../../dashboardClientesHotel/NavbarClienteHotel";
import { NavbarMobileClienteHotel } from "../../dashboardClientesHotel/NavbarMobileClienteHotel";

interface PedidoAdicional {
    id: number;
    id_pedido: number;
    servicio: string;
    costo: string;
}

interface Pedido {
    id: number;
    costo_alquiler: string;
    costo_total: string;
    pedidos_servicios_adicionales: PedidoAdicional[];
    costo_servicios_adicionales: string;
    descuento_estadia: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    pagado: string;
    saldo: string;
    HotelHabitaciones: HotelHabitaciones | null;
}

export interface HotelHabitaciones {
    id: number;
    nombre: string;
    banner: string;
}

export const ReservaResena = () => {
    const { reservaID } = useParams();
    const navigate = useNavigate();

    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const [pedido, setPedido] = useState<Pedido | null>(null);
    const [estado, setEstado] = useState<number | null>(1);
    const [input, setInput] = useState(5);
    const [comentario, setComentario] = useState("");
    const { socket } = useAppSelector((state) => state.activarSocket);
    const casa = useAppSelector((state) => state.landing_house);
    const dispatch = useDispatch();
    useEffect(() => {
        fetchAxiosToken({
            url: `/${
                tipo_usuario === 9 ? "pedidos_habitaciones" : "pedidos"
            }/getOne/${reservaID}/${id}`,
        }).then(async (e) => {
            setPedido(e);

            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/id/${e.id_casa}`,
            });

            dispatch(set_landing_house(data));
        });
    }, []);
    console.log("pedido", pedido);
    const enviarResena = async () => {
        if (comentario === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "Debes agregar un comentario",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }
        try {
            const data = await fetchAxiosToken({
                url: `/${tipo_usuario === 9 ? "resenas_hoteles" : "resenas"}`,
                method: "post",
                body: {
                    id_pedidos: pedido?.id,
                    puntuacion: input,
                    resena: comentario,
                    id_pedidos_habitaciones: pedido?.id,
                },
            });
            console.log(data);
            if (socket) {
                socket?.emit("send-notificacion-leida", {
                    id_cliente: id,
                    id_casa: casa.house.id,
                });
            }

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "success",
                title: "Reseña guardada",
                text: "su reseña fue guardada con exito",
                showConfirmButton: true,
                // timer: 2000,
            });
            if (tipo_usuario === 9) {
                navigate(`/dashboard-cliente-hotel/reservas`, {
                    replace: false,
                });
            } else {
                navigate(`/dashboard-cliente/reservas`, { replace: false });
            }
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "warning",
                title: "No se pudo guardar",
                text: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 2000,
            });
        }
    };

    const render = () => {
        return (
            <div className="flex flex-col w-full p-4 ">
                <span className="text-2xl font-semibold text-center">
                    Reseña de{" "}
                    {pedido?.HotelHabitaciones
                        ? pedido?.HotelHabitaciones.nombre
                        : casa.house.nombre}
                    {/* reserva #{pedido?.id} */}
                </span>
                <div className="font-medium text-center mt-7">
                    Reserva del <ShowDateToUser date={pedido?.fecha_inicio} />{" "}
                    al <ShowDateToUser date={pedido?.fecha_final} />
                </div>
                <div className="mt-4 text-base text-center">
                    <span>Tu comentario es muy importante para nosotros.</span>{" "}
                    <br></br> <span>Como fue tu experiencia ?</span>
                </div>
                <div className="flex justify-center mt-10 mb-2">
                    <span>
                        Tu puntuación <span className="text-red-500">*</span>
                    </span>
                </div>
                <div className="mx-auto ">
                    <StarsRating starsQuantity={5} setInput={setInput} />
                </div>
                <div className="flex flex-col w-1/2 gap-2 p-4 mx-auto mt-4 ">
                    <label className="mx-auto">
                        Tu comentario <span className="text-red-500">*</span>
                    </label>
                    <textarea
                        className="p-3 border rounded-lg border-blue-blueFrom "
                        name="comentarios"
                        value={comentario}
                        rows={5}
                        onChange={(e) => setComentario(e.target.value)}
                    />
                </div>
                {
                    <div className="w-full text-center">
                        <button
                            onClick={() => enviarResena()}
                            className="w-3/5 p-3 mt-4 font-medium text-white border rounded-lg bg-blue-blueFrom active:translate-y-1">
                            Enviar
                        </button>
                    </div>
                }
            </div>
        );
    };

    if (!pedido)
        return (
            <>
                <Navbar titulo="Reservas">
                    <div className="p-4 font-medium">Cargando pedido...</div>
                    <NavbarMobile />
                </Navbar>
            </>
        );
    return (
        <>
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Reseñas">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 5 || tipo_usuario === 7 ? (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Reseñas">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : tipo_usuario === 9 ? (
                <NavbarClienteHotel>
                    {render()}
                    <NavbarMobileClienteHotel />
                </NavbarClienteHotel>
            ) : null}
        </>
    );
};
