import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ElegirMoneda } from "../../../components/ElegirMoneda";
import { NavbarsAvatar } from "../../../components/NavbarsAvatar";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { logOut } from "../../../redux/reducers/authReducer";
import BurguerMenu from "./BurguerMenu";
import { Notificaiones } from "../../modalEdit/Notificaiones";

const TITLES = [
    {
        id: 1,
        title: "fotos",
        url: "fotos",
    },
    {
        id: 2,
        title: "imágenes",
        url: "imagenes",
    },
    {
        id: 3,
        title: "habitaciones",
        url: "habitaciones",
    },
    {
        id: 4,
        title: "servicios",
        url: "servicios",
    },
    {
        id: 5,
        title: "reseñas",
        url: "resenas",
    },
    {
        id: 6,
        title: "ubicación",
        url: "ubicacion",
    },
];

const Navbar = () => {
    const [titleSelected, setTitleSelected] = useState<number>(0);

    const navigate = useNavigate();

    const { casa_actividades } = useAppSelector(
        (state) => state.landing_house.house
    );
    const { id, nombre, view } = useParams();
    const casa = useAppSelector((state) => state.landing_house);

    const handleSmoothScroll = (title: string) => {
        if (id && nombre) {
            navigate(`/${id}/${nombre}/${title}`);
        } else {
            navigate(`/${title}`);
        }
    };

    useEffect(() => {
        if (view) {
            // window.scrollTo(0, 0);
            const element = document.getElementById(view);
            const marginTopToScroll = -90;
            if (element) {
                const elementYposition =
                    element?.getBoundingClientRect().top +
                    window.pageYOffset +
                    marginTopToScroll;

                window.scrollTo({ top: elementYposition, behavior: "smooth" });
            }
        }
    }, [view]);

    return (
        <div className="sticky top-0 z-50 w-full ">
            <div className="hidden w-full md818:flex justify-between items-center bg-[#1C4CBB] p-4 px-5 text-white">
                <span
                    onClick={() => navigate("/")}
                    className="font-medium uppercase cursor-pointer"
                >
                    {casa.house.nombre}
                </span>
                <div className="flex items-center gap-4 lg:gap-6">
                    {TITLES.map((title) => (
                        <div
                            key={title.id}
                            onClick={() => handleSmoothScroll(title.url)}
                            onMouseEnter={() => setTitleSelected(title.id)}
                            onMouseLeave={() => setTitleSelected(0)}
                            className={`relative font-medium capitalize cursor-pointer ${
                                !casa_actividades.length &&
                                title.title === "actividades" &&
                                "hidden"
                            }`}
                        >
                            {title.title}
                            <span
                                className={`${
                                    titleSelected === title.id
                                        ? "w-full"
                                        : "w-0"
                                } absolute top-6 left-0 bg-gray-500 h-0.5 transition-all ease-in-out duration-300`}
                            ></span>
                        </div>
                    ))}
                    <Notificaiones />

                    <ElegirMoneda />
                    <NavbarsAvatar />
                </div>
            </div>
            <BurguerMenu
                className="md818:hidden"
                titles={TITLES}
                handleSmoothScroll={handleSmoothScroll}
            />
        </div>
    );
};

export default Navbar;
