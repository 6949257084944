import React, { useState, useEffect } from "react";
import Spinner from "../../../components/helpers/Spinner";
import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";
import { Paginado } from "../../../components/Paginado";
// import SearchBar from "./SearchBar";
import { useNavigate, useParams } from "react-router-dom";
// import ModalUsuariosCasa from "./ModalUsuariosCasa";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { useAppSelector } from "../../../hooks/hooks";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { ModalOnePago } from "./ModalOnePago";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { NavbarHotel } from "../../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../../dashboardHotel/NavbarMobileHotel";

interface getComfort {
    pagina: number;
    habilitado: boolean;
    query: string;
}

export interface CasasChats {
    id: number;
    count_chats: number;
    casa: Casa;
}

let DATA = {
    starter: {
        una_casa: 19,
        casa_adicional: 7,
    },

    standard: {
        una_casa: 39,
        casa_adicional: 10,
    },
    premium: {
        una_casa: 79,
        casa_adicional: 15,
    },
};

export interface SuscripcionActual {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
    id_stripe: string;
    unidades: number;
}
export interface PagoSuscricion {
    id: number;
    id_casa: number;
    plan: string;
    costo: string;
    fecha_pago: string;
    fecha_vencimiento: string;
    fecha_inicio: string;
    tipo_pago: string;
    estado: string;
    motivo_rechazo: string;
    metodo: string;
    imagen: string;
    casa: Casa;
    actual: string;
    tipo_moneda: string;
    id_stripe: string;
    unidades: number;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

export const Suscripciones = () => {
    AOS.init();

    const { headers } = useHeaders();
    const [suscricionActual, setSuscripcionActual] =
        useState<SuscripcionActual>();
    const [pagoSelect, setPagoSelect] = useState<PagoSuscricion>();
    const [comforts, setComforts] = useState<PagoSuscricion[]>([]);
    const casa = useAppSelector((state) => state.landing_house.house);
    const [Plan, setPlan] = useState("Mensual");
    const { idCasa } = useParams();

    const {
        id: id_usuario,
        tipo_usuario,
        grupo_trabajo,
    } = useAppSelector((state) => state.usuario_logeado);
    const [numberHouse, setNumberHouse] = useState<number>(1);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [moneda, setMoneda] = useState("$");
    const [activeButton, setActiveButton] = useState(null);

    const [cargando, setCargando] = useState<boolean>(false);

    const navigate = useNavigate();
    const [mes, setMes] = useState<number>(new Date().getMonth() + 1);
    const [ano, setAno] = useState<number>(new Date().getFullYear());
    const [currency, setCurrency] = useState("USD");
    const [verMas, setVerMas] = useState<boolean>(false);

    const getPedidos = async ({ pagina, habilitado, query }: getComfort) => {
        console.log("tipo_usuario", tipo_usuario);
        console.log("id_casa", casa.id);
        try {
            setCargando(false);
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/suscripcion_pagos/${
                    tipo_usuario === 6 ? "getAllCorredores" : "getAll"
                }/${pagina}/${tipo_usuario === 6 ? id_usuario : casa.id}`,
                { headers }
            );
            console.log("data listado", data);
            setComforts(data);
            // setTotal(data.total)
            setCargando(true);
        } catch (error) {
            console.log(error);
        }
    };
    console.log("casa", casa);
    const getSuscripcionActual = async () => {
        try {
            // setCargando(false)
            const { data } = await axios.get(
                `${process.env.REACT_APP_PROXY}/suscripcion_pagos/${
                    tipo_usuario === 6
                        ? "getCurrentSuscripcionCorredor"
                        : "getCurrentSuscripcion"
                }/${tipo_usuario === 6 ? id_usuario : casa.id}`,
                { headers }
            );
            console.log("data", data);
            setSuscripcionActual(data);
            // setTotal(data.total)
            // setCargando(true)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (tipo_usuario === 6 || casa.id > 0) {
            getSuscripcionActual();
            getPedidos({
                pagina: pageNumber,
                habilitado: enabled,
                query: "",
            });
        }
    }, [casa.id, tipo_usuario, enabled, create, mes, ano]);

    const getOneSuscripcion = async (pagoSelect: PagoSuscricion) => {
        setPagoSelect(pagoSelect);
        setVerMas(true);
    };

    const handlePlan = (planString: string) => {
        setPlan(planString);
    };
    const handleButtonClick = (button: any) => {
        setActiveButton(button);
        if (button === "+") {
            setNumberHouse(Number(numberHouse) + 1);
        }

        if (button === "-") {
            if (numberHouse === 1) return;
            setNumberHouse(Number(numberHouse) - 1);
        }
    };
    const getStarter = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi-Anual") {
            porcentaje = 0.7;
        }
        let price =
            numberHouse < 2
                ? DATA.starter.una_casa
                : Number(DATA.starter.una_casa) +
                  Number(DATA.starter.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (porcentaje) {
            price = Math.ceil(price * porcentaje);
        }

        return price;
    };

    const getStandard = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi-Anual") {
            porcentaje = 0.7;
        }
        let price =
            numberHouse < 2
                ? DATA.standard.una_casa
                : Number(DATA.standard.una_casa) +
                  Number(DATA.standard.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (porcentaje) {
            price = Math.ceil(price * porcentaje);
        }

        return price;
    };

    const getPremium = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi-Anual") {
            porcentaje = 0.7;
        }

        let price =
            numberHouse < 2
                ? DATA.premium.una_casa
                : Number(DATA.premium.una_casa) +
                  Number(DATA.premium.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (porcentaje) {
            price = Math.ceil(price * porcentaje);
        }

        return price;
    };

    const handleRegister = async (id_suscripcion: string) => {
        let costo =
            id_suscripcion === "1"
                ? getStarter({
                      tipo: Plan,
                  })
                : id_suscripcion === "2"
                ? getStandard({
                      tipo: Plan,
                  })
                : getPremium({
                      tipo: Plan,
                  });

        console.log("costo", costo);

        let plan =
            id_suscripcion === "1"
                ? "Starter"
                : id_suscripcion === "2"
                ? "Stándar"
                : "Premium";
        //"month", "semiannual", "year", "biannual"
        let tipo_pago_str =
            Plan === "Mensual"
                ? "month"
                : Plan === "Semestral"
                ? "semiannual"
                : Plan === "Anual"
                ? "year"
                : "biannual";

        let datos = {
            costo:
                costo *
                (Plan === "Anual"
                    ? 12
                    : Plan === "Semestral"
                    ? 6
                    : Plan === "Bi-Anual"
                    ? 24
                    : 1),
            id_corredor: tipo_usuario === 6 ? id_usuario : 0,
            plan: plan,
            nombre_plan: `BluStay ${plan} ${
                tipo_usuario === 6
                    ? "Corredor"
                    : tipo_usuario === 8
                    ? "Hotel"
                    : "Propietario"
            }`,
            tipo_moneda: currency,
            tipo_pago: tipo_pago_str,
            id_casa: casa.id,

            user: {
                subdomain: {
                    name: grupo_trabajo,
                },
                id: id_usuario,
            },

            cancel_url: `${
                tipo_usuario === 6
                    ? "/dashboard-corredor/suscripciones"
                    : tipo_usuario === 8
                    ? "/dashboard-hotel/suscripciones"
                    : "/dashboard/suscripciones"
            }`,
            success_url: `${
                tipo_usuario === 6
                    ? "/dashboard-corredor/suscripciones"
                    : tipo_usuario === 8
                    ? "/dashboard-hotel/suscripciones"
                    : "/dashboard/suscripciones"
            }`,
            domain: true,
            unidades: numberHouse,
        };
        console.log("datos", datos);
        try {
            const response = await fetchAxiosToken({
                url: `/suscripcion_pagos/stripe-dynamic/`,
                method: "post",
                body: datos,
            });

            const urlStripe = response.sessionUrl;
            window.location.href = urlStripe;
        } catch (error) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                icon: "error",
                title: "Hubo un error contactarse con soporte",

                showConfirmButton: true,
            });
        }
    };

    const handleChange = (e: any) => {
        const value = e.target.value;

        // Verifica que el valor sea un número y que sea al menos 1
        if (/^\d*$/.test(value) && (value === "" || parseInt(value) >= 1)) {
            setNumberHouse(value); // Solo actualiza si pasa las validaciones
        }
    };

    const handleDelete = (id: string) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que cancelar su suscripción?",
            text: "Su suscripción estara activa hasta la fecha de vencimiento",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_PROXY}/suscripcion_pagos/cancel-subscription-stripe`,
                        {
                            subscriptionId: id,
                        },
                        { headers }
                    );
                    await getSuscripcionActual();
                    getPedidos({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                    });
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "success",
                        title: "Suscripción cancelada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const render = () => {
        return (
            <div>
                <div className="absolute z-[999] flex items-center justify-center w-full text-center">
                    {verMas && pagoSelect !== undefined && (
                        <ModalOnePago
                            modalVisible={verMas}
                            setModalVisible={setVerMas}
                            pagoSelect={pagoSelect}
                        />
                    )}
                </div>

                <div className="w-full mt-7 border-[#CCCCCC] border-2 rounded-xl min-h-[80vh] ">
                    <div className="flex justify-around w-full px-2 xs:px-6 mx-auto border-[#CCCCCC] border-b-2 2xl:py-8 py-3 text-[#333333]">
                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Plan actual:
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] bg-[#DAF900] bg-opacity-20 2xl:text-base text-sm">
                                {suscricionActual?.plan || "Caducado"}
                            </p>
                        </div>

                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Vencimiento:
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] 2xl:text-base text-sm">
                                <ShowDateToUser
                                    date={suscricionActual?.fecha_vencimiento.slice(
                                        0,
                                        10
                                    )}
                                />
                            </p>
                        </div>

                        <div className="flex flex-col items-center justify-center gap-2 lg:flex-row">
                            <p className="text-base 2xl:text-lg">
                                Ciclo de Pago:{" "}
                            </p>{" "}
                            <p className="font-bold px-4 py-2 rounded-lg border border-[#CCCCCC] 2xl:text-base text-sm">
                                {suscricionActual?.tipo_pago || "Caducado"}
                            </p>
                        </div>
                    </div>

                    {(casa.prueba !== "No" ||
                        new Date(
                            suscricionActual?.fecha_vencimiento || ""
                        ).getTime() < new Date().getTime()) && (
                        <div className="flex flex-col items-start justify-start w-5/6 mx-auto mt-5 2xl:mt-10 ">
                            {(tipo_usuario === 6 || tipo_usuario === 8) && (
                                <div className="flex flex-col items-center justify-center gap-4 mx-auto">
                                    <div className="text-lg font-semibold">
                                        Elije el número de unidades
                                    </div>
                                    <div className="flex items-center justify-center gap-2 p-2 border-2 border-gray-300 rounded-full">
                                        <div
                                            className={`border-2 ${
                                                activeButton === "-"
                                                    ? "border-gray-500 bg-gray-200"
                                                    : "border-gray-100"
                                            }  rounded-full h-10 w-10 flex justify-center items-center cursor-pointer`}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Evita que el clic en este botón cierre el estado activo
                                                handleButtonClick("-");
                                                // Aquí puedes manejar la lógica para restar
                                            }}>
                                            <span className="mb-1 text-2xl font-semibold leading-none">
                                                -
                                            </span>
                                        </div>
                                        <div className="flex items-center justify-center h-10 border-2 border-gray-300 rounded-md">
                                            <input
                                                type="text"
                                                className="w-16 text-xl text-center "
                                                value={numberHouse}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div
                                            className={`border-2 ${
                                                activeButton === "+"
                                                    ? "border-gray-500 bg-gray-200"
                                                    : "border-gray-100"
                                            }  rounded-full h-10 w-10 flex justify-center items-center cursor-pointer`}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Evita que el clic en este botón cierre el estado activo
                                                handleButtonClick("+");
                                                // Aquí puedes manejar la lógica para restar
                                            }}>
                                            <span className="mb-1 text-2xl font-semibold leading-none">
                                                +
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-col justify-center h-auto mx-auto mb-5">
                                <div className="">
                                    <div className="flex flex-col items-center justify-center gap-4">
                                        <div className="text-lg font-semibold">
                                            Elije como quieres pagar
                                        </div>
                                        <div className="flex items-center justify-center px-1 py-1 border-2 w-96 rounded-3xl">
                                            <div
                                                className={`${
                                                    Plan === "Bi-Anual"
                                                        ? "bg-[#0E1928] text-[#C0D345]"
                                                        : " text-[#0E1928]"
                                                }   text-center  rounded-l-3xl cursor-pointer w-1/2 h-full `}
                                                onClick={() => {
                                                    handlePlan("Bi-Anual");
                                                }}>
                                                <p className="text-lg ">
                                                    Bi Anual
                                                </p>
                                                <p
                                                    className={`text-[8px] w-full  mb-1 ${
                                                        Plan === "Bi-Anual"
                                                            ? "text-white"
                                                            : " text-[#0E1928]"
                                                    }`}>
                                                    - 30%
                                                </p>
                                            </div>
                                            <div
                                                className={`${
                                                    Plan === "Anual"
                                                        ? "bg-[#0E1928] text-[#C0D345]"
                                                        : " text-[#0E1928]"
                                                }   text-center  cursor-pointer w-1/2 h-full `}
                                                onClick={() => {
                                                    handlePlan("Anual");
                                                }}>
                                                <p className="text-lg ">
                                                    Anual
                                                </p>
                                                <p
                                                    className={`text-[8px] w-full  mb-1 ${
                                                        Plan === "Anual"
                                                            ? "text-white"
                                                            : " text-[#0E1928]"
                                                    }`}>
                                                    - 20%
                                                </p>
                                            </div>

                                            <div
                                                className={`${
                                                    Plan === "Semestral"
                                                        ? "bg-[#0E1928] text-[#C0D345]"
                                                        : " text-[#0E1928]"
                                                }   text-center  cursor-pointer w-1/2 h-full `}
                                                onClick={() => {
                                                    handlePlan("Semestral");
                                                }}>
                                                <p className="text-lg ">
                                                    Semestral
                                                </p>
                                                <p
                                                    className={`text-[8px] w-full  mb-1 ${
                                                        Plan === "Semestral"
                                                            ? "text-white"
                                                            : " text-[#0E1928]"
                                                    }`}>
                                                    - 10%
                                                </p>
                                            </div>
                                            <div
                                                className={` ${
                                                    Plan === "Mensual"
                                                        ? "bg-[#0E1928] text-[#C0D345]"
                                                        : " text-[#0E1928]"
                                                }   px-4 text-lg   rounded-r-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                                                onClick={() => {
                                                    handlePlan("Mensual");
                                                }}>
                                                <p className="text-lg ">
                                                    Mensual
                                                </p>
                                                <p
                                                    className={`text-[8px] w-full  mb-1 ${
                                                        Plan === "Mensual"
                                                            ? "text-white"
                                                            : " text-[#0E1928]"
                                                    }`}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col items-center justify-center w-full gap-6 mx-auto lg:flex-row ">
                                <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                                    <div
                                        className={`text-[#0E1928] text-md md:text-lg xl:text-xl  ${"mt-5"} `}>
                                        Starter
                                    </div>

                                    <div className="flex flex-col gap-8 md:gap-0">
                                        <div className="flex flex-col ">
                                            <div className="flex items-end text-[#0E1928] text-lg md:text-xl xl:text-3xl   mt-2">
                                                {Plan === "Mensual" ? (
                                                    <div className="flex">
                                                        <p className="text-base font-bold">
                                                            {moneda}
                                                        </p>
                                                        <p className="text-4xl font-bold">
                                                            {numberHouse < 2
                                                                ? DATA.starter
                                                                      .una_casa
                                                                : Number(
                                                                      DATA
                                                                          .starter
                                                                          .una_casa
                                                                  ) +
                                                                  Number(
                                                                      DATA
                                                                          .starter
                                                                          .casa_adicional
                                                                  ) *
                                                                      (Number(
                                                                          numberHouse
                                                                      ) -
                                                                          1)}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="flex">
                                                        <p className="text-base font-bold">
                                                            {moneda}
                                                        </p>
                                                        <p className="text-4xl text-[#179BD8] font-bold">
                                                            {" "}
                                                            {getStarter({
                                                                tipo: Plan,
                                                                descuento: true,
                                                            })}
                                                        </p>
                                                    </div>
                                                )}

                                                <div className="text-base font-semibold xl:text-lg">
                                                    /mes
                                                </div>
                                            </div>
                                            <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                                {moneda}
                                                {Plan === "Mensual"
                                                    ? getStarter({
                                                          tipo: Plan,
                                                      })
                                                    : getStarter({
                                                          tipo: Plan,
                                                          descuento: true,
                                                      }) * 12}{" "}
                                                al año.
                                                {Plan === "Semestral" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 10%
                                                    </div>
                                                )}
                                                {Plan === "Anual" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 20%
                                                    </div>
                                                )}
                                                {Plan === "Bi-Anual" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 30%
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col ">
                                            <div
                                                className={`text-[#0E1928] text-sm  mt-4 mb-0`}>
                                                <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                                    <li className="mb-1">
                                                        Landing personalizada
                                                    </li>
                                                    <li className="mb-1">
                                                        Dominio para tu página
                                                    </li>
                                                    <li className="mb-1">
                                                        Reseñas de clientes
                                                    </li>
                                                    <li className="mb-1">
                                                        Historial de reservas
                                                    </li>
                                                    <li className="mb-1">
                                                        Pago por transferencia
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleRegister("1")}
                                            className="bg-[#179BD8] py-1 px-8 my-8 text-sm md:text-base  text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-11/12 text-center mx-auto">
                                            <p className="w-full text-center">
                                                COMPRAR
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col  rounded-2xl  bg-[#0E1928] text-white border-solid px-5   h-auto w-[330px] mb-4 ">
                                    <div
                                        className={` text-md md:text-lg xl:text-xl  flex items-center justify-between w-full  ${"mt-5"} `}>
                                        <p>Estándar</p>{" "}
                                        <p className="text-xs text-[#C0D345] bg-[#C0D345]/10 rounded-3xl px-3 py-1">
                                            El más seleccionado
                                        </p>
                                    </div>

                                    <div className="flex flex-col gap-8 md:gap-0">
                                        <div className="flex flex-col ">
                                            <div className="flex items-end text-[#0E1928] text-lg md:text-xl xl:text-3xl   mt-2">
                                                {Plan === "Mensual" ? (
                                                    <div className="flex">
                                                        <p className="text-base font-bold">
                                                            {moneda}
                                                        </p>
                                                        <p className="text-4xl font-bold text-[#C0D345]">
                                                            {" "}
                                                            {numberHouse < 2
                                                                ? DATA.standard
                                                                      .una_casa
                                                                : Number(
                                                                      DATA
                                                                          .standard
                                                                          .una_casa
                                                                  ) +
                                                                  Number(
                                                                      DATA
                                                                          .standard
                                                                          .casa_adicional
                                                                  ) *
                                                                      (Number(
                                                                          numberHouse
                                                                      ) -
                                                                          1)}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="flex">
                                                        <p className="text-base font-bold">
                                                            {moneda}
                                                        </p>
                                                        <p className="text-5xl text-[#C0D345] font-bold">
                                                            {getStandard({
                                                                tipo: Plan,
                                                                descuento: true,
                                                            })}
                                                        </p>
                                                    </div>
                                                )}

                                                <div className="text-base font-semibold xl:text-lg">
                                                    /mes
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-1 mt-2 text-sm ">
                                                {moneda}
                                                {Plan === "Mensual"
                                                    ? getStandard({
                                                          tipo: Plan,
                                                      })
                                                    : getStandard({
                                                          tipo: Plan,
                                                          descuento: true,
                                                      }) * 12}{" "}
                                                al año.
                                                {Plan === "Semestral" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 10%
                                                    </div>
                                                )}
                                                {Plan === "Anual" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 20%
                                                    </div>
                                                )}
                                                {Plan === "Bi-Anual" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 10%
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col ">
                                            <div
                                                className={` text-sm  mt-4 mb-0`}>
                                                <ul className="ml-6 text-xs list-disc list-outside marker:text-white md:text-base">
                                                    <li className="mb-1">
                                                        Módulo de CRM (Casa,
                                                        whatsapp)
                                                    </li>
                                                    <li className="mb-1">
                                                        Modificación precios /
                                                        noche casas
                                                    </li>
                                                    <li className="mb-1">
                                                        Creación de cupones
                                                    </li>
                                                    <li className="mb-1">
                                                        Chat y gestión de
                                                        clientes
                                                    </li>
                                                    <li className="mb-1">
                                                        Resumen de estadísticas
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleRegister("2")}
                                            className="bg-[#C0D345] py-1 px-8 my-8 text-sm md:text-base text-[#0E1928] font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-10/12 text-center mx-auto">
                                            <p className="w-full text-center">
                                                COMPRAR
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                                    <div
                                        className={`text-[#0E1928] text-md md:text-lg xl:text-xl  ${"mt-5"} `}>
                                        Premium
                                    </div>

                                    <div className="flex flex-col gap-8 md:gap-0">
                                        <div className="flex flex-col ">
                                            <div className="flex items-end text-[#0E1928] text-lg md:text-xl xl:text-3xl   mt-2">
                                                {Plan === "Mensual" ? (
                                                    <div className="flex">
                                                        <p className="text-base font-bold">
                                                            {moneda}
                                                        </p>
                                                        <p className="text-4xl font-bold">
                                                            {" "}
                                                            {numberHouse < 2
                                                                ? DATA.premium
                                                                      .una_casa
                                                                : Number(
                                                                      DATA
                                                                          .premium
                                                                          .una_casa
                                                                  ) +
                                                                  Number(
                                                                      DATA
                                                                          .premium
                                                                          .casa_adicional
                                                                  ) *
                                                                      (Number(
                                                                          numberHouse
                                                                      ) -
                                                                          1)}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="flex">
                                                        <p className="text-base font-bold">
                                                            {moneda}
                                                        </p>
                                                        <p className="text-4xl text-[#179BD8] font-bold">
                                                            {" "}
                                                            {getPremium({
                                                                tipo: Plan,
                                                                descuento: true,
                                                            })}
                                                        </p>
                                                    </div>
                                                )}

                                                <div className="text-base font-semibold xl:text-lg">
                                                    /mes
                                                </div>
                                            </div>
                                            <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                                {moneda}
                                                {Plan === "Mensual"
                                                    ? getPremium({
                                                          tipo: Plan,
                                                      })
                                                    : getPremium({
                                                          tipo: Plan,
                                                          descuento: true,
                                                      }) * 12}{" "}
                                                al año.
                                                {Plan === "Semestral" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 10%
                                                    </div>
                                                )}
                                                {Plan === "Anual" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 20%
                                                    </div>
                                                )}
                                                {Plan === "Bi-Anual" && (
                                                    <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                        Ahorras 10%
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col ">
                                            <div
                                                className={`text-[#0E1928] text-sm  mt-4 mb-0`}>
                                                <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                                    <li className="mb-1">
                                                        Chatbot interno
                                                    </li>
                                                    <li className="mb-1">
                                                        Integración google
                                                        calendar
                                                    </li>
                                                    <li className="mb-1">
                                                        Plantillas para
                                                        contratos por Alquiler
                                                    </li>
                                                    <li className="mb-1">
                                                        Chatroom Whatsapp
                                                    </li>
                                                    <li className="mb-1">
                                                        Integración Airbnb /
                                                        Booking - Calendario
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleRegister("3")}
                                            className="bg-[#179BD8] py-1 px-8 my-8 text-sm md:text-base  text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-11/12 text-center mx-auto">
                                            <p className="w-full text-center">
                                                COMPRAR
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex justify-center w-full">
                        <p className="mx-auto text-[#1C4CBB] font-bold 2xl:text-3xl text-2xl">
                            Historial
                        </p>
                    </div>
                    <section className="flex justify-center items-center lg:py-[10px] w-full px-4">
                        <div className="w-full">
                            <div className="flex flex-wrap -mx-4">
                                <div className="w-full ">
                                    <div className="flex flex-col">
                                        {cargando ? (
                                            <>
                                                <div className={` `}>
                                                    <table className="w-full mt-2 overflow-x-auto">
                                                        <thead className="text-sm text-left 2xl:text-base">
                                                            {comforts.length ? (
                                                                <tr className="border-y-2  border-[#CCCCCC]">
                                                                    <th className="w-full min-w-[6rem] md:w-4/12 py-4 text-center ">
                                                                        Monto
                                                                    </th>
                                                                    <th className="w-full min-w-[8rem] md:w-4/12 table-cell py-4 text-center">
                                                                        Fecha de
                                                                        pago
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden lg:table-cell py-4">
                                                                        Fecha
                                                                        inicio
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden lg:table-cell py-4">
                                                                        Fecha
                                                                        final
                                                                    </th>
                                                                    <th className="w-2/12 text-center min-w-[6rem] hidden md723:table-cell py-4">
                                                                        Plan
                                                                    </th>

                                                                    <th className="w-2/12 text-center min-w-[6rem] md:table-cell   py-4">
                                                                        Estado
                                                                    </th>
                                                                    {tipo_usuario >
                                                                    5 ? (
                                                                        <th className="w-2/12 text-center min-w-[4rem] md:table-cell   py-4">
                                                                            {tipo_usuario ===
                                                                            6
                                                                                ? "Casas"
                                                                                : "Habitaciones"}
                                                                        </th>
                                                                    ) : null}
                                                                    <th className="w-2/12 text-center min-w-[4rem] md:table-cell   py-4"></th>
                                                                </tr>
                                                            ) : (
                                                                <p className="text-center"></p>
                                                            )}
                                                        </thead>
                                                        <div className="2xl:py-2"></div>
                                                        <tbody className="text-sm 2xl:text-base">
                                                            {comforts?.map(
                                                                (
                                                                    comforts,
                                                                    i
                                                                ) => (
                                                                    <tr
                                                                        onClick={() =>
                                                                            getOneSuscripcion(
                                                                                comforts
                                                                            )
                                                                        }
                                                                        key={i}
                                                                        className={`w-full ${
                                                                            comforts.actual ===
                                                                            "Si"
                                                                                ? "bg-[#5aad00] bg-opacity-25"
                                                                                : "bg-white"
                                                                        } `}>
                                                                        <td className="border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 text-center cursor-pointer">
                                                                                <h1 className="mx-3 text-[#3C3C3C]">
                                                                                    {
                                                                                        comforts?.costo
                                                                                    }{" "}
                                                                                    {
                                                                                        comforts?.tipo_moneda
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_pago
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="lg:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_inicio
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="lg:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    <ShowDateToUser
                                                                                        date={
                                                                                            comforts?.fecha_vencimiento
                                                                                        }
                                                                                    />
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="md723:table-cell hidden border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    {
                                                                                        comforts.plan
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        <td className="table-cell border-r-2 border-[#CCCCCC]">
                                                                            <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                    {
                                                                                        comforts.estado
                                                                                    }
                                                                                </h1>
                                                                            </div>
                                                                        </td>
                                                                        {tipo_usuario >
                                                                        5 ? (
                                                                            <td className="table-cell border-r-2 border-[#CCCCCC]">
                                                                                <div className="flex items-center justify-center py-3 cursor-pointer">
                                                                                    <h1 className=" text-[#3C3C3C] pl-2 text-center">
                                                                                        {
                                                                                            comforts.unidades
                                                                                        }
                                                                                    </h1>
                                                                                </div>
                                                                            </td>
                                                                        ) : null}
                                                                        <td className="table-cell pl-2">
                                                                            <div className="flex justify-center">
                                                                                <button
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation();
                                                                                        setVerMas(
                                                                                            true
                                                                                        );
                                                                                        setPagoSelect(
                                                                                            comforts
                                                                                        );
                                                                                    }}
                                                                                    title="Ver detalles"
                                                                                    className="flex items-center justify-center w-8 h-8 bg-[#029BFC] px-1 py-1 mr-2 border rounded text-blue-blueFrom border-[#029BFC] group hover:bg-blue-blueFrom hover:white hover:scale-105">
                                                                                    <svg
                                                                                        className="w-5 h-5 "
                                                                                        id="Capa_2"
                                                                                        data-name="Capa 2"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        viewBox="0 0 87.07 100">
                                                                                        <g id="Testimonios">
                                                                                            <g>
                                                                                                <path
                                                                                                    fill="white"
                                                                                                    d="m.04,54.11c0-7.86-.1-15.72.03-23.57.14-8.34,3.89-14.75,10.99-19.13,3.44-2.12,6.37-.48,6.58,3.58.32,6.3,4.67,11.61,10.77,13.11,1.1.27,2.21.4,3.35.4,7.82-.01,15.64.01,23.46-.01,7.7-.02,13.8-5.86,14.2-13.53.15-2.97,1.71-4.6,4.24-4.46.68.04,1.29.29,1.86.63,7.26,4.3,11.34,10.66,11.44,19.09.18,16.02.1,32.05.03,48.08-.05,10.94-9.36,20.72-20.26,21.54-1.59.12-3.18.15-4.78.15-12.84,0-25.67-.01-38.51,0-6.84.01-12.74-2.23-17.44-7.28C2.14,88.56.1,83.6.06,77.92c-.07-7.94-.02-15.87-.02-23.81Zm60.99-2.84c.04-2.35-1.44-3.81-3.63-3.79-1.19.01-2.04.62-2.83,1.41-5.24,5.26-10.5,10.5-15.75,15.75q-1.19,1.19-2.41-.04c-1.21-1.21-2.39-2.45-3.65-3.61-1.6-1.48-3.72-1.48-5.14-.08-1.42,1.41-1.48,3.57.02,5.13,2.4,2.5,4.84,4.94,7.34,7.34,1.66,1.59,3.7,1.56,5.31-.05,6.56-6.52,13.09-13.07,19.63-19.62.71-.71,1.17-1.55,1.11-2.45Z"
                                                                                                />
                                                                                                <path
                                                                                                    fill="white"
                                                                                                    d="m43.45,23.72c-3.81,0-7.62,0-11.44,0-5.31-.01-9.29-3.6-9.72-8.9-.17-2.09-.24-4.21.07-6.29C23.13,3.47,26.84.13,31.95.07c7.7-.09,15.41-.09,23.11,0,5.61.06,9.46,3.99,9.69,9.71.07,1.82.08,3.65-.11,5.48-.51,4.8-4.43,8.4-9.28,8.46-.08,0-.16,0-.23,0-3.89,0-7.78,0-11.67,0Z"
                                                                                                />
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                </button>
                                                                                {comforts.actual ===
                                                                                    "Si" &&
                                                                                comforts
                                                                                    .id_stripe
                                                                                    .length ? (
                                                                                    <button
                                                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-2 border rounded bg-[#029BFC] text-blue-blueFrom border-[#029BFC] hover:scale-105 group hover:bg-blue-blueFrom hover:text-white"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation();
                                                                                            handleDelete(
                                                                                                comforts.id_stripe
                                                                                            );
                                                                                        }}
                                                                                        title="Eliminar">
                                                                                        <svg
                                                                                            className="w-5 h-5 "
                                                                                            id="Capa_2"
                                                                                            data-name="Capa 2"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            viewBox="0 0 91.81 100">
                                                                                            <g id="Testimonios">
                                                                                                <path
                                                                                                    fill="white"
                                                                                                    d="m69.32,100H22.49c-.28-.09-.55-.19-.83-.27-5.22-1.49-8.55-4.86-9.37-10.22-.74-4.81-.65-9.72-.96-14.58-.26-3.99-.41-7.98-.63-11.97-.43-7.56-.88-15.11-1.32-22.67-.27-4.74-.54-9.47-.78-14.21-.04-.73-.35-.96-1.03-.94-1.2.03-2.41.04-3.61,0-2.56-.11-4.44-2.66-3.83-5.15.52-2.1,2.14-3.35,4.44-3.36,5.04-.01,10.08-.02,15.12,0,.89,0,1.24-.28,1.19-1.18-.06-1-.02-2.02-.01-3.02C20.88,5.58,26.42.03,33.28.02c8.39-.01,16.78-.04,25.17,0,5.22.03,8.92,2.59,11.31,7.15,1.24,2.37,1.24,4.94,1.2,7.52-.04,1.92,0,1.92,1.88,1.92,4.88,0,9.76-.02,14.63.01,2.49.02,4.38,1.96,4.34,4.34-.04,2.37-1.91,4.15-4.38,4.17-.81,0-1.63,0-2.44,0-1.82,0-1.75-.08-1.8,1.65-.02.49-.11.97-.13,1.46-.33,5.84-.65,11.68-.97,17.52-.39,7.1-.78,14.21-1.17,21.31-.39,7.04-.78,14.08-1.17,21.12-.2,3.61-1.72,6.55-4.42,8.92-1.73,1.52-3.81,2.33-6,2.88ZM45.9,16.62c5.13,0,10.27-.01,15.4.01.84,0,1.18-.27,1.14-1.13-.05-1,0-2.02-.02-3.02-.07-2.18-1.75-3.91-3.93-3.92-8.38-.03-16.77-.03-25.15,0-2.19,0-3.87,1.73-3.94,3.91-.03.94-.01,1.88,0,2.83,0,1.29.03,1.32,1.31,1.32,5.07,0,10.14,0,15.21,0Zm4.04,41.72c0-4,0-8,0-12,0-.45,0-.92-.11-1.35-.49-2.09-2.32-3.32-4.55-3.07-1.9.21-3.4,1.94-3.41,4.01-.01,8.19-.01,16.39,0,24.58,0,2.58,1.75,4.46,4.07,4.42,2.32-.04,3.99-1.86,4-4.4,0-4.06,0-8.13,0-12.19Zm16.61.07s0,0,0,0c0-4.26.03-8.52-.01-12.78-.02-1.92-1.53-3.52-3.36-3.72-2.16-.23-3.98.93-4.5,2.89-.16.6-.21,1.21-.21,1.83,0,4.62,0,9.23,0,13.85,0,3.35-.03,6.7,0,10.05.03,2.96,2.41,4.91,5.09,4.22,1.77-.45,2.96-1.94,2.97-3.86.03-4.16,0-8.32,0-12.48Zm-33.21.01c0-3.93,0-7.87,0-11.8,0-.62-.06-1.24-.22-1.83-.52-1.93-2.27-3.07-4.41-2.88-1.76.16-3.41,1.68-3.42,3.45-.05,8.68-.06,17.36,0,26.04.02,2.19,2.43,3.87,4.58,3.44,2.19-.44,3.43-2,3.45-4.42.02-4,0-8,0-12Z"
                                                                                                />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </button>
                                                                                ) : null}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    };

    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Suscripciones">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Suscripciones">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : tipo_usuario === 8 ? (
        <NavbarHotel titulo="Suscripciones">
            {render()}
            <NavbarMobileHotel />
        </NavbarHotel>
    ) : null;
};
