import React, { useState } from "react";
import { funcionalidades } from "./list_funcionalidades_hoteles";

interface Funcionalidad {
    title: string;
    content: Content;
}

interface Content {
    basico: string;
    estandar: string;
    premium: string;
}

export const FuncionalidadesHoteles = ({ activo }: { activo: boolean }) => {
    const [planHome, setPlanHome] = useState(activo);

    const handlePlanHome = () => {
        setPlanHome(!planHome);
    };
    return (
        <div className=" mt-40 py-10 ">
            <div className="text-[#179BD8] text-2xl md:text-3xl xl:text-4xl font-bold text-center">
                Nuestros beneficios para hoteles
            </div>

            <div className="text-[#179BD8] text-sm md:text-base xl:text-lg font-bold text-center mt-5">
                Compara todas las funcionalidades de los planes
            </div>

            <div className="flex flex-col justify-center items-center gap-0.5 lg:gap-12  mx-auto mb-10 md:mb-20">
                {funcionalidades.map((funcionalidad, index: number) => (
                    <div className="w-11/12 mt-14 md:w-5/6 xl:w-4/5 ">
                        <div className="flex justify-center px-1 md:px-10">
                            <div className="w-2/5 text-[#0E1928] ">
                                <div className="mb-5 text-xs font-bold text-left lg:text-xl">
                                    {Number(index) + 1}. {funcionalidad?.group}
                                </div>
                            </div>
                            <div className="w-1/5 text-[#0E1928] text-xs lg:text-xl font-bold text-center">
                                Starter
                            </div>
                            <div className="w-1/5 text-[#0E1928] text-xs lg:text-xl font-bold text-center">
                                Estándar
                            </div>
                            <div className="w-1/5 text-[#0E1928] text-xs lg:text-xl font-bold text-center">
                                Premium
                            </div>
                        </div>
                        <div className="bg-[rgba(14,25,40,0.03)] rounded-lg ">
                            <div>
                                <div className="flex flex-col ">
                                    {funcionalidad?.content.map((content) => (
                                        <div className="flex flex-col">
                                            <div className="flex items-center mx-1 h-14 md:mx-10">
                                                <div className="w-2/5 text-xs lg:text-base font-medium text-[#0E1928]">
                                                    {content.title}
                                                </div>
                                                <div className="flex justify-center items-center w-1/5 text-[#0E1928]">
                                                    {content.content.basico ===
                                                    "Si" ? (
                                                        <div>
                                                            <svg
                                                                id="Capa_2"
                                                                data-name="Capa 2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 99.97 100"
                                                                fill="#10E649"
                                                                className="w-5 h-5 lg:w-7 lg:h-7 md:w-6 md:h-6"
                                                            >
                                                                <g id="Pop-up">
                                                                    <path d="m49.84,0c27.53.09,49.9,21.75,50.12,49.8.22,27.65-22.33,50.18-49.9,50.2C22.41,100.03.04,77.77,0,50.2-.04,22.43,22.18.05,49.84,0Zm-29.07,44.39c-3.19-.03-5.36,1.69-6.69,4.35-1.43,2.86-.94,5.71,1.29,7.98,6.13,6.26,12.35,12.43,18.58,18.59,1.32,1.3,2.93,2.03,4.88,2.08,3.01.08,5.03-1.65,6.95-3.57,6.5-6.51,12.97-13.05,19.47-19.56,6.36-6.37,12.78-12.69,19.1-19.1,2.77-2.81,2.79-7.39.25-10.03-3.17-3.28-7.21-3.53-10.52-.51-2.4,2.19-4.69,4.51-6.99,6.8-8.91,8.87-17.81,17.74-26.67,26.66-1.11,1.12-1.78,1.08-2.86-.03-3.7-3.82-7.51-7.53-11.25-11.31-1.53-1.55-3.34-2.38-5.53-2.36Z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    ) : content.content
                                                          .basico === "No" ? (
                                                        <div>
                                                            <svg
                                                                id="Capa_2"
                                                                data-name="Capa 2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 100 99.99"
                                                                fill="#FD1C1C"
                                                                className="w-5 h-5 lg:w-7 lg:h-7 md:w-6 md:h-6"
                                                            >
                                                                <g id="Testimonios">
                                                                    <path d="m100,49.92c0,27.86-22.24,50.17-50.1,50.07C22.4,99.89.06,77.94,0,49.83-.06,22.44,22.59-.01,50.1,0c27.62.01,49.9,22.31,49.9,49.92ZM29.4,22.64c-2.54.22-4.94,1.57-6.16,4.54-1.17,2.86-.8,5.56,1.41,7.8,4.43,4.5,8.88,8.99,13.42,13.38,1.32,1.28,1.25,2.05-.04,3.28-4.37,4.21-8.64,8.52-12.93,12.81-2.52,2.52-3.13,5.55-1.77,8.57,1.29,2.87,4.24,4.58,7.45,4.31,2.19-.18,3.83-1.4,5.32-2.89,4.11-4.12,8.27-8.18,12.31-12.36,1.25-1.29,2.07-1.33,3.33-.03,4.32,4.44,8.7,8.81,13.13,13.14,3.1,3.03,7.58,2.9,10.53-.18,2.87-2.99,2.75-7.42-.27-10.43-4.3-4.27-8.6-8.55-12.9-12.82-1.66-1.64-1.66-1.8-.04-3.42,4.37-4.38,8.76-8.75,13.11-13.16,2.93-2.97,2.87-7.5-.1-10.41-3.29-3.23-7.58-2.91-11.25.8-4.09,4.14-8.23,8.22-12.28,12.4-1.19,1.23-1.97,1.26-3.19.02-4.42-4.51-8.93-8.92-13.43-13.35-1.4-1.38-3.12-2.04-5.67-2.01Z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    ) : (
                                                        <div className="px-2 text-xs font-bold text-center md:text-lg lg:text-xl">
                                                            {
                                                                content.content
                                                                    .basico
                                                            }
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="flex justify-center items-center w-1/5 text-[#0E1928]">
                                                    {content.content
                                                        .estandar === "Si" ? (
                                                        <div>
                                                            <svg
                                                                id="Capa_2"
                                                                data-name="Capa 2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 99.97 100"
                                                                fill="#10E649"
                                                                className="w-5 h-5 lg:w-7 lg:h-7 md:w-6 md:h-6"
                                                            >
                                                                <g id="Pop-up">
                                                                    <path d="m49.84,0c27.53.09,49.9,21.75,50.12,49.8.22,27.65-22.33,50.18-49.9,50.2C22.41,100.03.04,77.77,0,50.2-.04,22.43,22.18.05,49.84,0Zm-29.07,44.39c-3.19-.03-5.36,1.69-6.69,4.35-1.43,2.86-.94,5.71,1.29,7.98,6.13,6.26,12.35,12.43,18.58,18.59,1.32,1.3,2.93,2.03,4.88,2.08,3.01.08,5.03-1.65,6.95-3.57,6.5-6.51,12.97-13.05,19.47-19.56,6.36-6.37,12.78-12.69,19.1-19.1,2.77-2.81,2.79-7.39.25-10.03-3.17-3.28-7.21-3.53-10.52-.51-2.4,2.19-4.69,4.51-6.99,6.8-8.91,8.87-17.81,17.74-26.67,26.66-1.11,1.12-1.78,1.08-2.86-.03-3.7-3.82-7.51-7.53-11.25-11.31-1.53-1.55-3.34-2.38-5.53-2.36Z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    ) : content.content
                                                          .estandar === "No" ? (
                                                        <div>
                                                            <svg
                                                                id="Capa_2"
                                                                data-name="Capa 2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 100 99.99"
                                                                fill="#FD1C1C"
                                                                className="w-5 h-5 lg:w-7 lg:h-7 md:w-6 md:h-6"
                                                            >
                                                                <g id="Testimonios">
                                                                    <path d="m100,49.92c0,27.86-22.24,50.17-50.1,50.07C22.4,99.89.06,77.94,0,49.83-.06,22.44,22.59-.01,50.1,0c27.62.01,49.9,22.31,49.9,49.92ZM29.4,22.64c-2.54.22-4.94,1.57-6.16,4.54-1.17,2.86-.8,5.56,1.41,7.8,4.43,4.5,8.88,8.99,13.42,13.38,1.32,1.28,1.25,2.05-.04,3.28-4.37,4.21-8.64,8.52-12.93,12.81-2.52,2.52-3.13,5.55-1.77,8.57,1.29,2.87,4.24,4.58,7.45,4.31,2.19-.18,3.83-1.4,5.32-2.89,4.11-4.12,8.27-8.18,12.31-12.36,1.25-1.29,2.07-1.33,3.33-.03,4.32,4.44,8.7,8.81,13.13,13.14,3.1,3.03,7.58,2.9,10.53-.18,2.87-2.99,2.75-7.42-.27-10.43-4.3-4.27-8.6-8.55-12.9-12.82-1.66-1.64-1.66-1.8-.04-3.42,4.37-4.38,8.76-8.75,13.11-13.16,2.93-2.97,2.87-7.5-.1-10.41-3.29-3.23-7.58-2.91-11.25.8-4.09,4.14-8.23,8.22-12.28,12.4-1.19,1.23-1.97,1.26-3.19.02-4.42-4.51-8.93-8.92-13.43-13.35-1.4-1.38-3.12-2.04-5.67-2.01Z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    ) : (
                                                        <div className="px-2 text-xs font-bold text-center md:text-lg lg:text-xl">
                                                            {
                                                                content.content
                                                                    .estandar
                                                            }
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="flex justify-center items-center w-1/5 text-[#0E1928]">
                                                    {content.content.premium ===
                                                    "Si" ? (
                                                        <div>
                                                            <svg
                                                                id="Capa_2"
                                                                data-name="Capa 2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 99.97 100"
                                                                fill="#10E649"
                                                                className="w-5 h-5 lg:w-7 lg:h-7 md:w-6 md:h-6"
                                                            >
                                                                <g id="Pop-up">
                                                                    <path d="m49.84,0c27.53.09,49.9,21.75,50.12,49.8.22,27.65-22.33,50.18-49.9,50.2C22.41,100.03.04,77.77,0,50.2-.04,22.43,22.18.05,49.84,0Zm-29.07,44.39c-3.19-.03-5.36,1.69-6.69,4.35-1.43,2.86-.94,5.71,1.29,7.98,6.13,6.26,12.35,12.43,18.58,18.59,1.32,1.3,2.93,2.03,4.88,2.08,3.01.08,5.03-1.65,6.95-3.57,6.5-6.51,12.97-13.05,19.47-19.56,6.36-6.37,12.78-12.69,19.1-19.1,2.77-2.81,2.79-7.39.25-10.03-3.17-3.28-7.21-3.53-10.52-.51-2.4,2.19-4.69,4.51-6.99,6.8-8.91,8.87-17.81,17.74-26.67,26.66-1.11,1.12-1.78,1.08-2.86-.03-3.7-3.82-7.51-7.53-11.25-11.31-1.53-1.55-3.34-2.38-5.53-2.36Z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    ) : content.content
                                                          .premium === "No" ? (
                                                        <div>
                                                            <svg
                                                                id="Capa_2"
                                                                data-name="Capa 2"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 100 99.99"
                                                                fill="#FD1C1C"
                                                                className="w-5 h-5 lg:w-7 lg:h-7 md:w-6 md:h-6"
                                                            >
                                                                <g id="Testimonios">
                                                                    <path d="m100,49.92c0,27.86-22.24,50.17-50.1,50.07C22.4,99.89.06,77.94,0,49.83-.06,22.44,22.59-.01,50.1,0c27.62.01,49.9,22.31,49.9,49.92ZM29.4,22.64c-2.54.22-4.94,1.57-6.16,4.54-1.17,2.86-.8,5.56,1.41,7.8,4.43,4.5,8.88,8.99,13.42,13.38,1.32,1.28,1.25,2.05-.04,3.28-4.37,4.21-8.64,8.52-12.93,12.81-2.52,2.52-3.13,5.55-1.77,8.57,1.29,2.87,4.24,4.58,7.45,4.31,2.19-.18,3.83-1.4,5.32-2.89,4.11-4.12,8.27-8.18,12.31-12.36,1.25-1.29,2.07-1.33,3.33-.03,4.32,4.44,8.7,8.81,13.13,13.14,3.1,3.03,7.58,2.9,10.53-.18,2.87-2.99,2.75-7.42-.27-10.43-4.3-4.27-8.6-8.55-12.9-12.82-1.66-1.64-1.66-1.8-.04-3.42,4.37-4.38,8.76-8.75,13.11-13.16,2.93-2.97,2.87-7.5-.1-10.41-3.29-3.23-7.58-2.91-11.25.8-4.09,4.14-8.23,8.22-12.28,12.4-1.19,1.23-1.97,1.26-3.19.02-4.42-4.51-8.93-8.92-13.43-13.35-1.4-1.38-3.12-2.04-5.67-2.01Z" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    ) : (
                                                        <div className="px-2 text-xs font-bold text-center md:text-lg lg:text-xl">
                                                            {
                                                                content.content
                                                                    .premium
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <hr className="border-[#C0D345] mx-1 md:mx-10" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
