import { useEffect, useState } from "react";
import BotonInformacion from "../../../../components/helpers/BotonInformacion";
import { fetchAxiosToken } from "../../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../../hooks/hooks";
import { Navbar } from "../../Navbar";
import { NavbarMobile } from "../../NavbarMobile";
import { EditarTarifa } from "./EditarTarifa";
import { ModalNewTarifa } from "./ModalNewTarifa";
import { TarifaAvanzada } from "./TarifaAvanzada";
import { ButtonRegresar } from "../../../../components/ButtonRegresar";
import { useParams } from "react-router-dom";
import { useDispatchDominio } from "../../../../hooks/useDispatchDominio";
import { NavbarBroker } from "../../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../../../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../../../dashboardHotel/NavbarMobileHotel";
import Swal from "sweetalert2";

export interface tarifa {
    id: number;
    id_casa: number;
    nombre: string;
    opcional: string;
    precio: string;
}

interface Habitacion {
    nombre: string;
}

export const TarifasAdicionales = () => {
    const { idCasa } = useParams();
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const dispatchDominio = useDispatchDominio(
        tipo_usuario === 8
            ? undefined
            : idCasa && Number(idCasa) > 0
            ? idCasa
            : undefined
    );

    const [tarifas, setTarifas] = useState<tarifa[]>([]);
    const [tarifaSelected, setTarifaSelected] = useState<tarifa | null>(null);
    const [modalAvanzado, setModalAvanzado] = useState(false);
    const [modalEditarTarifa, setModalEditarTarifa] = useState(false);

    const [habitacion, setHabitacion] = useState<Habitacion>({ nombre: "" });
    const [modalTarifa, setModalTarifa] = useState(false);
    const { id: id_casa, tipo_moneda } = useAppSelector(
        (state) => state.landing_house.house
    );
    useEffect(() => {
        if (tipo_usuario === 3 || tipo_usuario === 4 || tipo_usuario === 6) {
            if (idCasa && Number(idCasa) > 0) dispatchDominio();
        }
    }, []);

    const getTarifas = async () => {
        if (tipo_usuario === 3 || tipo_usuario === 4 || tipo_usuario === 6) {
            fetchAxiosToken({
                url: `/servicios_adicionales/getAll/${id_casa}`,
            })
                .then((res) => setTarifas(res))
                .catch((error) => console.log(error.response));
        }

        if (tipo_usuario === 8) {
            getHabitacion();

            fetchAxiosToken({
                url: `/servicios_adicionales_habitaciones/getAll/${idCasa}`,
            })
                .then((res) => setTarifas(res))
                .catch((error) => console.log(error.response));
        }
    };

    useEffect(() => {
        getTarifas();
    }, [id_casa]);

    const getHabitacion = async () => {
        if (idCasa) {
            const data = await fetchAxiosToken({
                url: `/hotel_habitaciones/detalle/${idCasa}`,
            });

            setHabitacion(data);
        }
    };

    const handleDelete = async (id: number) => {
        if (tipo_usuario === 3 || tipo_usuario === 4 || tipo_usuario === 6) {
            await fetchAxiosToken({
                url: `/servicios_adicionales/delete/${id}`,
                method: "delete",
            });
            getTarifas();
        }
        if (tipo_usuario === 8) {
            await fetchAxiosToken({
                url: `/servicios_adicionales_habitaciones/delete/${id}`,
                method: "delete",
            });
            getTarifas();
        }
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "Eliminado con éxito",

            icon: "success",
            confirmButtonText: "Ok",
        });
    };

    const handleEdit = (tarifa: tarifa) => {
        setTarifaSelected(tarifa);
        setModalEditarTarifa(true);
    };

    const handleEditAvanzado = (tarifa: tarifa) => {
        setTarifaSelected(tarifa);
        setModalAvanzado(true);
    };
    const render = () => {
        return (
            <div className="flex">
                <div className="flex flex-col items-center justify-end w-full sm:mb-0">
                    <div className="grid w-11/12 gap-4 mx-auto mt-12 max-w-7xl lg:grid-cols-2 right-2">
                        <div className=" text-[#4D4D4D]">
                            <ButtonRegresar
                                // url={`/dashboard/onboarding/alojamiento`}
                                className="-ml-2 "
                                arrowColor="black"
                                textColor="black"
                            />
                            <p className="text-xl font-bold 2xl:text-2xl ">
                                Tarifas adicionales
                            </p>
                            <p className="mt-6 text-sm 2xl:text-base">
                                Personaliza tus tarifas y condiciones para
                                cobrar por servicios adicionales.
                            </p>
                            <p className="mt-4 text-sm 2xl:text-base">
                                Ejemplo: Limpieza, Cocinera, entre otros.
                            </p>
                        </div>
                        <div className="w-full p-4 border border-[#CCCCCC] rounded-lg shadow-xl mb-6">
                            <div>
                                <button
                                    onClick={() => setModalTarifa(true)}
                                    className="flex items-center gap-1 p-2 border rounded-lg bg-[#E6E6E6] hover:scale-105 ">
                                    <svg
                                        className="h-5 w-5 mt-0.5 text-[#4D4D4D]"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <span className="text-[#4D4D4D] text-sm">
                                        Nueva tarifa
                                    </span>
                                </button>
                            </div>
                            {tarifas.length > 0 ? (
                                <div className="flex flex-col w-full gap-2 mt-4 lg:gap-4">
                                    {tarifas.map((tarifa) => (
                                        <div
                                            key={tarifa.id}
                                            className="flex justify-between w-full p-3 border-2 border-[#CCCCCC] rounded-lg">
                                            <div className="flex flex-col">
                                                <span className=" font-semibold text-[#4D4D4D] 2xl:text-base text-sm">
                                                    {tarifa.nombre}
                                                </span>
                                                <span className="italic text-[#4D4D4D] 2xl:text-base text-sm">
                                                    {tarifa.precio}{" "}
                                                    {tipo_moneda} -{" "}
                                                    {tarifa.opcional === "Si"
                                                        ? "Opcional"
                                                        : "Obligatoria"}
                                                </span>
                                            </div>
                                            <div className="flex items-center gap-3">
                                                <button
                                                    className="rounded-lg bg-[#E6E6E6] p-2 hover:scale-105"
                                                    title="Editar tarifa"
                                                    onClick={() =>
                                                        handleEdit(tarifa)
                                                    }>
                                                    <svg
                                                        className="w-4 h-4"
                                                        id="Capa_2"
                                                        data-name="Capa 2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 100 99.97">
                                                        <g id="Testimonios">
                                                            <g>
                                                                <path
                                                                    fill="#4D4D4D"
                                                                    d="m.01,95.86c1.03-2.98,1.79-6.04,2.66-9.06,1.65-5.68,3.25-11.36,4.88-17.05.35-1.21.41-1.22,1.3-.33,7.26,7.26,14.53,14.52,21.79,21.79.86.86.85.88-.32,1.21-8.47,2.41-16.93,4.82-25.39,7.23-.28.08-.54.21-.82.31-1.24,0-2.47-.02-3.71.01-.34,0-.41-.06-.4-.4.03-1.23.01-2.47.01-3.71Z"
                                                                />
                                                                <path
                                                                    fill="#4D4D4D"
                                                                    d="m36.11,88.77c-.31-.02-.47-.25-.65-.43-7.95-7.95-15.9-15.91-23.87-23.84-.72-.72-.2-1.05.23-1.48,8.48-8.48,16.96-16.96,25.44-25.44,7.95-7.95,15.9-15.9,23.86-23.85q.95-.95,1.88-.02c7.77,7.77,15.53,15.55,23.32,23.29.73.73.64,1.1-.03,1.77-16.45,16.42-32.87,32.86-49.3,49.29-.27.27-.49.61-.89.71Z"
                                                                />
                                                                <path
                                                                    fill="#4D4D4D"
                                                                    d="m78.52,0c2.53-.02,4.61.81,6.3,2.48,4.23,4.17,8.46,8.36,12.61,12.61,3.32,3.4,3.45,8.57.29,12.11-1.94,2.18-4.09,4.17-6.13,6.27-.61.63-.95.17-1.34-.22-4.55-4.55-9.1-9.1-13.65-13.65-3.29-3.29-6.56-6.58-9.87-9.84-.63-.62-.66-1.02,0-1.64,1.85-1.78,3.63-3.62,5.46-5.43C73.97.92,76.1-.04,78.52,0Z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>

                                                <button
                                                    className="rounded-lg bg-[#E6E6E6] p-2 hover:scale-105"
                                                    title="Configuración avanzada"
                                                    onClick={() =>
                                                        handleEditAvanzado(
                                                            tarifa
                                                        )
                                                    }>
                                                    <svg
                                                        className="w-4 h-4"
                                                        id="Capa_2"
                                                        data-name="Capa 2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 100 94.85">
                                                        <g id="Testimonios">
                                                            <path
                                                                fill="#4D4D4D"
                                                                d="m65.95,94.38c-2.01-.02-3.64-.85-4.85-2.42-2.49-3.26-5.79-5.08-9.84-5.27-4.44-.2-8.08,1.58-10.83,5.11-2.41,3.08-4.72,3.77-8.38,2.35-5.02-1.94-9.6-4.64-13.72-8.1-2.24-1.88-2.78-4.58-1.6-7.55,1.87-4.74,1.39-9.19-1.77-13.23-2.12-2.71-4.97-4.27-8.39-4.69-3.26-.4-5.37-2.26-5.92-5.48-.97-5.77-.85-11.54.36-17.26.56-2.67,2.75-4.53,5.46-4.86,6.65-.8,11.41-5.88,11.65-12.54.06-1.63-.28-3.24-.82-4.79-1.2-3.41-.44-5.85,2.42-8.05,3.85-2.96,8.04-5.29,12.56-7.03,3.11-1.19,5.69-.52,7.8,2.03,5.13,6.21,14.69,6.24,19.84.05C62.13,0,64.64-.63,67.89.63c5.01,1.95,9.61,4.61,13.75,8.05,2.39,1.99,3.09,4.43,2.06,7.45-1.44,4.23-.98,8.22,1.55,11.9,2.04,2.98,4.92,4.73,8.46,5.35,3,.53,4.87,2.23,5.46,5.21,1.15,5.86,1.08,11.72-.1,17.57-.52,2.57-2.57,4.39-5.34,4.86-5.67.96-9.64,4.57-10.91,9.92-.59,2.5-.4,4.95.48,7.36,1.29,3.55.63,5.87-2.34,8.25-3.75,3.01-7.86,5.44-12.3,7.28-.88.36-1.78.56-2.72.56Zm-32.48-46.87c.29,9.29,7.3,16.39,16.47,16.42,9.3.04,16.63-7.16,16.57-16.69-.06-9.3-7.31-16.38-16.47-16.37-9.45.02-16.41,7.33-16.57,16.63Z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </button>

                                                <button
                                                    className="rounded-lg bg-[#E6E6E6] p-2 hover:scale-105"
                                                    onClick={() =>
                                                        handleDelete(tarifa.id)
                                                    }
                                                    title="Eliminar tarifa">
                                                    <svg
                                                        className="w-4 h-4  text-[#4D4D4D]"
                                                        id="Capa_2"
                                                        data-name="Capa 2"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 91.81 100">
                                                        <g id="Testimonios">
                                                            <path
                                                                fill="#4D4D4D"
                                                                d="m69.32,100H22.49c-.28-.09-.55-.19-.83-.27-5.22-1.49-8.55-4.86-9.37-10.22-.74-4.81-.65-9.72-.96-14.58-.26-3.99-.41-7.98-.63-11.97-.43-7.56-.88-15.11-1.32-22.67-.27-4.74-.54-9.47-.78-14.21-.04-.73-.35-.96-1.03-.94-1.2.03-2.41.04-3.61,0-2.56-.11-4.44-2.66-3.83-5.15.52-2.1,2.14-3.35,4.44-3.36,5.04-.01,10.08-.02,15.12,0,.89,0,1.24-.28,1.19-1.18-.06-1-.02-2.02-.01-3.02C20.88,5.58,26.42.03,33.28.02c8.39-.01,16.78-.04,25.17,0,5.22.03,8.92,2.59,11.31,7.15,1.24,2.37,1.24,4.94,1.2,7.52-.04,1.92,0,1.92,1.88,1.92,4.88,0,9.76-.02,14.63.01,2.49.02,4.38,1.96,4.34,4.34-.04,2.37-1.91,4.15-4.38,4.17-.81,0-1.63,0-2.44,0-1.82,0-1.75-.08-1.8,1.65-.02.49-.11.97-.13,1.46-.33,5.84-.65,11.68-.97,17.52-.39,7.1-.78,14.21-1.17,21.31-.39,7.04-.78,14.08-1.17,21.12-.2,3.61-1.72,6.55-4.42,8.92-1.73,1.52-3.81,2.33-6,2.88ZM45.9,16.62c5.13,0,10.27-.01,15.4.01.84,0,1.18-.27,1.14-1.13-.05-1,0-2.02-.02-3.02-.07-2.18-1.75-3.91-3.93-3.92-8.38-.03-16.77-.03-25.15,0-2.19,0-3.87,1.73-3.94,3.91-.03.94-.01,1.88,0,2.83,0,1.29.03,1.32,1.31,1.32,5.07,0,10.14,0,15.21,0Zm4.04,41.72c0-4,0-8,0-12,0-.45,0-.92-.11-1.35-.49-2.09-2.32-3.32-4.55-3.07-1.9.21-3.4,1.94-3.41,4.01-.01,8.19-.01,16.39,0,24.58,0,2.58,1.75,4.46,4.07,4.42,2.32-.04,3.99-1.86,4-4.4,0-4.06,0-8.13,0-12.19Zm16.61.07s0,0,0,0c0-4.26.03-8.52-.01-12.78-.02-1.92-1.53-3.52-3.36-3.72-2.16-.23-3.98.93-4.5,2.89-.16.6-.21,1.21-.21,1.83,0,4.62,0,9.23,0,13.85,0,3.35-.03,6.7,0,10.05.03,2.96,2.41,4.91,5.09,4.22,1.77-.45,2.96-1.94,2.97-3.86.03-4.16,0-8.32,0-12.48Zm-33.21.01c0-3.93,0-7.87,0-11.8,0-.62-.06-1.24-.22-1.83-.52-1.93-2.27-3.07-4.41-2.88-1.76.16-3.41,1.68-3.42,3.45-.05,8.68-.06,17.36,0,26.04.02,2.19,2.43,3.87,4.58,3.44,2.19-.44,3.43-2,3.45-4.42.02-4,0-8,0-12Z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="flex flex-col items-center h-full mt-8">
                                    Aún no tienes tarifas adicionales
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <ModalNewTarifa
                    modalVisible={modalTarifa}
                    setModalVisible={setModalTarifa}
                    getTarifas={getTarifas}
                />
                <EditarTarifa
                    tarifaSelected={tarifaSelected}
                    modalVisible={modalEditarTarifa}
                    setModalVisible={setModalEditarTarifa}
                    getTarifas={getTarifas}
                />
                <TarifaAvanzada
                    tarifaSelected={tarifaSelected}
                    modalVisible={modalAvanzado}
                    setModalVisible={setModalAvanzado}
                    getTarifas={getTarifas}
                />
            </div>
        );
    };
    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Tarifas adicionales">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Tarifas adicionales">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : tipo_usuario === 8 ? (
        <NavbarHotel titulo={`Tarifas adicionales ${habitacion.nombre} `}>
            {render()}
            <NavbarMobileHotel />
        </NavbarHotel>
    ) : null;
};
