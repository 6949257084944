import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";

import Swal from "sweetalert2";
import { useAppSelector } from "../../../hooks/hooks";

import { useNavigate, useParams } from "react-router-dom";
import landing_house from "../../../redux/reducers/landingReducer";
import { ModalEditName } from "../../../designs/modalEdit/ModalEditName";
import { ModalEditPrecioBase } from "../../../designs/modalEdit/ModalEditPrecioBase";
import { ModalEditCapacidades } from "../../../designs/modalEdit/ModalEditCapacidades";
import { ModalEditImageCarrousel } from "../../../designs/modalEdit/ModalEditImageCarrousel";
import { ModalEditDescripcion1 } from "../../../designs/modalEdit/ModalEditDescripcion1";
import { ModalEditDescripcion2 } from "../../../designs/modalEdit/ModalEditDescripcion2";
import { ModalEditRooms } from "../../../designs/modalEdit/ModalEditRooms";
import { ModalEditComforts } from "../../../designs/modalEdit/ModalEditComforts";
import { ModalEditEvents } from "../../../designs/modalEdit/ModalEditEvents";
import { ModalEditLocation } from "../../../designs/modalEdit/ModalEditLocation";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../NavbarHotel";
import { NavbarMobileHotel } from "../NavbarMobileHotel";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const ConfiguracionesHotel = () => {
    const navigate = useNavigate();
    const { plan, tema } = useAppSelector((state) => state.landing_house.house);
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const [modalVisible, setModalVisible] = useState(false);
    const [modalName, setMondalName] = useState("");

    const ocultar = (titulo: string) => {
        const nombres = [
            "Personas adicionales",
            "Descuento por estadía",
            "descuento-estadia",
            "Cupones",
            "Disponibilidad máxima",
            "Conexiones externas",
        ];

        if (nombres.includes(titulo) && plan < 2) {
            return "hidden";
        } else {
            return "";
        }
    };
    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);
    const ocultarlanding = (titulo: string) => {
        const nombres = ["descripcion 2"];

        if (titulo === "descripcion 2" && tema !== "2") {
            return "hidden";
        } else if (titulo === "novedades" && plan < 2) {
            return "hidden";
        }
    };
    const opcionesPremiumLanding = (titulo: string) => {
        const nombres = ["descripcion 2", "novedades"];

        if (titulo === "descripcion 2" && tema !== "2") {
            return true;
        } else if (titulo === "novedades" && plan < 2) {
            return true;
        } else {
            return false;
        }
    };

    const opcionesPremium = (titulo: string) => {
        const nombres = [
            "descuento-estadia",
            "Cupones",
            "Disponibilidad máxima",
            "Conexiones externas",
        ];

        if (nombres.includes(titulo) && plan < 2 && tipo_usuario !== 6) {
            return true;
        } else {
            return false;
        }
    };

    const OPCIONES = [
        {
            nombre: "Cómo editar mi landing",
            url: "editar-landing",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Plantillas para mi landing",
            url: "tema-landing",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 78.89 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m12.55,100c-2.92-.77-5.28-2.31-6.85-4.94-.9-1.51-1.4-3.14-1.41-4.91,0-6.05,0-12.1-.02-18.14,0-.77.14-1.18,1.03-.98.4.09.84.04,1.26.04,19.96,0,39.93,0,59.89,0,.52,0,1.05.05,1.56-.05.81-.16,1.01.16,1,.92-.03,3.48-.04,6.96,0,10.44.03,2.89-.16,5.78.15,8.67.47,4.48,4.27,8.26,8.79,8.71.32.03.65.05.94.23h-16c-4.79-1.61-6.67-4.22-6.67-9.27,0-2.7.01-5.4,0-8.09-.01-1.45-.87-2.24-2.19-2.11-1.05.1-1.66.84-1.67,2.13-.02,2.92-.02,5.85,0,8.77,0,.94.02,1.88.19,2.81.4,2.17,1.48,4,2.75,5.75h-10.73c-3.92-1.02-6.82-4.26-6.53-9.16.15-2.62.04-5.27.02-7.9-.01-1.58-.76-2.43-2.06-2.42-1.26.01-1.91.82-1.92,2.4,0,2.96,0,5.92,0,8.88,0,2.73.81,5.18,2.42,7.37.19.26.46.47.46.83h-9.56c-4.62-1.33-6.74-5.04-6.52-9.31.14-2.59.03-5.2.02-7.8,0-1.55-.7-2.37-1.97-2.36-1.25,0-2,.87-2.01,2.36-.01,2.37.08,4.75-.02,7.12-.15,3.72.68,7.09,3.08,10h-7.41Z"
                            />
                            <path
                                fill="#333333"
                                d="m36.64,67.09c-9.92,0-19.83,0-29.75,0-4.14,0-6.87-2.7-6.86-6.82,0-2.43-.21-4.87.36-7.28,1.32-5.57,6.19-9.58,11.88-9.85,2.83-.14,5.67-.24,8.48-.05,5.02.33,8.01-3.07,7.55-7.74-.34-3.45-.24-6.95-.96-10.37-.6-2.82-1.74-5.45-2.93-8.04-2.91-6.32-.47-11.95,5.11-15.09,6.09-3.41,14.09-1.97,18.3,3.33,2.41,3.03,2.96,6.45,1.73,10.1-.59,1.75-1.39,3.43-2.1,5.14-1.36,3.25-1.96,6.65-2.08,10.16-.07,1.92-.2,3.83-.24,5.74-.09,4.07,2.47,6.65,6.55,6.71,3.31.05,6.63-.13,9.94.12,6.21.47,11.47,5.91,11.65,12.11.05,1.88.05,3.77,0,5.66-.08,3.41-2.88,6.16-6.3,6.17-10.11.02-20.22,0-30.33,0Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Mensaje de bienvenida",
            url: "mensaje-bienvenida",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 99.97">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m100,22.92v2.34c-.43,1.04-1.11,1.89-1.92,2.68-1.84,1.79-3.64,3.62-5.46,5.43-7.99,7.96-15.99,15.92-23.98,23.88-1.65,1.65-1.77,3.64-.32,5.09,1.39,1.4,3.5,1.28,5.11-.3,1.09-1.07,2.17-2.16,3.25-3.23,4.84-4.81,9.69-9.62,14.52-14.44,1.07-1.07,2.32-1.69,3.85-1.51,1.83.22,3.16,1.2,3.78,2.95.63,1.78.23,3.4-1.1,4.75-1.8,1.83-3.63,3.63-5.45,5.45-9.94,9.92-19.89,19.84-29.81,29.77-6.38,6.39-14.04,10.14-23.04,10.86-8.91.72-17.12-1.46-24.35-6.79C6.08,83.24,1.05,74.29.12,63.17c-.61-7.3,1.08-14.17,4.52-20.66,4.17-7.86,7.48-16.08,10.06-24.6,1.05-3.49,1.92-7.02,2.74-10.56.63-2.72,2.68-4.49,5.24-4.58,3.88-.14,6.52,3.29,5.56,7.28-1.77,7.36-3.58,14.71-5.34,22.07-.46,1.92-1.09,3.82-1.29,5.8-.15,1.44.48,2.54,1.74,3.22,1.31.71,2.58.51,3.73-.4.41-.32.77-.7,1.13-1.07,12.55-12.55,25.1-25.1,37.65-37.65.32-.32.64-.65.98-.95,1.75-1.48,4.29-1.42,5.97.14,2.04,1.9,2.04,4.72-.05,6.81-7.72,7.73-15.44,15.45-23.17,23.18-.34.34-.7.68-.99,1.07-1.4,1.91-.56,4.5,1.68,5.23,1.48.48,2.66-.07,3.69-1.11,4.64-4.65,9.29-9.29,13.93-13.93,5.01-5.01,10.02-10.03,15.04-15.03,1.52-1.51,3.4-1.87,5.18-1.05,1.86.85,2.9,2.64,2.74,4.74-.1,1.32-.84,2.27-1.74,3.17-9.45,9.4-18.89,18.81-28.33,28.22-.28.28-.56.54-.81.84-1.67,1.96-.77,4.81,1.73,5.49,1.43.39,2.52-.21,3.5-1.18,4.56-4.54,9.13-9.08,13.69-13.62,4.4-4.38,8.78-8.78,13.2-13.14,2.47-2.43,6.1-1.73,7.59,1.41.1.21.19.41.28.62Z"
                            />
                            <path
                                fill="#333333"
                                d="m78.54,99.97c-1.34-.65-2.16-1.64-2.02-3.22.14-1.49,1.11-2.24,2.41-2.75,7.01-2.75,12.07-7.57,15.21-14.41.56-1.22,1.41-2.03,2.79-2.1,1.34-.07,2.28.6,2.91,1.75.05.09.1.16.16.25v1.95c-2.46,5.67-6.14,10.36-11.12,14.04-2.58,1.9-5.43,3.3-8.39,4.5h-1.95Z"
                            />
                            <path
                                fill="#333333"
                                d="m71.25,87.6c-.01-1.41.67-2.42,2.1-2.94,2.66-.97,5.05-2.36,7.11-4.31,1.76-1.67,3.18-3.6,4.22-5.79.13-.26.24-.53.38-.79.82-1.53,2.48-2.14,3.96-1.47,1.53.69,2.21,2.39,1.53,4.01-1.21,2.91-2.97,5.48-5.17,7.73-2.81,2.88-6.12,5.01-9.94,6.3-2.2.75-4.18-.56-4.2-2.73Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },

        {
            nombre: "Cupones",
            url: "cupones",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 64.37">
                    <g id="Testimonios">
                        <path
                            fill="#333333"
                            d="m64.18.04c8.2,0,16.41.12,24.61-.04,6.24-.12,11.26,4.98,11.21,11.15-.12,14.03-.03,28.07-.04,42.1,0,6.49-4.59,11.1-11.06,11.1-16.48,0-32.97-.01-49.45.02-2.43,0-4.58-.71-6.59-2.03-.57-.37-.79-.79-.78-1.45.03-2.34.05-4.68,0-7.02-.06-2.67-2.34-4.36-4.67-3.5-1.42.52-2.26,1.82-2.28,3.62-.02,2.26-.03,4.53,0,6.79.01.75-.22,1.21-.87,1.63-1.99,1.28-4.1,2.01-6.5,1.96-2.38-.05-4.75,0-7.13-.02C4.74,64.31.02,59.6.01,53.69,0,39.36,0,25.03.01,10.7.02,4.8,4.73.09,10.62.05c2.56-.02,5.12,0,7.68,0,2.36,0,4.4.9,6.31,2.19.49.33.5.77.5,1.26,0,2.34-.03,4.68.01,7.02.05,2.62,2.26,4.31,4.56,3.54,1.45-.48,2.37-1.85,2.39-3.66.02-2.26.03-4.53,0-6.79-.01-.75.23-1.21.88-1.62C34.94.71,37.06.01,39.45.02c8.24.04,16.49.02,24.73.02m-10.5,46.17c1.23.12,2.14-.69,3.02-1.58,6.66-6.66,13.32-13.32,19.98-19.99.36-.37.74-.74,1.02-1.17.74-1.11.78-2.32.21-3.49-.58-1.19-1.6-1.77-2.96-1.81-1.37-.04-2.29.7-3.17,1.59-6.66,6.67-13.32,13.33-19.98,19.99-.36.37-.74.74-1.02,1.17-.74,1.11-.78,2.32-.21,3.49.58,1.19,1.6,1.82,3.11,1.8m-28.56-14.11v5.67c0,.08,0,.15,0,.22.15,2.11,1.5,3.5,3.41,3.53,1.99.02,3.48-1.35,3.52-3.5.08-3.89.08-7.78,0-11.67-.04-2.06-1.47-3.43-3.33-3.47-2.09-.05-3.53,1.39-3.59,3.67-.05,1.85-.01,3.7-.01,5.56m53.27,9.04c-.04-2.9-2.39-5.28-5.23-5.28-2.83,0-5.27,2.44-5.25,5.26.02,2.85,2.4,5.2,5.29,5.22,2.74.02,5.22-2.46,5.19-5.2m-17.82-17.76c.05-2.9-2.33-5.31-5.28-5.34-2.7-.03-5.1,2.32-5.19,5.08-.1,2.87,2.33,5.38,5.22,5.4,2.78.02,5.21-2.36,5.25-5.14"
                        />
                    </g>
                </svg>
            ),
        },

        {
            nombre: "Url",
            url: "url",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.85 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m99.85,26.15c-.45,2.49-1.02,4.95-2.11,7.26-1.25,2.64-2.95,4.95-5.01,7.01-6.82,6.82-13.64,13.64-20.45,20.46-4.33,4.34-9.51,6.7-15.68,7.06-6.88.4-12.67-1.9-17.57-6.6-1.89-1.82-2.59-4.1-1.85-6.68.72-2.54,2.46-4.08,5.01-4.65,2.4-.54,4.47.22,6.3,1.8,4.44,3.85,10.01,3.68,14.15-.45,6.92-6.9,13.85-13.79,20.74-20.72,4.94-4.97,3.53-13.12-2.72-16.01-3.7-1.71-8.11-.97-11.08,1.96-3.96,3.9-7.89,7.81-11.77,11.78-1.1,1.13-2.16,1.42-3.64.81-3.22-1.34-6.65-1.68-10.1-1.8-.82-.03-1.47-.28-1.8-1.07-.33-.78-.11-1.48.47-2.05,6.23-6.16,12.1-12.72,18.72-18.46,7.34-6.36,15.88-7.46,24.74-3.53,7.79,3.45,12.2,9.71,13.53,18.13.03.16.08.31.13.47v5.26Z"
                            />
                            <path
                                fill="#333333"
                                d="m20.54,100c-2.74-.53-5.45-1.19-7.93-2.54-7.03-3.83-11.27-9.69-12.36-17.62-1.06-7.67,1.35-14.31,6.76-19.8,6.88-6.98,13.85-13.89,20.78-20.82,4.5-4.5,9.96-6.92,16.31-7.01,6.78-.09,12.68,2.28,17.29,7.34,2.32,2.54,2.1,6.32-.18,8.78-2.21,2.39-6.17,2.75-8.91.79-.37-.26-.72-.56-1.05-.86-3.92-3.5-9.78-3.49-13.52.2-7.21,7.11-14.37,14.28-21.49,21.49-2.79,2.82-3.37,6.31-2.16,10.01,1.18,3.62,3.83,5.79,7.52,6.44,3.35.59,6.36-.32,8.82-2.8,3.91-3.94,7.87-7.83,11.75-11.8,1.06-1.09,2.09-1.34,3.48-.77,3.2,1.31,6.59,1.67,10,1.8.87.03,1.63.17,2.01,1.05.39.92-.02,1.63-.66,2.26-5.74,5.74-11.46,11.5-17.23,17.21-3.59,3.55-7.93,5.69-12.93,6.48-.22.04-.44.1-.65.16h-5.65Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
    ];

    const OPCIONESLANDING = [
        {
            nombre: "Nombre",
            url: "nombre",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Fotos",
            url: "foto",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Descripción",
            url: "descripcion",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Descripción 2",
            url: "descripcion 2",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },

        {
            nombre: "Servicios",
            url: "servicios",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Novedades",
            url: "novedades",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
        {
            nombre: "Mapa",
            url: "mapa",
            icono: (
                <svg
                    id="Capa_2"
                    className="w-4 h-4 2xl:w-5 2xl:h-5"
                    data-name="Capa 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 99.97 100">
                    <g id="Testimonios">
                        <g>
                            <path
                                fill="#333333"
                                d="m0,16.11c.56-2.36,1.59-4.45,3.41-6.12,2.09-1.92,4.58-2.86,7.37-2.87,14.11-.03,28.22-.02,42.33,0,2.32,0,3.99,1.51,4.08,3.6.08,1.99-1.39,3.5-3.68,3.77-.51.06-1.04.06-1.56.06-13.36,0-26.73,0-40.09,0-.52,0-1.04,0-1.56.04-1.56.13-2.86,1.41-3.01,2.96-.03.26-.02.52-.02.78,0,23.51,0,47.02,0,70.52,0,.52,0,1.03.18,1.54.48,1.36,1.62,2.26,3.06,2.33.81.04,1.63.02,2.44.02,20.48,0,40.97,0,61.45,0,2.14,0,3.46-.91,3.87-2.69.11-.5.1-1.03.1-1.55,0-11.38,0-22.76,0-34.14,0-.62,0-1.23.2-1.83.56-1.73,2.19-2.74,4.03-2.5,1.65.21,2.99,1.68,3.09,3.4.02.39.01.78.01,1.17,0,11.28-.11,22.57.05,33.85.08,5.88-3.69,10.44-8.62,11.45-.09.02-.18.08-.27.12H8.78c-1.56-.45-3.08-.98-4.42-1.95-2.35-1.71-3.66-4.07-4.36-6.82V16.11Z"
                            />
                            <path
                                fill="#333333"
                                d="m99.97,13.83c-.03,3.61-1.28,6.73-3.73,9.36-.97,1.05-2.01,2.04-3.02,3.05-14.53,14.53-29.06,29.05-43.57,43.58-.87.88-1.85,1.49-3.02,1.88-6.6,2.18-13.2,4.38-19.79,6.58-.78.26-1.56.42-2.39.32-1.93-.24-3.34-2-3.1-3.93.06-.51.2-1.02.37-1.51,2.24-6.72,4.49-13.43,6.73-20.15.32-.95.81-1.75,1.52-2.46,15.43-15.41,30.84-30.84,46.28-46.25,5.88-5.87,14.85-5.72,20.28.34,2.32,2.59,3.48,5.68,3.45,9.18Z"
                            />
                        </g>
                    </g>
                </svg>
            ),
        },
    ];

    const render = () => {
        return (
            <div>
                <div className="w-full">
                    {modalName === "nombre" && modalVisible && (
                        <ModalEditName
                            modalVisible
                            setModalVisible={setModalVisible}
                        />
                    )}
                    {modalName === "precio" && modalVisible && (
                        <ModalEditPrecioBase
                            modalVisible
                            setModalVisible={setModalVisible}
                        />
                    )}
                    {modalName === "capacidades" && modalVisible && (
                        <ModalEditCapacidades
                            modalVisible
                            setModalVisible={setModalVisible}
                        />
                    )}
                    {modalName === "foto" && modalVisible && (
                        <ModalEditImageCarrousel
                            modalVisible
                            setModalVisible={setModalVisible}
                        />
                    )}
                    {modalName === "descripcion" && modalVisible && (
                        <ModalEditDescripcion1
                            modalVisibleComodidades={modalVisible}
                            setModalVisibleComodidades={setModalVisible}
                        />
                    )}
                    {modalName === "descripcion 2" && modalVisible && (
                        <ModalEditDescripcion2
                            modalVisibleDescripcion={modalVisible}
                            setModalVisibleDescripcion={setModalVisible}
                        />
                    )}
                    {modalName === "habitaciones" && modalVisible && (
                        <ModalEditRooms
                            modalVisibleRooms={modalVisible}
                            setModalVisibleRooms={setModalVisible}
                        />
                    )}
                    {modalName === "servicios" && modalVisible && (
                        <ModalEditComforts
                            modalVisibleComodidades={modalVisible}
                            setModalVisibleComodidades={setModalVisible}
                        />
                    )}
                    {modalName === "novedades" && modalVisible && (
                        <ModalEditEvents
                            modalVisibleEvents={modalVisible}
                            setModalVisibleEvents={setModalVisible}
                        />
                    )}
                    {modalName === "mapa" && modalVisible && (
                        <ModalEditLocation
                            modalVisibleLocation={modalVisible}
                            setModalVisibleLocation={setModalVisible}
                        />
                    )}

                    <div className="grid max-w-4xl grid-cols-2 gap-8 px-2 mx-auto mt-12 mb-10 text-sm xs:grid-cols-3 lg:grid-cols-4 2xl:text-base">
                        {OPCIONES.map((opcion, index) => {
                            return (
                                <div
                                    onClick={() =>
                                        !opcionesPremium(opcion.nombre) &&
                                        navigate(
                                            `/dashboard-hotel/configuraciones/${opcion.url}`
                                        )
                                    }
                                    className={` cursor-pointer border  pb-12 pt-4 px-6 rounded-lg shadow-md relative `}
                                    key={index}>
                                    <p>{opcion.nombre}</p>
                                    {opcionesPremium(opcion.nombre) && (
                                        <div className="absolute bottom-4 left-4">
                                            <span className="text-[10px] border border-orange-500 text-orange-500  p-[2px] rounded-md">
                                                Estándar
                                            </span>
                                        </div>
                                    )}
                                    <div className="absolute bottom-4 right-4">
                                        <p className="">{opcion.icono}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="max-w-4xl gap-8 px-2 pt-4 mx-auto mt-10 border-t-2 xs:grid-cols-3 lg:grid-cols-4">
                        <p className="text-[#029BFC] text-lg font-bold">
                            Editar mi landing
                        </p>
                    </div>
                    <div className="grid max-w-4xl grid-cols-2 gap-8 px-2 pt-4 mx-auto my-10 text-sm xs:grid-cols-3 lg:grid-cols-4 2xl:text-base">
                        {OPCIONESLANDING.map((opcion, index) => {
                            return (
                                <div
                                    onClick={() => (
                                        !opcionesPremiumLanding(opcion.url) &&
                                            setMondalName(opcion.url),
                                        setModalVisible(true)
                                    )}
                                    className={` ${ocultarlanding(
                                        opcion.url
                                    )}  ${
                                        opcionesPremiumLanding(opcion.url)
                                            ? "cursor-not-allowed bg-slate-50"
                                            : "bg-[#FAFAFA] hover:scale-105 cursor-pointer"
                                    } border  pb-12 pt-4 px-6 rounded-lg shadow-md relative `}
                                    key={index}>
                                    <p>{opcion.nombre}</p>
                                    {opcionesPremiumLanding(opcion.url) && (
                                        <div className="absolute bottom-4 left-4">
                                            <span className="text-[10px] border border-orange-500 text-orange-500 p-[2px] rounded-md">
                                                Estándar
                                            </span>
                                        </div>
                                    )}
                                    <div className="absolute bottom-4 right-4">
                                        <p className="">{opcion.icono}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            <NavbarHotel titulo="Configuraciones">
                {render()}
                <NavbarMobileHotel />
            </NavbarHotel>
        </>
    );
};
