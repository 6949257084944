import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Lazy } from "swiper";
import "swiper/css/lazy";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import ToggleButton from "../../components/helpers/ToggleButton";
import { useAppSelector } from "../../hooks/hooks";
import { FooterLanding } from "./FooterLanding";
import { ModalEditLanding } from "./ModalEditLanding";
import { useNavigate } from "react-router-dom";

import { normalizeString } from "../../helpers/normalizeString";
import NavbarCorredor from "./NavbarCorredor";
import { ReactSortable } from "react-sortablejs";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";

interface casa {
    id: string;
    nombre: string;
    estadia_minima: number;
    pais: string;
    banner: string;
    ciudad: string;
    departamento: string;
    precio_base: string;
    tipo_moneda: string;
    banos: string;
    personas: string;
    habitaciones: string;
    casa_imagenes: casa_imagen[];
}

interface casa_imagen {
    id: number;
    id_casa: number;
    imagen: string;
}

const LandingCorredor = () => {
    const [toggleButton, setToggleButton] = useState(false);
    const [casas, setCasas] = useState<casa[]>([]);
    const corredor = useAppSelector((state) => state.corredor);
    const [modalVisible, setModalVisible] = useState(false);
    const [type, setType] = useState("");
    const { id } = useAppSelector((state) => state.usuario_logeado);
    const navigate = useNavigate();
    const handleModal = (type: string) => {
        setModalVisible(true);
        setType(type);
    };

    useEffect(() => {
        console.log(corredor.casas);
        setCasas(corredor.casas);
    }, []);

    const handleDragEnd = async (newOrder: any[]) => {
        const allHousesID = newOrder.map((casa) => casa.id);
        console.log(allHousesID);
        try {
            fetchAxiosToken({
                url: `/corredor/updateOrdenCorredor`,
                method: "put",
                body: {
                    array_espacios: allHousesID,
                },
            })
                .then((res) => {
                    console.log(res);
                    /* dispatch(set_corredor(res)); */
                })
                .catch((err) => {
                    console.log(err);
                    // dispatch(set_landing_house(null));
                });
        } catch (error: any) {
            console.log(error.response);
        }
    };

    return (
        <div>
            {corredor.terminado === "No" ? (
                <div className="flex items-center justify-center h-screen p-4">
                    <div className="max-w-md font-medium text-center">
                        Aún no se ha terminado de crear tu pagina principal,
                        puedes intentar
                        <button
                            onClick={() => {
                                // const url = url.mat
                                window.open(
                                    `${process.env.REACT_APP_FRONT_ROOT_URL}`,
                                    "_self"
                                );
                            }}
                            className="mx-1 text-blue-600 hover:underline"
                        >
                            Regresar a blustay
                        </button>
                        <span>o</span>
                        <button
                            onClick={() => {
                                navigate("/login");
                            }}
                            className="mx-1 text-blue-600 hover:underline"
                        >
                            Ir al login
                        </button>
                        <span>y continuar tu onboarding.</span>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col min-h-screen">
                    <NavbarCorredor />{" "}
                    {modalVisible && (
                        <ModalEditLanding
                            modalVisible={modalVisible}
                            setModalVisible={setModalVisible}
                            type={type}
                        />
                    )}
                    <div className="flex-grow">
                        <div className="w-full">
                            <div className="relative w-full h-[12rem] md:h-[15rem] xl:h-[18rem]">
                                <img
                                    src={
                                        `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                        corredor?.banner
                                    }
                                    alt="banner"
                                    className="w-full h-full object-cover"
                                />

                                {toggleButton && (
                                    <div
                                        onClick={() => handleModal("banner")}
                                        className="absolute top-8  w-full h-[2.5rem] left-4"
                                    >
                                        <div className="flex ">
                                            <div
                                                title="editar fotos"
                                                className="z-20 flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer opacity-60 bg-slate-200 hover:opacity-100"
                                            >
                                                <svg
                                                    className="w-12 h-12 text-[#1C4CBB]"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                    <line
                                                        x1="13.5"
                                                        y1="6.5"
                                                        x2="17.5"
                                                        y2="10.5"
                                                    />
                                                </svg>
                                                <div className="text-xs text-[#1C4CBB] text-center">
                                                    Editar Banner
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="absolute left-1/2 transform -translate-x-1/2 -bottom-14 md:-bottom-16 xl:-bottom-20 rounded-full border-2 border-blue-500">
                                    <img
                                        src={
                                            `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                            corredor?.avatar
                                        }
                                        alt="profile"
                                        className="rounded-full xl:w-40  xl:h-40 md:w-32 md:h-32 h-28 w-28 object-cover"
                                    />
                                    {toggleButton && (
                                        <div
                                            onClick={() =>
                                                handleModal("avatar")
                                            }
                                            className="absolute xl:left-10 md:left-8 left-5 md:-top-3 -top-1  w-full h-[2.5rem]"
                                        >
                                            <div className="flex ">
                                                <div
                                                    title="editar fotos"
                                                    className="z-20 flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer  bg-slate-200 hover:opacity-100"
                                                >
                                                    <svg
                                                        className="w-8 h-8 text-[#1C4CBB]"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="2"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        {" "}
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                        />{" "}
                                                        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                        <line
                                                            x1="13.5"
                                                            y1="6.5"
                                                            x2="17.5"
                                                            y2="10.5"
                                                        />
                                                    </svg>
                                                    <div className="text-xs text-[#1C4CBB] text-center">
                                                        Editar Foto
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className=" md:mt-24 mt-16 flex md:flex-row flex-col justify-center items-center gap-4 xl:text-lg md:text-base text-sm ">
                                <div className=" flex justify-center items-center gap-2 py-2 px-5 rounded-md bg-blue-500 text-white">
                                    <svg
                                        className="md:h-8 md:w-8 h-7 w-7"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />{" "}
                                        <polyline points="22,6 12,13 2,6" />
                                    </svg>

                                    {corredor.correo}
                                </div>
                                {toggleButton && (
                                    <div
                                        onClick={() => handleModal("correo")}
                                        className=""
                                    >
                                        <div className="flex ">
                                            <div
                                                title="editar correo"
                                                className="z-20 flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer  bg-slate-200 hover:opacity-100"
                                            >
                                                <svg
                                                    className="w-8 h-8 text-[#1C4CBB]"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                    <line
                                                        x1="13.5"
                                                        y1="6.5"
                                                        x2="17.5"
                                                        y2="10.5"
                                                    />
                                                </svg>
                                                <div className="text-xs text-[#1C4CBB] text-center">
                                                    Editar Correo
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {corredor.celular !== "" && (
                                    <div className="flex justify-center items-center gap-2 py-2 px-5 rounded-md bg-blue-500 text-white">
                                        <svg
                                            className="md:h-8 md:w-8 h-7 w-7"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            {" "}
                                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                                        </svg>

                                        {corredor.celular}
                                    </div>
                                )}

                                {toggleButton && (
                                    <div
                                        onClick={() => handleModal("celular")}
                                        className=""
                                    >
                                        <div className="flex ">
                                            <div
                                                title="editar Celular"
                                                className="z-20 flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer  bg-slate-200 hover:opacity-100"
                                            >
                                                <svg
                                                    className="w-8 h-8 text-[#1C4CBB]"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                    <line
                                                        x1="13.5"
                                                        y1="6.5"
                                                        x2="17.5"
                                                        y2="10.5"
                                                    />
                                                </svg>
                                                <div className="text-xs text-[#1C4CBB] text-center">
                                                    Editar Celular
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {id === id && (
                                <div className="flex justify-end w-11/12 mt-3">
                                    <p>
                                        <span className="text-[#053465] font-medium mr-2">
                                            Activar edición rápida
                                        </span>
                                    </p>
                                    <ToggleButton
                                        isToggled={false}
                                        onChangeToggle={(e) =>
                                            setToggleButton(e)
                                        }
                                    />
                                </div>
                            )}

                            <div
                                className="mx-auto xl:w-3/4 md:w-4/5 w-5/6 md:text-base text-sm text-justify md:mt-10 mt-5"
                                style={{ whiteSpace: "pre-wrap" }}
                            >
                                {corredor.descripcion
                                    .split("\n")
                                    .map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                {toggleButton && (
                                    <div
                                        onClick={() =>
                                            handleModal("descripcion")
                                        }
                                        className=""
                                    >
                                        <div className="flex ">
                                            <div
                                                title="editar descripción"
                                                className="z-20 flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer  bg-slate-200 hover:opacity-100"
                                            >
                                                <svg
                                                    className="w-8 h-8 text-[#1C4CBB]"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                                    <line
                                                        x1="13.5"
                                                        y1="6.5"
                                                        x2="17.5"
                                                        y2="10.5"
                                                    />
                                                </svg>
                                                <div className="text-xs text-[#1C4CBB] text-center">
                                                    Editar Descripción
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {toggleButton && (
                                <div className="mt-5 shadow-md w-2/3 py-4 mx-auto flex justify-center items-center gap-2 bg-gray-200 rounded-md border border-gray-300">
                                    <div>
                                        {" "}
                                        Puedes acomodar el orden de tus casas,
                                        solamente arrastra las cards desde el
                                        icono{" "}
                                    </div>

                                    <div className="drag-handle rounded-md cursor-move p-1 bg-gray-300 flex justify-center items-center ">
                                        <svg
                                            className="h-6 w-6 text-blue-500"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            {" "}
                                            <polyline points="5 9 2 12 5 15" />{" "}
                                            <polyline points="9 5 12 2 15 5" />{" "}
                                            <polyline points="15 19 12 22 9 19" />{" "}
                                            <polyline points="19 9 22 12 19 15" />{" "}
                                            <line
                                                x1="2"
                                                y1="12"
                                                x2="22"
                                                y2="12"
                                            />{" "}
                                            <line
                                                x1="12"
                                                y1="2"
                                                x2="12"
                                                y2="22"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            )}

                            <div className="w-full">
                                <ReactSortable
                                    disabled={!toggleButton}
                                    animation={200}
                                    delay={2}
                                    handle=".drag-handle"
                                    list={casas.map((item) => ({ ...item }))}
                                    setList={(newList) => {
                                        setCasas([...newList]);
                                        handleDragEnd([...newList]); // Llamar con el nuevo orden
                                    }}
                                    className="mb-20 xl:mt-10 mt-5 grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:w-3/4 md:w-4/5 w-5/6 gap-8 mx-auto "
                                >
                                    {casas?.map((product) => {
                                        return (
                                            <div
                                                id={product.id}
                                                key={product.id}
                                                className="relative mt-5 shadow-xl select-none rounded-xl md:mt-0"
                                            >
                                                <div className="relative w-full  h-[250px] flex overflow-hidden">
                                                    <Swiper
                                                        modules={[
                                                            Navigation,
                                                            Lazy,
                                                        ]}
                                                        slidesPerView={1}
                                                        watchSlidesProgress
                                                        lazy={true}
                                                        navigation
                                                        rewind={true}
                                                        loop={true}
                                                    >
                                                        <SwiperSlide
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                window.open(
                                                                    `/${
                                                                        product.id
                                                                    }/${normalizeString(
                                                                        product.nombre
                                                                    )}`,
                                                                    "_blank"
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                className="object-cover w-full h-full rounded-xl swiper-lazy"
                                                                alt="Imagen del producto"
                                                                data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa/getImage/${product.banner}`}
                                                                data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa/getImage/${product.banner}`}
                                                            />
                                                            <div className="swiper-lazy-preloader">
                                                                <div className="flex flex-col w-full h-56 gap-2 p-3 bg-gray-200">
                                                                    <div className="bg-slate-50 w-full h-[80%] rounded-xl"></div>
                                                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                                    <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                        {product.casa_imagenes.map(
                                                            (imagen) => (
                                                                <SwiperSlide
                                                                    key={
                                                                        imagen.id
                                                                    }
                                                                    onClick={() =>
                                                                        window.open(
                                                                            `/${
                                                                                product.id
                                                                            }/${normalizeString(
                                                                                product.nombre
                                                                            )}`,
                                                                            "_blank"
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        loading="lazy"
                                                                        className="object-cover w-full h-full rounded-xl swiper-lazy"
                                                                        alt="Imagen del producto"
                                                                        data-src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa/getImage/${imagen.imagen}`}
                                                                        data-srcset={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/casa/getImage/${imagen.imagen}`}
                                                                    />
                                                                    <div className="swiper-lazy-preloader">
                                                                        <div className="flex flex-col w-full h-56 gap-2 p-3 bg-gray-200">
                                                                            <div className="bg-slate-50 w-full h-[80%] rounded-xl"></div>
                                                                            <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                                            <div className="bg-slate-50 w-full h-[10%] rounded-full"></div>
                                                                        </div>
                                                                    </div>
                                                                </SwiperSlide>
                                                            )
                                                        )}
                                                    </Swiper>

                                                    <div
                                                        className={`absolute bottom-0 left-0 z-10 flex items-center w-full h-20 p-5   font-bold text-white  bg-black bg-opacity-50 `}
                                                    >
                                                        <div className="2xl:text-2xl text-lg">
                                                            Desde{" "}
                                                            {
                                                                product.precio_base
                                                            }{" "}
                                                            {
                                                                product.tipo_moneda
                                                            }{" "}
                                                            por noche
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        window.open(
                                                            `/${
                                                                product.id
                                                            }/${normalizeString(
                                                                product.nombre
                                                            )}`,
                                                            "_blank"
                                                        )
                                                    }
                                                    className="px-4 py-2 cursor-pointer"
                                                >
                                                    <div className="flex justify-between items-center">
                                                        <div className="flex items-center justify-start">
                                                            <div className="p-2 mt-1 text-white bg-orange-500 rounded-lg">
                                                                Mínimo{" "}
                                                                {
                                                                    product.estadia_minima
                                                                }{" "}
                                                                {product.estadia_minima ===
                                                                1
                                                                    ? "noche"
                                                                    : "noches"}
                                                            </div>
                                                        </div>
                                                        {toggleButton && (
                                                            <div className="drag-handle rounded-md cursor-move p-1 bg-gray-300 flex justify-center items-center ">
                                                                <svg
                                                                    className="h-6 w-6 text-blue-500"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                >
                                                                    {" "}
                                                                    <polyline points="5 9 2 12 5 15" />{" "}
                                                                    <polyline points="9 5 12 2 15 5" />{" "}
                                                                    <polyline points="15 19 12 22 9 19" />{" "}
                                                                    <polyline points="19 9 22 12 19 15" />{" "}
                                                                    <line
                                                                        x1="2"
                                                                        y1="12"
                                                                        x2="22"
                                                                        y2="12"
                                                                    />{" "}
                                                                    <line
                                                                        x1="12"
                                                                        y1="2"
                                                                        x2="12"
                                                                        y2="22"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div
                                                        className={`mt-3 2xl:text-2xl text-lg  text-blue-500 font-bold  flex justify-between items-center`}
                                                    >
                                                        <span className="cursor-pointer">
                                                            {product.nombre}
                                                        </span>
                                                    </div>

                                                    <div className="mt-1 text-base text-gray-500 mb-7 xl:mb-10 2xl:mb-7">
                                                        {product.ciudad},{" "}
                                                        {product.pais}
                                                    </div>
                                                    <div className="absolute text-sm bottom-1 left-4 xl:text-base text-amber-600">
                                                        <span className="mr-1">
                                                            {product.personas}{" "}
                                                            personas
                                                        </span>
                                                        <span className="xl:mx-1">
                                                            {
                                                                product.habitaciones
                                                            }{" "}
                                                            habitaciones
                                                        </span>
                                                        <span className="mx-1">
                                                            {product.banos}{" "}
                                                            baños
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </ReactSortable>
                            </div>
                        </div>
                    </div>
                    <FooterLanding toggleButton={toggleButton} />
                </div>
            )}
        </div>
    );
};

export default LandingCorredor;
