import getEveryDayBetween2Dates from "../../helpers/getEveryDayBetween2Dates";

interface disponibilidad {
    fecha_inicio: string;
    fecha_final: string;
    estadia_minima: number;
}

interface Props {
    minimaDisponibilidad: disponibilidad[];
    selected_dates: any;
    estadia_maxima: number;
    estadia_minima: number;
}

export const calcularMinimaDisponibilidad = ({
    selected_dates,
    minimaDisponibilidad,
    estadia_maxima,
    estadia_minima,
}: Props) => {
    let isAvailable = [];
    const [FRI, FRF] = selected_dates;
    const array_dias_reserva = getEveryDayBetween2Dates({
        array_fechas: [FRI, FRF],
    });

    const allowMenosDias = false;

    const allowReservation = (
        array_dias_reserva: any,
        array_dias_disponibilidad: any,
        numero_noches_necesarios: any
    ) => {
        let cantDiasCoinciden = 0;

        const new_array_disponiblidad = array_dias_disponibilidad.map(
            (day: Date) => day.getTime()
        );

        array_dias_reserva.forEach((day_reserva: Date) => {
            if (new_array_disponiblidad.includes(day_reserva.getTime())) {
                cantDiasCoinciden += 1;
            }
        });
        console.log(cantDiasCoinciden, numero_noches_necesarios);
        if (cantDiasCoinciden - 1 >= numero_noches_necesarios) return true;
        else return false;
    };

    isAvailable = minimaDisponibilidad.map(
        ({ fecha_inicio, fecha_final, estadia_minima }) => {
            const FDI = new Date(fecha_inicio + "T00:00:00");
            const FDF = new Date(fecha_final + "T00:00:00");

            const array_dias_disponibilidad = getEveryDayBetween2Dates({
                array_fechas: [FDI, FDF],
            });
            console.log(
                { "fecha calendario inicio": FRI },
                { "fecha calendario final": FRF },
                { "fecha regla inicio": FDI }
            );
            if (FRI <= FDI) {
                if (FRF <= FDI) {
                    return {
                        available: true,
                        message: "Se puede reservar 1",
                    }; // case 1
                } else {
                    if (FRF >= FDF) {
                        return {
                            available: true,
                            message: "Se puede reservar 2",
                        }; // case 4
                    } else {
                        if (
                            allowReservation(
                                array_dias_reserva,
                                array_dias_disponibilidad,
                                estadia_minima
                            )
                        ) {
                            return {
                                available: true,
                                message: "Se puede reservar",
                            };
                        } else {
                            return {
                                available: false,
                                message: `Es necesario reservar mínimamente ${estadia_minima} noches entre el día 
                                ${FDI.toLocaleDateString("es-ES", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                })} y el día ${FDF.toLocaleDateString("es-ES", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                })}`,
                            }; // case 2
                        }
                    }
                }
            } else {
                if (FRI > FDF) {
                    return {
                        available: true,
                        message: "Se puede reservar 3",
                    }; // case 5
                } else {
                    if (
                        allowReservation(
                            array_dias_reserva,
                            array_dias_disponibilidad,
                            estadia_minima
                        )
                    ) {
                        return {
                            available: true,
                            message: "Se puede reservar",
                        };
                    } else {
                        return {
                            available: false,
                            message: `Es necesario reservar mínimamente ${estadia_minima} noches entre el día ${FDI.toLocaleDateString(
                                "es-ES",
                                {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                }
                            )} y el día ${FDF.toLocaleDateString("es-ES", {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                            })}`,
                        }; // case 3
                    }
                }
            }
        }
    );

    const estadiaMinimaPorRango = minimaDisponibilidad
        .filter(({ fecha_inicio, fecha_final }) => {
            const FDI = new Date(fecha_inicio + "T00:00:00");
            const FDF = new Date(fecha_final + "T00:00:00");
            return FRI >= FDI && FRF <= FDF; // Verifica si las fechas seleccionadas están dentro del rango permitido
        })
        .map(({ estadia_minima }) => estadia_minima);

    const estadiaMinimaFinal =
        estadiaMinimaPorRango.length > 0
            ? Math.min(...estadiaMinimaPorRango) // Si hay reglas, toma el mínimo permitido dentro de los rangos
            : estadia_minima;

    console.log(isAvailable);

    if (isAvailable.some((available) => available.available === false)) {
        return isAvailable;
    } else {
        if (estadia_maxima > 0 && array_dias_reserva.length >= estadia_maxima) {
            return (isAvailable = [
                {
                    available: false,
                    message: `La estadía maxima es de ${estadia_maxima} noches`,
                },
            ]);
        } else if (array_dias_reserva.length < estadiaMinimaFinal) {
            return (isAvailable = [
                {
                    available: false,
                    message: `La estadía minima es de ${estadia_minima} noches`,
                },
            ]);
        }
    }

    return isAvailable;
};
