import React, { ReactNode, useState } from "react";

import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import ResponsiveModalAbsolute from "../../../components/ResponsiveModalAbsolute";
import { InputDate } from "../../../components/InputDate";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { useParams } from "react-router-dom";

interface Input {
    id_casa?: number | null;
    id_habitacion?: number | null;

    estadia_minima: string;
    fecha_inicio: string;
    fecha_final: string;
}

interface EstadiaMinima {
    id: number;
    id_casa?: number;
    id_habitacion?: number;
    fecha_inicio: string;
    fecha_final: string;
    estadia_minima: number | string;
}

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    estadiaSelect: EstadiaMinima;
    setEstadiaSelect: (s: EstadiaMinima | null) => void;
}

export const ModalEditAvailability = ({
    modalVisible,
    setModalVisible,
    estadiaSelect,
    setEstadiaSelect,
}: ModalProps) => {
    registerLocale("es", es);
    const dispatch = useAppDispatch();
    const { idCasa } = useParams();

    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const dispatchDominio = useDispatchDominio(
        tipo_usuario === 8
            ? undefined
            : idCasa && Number(idCasa) > 0
            ? idCasa
            : undefined
    );

    const { id: id_casa, id_propietario } = useAppSelector(
        (state) => state.landing_house.house
    );

    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState<EstadiaMinima>({
        id_casa: estadiaSelect.id_casa,
        id_habitacion: estadiaSelect.id_habitacion,
        id: estadiaSelect.id,
        estadia_minima: `${estadiaSelect.estadia_minima}`,
        fecha_inicio: estadiaSelect.fecha_inicio,
        fecha_final: estadiaSelect.fecha_final,
    });

    const handleSubmit = async () => {
        if (input.fecha_inicio === "" || input.fecha_final === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes seleccionar la fecha de inicio y final ",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        if (input.estadia_minima === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar las noches mínimas de reserva",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        try {
            setLoading(true);
            if (
                tipo_usuario === 3 ||
                tipo_usuario === 4 ||
                tipo_usuario === 6
            ) {
                await fetchAxiosToken({
                    url: `/estadia_minima/put/${input.id}`,
                    body: {
                        ...input,
                    },
                    method: "put",
                });
            }

            if (tipo_usuario === 8) {
                await fetchAxiosToken({
                    url: `/habitaciones_estadias_minimas/put/${input.id}`,
                    body: {
                        ...input,
                    },
                    method: "put",
                });
            }

            dispatchDominio();

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });
            setModalVisible(false);
            setLoading(false);
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: `${error.response.data.error}`,
                icon: "error",
                confirmButtonText: "Ok",
            });
            setLoading(false);
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
        >
            <h1 className="text-base font-medium text-center 2xl:text-lg">
                Editar disponibilidad mínima
            </h1>
            <div className="flex flex-col max-w-md gap-4 p-4 mx-auto ">
                <div className="flex flex-col items-center justify-center">
                    <>
                        <hr className="w-full my-4 bg-gray-300" />
                        <div className="w-full">
                            <h2 className="text-sm font-medium text-left 2xl:text-base">
                                Fechas
                            </h2>
                            <div className="flex items-center w-full mt-2">
                                <label className="text-base font-medium 2xl:text-lg ">
                                    Inicial:
                                </label>
                                <InputDate
                                    className="w-8/12 2xl:text-base text-sm 2xl:p-2 p-1.5 ml-6 bg-white border rounded-lg cursor-pointer"
                                    selectedDay={input.fecha_inicio}
                                    placeholderText="Seleccionar fecha"
                                    onChange={(date) =>
                                        setInput({
                                            ...input,
                                            fecha_inicio: date ? date : "",
                                        })
                                    }
                                />
                            </div>
                            <div className="flex items-center w-full mt-2">
                                <label className="mr-2 text-base font-medium 2xl:text-lg">
                                    Final:
                                </label>

                                <InputDate
                                    className="w-8/12 2xl:text-base text-sm 2xl:p-2 p-1.5 ml-6 bg-white border rounded-lg cursor-pointer"
                                    selectedDay={input.fecha_final}
                                    placeholderText="Seleccionar fecha"
                                    onChange={(date) =>
                                        setInput({
                                            ...input,
                                            fecha_final: date ? date : "",
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </>

                    <hr className="w-full my-4 bg-gray-300" />
                    <div className="w-full">
                        <h2 className="text-sm font-medium text-left 2xl:text-base">
                            Cantidad de noches
                        </h2>
                        <div className="flex items-center w-full mt-2">
                            <label className="text-base font-medium 2xl:text-lg ">
                                Noches mínimas para reservar:
                            </label>
                            <input
                                type="number"
                                placeholder="Dias"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        estadia_minima: e.target.value,
                                    })
                                }
                                className=" w-20  ml-2 pl-[1px]  xs:px-2 rounded-lg 2xl:text-base text-sm 2xl:p-2 p-1.5 bg-transparent border border-[#E9E9E9] outline-none focus:outline-none placeholder:text-gray-400"
                                value={input.estadia_minima}
                            />
                        </div>
                    </div>
                </div>

                <button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                    className="p-3 mt-6 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm "
                >
                    Actualizar disponibilidad
                </button>
            </div>
        </ResponsiveModalAbsolute>
    );
};
