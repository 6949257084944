import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { NavbarAdministrativa } from "../../dashboardAdministrativo/NavbarAdministrativa";
import { NavbarAdministrativaMobile } from "../../dashboardAdministrativo/NavbarAdministrativaMobile";
import { NavbarCliente } from "../../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../../dashboardClientes/NavbarMobileCliente";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { ModalDetalleDePagos } from "./ModalDetalleDePagos";
import { formatearNumero } from "../../../components/helpers/formatearNumero";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

interface PedidoAdicional {
    id: number;
    id_pedido: number;
    servicio: string;
    costo: string;
}

interface Pedido {
    id: number;
    costo_alquiler: string;
    garantia: string;
    costo_total: string;
    pedidos_servicios_adicionales: PedidoAdicional[];
    costo_servicios_adicionales: string;
    descuento_estadia: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    pagado: string;
    saldo: string;
    tipo_garantia: string;
    descuento_cupon: string;
}

export const ReservaDetalle = () => {
    const { reservaID } = useParams();
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const [pedido, setPedido] = useState<Pedido | null>(null);
    const [estado, setEstado] = useState<number | null>(1);
    const porcentaje = [5, 25, 40, 60, 80, 100];
    const estados = [
        "Confirmar disponibilidad",
        "Pendiente de pago",
        "Pago Parcial",
        "Pago Completo",
        "Reseña de casa",
        "Devolución de garantía",
    ];

    const [servicios, setServicios] = useState(false);
    const { socket } = useAppSelector((state) => state.activarSocket);
    const casa = useAppSelector((state) => state.landing_house);

    useEffect(() => {
        fetchAxiosToken({
            url: `/pedidos/getOne/${reservaID}/${id}`,
        }).then((e) => {
            setPedido(e);
            if (socket) {
                socket?.emit("send-notificacion-leida", {
                    id_cliente: e.id_cliente,
                    id_casa: casa.house.id,
                });
            }
            setEstado(estados.indexOf(e.estado));
        });
    }, [modalVisible]);

    const handleDisponibilidad = async (disponibilidad: string) => {
        try {
            const data = await fetchAxiosToken({
                url: `/pedidos/putConfirm/${pedido?.id}`,
                body: {
                    disponibilidad,
                    dashboard: "Si",
                },
                method: "put",
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "success",
                title: "Reserva notificada",
                text: `Ya le avisamos a tu cliente que ${disponibilidad} esta disponible`,
                showConfirmButton: true,
                // timer: 2000,
            });
            fetchAxiosToken({
                url: `/pedidos/getOne/${reservaID}/${id}`,
            }).then((e) => {
                console.log("pedido actualizado", e);
                setPedido(e);
                setEstado(estados.indexOf(e.estado));
            });
        } catch (error) {
            console.log(error);
        }
    };

    const botonVolver = () => {
        window.history.back();
    };

    const render = () => {
        return (
            <>
                <div className="relative flex flex-col w-full p-4 mt-1 2xl:mt-4">
                    <div
                        className={
                            "flex items-center text-lg cursor-pointer pt-4 px-4 absolute top-1 left-1 "
                        }
                        onClick={() => {
                            botonVolver();
                        }}
                    >
                        <svg
                            className="w-6 h-6 -ml-2 text-black"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <polyline points="15 18 9 12 15 6" />
                        </svg>
                        <span className="mx-1">Regresar</span>
                    </div>

                    {modalVisible && (
                        <ModalDetalleDePagos
                            modalVisible={modalVisible}
                            setModalVisible={setModalVisible}
                            idPedido={Number(reservaID)}
                            moneda={pedido?.tipo_moneda || ""}
                        />
                    )}

                    <div className="relative z-10 flex items-center justify-center mx-auto w-52">
                        <p className="pr-2 text-lg font-medium text-center">
                            Pedido número #{pedido?.id}
                        </p>
                        <BotonInformacion
                            text="1. Confirmar Disponibilidad: Aca deberás aceptar la reserva para que el Cliente pueda efectuar el pago.
                                        2. Pendiente de Pago: Estas a la espera de que el cliente realize el pago de la reserva.
                                        3. Pago Parcial: El cliente habrá realizado el 50% del pago del Alquiler.
                                        4. Pago Completo: El cliente habrá realizado el pago total de la Reserva.
                                        5. Reseña de casa: El cliente habrá realizado la reseña de la reserva.
                                        6 Devolución de garantía: Hiciste la devolución de la garantía al cliente."
                        />
                    </div>

                    <div className="mt-10 text-sm font-medium 2xl:text-base">
                        Detalles pedido del{" "}
                        <ShowDateToUser date={pedido?.fecha_inicio} /> al{" "}
                        <ShowDateToUser date={pedido?.fecha_final} />
                    </div>
                    <div className="mt-4 text-sm font-medium 2xl:text-base">
                        Estado de la reserva:{" "}
                        <span
                            className={
                                pedido?.estado !== "No disponible"
                                    ? "font-normal"
                                    : "font-normal text-red-500"
                            }
                        >
                            {pedido?.estado}
                        </span>
                    </div>
                    <div
                        className={
                            pedido?.estado !== "No disponible"
                                ? "flex justify-center mt-10 mb-7"
                                : "hidden"
                        }
                    >
                        <div className="relative w-10/12 h-32 px-5 overflow-x-auto overflow-y-hidden ">
                            {estado !== null && (
                                <div className="flex absolute mt-16 w-[28rem] xs:w-[30rem] sm:w-[26rem] md723:w-[32rem] lg:w-[50rem] xl:w-[60rem] mx-auto h-1 md:h-2 bg-gray-300 top-3 2xl:top-4 ">
                                    <div
                                        style={{
                                            width:
                                                estado &&
                                                porcentaje[estado] + "%",
                                        }}
                                        className={`bg-[#053465] `}
                                    ></div>
                                </div>
                            )}
                            {estado !== null && (
                                <div className="flex justify-between w-[28rem] xs:w-[30rem] sm:w-[26rem] md723:w-[32rem] lg:w-[50rem] xl:w-[60rem] mt-16">
                                    <div
                                        className={` relative md:w-7 md:h-7 w-8 h-8 2xl:w-10 2xl:h-10 rounded-full ${
                                            estado >= 0
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}
                                    >
                                        <span
                                            className={`absolute top-[-50px] 2xl:w-14 w-12 -right-3 text-[10px] 2xl:text-xs tracking-widest ${
                                                estado >= 0
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}
                                        >
                                            Confirmando disponibilidad
                                        </span>
                                    </div>
                                    <div
                                        className={` relative md:w-7 md:h-7 w-8 h-8 2xl:w-10 2xl:h-10 rounded-full ${
                                            estado >= 1
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}
                                    >
                                        <span
                                            className={`absolute top-[-50px] 2xl:w-14 w-12 -right-3 tracking-widest  text-[10px] 2xl:text-xss ${
                                                estado >= 1
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}
                                        >
                                            {" "}
                                            Pendiente de pago
                                        </span>
                                    </div>
                                    <div
                                        className={` relative md:w-7 md:h-7 w-8 h-8 2xl:w-10 2xl:h-10 rounded-full ${
                                            estado >= 2
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}
                                    >
                                        <span
                                            className={`absolute top-[-50px] 2xl:w-14 w-12 -right-7 tracking-widest leading-4 text-[10px] 2xl:text-xs ${
                                                estado >= 2
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}
                                        >
                                            Pago Parcial
                                        </span>
                                    </div>
                                    <div
                                        className={` relative md:w-7 md:h-7 w-8 h-8 2xl:w-10 2xl:h-10 rounded-full ${
                                            estado >= 3
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}
                                    >
                                        <span
                                            className={`absolute top-[-50px]  2xl:w-14 w-12 -right-7 tracking-widest text-[10px] 2xl:text-xs ${
                                                estado >= 3
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}
                                        >
                                            Pago Completo
                                        </span>
                                    </div>
                                    <div
                                        className={` relative md:w-7 md:h-7 w-8 h-8 2xl:w-10 2xl:h-10 rounded-full ${
                                            estado >= 4
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}
                                    >
                                        <span
                                            className={`absolute top-[-50px] 2xl:w-14 w-12 -right-7 tracking-widest text-[10px] 2xl:text-xs ${
                                                estado >= 4
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}
                                        >
                                            Reseña de casa
                                        </span>
                                    </div>
                                    <div
                                        className={` relative md:w-7 md:h-7 w-8 h-8 2xl:w-10 2xl:h-10 rounded-full ${
                                            estado >= 5
                                                ? "bg-[#053465]"
                                                : "bg-gray-300"
                                        } top-1/2  `}
                                    >
                                        <span
                                            className={`absolute top-[-50px] md:bottom-[-65px] 2xl:w-14 w-12 -right-7 tracking-widest   text-[10px] 2xl:text-xs ${
                                                estado >= 5
                                                    ? "opacity-100"
                                                    : "opacity-50"
                                            }`}
                                        >
                                            Devolución de garantía
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <div className="flex flex-col items-center justify-center w-full bg-red-500"> */}
                    <div className="flex flex-col  gap-2 p-4 mt-4 border w-[500px] 2xl:w-[576px] rounded-xl mx-auto">
                        <div className="flex justify-between">
                            <label className="text-sm font-medium 2xl:text-base">
                                Costo alquiler
                            </label>
                            <div className="text-sm 2xl:text-base">
                                {formatearNumero(
                                    Number(pedido?.costo_alquiler)
                                )}{" "}
                                {pedido?.tipo_moneda}
                            </div>
                        </div>
                        {Number(pedido?.descuento_cupon) > 0 && (
                            <div className="flex justify-between text-green-500">
                                <label className="text-sm font-medium 2xl:text-base">
                                    Descuento cupón
                                </label>
                                <div className="text-sm 2xl:text-base">
                                    -{" "}
                                    {formatearNumero(
                                        Number(pedido?.descuento_cupon)
                                    )}{" "}
                                    {pedido?.tipo_moneda}
                                </div>
                            </div>
                        )}
                        {pedido?.tipo_garantia !== "Efectivo" && (
                            <>
                                <hr className="my-1" />
                                <div className="flex justify-between">
                                    <label className="text-sm font-medium 2xl:text-base">
                                        Garantía
                                    </label>
                                    <div className="text-sm 2xl:text-base">
                                        {formatearNumero(
                                            Number(pedido?.garantia)
                                        )}{" "}
                                        {pedido?.tipo_moneda}
                                    </div>
                                </div>
                            </>
                        )}
                        <hr className="my-1" />
                        {Number(pedido?.descuento_estadia) > 0 && (
                            <>
                                <div className="flex justify-between">
                                    <label className="text-sm font-medium 2xl:text-base">
                                        Descuento larga estadía
                                    </label>
                                    <div className="text-sm 2xl:text-base">
                                        -{" "}
                                        {formatearNumero(
                                            Number(pedido?.descuento_estadia)
                                        )}{" "}
                                        {pedido?.tipo_moneda}
                                    </div>
                                </div>
                                <hr className="my-1" />
                            </>
                        )}
                        {pedido &&
                            pedido?.pedidos_servicios_adicionales?.length >
                                0 && (
                                <>
                                    <div
                                        onClick={() => setServicios(!servicios)}
                                        className="flex justify-between hover:cursor-pointer"
                                    >
                                        <div className="flex items-center">
                                            {!servicios ? (
                                                <svg
                                                    className="w-5 h-5 text-black"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path
                                                        d="M18 15l-6-6l-6 6h12"
                                                        transform="rotate(90 12 12)"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    className="w-5 h-5 text-black"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    {" "}
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                    />{" "}
                                                    <path
                                                        d="M18 15l-6-6l-6 6h12"
                                                        transform="rotate(180 12 12)"
                                                    />
                                                </svg>
                                            )}
                                            <span className="text-sm font-medium 2xl:text-base">
                                                Servicio adicionales
                                            </span>
                                        </div>
                                        {!servicios && (
                                            <div className="text-sm 2xl:text-base">
                                                {formatearNumero(
                                                    Number(
                                                        pedido?.costo_servicios_adicionales
                                                    )
                                                )}{" "}
                                                {pedido?.tipo_moneda}
                                            </div>
                                        )}
                                    </div>
                                    {servicios &&
                                        pedido?.pedidos_servicios_adicionales.map(
                                            (service) => (
                                                <div
                                                    key={service.id}
                                                    className="flex justify-between mt-2"
                                                >
                                                    <label className="text-sm 2xl:text-base">
                                                        {service.servicio}
                                                    </label>
                                                    <div className="text-sm 2xl:text-base">
                                                        {formatearNumero(
                                                            Number(
                                                                service?.costo
                                                            )
                                                        )}{" "}
                                                        {pedido.tipo_moneda}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    <hr className="my-1" />
                                </>
                            )}
                        <div className="flex justify-between">
                            <label className="text-sm font-medium 2xl:text-base">
                                Costo total
                            </label>
                            <div className="text-sm 2xl:text-base">
                                {formatearNumero(Number(pedido?.costo_total))}{" "}
                                {pedido?.tipo_moneda}
                            </div>
                        </div>
                        <hr className="my-1" />
                        <div className="flex justify-between">
                            <label className="text-sm font-medium 2xl:text-base">
                                Pagado
                            </label>
                            <div className="text-sm 2xl:text-base">
                                {formatearNumero(Number(pedido?.pagado))}{" "}
                                {pedido?.tipo_moneda}
                            </div>
                        </div>

                        <hr className="my-1" />
                        <div className="flex justify-between">
                            <label className="text-sm font-medium 2xl:text-base">
                                Saldo
                            </label>
                            <div className="text-sm 2xl:text-base">
                                {formatearNumero(Number(pedido?.saldo))}{" "}
                                {pedido?.tipo_moneda}
                            </div>
                        </div>
                        {pedido?.garantia !== "0.00" &&
                            pedido?.tipo_garantia === "Efectivo" && (
                                <div className="flex w-full px-1 mt-4 bg-blue-200 border-4 border-blue-300 rounded-lg">
                                    <span className="underline">
                                        Garantía:{" "}
                                    </span>
                                    {tipo_usuario === 5 ? (
                                        <span className="ml-2 italic font-thin">
                                            Deberás pagar{" "}
                                            {formatearNumero(
                                                Number(pedido.garantia)
                                            )}{" "}
                                            {pedido?.tipo_moneda} en efectivo al
                                            momento de llegar a la propiedad
                                        </span>
                                    ) : (
                                        <span className="ml-2 italic font-thin">
                                            El cliente pagara{" "}
                                            {formatearNumero(
                                                Number(pedido.garantia)
                                            )}{" "}
                                            {pedido?.tipo_moneda} en efectivo al
                                            momento de llegar a la propiedad
                                        </span>
                                    )}
                                </div>
                            )}
                        {/* </div> */}
                    </div>
                    {pedido?.estado === "Confirmar disponibilidad" &&
                    (tipo_usuario === 3 || tipo_usuario === 6) ? (
                        <div className="flex flex-col items-center justify-center w-full mx-auto text-center">
                            <button
                                onClick={() => handleDisponibilidad("Si")}
                                className="2xl:w-3/5 w-2/5 p-3 mt-4 font-medium text-white border rounded-lg bg-[#119b0e] active:translate-y-1 mx-auto"
                            >
                                Si esta disponible
                            </button>
                            <button
                                onClick={() => handleDisponibilidad("No")}
                                className="2xl:w-3/5 w-2/5 p-3 mt-4 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1 mx-auto"
                            >
                                No esta disponible
                            </button>
                        </div>
                    ) : null}
                    {pedido?.estado === "No disponible" && (
                        <div className="w-full text-center">
                            <button
                                onClick={() => handleDisponibilidad("Si")}
                                className="2xl:w-3/5 w-2/5 p-3 mt-4 font-medium text-white border rounded-lg bg-[#119b0e] active:translate-y-1"
                            >
                                Si esta disponible
                            </button>
                        </div>
                    )}
                    {pedido?.estado !== "Confirmar disponibilidad" &&
                    pedido?.estado !== "No disponible" ? (
                        <div className="w-full text-center">
                            <button
                                onClick={() => setModalVisible(true)}
                                className="2xl:w-3/5 w-2/5 p-3 mt-4 font-medium text-white rounded-lg bg-[#029BFC] active:translate-y-1"
                            >
                                Detalles de pago
                            </button>
                        </div>
                    ) : null}

                    {pedido?.estado === "Pendiente de pago" ||
                    pedido?.estado === "Pago Parcial" ? (
                        <div className="w-full text-center">
                            <button
                                onClick={() =>
                                    navigate(`/checkout/${reservaID}`, {
                                        replace: false,
                                    })
                                }
                                className="2xl:w-3/5 w-2/5 p-3 mt-4 font-medium text-white border rounded-lg bg-[#029BFC] active:translate-y-1"
                            >
                                Pagar reserva
                            </button>
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    if (!pedido)
        return (
            <>
                <Navbar titulo="Reservas">
                    <div className="p-4 font-medium">Cargando pedido...</div>
                    <NavbarMobile />
                </Navbar>
            </>
        );
    return (
        <>
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Reserva">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 1 || tipo_usuario === 2 ? (
                <NavbarAdministrativa titulo="Reserva">
                    {render()}
                    <NavbarAdministrativaMobile />
                </NavbarAdministrativa>
            ) : tipo_usuario === 5 || tipo_usuario === 7 ? (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Reserva">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : null}
        </>
    );
};
