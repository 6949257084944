import React, { useState, useEffect } from "react";
import ToggleButton from "../../../components/helpers/ToggleButton";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import imagen1 from "./../../../img/mercado_pago/1.webp";
import imagen2 from "./../../../img/mercado_pago/2.webp";
import imagen3 from "./../../../img/mercado_pago/3.webp";
import imagen4 from "./../../../img/mercado_pago/4.webp";
import imagen5 from "./../../../img/mercado_pago/8.webp";
import imagen6 from "./../../../img/mercado_pago/9.webp";
import { MicuentaBancaria } from "../MicuentaBancaria";
import Swal from "sweetalert2";

import Spinner from "../../../components/helpers/Spinner";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { verifyToken } from "../../../redux/reducers/authReducer";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../../dashboardHotel/NavbarMobileHotel";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const MediosDePagoMercadoPago = () => {
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const casa = useAppSelector((state) => state.landing_house);
    const dispatch = useAppDispatch();
    const [saving, setSaving] = useState<boolean>(false);
    const [toggleButton, setToggleButton] = useState<boolean | null>(null);
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        id: "",
        publica: "",
        privada: "",
    });
    const getHouseFromTenant = async () => {
        try {
            if (
                tipo_usuario === 3 ||
                tipo_usuario === 4 ||
                tipo_usuario === 8
            ) {
                const llavePrivada = await fetchAxiosToken({
                    url: `/casa/getLlaveMP/`,
                });
                const data = await fetchAxiosNoToken({
                    url: `/casa/getOne/dominio`,
                });

                setToggleButton(data.is_mercado_pago === "Si" ? true : false);
                setUserInfo({
                    id: data.id,
                    publica: data.llave_publica_mp ? data.llave_publica_mp : "",
                    privada: llavePrivada.llave_privada_mp
                        ? llavePrivada.llave_privada_mp
                        : "",
                });
            }

            if (tipo_usuario === 6) {
                const llavePrivada = await fetchAxiosToken({
                    url: `/usuarios/getLlaveMP/${id}`,
                });

                setToggleButton(
                    llavePrivada.is_mercado_pago === "Si" ? true : false
                );
                setUserInfo({
                    id: llavePrivada.id,
                    publica: llavePrivada.llave_publica_mp
                        ? llavePrivada.llave_publica_mp
                        : "",
                    privada: llavePrivada.llave_privada_mp
                        ? llavePrivada.llave_privada_mp
                        : "",
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getHouseFromTenant();
        window.scrollTo(0, 0);
    }, []);

    const casas = [
        {
            tipo: "Casa del mes",
            id: 1,
            img: imagen1,
            url: "/alojamientos/38746/casa-reflejo",
            txt: ``,
        },
        {
            tipo: "Casa del mes",
            id: 2,
            img: imagen2,
            url: "/alojamientos/38746/casa-reflejo",
            txt: "Crear una nueva aplicacion",
        },
        {
            tipo: "Casa del mes",
            id: 3,
            img: imagen3,
            url: "/alojamientos/38746/casa-reflejo",
            txt: "Si tienes verificacion en 2 pasos escanea el QR",
        },
        {
            tipo: "Casa del mes",
            id: 4,
            img: imagen4,
            url: "/alojamientos/38746/casa-reflejo",
            txt: "Colocar nombre de la aplicacion (BLU)",
        },
        {
            tipo: "Casa del mes",
            id: 5,
            img: imagen5,
            url: "/alojamientos/38746/casa-reflejo",
            txt: "Luego que aparezca el mensaje de aplicacion creada . ir a credenciales de produccion",
        },
        {
            tipo: "Casa del mes",
            id: 6,
            img: imagen6,
            url: "/alojamientos/38746/casa-reflejo",
            txt: "Copiar Public Key y Access Token,  Pegar Public Key y Access Token en la parte de abajo de esta pagina y darle al boton guardar",
        },
    ];

    const INPUTS = [
        {
            input_id: 0,
            label: "Public Key",
            nombre: "publica",
            type: "text",
        },
        {
            input_id: 1,
            label: "Access Token",
            nombre: "privada",
            type: "text",
        },
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = async () => {
        if (
            (userInfo.publica === "" || userInfo.privada === "") &&
            toggleButton
        ) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: "Para activar mercado pago debe agregar tanto su llave pública como privada",

                showConfirmButton: true,
                // timer: 2000,
            });
            return;
        }

        setSaving(true);
        try {
            if (
                tipo_usuario === 3 ||
                tipo_usuario === 4 ||
                tipo_usuario === 8
            ) {
                const json = await fetchAxiosToken({
                    url: `/casa/updateLlaveMp/${userInfo.id}`,
                    method: "put",
                    body: {
                        llave_publica_mp: userInfo.publica,
                        llave_privada_mp: userInfo.privada,
                        is_mercado_pago: toggleButton ? "Si" : "No",
                    },
                });
                console.log(json);
                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    position: "top-end",
                    icon: "success",
                    title: "Cuenta mercado pago actualizada con exito!",
                    showConfirmButton: false,
                    timer: 1500,
                });
                getHouseFromTenant();
                setSaving(false);
            }

            if (tipo_usuario === 6) {
                const json = await fetchAxiosToken({
                    url: `/corredor/updateLlaveMp/${id}`,
                    method: "put",
                    body: {
                        llave_publica_mp: userInfo.publica,
                        llave_privada_mp: userInfo.privada,
                        is_mercado_pago: toggleButton ? "Si" : "No",
                    },
                });

                Swal.fire({
                    confirmButtonColor: "#029BFC",
                    iconColor: "#029BFC",
                    position: "top-end",
                    icon: "success",
                    title: "Cuenta mercado pago actualizada con exito!",
                    showConfirmButton: false,
                    timer: 1500,
                });
                getHouseFromTenant();
                setSaving(false);
            }
        } catch (error: any) {
            console.log(error);
            setSaving(false);

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                // position: "top-end",
                icon: "error",
                title: `${error.response.data.error}`,
                showConfirmButton: true,
                // timer: 1500,
            });
        }
    };

    const render = () => {
        return (
            <div className="mt-12">
                <div className="flex justify-end w-10/12 ">
                    <div className="relative z-10 flex items-center justify-center ">
                        <BotonInformacion text="Para que tus clientes tengan la opcion de pagar por tarjeta de credito/Debito habilita la opcion de Mercado pago." />
                        <span className="text-[#053465] font-medium mx-2">
                            Mercado pago
                        </span>
                    </div>
                    <p></p>
                    {toggleButton !== null && (
                        <ToggleButton
                            isToggled={toggleButton}
                            onChangeToggle={(e) => setToggleButton(e)}
                        />
                    )}
                </div>

                <div className="p-2 xs:p-6 border-[#CCCCCC] border-2 rounded-xl w-11/12 mx-auto mt-4 grid lg:grid-cols-2 gap-4 mb-12 xs:mb-6">
                    <div className="lg:border-r border-[#CCCCCC] lg:pr-4">
                        <p className="mb-4 font-serif font-bold text-center text-[#4D4D4D] ">
                            ¿Cómo vincular mi cuenta de mercado pago?
                        </p>

                        <Swiper
                            className="w-80 rounded-2xl lg:w-full "
                            modules={[Navigation, Pagination, Autoplay]}
                            spaceBetween={10}
                            slidesPerView={1}
                            rewind={true}
                            loop={true}
                            navigation
                            pagination={{ clickable: true }}>
                            {casas.map((casa) => (
                                <SwiperSlide
                                    key={casa.id}
                                    // className="flex flex-col w-full md:px-1 lg:px-1 rounded-xl "
                                    className="">
                                    {casa.id === 1 ? (
                                        <p
                                            onClick={() => {
                                                window.open(
                                                    `https://www.mercadopago.com.ar/developers/panel/app`,
                                                    "_blank"
                                                );
                                            }}
                                            className="mx-4 mb-2 text-[#4D4D4D]">
                                            Ingresar al{" "}
                                            <span className="font-medium ">
                                                LINK
                                            </span>{" "}
                                            con su cuenta de mercado pago. Si no
                                            tiene una, debe crearla
                                        </p>
                                    ) : (
                                        <p className="mx-4 mb-2">{casa.txt}</p>
                                    )}

                                    <img
                                        // width="276px"
                                        // height="326px"
                                        alt="Imagen no encontrada"
                                        src={casa.img}
                                        // className="object-cover w-full sm:h-[600px] h-[270px]  rounded-xl"
                                        className="object-cover mx-auto transition-all duration-200 ease-out rounded-xl"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <div>
                        {INPUTS.map(({ nombre, input_id, type, label }) => (
                            <div
                                key={input_id}
                                className="w-full max-w-xl mx-auto mb-4 lg:mx-0">
                                <label className="font-medium text-[#4D4D4D]">
                                    {label}
                                </label>
                                <input
                                    className="w-full p-2 mt-1 border-[#CCCCCC] border-2 rounded-lg"
                                    placeholder={label}
                                    name={nombre}
                                    value={userInfo[nombre]}
                                    onChange={handleInputChange}
                                    type={type}
                                />
                            </div>
                        ))}
                        <p className="ml-2 font-medium text-[#029BFC] text-sm">
                            *BLU no puede ver tu Acces Token, la tenemos
                            encriptada
                        </p>
                        <div className="flex items-end justify-center w-full mt-4 lg:justify-end">
                            {saving ? (
                                <Spinner />
                            ) : (
                                <button
                                    onClick={() => handleSave()}
                                    className=" text-center  text-white border rounded-lg py-2 w-64 bg-[#029BFC] hover:scale-105">
                                    Guardar
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex">
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Cuenta Mercado Pago">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Cuenta Mercado Pago">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : tipo_usuario === 8 ? (
                <NavbarHotel titulo="Cuenta Mercado Pago">
                    {render()}
                    <NavbarMobileHotel />
                </NavbarHotel>
            ) : null}
        </div>
    );
};
