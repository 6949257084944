import { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditImageCarrousel } from "../modalEdit/ModalEditImageCarrousel";
import ModalReserva from "./ModalReserva/ModalReserva";
import { InputDate } from "../../components/InputDate";
import es from "date-fns/locale/es";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
interface Props {
    toggleButton: boolean;
    tipo_landing: number;
}

interface Input {
    nombre: string;
    precio_base: string;
    tipo_moneda: string;
}

const Carrousel = ({ toggleButton, tipo_landing }: Props) => {
    registerLocale("es", es);
    const { banner, nombre, personas } = useAppSelector(
        (state) => state.landing_house.house
    );

    const navigate = useNavigate();
    const [datesSelected, setDatesSelected] = useState<boolean>(false);
    const [selectedDate1, setSelectedDate1] = useState<Date>(new Date());
    const [isDatePickerVisible1, setDatePickerVisible1] =
        useState<boolean>(false);

    const [selectedDate2, setSelectedDate2] = useState<Date>(new Date());
    const [isDatePickerVisible2, setDatePickerVisible2] =
        useState<boolean>(false);

    const [modalVisibleCarrousel, setModalVisibleCarrousel] =
        useState<boolean>(false);
    const [modalVisibleReserva, setModalVisibleReserva] =
        useState<boolean>(false);

    const [input, setInput] = useState({
        huespedes: 1,
        fecha_inicio: `${new Date().getFullYear()}-${String(
            new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
        fecha_final: `${new Date().getFullYear()}-${String(
            new Date().getMonth() + 1
        ).padStart(2, "0")}-${String(new Date().getDate()).padStart(2, "0")}`,
    });

    const handleDateChange = (date: Date | null, selectDate: number) => {
        if (date === null) return;
        setDatesSelected(true);
        const year = date?.getFullYear();
        const month = String(date?.getMonth() + 1).padStart(2, "0");
        const day = String(date?.getDate()).padStart(2, "0");

        if (selectDate === 1) {
            if (date) {
                setSelectedDate1(date);

                setInput({
                    ...input,
                    fecha_inicio: `${year}-${month}-${day}`,
                });
            }

            setDatePickerVisible1(false);
        } else {
            if (date) {
                setSelectedDate2(date);
                setInput({
                    ...input,
                    fecha_final: `${year}-${month}-${day}`,
                });
            }
            setDatePickerVisible2(false);
        }
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setInput({
            ...input,
            [event.target.name]: inputValue !== "" ? Number(inputValue) : "",
        });
    };

    const handleModalReserva = () => {
        if (new Date(input.fecha_inicio) > new Date(input.fecha_final)) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "la fecha de inicio no puede ser mayor a la fecha de salida ",

                icon: "error",
                confirmButtonText: "Ok",
            });
        } else {
            let datesParams = "";

            if (datesSelected) {
                datesParams = `fecha_inicio=${input.fecha_inicio}&fecha_final=${input.fecha_final}&`;
            }
            if (tipo_landing === 8) {
                navigate(
                    `/landing-habitaciones?${datesParams}adultos=${input.huespedes}&ninos=0`
                );
            } else {
                setModalVisibleReserva(true);
            }
        }
    };
    return (
        <div className="relative bg-[#f2f5ff]">
            {modalVisibleReserva && (
                <ModalReserva
                    modalVisible={modalVisibleReserva}
                    setModalVisible={setModalVisibleReserva}
                    toggleButton={toggleButton}
                    input={input}
                />
            )}
            {modalVisibleCarrousel && (
                <ModalEditImageCarrousel
                    modalVisible={modalVisibleCarrousel}
                    setModalVisible={setModalVisibleCarrousel}
                />
            )}

            <div className="relative h-[20rem] xl-[25rem] 2xl:h-[30rem]">
                <img
                    src={
                        typeof banner === "string"
                            ? `${process.env.REACT_APP_PROXY}/casa/getImage/${banner}`
                            : URL.createObjectURL(banner)
                    }
                    alt="No se encontro la imagen"
                    className="object-cover w-full h-full"
                />

                {toggleButton && (
                    <div
                        onClick={() => setModalVisibleCarrousel(true)}
                        className="absolute w-full h-[2.5rem] top-10 left-4"
                    >
                        <div className="flex ">
                            <div
                                title="editar fotos"
                                className="z-20 flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer opacity-60 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-12 h-12 text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                                <div className="text-xs text-[#1C4CBB] text-center">
                                    Editar Fotos
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="absolute bottom-0 w-full h-1/3 bg-gradient-to-b from-transparent to-zinc-900"></div>
                <div className="absolute p-4  font-semibold text-white uppercase bottom-5 right-6 text-center text-3xl md:text-5xl 2xl:text-6xl">
                    {nombre}
                </div>
            </div>
            <div className="grid items-center justify-center w-full grid-cols-2 2xl:py-5 py-1 mx-auto md:grid-cols-4 gap-1 md:gap-2 md:w-5/6  lg:w-4/5 ">
                <div className="relative flex flex-col items-start py-5 mx-auto 2xl:py-10 md:mx-3">
                    <p className="mb-2 text-[#1c4cbb] text-start font-bold text-sm lg:text-lg 2xl:text-xl">
                        INGRESO
                    </p>
                    <div className="relative">
                        <div
                            className="flex justify-center gap-5 text-white cursor-pointer 2xl:p-3 p-2.5 bg-[#1c4cbb] hover:bg-[#029BFC] rounded-xl w-[150px] lg:w-[200px] xl:w-[250px]"
                            onClick={() => setDatePickerVisible1(true)}
                        >
                            <div className="flex justify-center text-xs lg:text-base">
                                {selectedDate1.getDate() < 10
                                    ? `0${selectedDate1.getDate()}`
                                    : selectedDate1.getDate()}{" "}
                                {selectedDate1.toLocaleDateString("es-ES", {
                                    month: "long",
                                })}{" "}
                                {selectedDate1.getFullYear()}
                            </div>
                        </div>
                        {isDatePickerVisible1 && (
                            <div className="absolute bottom-0">
                                <DatePicker
                                    selected={selectedDate1}
                                    onChange={(date) =>
                                        handleDateChange(date, 1)
                                    }
                                    locale="es"
                                    inline
                                    autoComplete="off"
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="relative flex flex-col py-3 mx-auto items-star lg:py-10 md:mx-3">
                    <p className="mb-2 text-[#1c4cbb] text-start font-bold text-sm lg:text-lg 2xl:text-xl">
                        SALIDA
                    </p>
                    <div className="relative">
                        <div
                            className="flex justify-center gap-5 text-white cursor-pointer 2xl:p-3 p-2.5 bg-[#1c4cbb] hover:bg-[#029BFC] rounded-xl w-[150px] lg:w-[200px] xl:w-[250px] "
                            onClick={() => setDatePickerVisible2(true)}
                        >
                            <div className="flex justify-center text-xs lg:text-base">
                                {selectedDate2.getDate() < 10
                                    ? `0${selectedDate2.getDate()}`
                                    : selectedDate2.getDate()}{" "}
                                {selectedDate2.toLocaleDateString("es-ES", {
                                    month: "long",
                                })}{" "}
                                {selectedDate2.getFullYear()}
                            </div>
                        </div>
                        {isDatePickerVisible2 && (
                            <div className="absolute bottom-0">
                                <DatePicker
                                    locale="es"
                                    selected={selectedDate2}
                                    onChange={(date) =>
                                        handleDateChange(date, 2)
                                    }
                                    inline
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="relative flex flex-col items-start py-3 mx-auto lg:py-10 md:mx-3 max-w-[250px]">
                    <p className="mb-2 text-[#1c4cbb] text-start font-bold text-sm lg:text-lg 2xl:text-xl">
                        INVITADOS
                    </p>
                    <div className="flex items-center justify-between p-2 2xl:p-3  border rounded-xl  bg-[#1c4cbb] hover:bg-[#029BFC] text-white font-bold w-[150px] lg:w-[200px] xl:w-[250px]">
                        {/* <span className="ml-1 mr-6 font-bold select-none ">
                            {input.huespedes}
                        </span> */}
                        <input
                            className="w-8 ml-1 mr-6 font-bold bg-transparent outline-none select-none"
                            type="number"
                            name="huespedes"
                            value={input.huespedes}
                            onChange={handleInputChange}
                        />
                        <div>
                            <button
                                onClick={() =>
                                    setInput({
                                        ...input,
                                        huespedes:
                                            input.huespedes < personas
                                                ? input.huespedes + 1
                                                : tipo_landing === 8
                                                ? Number(input.huespedes) + 1
                                                : input.huespedes,
                                    })
                                }
                            >
                                <svg
                                    className="w-5 h-5 font-bold cursor-pointer hover:scale-110 hover:text-[#1C4CBB]"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    {" "}
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                    />{" "}
                                    <polyline points="6 15 12 9 18 15" />
                                </svg>
                            </button>

                            <button
                                onClick={() =>
                                    setInput({
                                        ...input,
                                        huespedes:
                                            input.huespedes > 1
                                                ? input.huespedes - 1
                                                : input.huespedes,
                                    })
                                }
                            >
                                <svg
                                    user-select="none"
                                    className="w-5 h-5 font-bold cursor-pointer hover:scale-110 hover:text-[#1C4CBB]"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <polyline points="6 9 12 15 18 9" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="relative flex flex-col items-center justify-center p-3 lg:p-4 mx-auto md:mx-3 w-[150px] lg:w-[200px] xl:w-[250px] mt-5 md:mt-7">
                    <p className="mb-2 text-[#1c4cbb] text-start font-bold ">
                        {" "}
                    </p>
                    <div
                        onClick={() => handleModalReserva()}
                        className="flex p-2.5 2xl:p-3  text-white text-center  rounded-xl w-[150px] lg:w-[200px] xl:w-[250px] cursor-pointer font-bold bg-[#029BFC] hover:scale-105 text-sm sm:text-base select-none justify-center disabled:bg-slate-300 disabled:scale-100 "
                    >
                        Consultar
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Carrousel;
