import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../hooks/hooks";
import { useDispatchDominioCorredor } from "../../../hooks/useDispatchDominioCorredor";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import nombreAlojamiento from "../../../img/onboarding/nombra_alojamiento.jpg";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { ButtonSiguiente } from "../../onboardingPropietario/ButtonSiguiente";

interface selectedFile {
    banner: File | string;
}
export const DatosLandingCorredor = () => {
    const {
        descripcion,
        id: id_corredor,
        avatar,
        banner,
        plan,
    } = useAppSelector((state) => state.corredor);
    const dispatchDominio = useDispatchDominioCorredor();
    const [input, setInput] = useState({
        descripcion: descripcion,
    });
    const [preview, setPreview] = useState("");

    const { id: id_usuario } = useAppSelector((state) => state.usuario_logeado);

    const [selectedFiles, setSelectedFiles] = useState<selectedFile>({
        banner: banner,
    });
    const [showBannerPreview, setShowBannerPreview] = useState<boolean>(false);

    const [bannerPreview, setBannerPreview] = useState<string | undefined>(
        undefined
    );
    const [selectedFile, setSelectedFile] = useState<File>();
    console.log("plan", plan);
    const navigate = useNavigate();
    const handleChangeImageAvatar = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(selectedFile);
            return;
        }
        setSelectedFile(e.target.files[0]);
        const objectUrl = URL.createObjectURL(e.target.files[0]);
        setPreview(objectUrl);
    };

    const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.name === "banner") {
            const imagePreview = URL.createObjectURL(e.target.files[0]);

            setSelectedFiles({
                ...selectedFiles,
                banner: e.target.files[0],
            });

            setBannerPreview(imagePreview);

            setShowBannerPreview(true);
        }
    };

    const handleInputChange = (e: any) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        setInput({
            descripcion: descripcion,
        });
    }, [descripcion]);

    const handleSubmit = async () => {
        if (!input.descripcion)
            return Swal.fire({
                title: "Debes escoger un descripcion válido",
                icon: "warning",
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
            });

        const form = new FormData();

        form.append("descripcion", input.descripcion.trim());
        form.append("avatar_corredor", selectedFile || avatar);
        form.append("banner_corredor", selectedFiles.banner);

        try {
            if (id_corredor) {
                await fetchAxiosToken({
                    url: `/corredor/putOnboarding/${id_corredor}`,
                    method: "put",
                    body: form,
                });

                // await fetchAxiosToken({
                //     url: `/casa/putPagina/${id_corredor}`,
                //     method: "put",
                //     body: {
                //         pagina: "capacidad",
                //     },
                // });
                dispatchDominio();

                if (plan > 0) {
                    navigate(`/dashboard-corredor/onboarding/redes`);
                } else {
                    navigate(`/dashboard-corredor/onboarding/suscripcion`);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 lg:grid">
            <div className="block h-56 md:h-1/4 lg:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center lg:h-screen">
                    <img
                        src={nombreAlojamiento}
                        alt="nombreAlojamiento"
                        className="w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Te falta poco para tener tu nuevo sitio web
                        </div>

                        <div className="mt-5 text-lg text-center text-white md:text-xl 2xl:text-2xl md:mt-14 ">
                            ¡No te preocupes podrás cambiarlo más adelante!
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center p-6 text-center text-black bg-white lg:overflow-x-auto">
                <ButtonRegresar
                    url={`/dashboard-corredor/onboarding/bienvenida`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />
                <div className="flex flex-col pb-4 mt-20 lg:mt-52 ">
                    <p className="my-2 mt-6 text-lg font-medium 2xl:text-xl">
                        Sube una foto de perfil
                    </p>
                    <div className="flex flex-col items-center w-full mt-4 select-none">
                        <div className="flex overflow-hidden transition-transform duration-300 ease-in-out rounded-full w-44 h-44">
                            <div className="relative transition-transform duration-300 ease-in-out hover:scale-125">
                                <input
                                    onChange={handleChangeImageAvatar}
                                    type="file"
                                    accept="image/*"
                                    className="absolute left-0 z-50 w-full h-full bg-red-100 rounded-full opacity-0 cursor-pointer select-none file:cursor-pointer"
                                />
                                {preview && avatar ? (
                                    <img
                                        src={preview}
                                        className="rounded-full flex h-44 w-44 p-[0.050rem] border-[3px] object-cover cursor-pointer border-blue-blueFrom"
                                        alt="foto de perfil"
                                    />
                                ) : (
                                    <img
                                        src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${avatar}`}
                                        className="rounded-full p-[0.050rem] w-44 h-44 border-[3px] object-cover cursor-pointer border-blue-blueFrom"
                                        alt="foto   "
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <p className="my-2 text-lg font-medium 2xl:text-xl">
                        Sube tu imagen de portada
                    </p>
                    <div className="cursor-pointer mt-4 relative flex justify-center w-[350px] h-44 border-2 border-dashed border-[#E9E9E9]  rounded-lg hover:cursor-pointer mx-auto ">
                        {!selectedFiles.banner ? (
                            <>
                                <div className="absolute">
                                    <div className="flex flex-col w-full mx-auto ">
                                        <div className="relative top-4 ">
                                            <span className="block mt-16 text-base font-semibold text-center ">
                                                Agrega o arrastra una foto aquí
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    accept="image/*"
                                    type="file"
                                    name="banner"
                                    className="w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                    onChange={handleChangeImage}
                                />
                            </>
                        ) : !showBannerPreview ? (
                            <>
                                <img
                                    className="object-cover w-full rounded-lg"
                                    src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${selectedFiles.banner}`}
                                    alt="banner del alojamiento"
                                />
                                <input
                                    accept="image/*"
                                    type="file"
                                    name="banner"
                                    className="absolute w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                    onChange={handleChangeImage}
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    className="object-cover w-full rounded-lg"
                                    src={bannerPreview}
                                    alt="banner del alojamiento"
                                />
                                <input
                                    accept="image/*"
                                    type="file"
                                    name="banner"
                                    className="absolute w-full h-full opacity-0 cursor-pointer file:cursor-pointer"
                                    onChange={handleChangeImage}
                                />
                            </>
                        )}
                    </div>
                    <p className="my-2 text-lg font-medium 2xl:text-xl">
                        Descripción
                    </p>
                    <textarea
                        name="descripcion"
                        value={input.descripcion}
                        onChange={(e) => handleInputChange(e)}
                        placeholder="ej. casa rosales"
                        className=" w-[350px] h-32 p-3 mt-4 text-black border border-gray-500 rounded-lg placeholder:italic mx-auto"
                    />

                    <div className="w-2/3 mx-auto">
                        <ButtonSiguiente onSubmit={() => handleSubmit()} />
                    </div>
                </div>
            </div>
        </div>
    );
};
