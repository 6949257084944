import React, { useEffect, useMemo, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../hooks/hooks";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { CapacidadesHotel } from "../capacidades/CapacidadesHotel";
import { formatearNumero } from "../../components/helpers/formatearNumero";
import { usePriceToSelectedCurrency } from "../../hooks/usePriceToSelectedCurrency";
import Habitaciones from "../design1/Habitaciones";
import Spinner from "../../components/helpers/Spinner";
import Swal from "sweetalert2";
import { useDispatchDominio } from "../../hooks/useDispatchDominio";
import useCurrencyPrices from "../../hooks/useCurrencyPrices";

interface Props {
    modalVisible: boolean;
    setModalVisible: (b: boolean) => void;
    habitacionSelected?: Habitacion;
    id_hotel: number;
    landing: number;
}

interface Habitacion {
    id: number;
    nombre: string;
    descripcion: string;
    banner: string;
    precio_base: string;
    personas: string;
    mascotas: string;
    banos: string;
    camas: string;
    landing: number;
    check_in: string;
    check_out: string;
    id_hotel: number;
    hotel_habitaciones_imagenes: any[];
}

export const ModalSelectHabitaciones = ({
    modalVisible,
    setModalVisible,
    habitacionSelected,
    id_hotel,
    landing,
}: Props) => {
    const [index, setIndex] = useState(-1);
    const { SELECTED_CURRENCY } = useCurrencyPrices();
    const handleClick = (habitacion: Habitacion) => {
        setCurrentHabitacion(habitacion);
    };
    const dispatchDominio = useDispatchDominio();
    const { tipo_moneda, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );

    const [habitaciones, setHabitaciones] = useState<Habitacion[]>();
    const [currentHabitacion, setCurrentHabitacion] = useState<Habitacion>();
    const [saving, setSaving] = useState<boolean>(false);

    const { price_to_currency } = usePriceToSelectedCurrency();

    useEffect(() => {
        setCurrentHabitacion(habitacionSelected);
        getHabitaciones();
    }, []);

    const getHabitaciones = async () => {
        const response = await fetchAxiosToken({
            url: `/hotel_habitaciones/getAllByHotel/${id_hotel}?query=Publicado`,
            method: "get",
        });

        setHabitaciones(response);
    };

    const handleSubmit = async () => {
        if (currentHabitacion?.id === undefined) {
            return Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Selecciona una habitación",
                icon: "warning",
                confirmButtonText: "Aceptar",
            });
        } else {
            try {
                setSaving(true);
                await fetchAxiosToken({
                    url: `/hotel_habitaciones/updateLandingHabitacion/${currentHabitacion?.id}`,
                    method: "put",
                    body: { landing },
                });

                dispatchDominio();
                setTimeout(() => {
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        title: "Actualizado",
                        text: "Habitación actualizada",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                    setSaving(false);

                    setModalVisible(false);
                }, 2000);
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <ResponsiveModalAbsolute
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            max_width={100}
        >
            <div className="flex flex-col items-center justify-center gap-4 p-4 mx-auto ">
                <h2 className="2xl:text-4xl text-3xl text-[#1C4CBB] text-center font-medium">
                    Selecciona una habitación
                </h2>
                <hr className="w-full bg-gray-300" />

                <div>La habitación seleccionada aparecerá en la landing</div>

                <div className="w-full h-full flex flex-col items-center justify-center gap-4 p-4 mx-auto ">
                    <div className="grid grid-cols-1 gap-4 w-full ">
                        {habitacionSelected && (
                            <div
                                key={`habitacion-${habitacionSelected.id}`}
                                className={`${
                                    habitacionSelected.id ===
                                    currentHabitacion?.id
                                        ? "border-2 border-[#1C4CBB]"
                                        : ""
                                } w-full h-36 shadow-md cursor-pointer rounded-md flex justify-start px-5 items-center gap-10`}
                                onClick={() =>
                                    habitacionSelected &&
                                    handleClick(habitacionSelected)
                                }
                            >
                                <img
                                    src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${habitacionSelected?.banner}`}
                                    alt="habitacion"
                                    className=" w-24 h-24 object-cover rounded-md"
                                />
                                {/* <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-black to-transparent"></div> */}
                                <div className=" flex flex-col items-center justify-center">
                                    <p className=" font-bold text-base text-[#1C4CBB]">
                                        {habitacionSelected?.nombre}
                                    </p>
                                    <div className="flex flex-col items-center justify-center w-full ">
                                        <CapacidadesHotel
                                            personas={
                                                habitacionSelected?.personas ||
                                                ""
                                            }
                                            mascotas={
                                                habitacionSelected?.mascotas ||
                                                ""
                                            }
                                            banos={
                                                habitacionSelected?.banos || ""
                                            }
                                            camas={
                                                habitacionSelected?.camas || ""
                                            }
                                            id_habitacion={
                                                habitacionSelected?.id || 0
                                            }
                                            toggleButton={false}
                                        />
                                    </div>

                                    <div className=" text-lg text-center text-[#1C4CBB] align-middle ">
                                        <p className="font-light select-none">
                                            Desde{" "}
                                            <span className="font-semibold">{`${tipo_moneda} ${formatearNumero(
                                                price_to_currency(
                                                    Number(
                                                        habitacionSelected?.precio_base
                                                    )
                                                )
                                            )} `}</span>{" "}
                                            /por noche
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {habitaciones
                            ?.filter(
                                (habitacion) =>
                                    habitacion.id !== habitacionSelected?.id
                            )
                            ?.map((habitacion, i) => (
                                <div
                                    key={i}
                                    className={`${
                                        currentHabitacion?.id === habitacion.id
                                            ? "border-2 border-[#1C4CBB]"
                                            : ""
                                    } w-full h-36 shadow-md cursor-pointer rounded-md flex justify-start px-5 items-center gap-10`}
                                    onClick={() => handleClick(habitacion)}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/hotel_habitaciones/getImage/${habitacion.banner}`}
                                        alt="habitacion"
                                        className=" w-24 h-24 object-cover rounded-md"
                                    />
                                    {/* <div className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-black to-transparent"></div> */}
                                    <div className=" flex flex-col items-center justify-center">
                                        <p className=" font-bold text-base text-[#1C4CBB]">
                                            {habitacion.nombre}
                                        </p>
                                        <div className="flex flex-col items-center justify-center w-full ">
                                            <CapacidadesHotel
                                                personas={habitacion.personas}
                                                mascotas={habitacion.mascotas}
                                                banos={habitacion.banos}
                                                camas={habitacion.camas}
                                                id_habitacion={habitacion.id}
                                                toggleButton={false}
                                            />
                                        </div>

                                        <div className=" text-lg text-center text-[#1C4CBB] align-middle ">
                                            <p className="font-light select-none">
                                                Desde{" "}
                                                <span className="font-semibold">{`${SELECTED_CURRENCY} ${formatearNumero(
                                                    price_to_currency(
                                                        Number(
                                                            habitacion.precio_base
                                                        )
                                                    )
                                                )} `}</span>{" "}
                                                /por noche
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div className="w-10/12 2xl:mt-8 ">
                    {saving ? (
                        <Spinner />
                    ) : (
                        <button
                            onClick={() => handleSubmit()}
                            className="w-full p-3 px-5 text-white rounded-lg bg-[#1C4CBB] hover:scale-105 2xl:text-base text-sm"
                        >
                            Guardar cambios
                        </button>
                    )}
                </div>
            </div>
        </ResponsiveModalAbsolute>
    );
};
