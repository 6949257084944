import React from "react";

import imagenBanner from "../../img/banner_landing_hotel.webp";
import encabezado from "../../img/Logo-BluStay-02.png";
/* import videoHotel from "../../img/video_landing_hotel.mp4"; */
import { FormularioHotel } from "./FormularioHotel";
const videoHotel = require("../../img/video_landing_hotel.mp4");

export const Banner = () => {
    return (
        <div className="">
            <div
                className="relative flex items-center justify-center py-8 pb-20 bg-center bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${imagenBanner})` }}>
                <div className="absolute inset-0 bg-[#071829] bg-opacity-60"></div>
                <div className="z-10 w-11/12 h-full mx-auto text-white xl:w-4/5 2xl:w-3/4">
                    <div className="w-full">
                        <img
                            src={encabezado}
                            alt="Encabezado"
                            className="md:w-[12%] w-[35%] "
                        />
                    </div>

                    <div className="grid w-full grid-cols-1 gap-4 mt-5  md:grid-cols-[60%,40%]  ">
                        <div className="">
                            <div className="w-full md:w-11/12">
                                <div className="flex items-center justify-center gap-1 ">
                                    <div
                                        className="text-2xl font-bold text-center text-white 2xl:text-5xl md:text-4xl md:text-left "
                                        style={{ lineHeight: "1.3" }}>
                                        Simplifica la gestión de tu hotel y
                                        <p
                                            className="mx-1 inline text-2xl font-bold text-center 2xl:text-5xl md:text-4xl text-[#071829] bg-[#C3EA43] rounded-lg px-2   md:text-left"
                                            style={{
                                                boxDecorationBreak: "clone",
                                                WebkitBoxDecorationBreak:
                                                    "clone",
                                            }}>
                                            mejora la experiencia de tus
                                            huéspedes
                                        </p>
                                    </div>
                                </div>

                                <p className="w-5/6 mx-auto mt-4 text-sm text-center 2xl:text-lg xl:text-base md:text-left md:mx-0">
                                    Con <b>BluStay</b>, digitaliza la
                                    administración de tu hotel con una
                                    <b>
                                        plataforma integral que optimiza las
                                        reservas, el control de habitaciones y
                                        la experiencia de tus huéspedes.
                                    </b>
                                </p>
                            </div>
                            <div className="w-full mx-auto mt-6 md:w-3/4 md:mx-0">
                                <video
                                    className="mt-4 rounded-md"
                                    src={videoHotel}
                                    controls
                                    width="100%"></video>
                            </div>
                        </div>
                        <div
                            id="formularioReserva"
                            className="flex items-center justify-center w-full md:justify-start md:items-end md:max-w-md ">
                            <FormularioHotel />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
