import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../components/helpers/Spinner";

import axios from "axios";
import useHeaders from "../../../hooks/useHeaders";
import Swal from "sweetalert2";
import { Navbar } from "../Navbar";
import { NavbarCliente } from "../../dashboardClientes/NavbarCliente";
import { NavbarMobileCliente } from "../../dashboardClientes/NavbarMobileCliente";
import { NavbarMobile } from "../NavbarMobile";
import { Paginado } from "../../../components/Paginado";
import { ShowDateToUser } from "../../../components/ShowDateToUser";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/hooks";
import ToggleButton from "../../../components/helpers/ToggleButton";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import { formatearNumero } from "../../../components/helpers/formatearNumero";
import { useParams } from "react-router-dom";
import { fetchAxiosNoToken } from "../../../helpers/fetchAxiosNoToken";
import { useDispatch } from "react-redux";
import { set_landing_house } from "../../../redux/reducers/landingReducer";

import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
interface Cliente {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface Propietario {
    apellido: string;
    avatar: string;
    id: number;
    nombre: string;
}

interface casa {
    banner: string;
    id: number;
    nombre: string;
    Propietario: Propietario;
}

interface reserva {
    id: number;
    fecha_inicio: string;
    fecha_final: string;
    costo_total: number;
    avatar: string;
    estado: string;
    habilitado: string;
    fecha_registro: string;
    Cliente: Cliente;
    casa: casa;
    tipo_moneda: string;
}

interface getComfort {
    pagina: number;
    habilitado: boolean;
    query: string;
    estado: string;
}

export const Reservas = () => {
    const { headers } = useHeaders();
    const [reservas, setReservas] = useState<reserva[]>([]);
    const [reservasTotal, setReservasTotal] = useState<number>(0);
    const [enabled, setenabled] = useState<boolean>(true);
    const [pageNumber, setpageNumber] = useState<number>(1);
    const [create, setcreate] = useState<boolean>(false);
    const [modalEnabled, setModalEnabled] = useState<boolean>(false);
    const [comfortEdit, setComfortEdit] = useState<reserva>();
    const [input, setInput] = useState<string>("");
    const [estado, setEstado] = useState<string>("Todos");
    const estados = [
        "Todos",
        "Confirmar disponibilidad",
        "Pendiente de pago",
        "Pago parcial",
        "Pago completo",
        "Devolución de garantía",
    ];
    const debounceInput = useRef<ReturnType<typeof setTimeout> | null>(null);
    const { id_casa } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const RESERVAS_PER_PAGE = 20;
    const { id, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const casa = useAppSelector((state) => state.landing_house);

    const getComforts = async ({
        pagina,
        habilitado,
        query,
        estado,
    }: getComfort) => {
        try {
            if (id_casa) {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/pedidos/getAll/${pagina}/${
                        habilitado ? "Si" : "No"
                    }/${estado}/${id}?cliente=${query}&id_casa=${id_casa}`,
                    { headers }
                );

                setReservas(data.rows);
                setReservasTotal(data.count);
            } else if (casa.house.id !== 0) {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_PROXY}/pedidos/getAll/${pagina}/${
                        habilitado ? "Si" : "No"
                    }/${estado}/${id}?cliente=${query}&id_casa=${
                        casa.house.id
                    }`,
                    { headers }
                );

                setReservas(data.rows);
                setReservasTotal(data.count);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);

        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            getComforts({
                pagina: pageNumber,
                habilitado: enabled,
                query: "",
                estado: estado,
            });

            return;
        }
        debounceInput.current = setTimeout(() => {
            getComforts({
                pagina: pageNumber,
                habilitado: enabled,
                query: e.target.value,
                estado: estado,
            });
        }, 1000);
    };

    /*  useEffect(() => {
        getCasa();
    }, [id_casa]);

    const getCasa = async () => {
        if (id_casa) {
            const data = await fetchAxiosNoToken({
                url: `/casa/getOne/id/${id_casa}`,
            });

            dispatch(set_landing_house(data));
        }
    }; */

    useEffect(() => {
        getComforts({
            pagina: pageNumber,
            habilitado: enabled,
            query: "",
            estado: estado,
        });
    }, [enabled, create, comfortEdit, estado, casa]);

    const handleDelete = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo?",
            text: "Se enviará la reserva a deshabilitados!",
            icon: "warning",
            showCancelButton: true,

            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(
                        `${process.env.REACT_APP_PROXY}/pedidos/delete/${id}`,
                        { headers }
                    );
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        icon: "success",
                        title: "Reserva elimidada!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    getComforts({
                        pagina: pageNumber,
                        habilitado: enabled,
                        query: "",
                        estado: estado,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDetalles = async (id: number) => {
        console.log(id);
        if (tipo_usuario === 3) {
            navigate(`/dashboard/reservas/${id}`, { replace: false });
        } else if (tipo_usuario === 5 || tipo_usuario === 7) {
            navigate(`/dashboard-cliente/reservas/${id}`, { replace: false });
        } else if (tipo_usuario === 6) {
            navigate(`/dashboard-corredor/reservas/detalle/${id}`, {
                replace: false,
            });
        }
    };
    const resenaEnFecha = (fecha: string) => {
        let hoy = new Date().getTime();
        let final_reserva = new Date(fecha + "T00:00:00").getTime();

        return hoy > final_reserva;
    };

    const pagination = (pageNumber: number) => {
        setpageNumber(pageNumber);
        getComforts({
            pagina: pageNumber,
            habilitado: enabled,
            query: "",
            estado: estado,
        });
    };

    const render = () => {
        return (
            <div className="w-full">
                <div className="flex items-center px-3 mx-auto mt-12 sm:px-6 ">
                    <div className="flex">
                        {tipo_usuario === 3 || tipo_usuario === 4 ? (
                            <div className="flex w-full p-1 mr-2 border-2 border-[#CCCCCC] rounded-lg appearance-none md:w-80 left-2 text-stone-900">
                                <button className="flex items-center justify-center px-2">
                                    <svg
                                        className="2xl:w-6 2xl:h-6 w-5 h-5 text-[#808080]"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                                    </svg>
                                </button>{" "}
                                <input
                                    onBlur={() => setInput("")}
                                    value={input}
                                    type="text"
                                    onChange={handleInputChange}
                                    className="w-full bg-transparent outline-none appearance-none 2xl:py-2 placeholder:text-[#808080]"
                                    placeholder="Buscar"
                                />
                            </div>
                        ) : null}
                        {(tipo_usuario === 3 || tipo_usuario === 4) && (
                            <div
                                onMouseOut={() => setModalEnabled(false)}
                                onMouseOver={() => setModalEnabled(true)}
                                className="relative"
                            >
                                <button className="w-8 h-8 xs:w-[51px] xs:h-[51px] flex items-center justify-center px-1 py-1  text-blue-blueFrom border-2 border-[#CCCCCC] rounded-lg group hover:bg-[#CCCCCC] hover:text-white">
                                    <svg
                                        className="w-6 h-6 text-[#808080] group-hover:text-white"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                        />{" "}
                                        <circle cx="14" cy="6" r="2" />{" "}
                                        <line x1="4" y1="6" x2="12" y2="6" />{" "}
                                        <line x1="16" y1="6" x2="20" y2="6" />{" "}
                                        <circle cx="8" cy="12" r="2" />{" "}
                                        <line x1="4" y1="12" x2="6" y2="12" />{" "}
                                        <line x1="10" y1="12" x2="20" y2="12" />{" "}
                                        <circle cx="17" cy="18" r="2" />{" "}
                                        <line x1="4" y1="18" x2="15" y2="18" />{" "}
                                        <line x1="19" y1="18" x2="20" y2="18" />
                                    </svg>
                                </button>
                                <div
                                    className={
                                        modalEnabled
                                            ? "absolute top-8 xs:top-[50px]  w-56 z-10  pt-2 left-0 border rounded-lg  "
                                            : "hidden"
                                    }
                                >
                                    <div
                                        className={
                                            modalEnabled
                                                ? "block bg-white  top-16 md723:top-20 py-2 rounded-lg shadow-md "
                                                : "hidden"
                                        }
                                    >
                                        <div
                                            onClick={() => (
                                                setModalEnabled(false),
                                                setenabled(!enabled)
                                            )}
                                            className=" w-52 2xl:h-11 h-9 mx-auto cursor-pointer rounded-lg 2xl:text-base text-sm hover:bg-[#029BFC] hover:text-white flex items-center justify-start pl-4 2xl:my-1"
                                        >
                                            {!enabled
                                                ? `Habilitados`
                                                : `Deshabilitadas`}
                                        </div>
                                        {estados.map((e) => {
                                            return (
                                                <div
                                                    key={e}
                                                    onClick={() => (
                                                        setModalEnabled(false),
                                                        setEstado(e)
                                                    )}
                                                    className=" w-52 2xl:h-11 h-9 mx-auto cursor-pointer rounded-lg 2xl:text-base text-sm hover:bg-[#029BFC] hover:text-white flex items-center justify-start pl-4  2xl:my-1"
                                                >
                                                    <p className="">{e}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <section className="flex justify-center items-center 2xl:py-[10px] ">
                    <div className="w-full px-11">
                        <div className="flex flex-wrap -mx-8">
                            <div className="w-full ">
                                <div className="flex flex-col">
                                    {true ? (
                                        <div className="w-full ">
                                            <table className="w-full mt-8 overflow-x-auto 2xl:mt-14">
                                                <thead className="text-left border-b-[3px] border-[#CCCCCC] ">
                                                    <tr className="">
                                                        <th className="w-full min-w-[10rem] md:w-4/12 pb-4 2xl:text-base text-sm ">
                                                            {tipo_usuario === 3
                                                                ? "Cliente"
                                                                : ""}
                                                        </th>
                                                        <th className="w-full min-w-[8rem] md:w-2/12 hidden lg:table-cell pb-4 text-center 2xl:text-base text-sm">
                                                            Fecha inicio
                                                        </th>
                                                        <th className="w-full min-w-[8rem] md:w-2/12 hidden lg:table-cell pb-4 text-center 2xl:text-base text-sm">
                                                            Fecha final
                                                        </th>
                                                        <th className="w-full min-w-[8rem] md:w-2/12 hidden lg:table-cell pb-4 text-center 2xl:text-base text-sm">
                                                            Registrada
                                                        </th>
                                                        <th className="w-full min-w-[10rem] md:w-4/12 pb-4 text-center hidden lg:table-cell 2xl:text-base text-sm">
                                                            Estado
                                                        </th>
                                                        <th className="w-full min-w-[8rem] md:w-4/12 hidden lg:table-cell pb-4 text-center 2xl:text-base text-sm">
                                                            Precio
                                                        </th>
                                                        <th className="w-1/12 text-right min-w-[8rem] pr-10 pb-4 2xl:text-base text-sm">
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <div className="my-2 2xl:my-4"></div>
                                                <tbody className="">
                                                    {reservas?.map(
                                                        (reserva) => (
                                                            <tr
                                                                key={reserva.id}
                                                                className="w-full text-xs select-none sm:text-sm xl:text-base hover:bg-gray-200"
                                                            >
                                                                <td className="border-r-[3px] border-[#CCCCCC]  ">
                                                                    <div className="flex items-center py-2 2xl:py-3 ">
                                                                        {tipo_usuario ===
                                                                        3 ? (
                                                                            <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY}/usuarios/getImage/` +
                                                                                    reserva
                                                                                        ?.Cliente
                                                                                        .avatar
                                                                                }
                                                                                alt="icono"
                                                                                className="object-cover w-8 h-8 rounded 2xl:w-10 2xl:h-10"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={
                                                                                    `${process.env.REACT_APP_PROXY}/casa/getImage/` +
                                                                                    reserva
                                                                                        ?.casa
                                                                                        .banner
                                                                                }
                                                                                alt="icono"
                                                                                className="object-cover w-8 h-8 rounded 2xl:w-10 2xl:h-10"
                                                                            />
                                                                        )}
                                                                        {tipo_usuario ===
                                                                        3 ? (
                                                                            <h1 className="mx-3 text-[#3C3C3C] 2xl:text-base text-sm ">
                                                                                {`${reserva.Cliente.nombre} ${reserva.Cliente.apellido}`}
                                                                            </h1>
                                                                        ) : (
                                                                            <h1 className=" flex flex-col mx-3 text-[#3C3C3C] 2xl:text-base text-sm">
                                                                                <span>
                                                                                    {`${reserva.casa.nombre}`}
                                                                                </span>
                                                                                <span className="text-gray-400">
                                                                                    {`${reserva.casa.Propietario.nombre} ${reserva.casa.Propietario.apellido}`}
                                                                                </span>
                                                                            </h1>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td className="hidden pr-2 text-center lg:table-cell border-r-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                                    <ShowDateToUser
                                                                        date={
                                                                            reserva.fecha_inicio
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="hidden text-center lg:table-cell border-r-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                                    <ShowDateToUser
                                                                        date={
                                                                            reserva.fecha_final
                                                                        }
                                                                    />
                                                                </td>
                                                                <td className="hidden text-center lg:table-cell border-r-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                                    <ShowDateToUser
                                                                        date={reserva.fecha_registro.slice(
                                                                            0,
                                                                            10
                                                                        )}
                                                                    />
                                                                </td>
                                                                <td className=" text-center hidden lg:table-cell border-r-[3px] border-[#CCCCCC] px-1 2xl:text-base text-sm">
                                                                    <div className="grid grid-cols-[20%,80%] content-center">
                                                                        {reserva.estado ===
                                                                            "Confirmar disponibilidad" && (
                                                                            <p>
                                                                                <svg
                                                                                    className="w-4 h-4 mr-2"
                                                                                    id="Capa_2"
                                                                                    data-name="Capa 2"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 17.24 17.24"
                                                                                >
                                                                                    <g
                                                                                        id="Capa_1-2"
                                                                                        data-name="Capa 1"
                                                                                    >
                                                                                        <path
                                                                                            fill="#808080"
                                                                                            d="m8.62,0C3.86,0,0,3.86,0,8.62s3.86,8.62,8.62,8.62,8.62-3.86,8.62-8.62S13.38,0,8.62,0Zm0,13.29c-2.58,0-4.67-2.09-4.67-4.67s2.09-4.67,4.67-4.67,4.67,2.09,4.67,4.67-2.09,4.67-4.67,4.67Z"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </p>
                                                                        )}

                                                                        {(reserva.estado ===
                                                                            "Pendiente de pago" ||
                                                                            reserva.estado ===
                                                                                "No disponible") && (
                                                                            <p>
                                                                                <svg
                                                                                    className="w-4 h-4 mr-2"
                                                                                    id="Capa_2"
                                                                                    data-name="Capa 2"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 100 100"
                                                                                >
                                                                                    <g id="Testimonios">
                                                                                        <path
                                                                                            fill="#FE0000"
                                                                                            d="m50,0C22.39,0,0,22.39,0,50s22.39,50,50,50,50-22.39,50-50S77.61,0,50,0Zm27.62,57.04c0,1.26-1.02,2.29-2.28,2.29H24.67c-1.26,0-2.29-1.02-2.29-2.29v-14.08c0-1.26,1.02-2.29,2.29-2.29h50.67c1.26,0,2.28,1.02,2.28,2.29v14.08Z"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </p>
                                                                        )}
                                                                        {(reserva.estado ===
                                                                            "Pago Completo" ||
                                                                            reserva.estado ===
                                                                                "Reseña de casa" ||
                                                                            reserva.estado ===
                                                                                "Devolución de garantía") && (
                                                                            <p>
                                                                                <svg
                                                                                    className="w-4 h-4 mr-2"
                                                                                    id="Capa_2"
                                                                                    data-name="Capa 2"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 99.97 100"
                                                                                >
                                                                                    <g id="Pop-up">
                                                                                        <path
                                                                                            fill="#5AAD00"
                                                                                            d="m49.84,0c27.53.09,49.9,21.75,50.12,49.8.22,27.65-22.33,50.18-49.9,50.2C22.41,100.03.04,77.77,0,50.2-.04,22.43,22.18.05,49.84,0Zm-29.07,44.39c-3.19-.03-5.36,1.69-6.69,4.35-1.43,2.86-.94,5.71,1.29,7.98,6.13,6.26,12.35,12.43,18.58,18.59,1.32,1.3,2.93,2.03,4.88,2.08,3.01.08,5.03-1.65,6.95-3.57,6.5-6.51,12.97-13.05,19.47-19.56,6.36-6.37,12.78-12.69,19.1-19.1,2.77-2.81,2.79-7.39.25-10.03-3.17-3.28-7.21-3.53-10.52-.51-2.4,2.19-4.69,4.51-6.99,6.8-8.91,8.87-17.81,17.74-26.67,26.66-1.11,1.12-1.78,1.08-2.86-.03-3.7-3.82-7.51-7.53-11.25-11.31-1.53-1.55-3.34-2.38-5.53-2.36Z"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </p>
                                                                        )}
                                                                        {reserva.estado ===
                                                                            "Pago Parcial" && (
                                                                            <p>
                                                                                <svg
                                                                                    className="w-4 h-4 mr-2"
                                                                                    id="Capa_2"
                                                                                    data-name="Capa 2"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 99.97 100"
                                                                                >
                                                                                    <g id="Pop-up">
                                                                                        <path
                                                                                            fill="#E5AD00"
                                                                                            d="m49.84,0c27.53.09,49.9,21.75,50.12,49.8.22,27.65-22.33,50.18-49.9,50.2C22.41,100.03.04,77.77,0,50.2-.04,22.43,22.18.05,49.84,0Zm-29.07,44.39c-3.19-.03-5.36,1.69-6.69,4.35-1.43,2.86-.94,5.71,1.29,7.98,6.13,6.26,12.35,12.43,18.58,18.59,1.32,1.3,2.93,2.03,4.88,2.08,3.01.08,5.03-1.65,6.95-3.57,6.5-6.51,12.97-13.05,19.47-19.56,6.36-6.37,12.78-12.69,19.1-19.1,2.77-2.81,2.79-7.39.25-10.03-3.17-3.28-7.21-3.53-10.52-.51-2.4,2.19-4.69,4.51-6.99,6.8-8.91,8.87-17.81,17.74-26.67,26.66-1.11,1.12-1.78,1.08-2.86-.03-3.7-3.82-7.51-7.53-11.25-11.31-1.53-1.55-3.34-2.38-5.53-2.36Z"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </p>
                                                                        )}

                                                                        <p>
                                                                            {
                                                                                reserva.estado
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td className="hidden text-center lg:table-cell border-r-[3px] border-[#CCCCCC] 2xl:text-base text-sm">
                                                                    {formatearNumero(
                                                                        Number(
                                                                            reserva.costo_total
                                                                        )
                                                                    )}{" "}
                                                                    {
                                                                        reserva.tipo_moneda
                                                                    }
                                                                </td>
                                                                <td
                                                                    className={
                                                                        enabled
                                                                            ? " md:flex justify-center 2xl:py-5 py-3 "
                                                                            : " md:flex  justify-center pl-7 md:justify-end 2xl:py-5 py-3 md:pr-10 "
                                                                    }
                                                                >
                                                                    {enabled ? (
                                                                        <div className="flex justify-center">
                                                                            <button
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation();
                                                                                    handleDetalles(
                                                                                        reserva?.id
                                                                                    );
                                                                                }}
                                                                                title="Ver detalles"
                                                                                className="flex items-center justify-center w-8 h-8 bg-[#029BFC] px-1 py-1 mr-2 border rounded text-blue-blueFrom border-[#029BFC] group hover:bg-blue-blueFrom hover:white hover:scale-105"
                                                                            >
                                                                                <svg
                                                                                    className="w-5 h-5 "
                                                                                    id="Capa_2"
                                                                                    data-name="Capa 2"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 87.07 100"
                                                                                >
                                                                                    <g id="Testimonios">
                                                                                        <g>
                                                                                            <path
                                                                                                fill="white"
                                                                                                d="m.04,54.11c0-7.86-.1-15.72.03-23.57.14-8.34,3.89-14.75,10.99-19.13,3.44-2.12,6.37-.48,6.58,3.58.32,6.3,4.67,11.61,10.77,13.11,1.1.27,2.21.4,3.35.4,7.82-.01,15.64.01,23.46-.01,7.7-.02,13.8-5.86,14.2-13.53.15-2.97,1.71-4.6,4.24-4.46.68.04,1.29.29,1.86.63,7.26,4.3,11.34,10.66,11.44,19.09.18,16.02.1,32.05.03,48.08-.05,10.94-9.36,20.72-20.26,21.54-1.59.12-3.18.15-4.78.15-12.84,0-25.67-.01-38.51,0-6.84.01-12.74-2.23-17.44-7.28C2.14,88.56.1,83.6.06,77.92c-.07-7.94-.02-15.87-.02-23.81Zm60.99-2.84c.04-2.35-1.44-3.81-3.63-3.79-1.19.01-2.04.62-2.83,1.41-5.24,5.26-10.5,10.5-15.75,15.75q-1.19,1.19-2.41-.04c-1.21-1.21-2.39-2.45-3.65-3.61-1.6-1.48-3.72-1.48-5.14-.08-1.42,1.41-1.48,3.57.02,5.13,2.4,2.5,4.84,4.94,7.34,7.34,1.66,1.59,3.7,1.56,5.31-.05,6.56-6.52,13.09-13.07,19.63-19.62.71-.71,1.17-1.55,1.11-2.45Z"
                                                                                            />
                                                                                            <path
                                                                                                fill="white"
                                                                                                d="m43.45,23.72c-3.81,0-7.62,0-11.44,0-5.31-.01-9.29-3.6-9.72-8.9-.17-2.09-.24-4.21.07-6.29C23.13,3.47,26.84.13,31.95.07c7.7-.09,15.41-.09,23.11,0,5.61.06,9.46,3.99,9.69,9.71.07,1.82.08,3.65-.11,5.48-.51,4.8-4.43,8.4-9.28,8.46-.08,0-.16,0-.23,0-3.89,0-7.78,0-11.67,0Z"
                                                                                            />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                            {(tipo_usuario ===
                                                                                5 ||
                                                                                tipo_usuario ===
                                                                                    7) &&
                                                                                resenaEnFecha(
                                                                                    reserva.fecha_final
                                                                                ) &&
                                                                                reserva.estado ===
                                                                                    "Pago Completo" && (
                                                                                    <button
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation();
                                                                                            navigate(
                                                                                                `/dashboard-cliente/reviews/${reserva?.id}`,
                                                                                                {
                                                                                                    replace:
                                                                                                        false,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        title="Reseña"
                                                                                        className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-2 border rounded bg-[#029BFC] text-blue-blueFrom border-[#029BFC] hover:scale-105 group hover:bg-blue-blueFrom hover:text-white"
                                                                                    >
                                                                                        <svg
                                                                                            className="w-5 h-5 text-white"
                                                                                            viewBox="0 0 24 24"
                                                                                            fill="none"
                                                                                            stroke="currentColor"
                                                                                            strokeWidth="2"
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                        >
                                                                                            {" "}
                                                                                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                                                                                        </svg>
                                                                                    </button>
                                                                                )}
                                                                            <button
                                                                                className="flex items-center justify-center w-8 h-8 px-1 py-1 mx-2 border rounded bg-[#029BFC] text-blue-blueFrom border-[#029BFC] hover:scale-105 group hover:bg-blue-blueFrom hover:text-white"
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation();
                                                                                    handleDelete(
                                                                                        reserva.id
                                                                                    );
                                                                                }}
                                                                                title="Eliminar"
                                                                            >
                                                                                <svg
                                                                                    className="w-5 h-5 "
                                                                                    id="Capa_2"
                                                                                    data-name="Capa 2"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 91.81 100"
                                                                                >
                                                                                    <g id="Testimonios">
                                                                                        <path
                                                                                            fill="white"
                                                                                            d="m69.32,100H22.49c-.28-.09-.55-.19-.83-.27-5.22-1.49-8.55-4.86-9.37-10.22-.74-4.81-.65-9.72-.96-14.58-.26-3.99-.41-7.98-.63-11.97-.43-7.56-.88-15.11-1.32-22.67-.27-4.74-.54-9.47-.78-14.21-.04-.73-.35-.96-1.03-.94-1.2.03-2.41.04-3.61,0-2.56-.11-4.44-2.66-3.83-5.15.52-2.1,2.14-3.35,4.44-3.36,5.04-.01,10.08-.02,15.12,0,.89,0,1.24-.28,1.19-1.18-.06-1-.02-2.02-.01-3.02C20.88,5.58,26.42.03,33.28.02c8.39-.01,16.78-.04,25.17,0,5.22.03,8.92,2.59,11.31,7.15,1.24,2.37,1.24,4.94,1.2,7.52-.04,1.92,0,1.92,1.88,1.92,4.88,0,9.76-.02,14.63.01,2.49.02,4.38,1.96,4.34,4.34-.04,2.37-1.91,4.15-4.38,4.17-.81,0-1.63,0-2.44,0-1.82,0-1.75-.08-1.8,1.65-.02.49-.11.97-.13,1.46-.33,5.84-.65,11.68-.97,17.52-.39,7.1-.78,14.21-1.17,21.31-.39,7.04-.78,14.08-1.17,21.12-.2,3.61-1.72,6.55-4.42,8.92-1.73,1.52-3.81,2.33-6,2.88ZM45.9,16.62c5.13,0,10.27-.01,15.4.01.84,0,1.18-.27,1.14-1.13-.05-1,0-2.02-.02-3.02-.07-2.18-1.75-3.91-3.93-3.92-8.38-.03-16.77-.03-25.15,0-2.19,0-3.87,1.73-3.94,3.91-.03.94-.01,1.88,0,2.83,0,1.29.03,1.32,1.31,1.32,5.07,0,10.14,0,15.21,0Zm4.04,41.72c0-4,0-8,0-12,0-.45,0-.92-.11-1.35-.49-2.09-2.32-3.32-4.55-3.07-1.9.21-3.4,1.94-3.41,4.01-.01,8.19-.01,16.39,0,24.58,0,2.58,1.75,4.46,4.07,4.42,2.32-.04,3.99-1.86,4-4.4,0-4.06,0-8.13,0-12.19Zm16.61.07s0,0,0,0c0-4.26.03-8.52-.01-12.78-.02-1.92-1.53-3.52-3.36-3.72-2.16-.23-3.98.93-4.5,2.89-.16.6-.21,1.21-.21,1.83,0,4.62,0,9.23,0,13.85,0,3.35-.03,6.7,0,10.05.03,2.96,2.41,4.91,5.09,4.22,1.77-.45,2.96-1.94,2.97-3.86.03-4.16,0-8.32,0-12.48Zm-33.21.01c0-3.93,0-7.87,0-11.8,0-.62-.06-1.24-.22-1.83-.52-1.93-2.27-3.07-4.41-2.88-1.76.16-3.41,1.68-3.42,3.45-.05,8.68-.06,17.36,0,26.04.02,2.19,2.43,3.87,4.58,3.44,2.19-.44,3.43-2,3.45-4.42.02-4,0-8,0-12Z"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <div></div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <Spinner />
                                    )}
                                </div>

                                <div className="container flex items-center justify-between p-1 mx-auto">
                                    <Paginado
                                        elementsPerPage={RESERVAS_PER_PAGE}
                                        cantTotalElements={reservasTotal}
                                        pagination={pagination}
                                        actualPage={pageNumber}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };

    return (
        <div>
            <div className="absolute z-[999] flex items-center justify-center w-full text-center"></div>

            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Reservas">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 5 || tipo_usuario === 7 ? (
                <NavbarCliente>
                    {render()}
                    <NavbarMobileCliente />
                </NavbarCliente>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Reservas">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : null}
        </div>
    );
};
