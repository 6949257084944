import React, { useState, useEffect, useRef } from "react";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { ReactSortable } from "react-sortablejs";
import { Sortable, SortableEvent, Store } from "react-sortablejs";

import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ModalPerdido } from "./ModalPerdido";
import BotonInformacion from "../../../components/helpers/BotonInformacion";
import CRMModalEditEstado from "./CRMModalEditEstado";
import Accordion from "./Accordion";

import Export from "./Export";
import { useAppSelector } from "../../../hooks/hooks";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../../dashboardHotel/NavbarMobileHotel";

export interface ItemInterface {
    id: number;
    id_cliente: number;
    tipo: string;
    id_chat: number;
    id_pedidos: number;
    id_pedidos_habitaciones: any;
    id_hotel_habitaciones: number | null;
    proceso: string;
    id_casa: string;
    fecha_registro: string;
    motivo_perdida: string;
    chat: Chat;
    pedidos_habitacione: any;
    pedido: Pedido;
    usuario: Usuario;
    casa: Casa;
    hotel_habitacione: HotelHabitacione | null;
}
export interface HotelHabitacione {
    id: number;
    nombre: string;
    banner: string;
}

export interface Chat {
    id: number;
    chats_usuarios: ChatsUsuario[];
}

export interface ChatsUsuario {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    cliente: string;
    correo: string;
    usuario: Usuario;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

export interface Casa {
    id: number;
    nombre: string;
    banner: string;
}

export interface Pedido {
    id: number;
    id_cliente: number;
    id_casa: number;
    costo_alquiler: string;
    descuento_estadia: string;
    descuento_cupon: string;
    costo_servicios_adicionales: string;
    costo_total: string;
    pagado: string;
    saldo: string;
    habilitado: string;
    estado: string;
    fecha_inicio: string;
    fecha_final: string;
    tipo_moneda: string;
    fecha_registro: string;
    Cliente: Usuario;
}

export interface Estado {
    nombre: string;
    id: number;
}

export const CRM = () => {
    const [showButtons, setShowButtons] = useState(false);
    const [modaPerdido, setModalPerdido] = useState(0);
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const [estado, setEstado] = useState("Primer seguimiento");
    const [colum1, setColum1] = useState<ItemInterface[]>([]);
    const [colum2, setColum2] = useState<ItemInterface[]>([]);
    const [colum3, setColum3] = useState<ItemInterface[]>([]);
    const [colum4, setColum4] = useState<ItemInterface[]>([]);
    const [colum5, setColum5] = useState<ItemInterface[]>([]);
    const [colum6, setColum6] = useState<ItemInterface[]>([]);
    const [colum7, setColum7] = useState<ItemInterface[]>([]);

    const [estadoEdit, setEstadoEdit] = useState<Estado | null>(null);
    const [modalEliminar, setModalEliminar] = useState({
        visible: false,
        index: 0,
    });
    const [vista, setVista] = useState("cuadrada");
    const [input, setInput] = useState<string>("");
    const debounceInput = useRef<ReturnType<typeof setTimeout> | null>(null);
    const navigate = useNavigate();

    const getAll = async (cliente?: string) => {
        const json = await fetchAxiosToken({
            url: `/${
                tipo_usuario === 8 ? "crm_hoteles" : "crm"
            }/getAll/${tipo_usuario}?cliente=${cliente ?? ""}`,
            method: "get",
        });
        let con1: ItemInterface[] = [];
        let con2: ItemInterface[] = [];
        let con3: ItemInterface[] = [];
        let con4: ItemInterface[] = [];
        let con5: ItemInterface[] = [];
        let con6: ItemInterface[] = [];
        let con7: ItemInterface[] = [];

        console.log(json);

        if (json.length) {
            json.map((c: any) => {
                if (c.proceso === "Primer seguimiento") {
                    con1.push(c);
                    return;
                }
                if (c.proceso === "Segundo seguimiento") {
                    con2.push(c);
                    return;
                }
                if (c.proceso === "Propuesta enviada") {
                    con3.push(c);
                    return;
                }
                if (c.proceso === "Negociación") {
                    con4.push(c);
                    return;
                }
                if (c.proceso === "Ganados") {
                    con5.push(c);
                    return;
                }
                if (c.proceso === "Perdidos") {
                    con6.push(c);
                    return;
                }
                if (c.proceso === "Seguimiento a futuro") {
                    con7.push(c);
                    return;
                }
            });
        }
        setColum1(con1);
        setColum2(con2);
        setColum3(con3);
        setColum4(con4);
        setColum5(con5);
        setColum6(con6);
        setColum7(con7);
    };

    useEffect(() => {
        getAll();
    }, [estadoEdit]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);

        if (debounceInput.current) clearTimeout(debounceInput.current);
        if (e.target.value === "") {
            getAll();

            return;
        }
        debounceInput.current = setTimeout(() => {
            getAll(e.target.value);
        }, 1000);
    };

    const procesoText = {
        primero: "Esta es la primera Interaccion que tienes con el cliente",
        segundo:
            "Llevalo a esta lista cuando el cliente decidio que se lo contacte en otro momento",
        tercero:
            "Llevalo a esta lista cuando le enviaste al cliente una propuesta de reserva.",
        cuarto: "Llevalo a esta lista cuando el cliente esta interesado en la propuesta , esta contestando los mensajes o llamadas",
        quinto: "Llevalo a esta lista cuando la reserva se realizo con exito!",
        sexto: "Llevalo a esta lista cuando el cliente no esta interesado, o dejo de contestar llamadas o mensajes",
    };

    const handleSort = async (
        evt: SortableEvent,
        sortable: Sortable | null,
        store: Store
    ) => {
        const nuevaColumna = Number(evt.to?.id) - 1;
        const itemId = evt.item.id;
        let proceso = [
            "Primer seguimiento",
            "Segundo seguimiento",
            "Propuesta enviada",
            "Negociación",
            "Ganados",
            "Perdidos",
            "Seguimiento a futuro",
        ];
        console.log(proceso[nuevaColumna]);
        if (proceso[nuevaColumna] === "Perdidos") {
            setModalPerdido(Number(evt.item.id));
        } else {
            const json = await fetchAxiosToken({
                url: `/crm/updateProcess/${itemId}`,
                method: "put",
                body: {
                    proceso: proceso[nuevaColumna],
                    motivo_perdida: "",
                },
            });
            console.log(json);
        }
    };

    const handleEnable = (id: number) => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "¿Seguro que quieres eliminarlo",

            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "Cancelar",
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await fetchAxiosToken({
                        url: `/crm/deleteCrm/${id}`,
                        method: "delete",
                    });
                    getAll();
                    Swal.fire({
                        confirmButtonColor: "#029BFC",
                        iconColor: "#029BFC",
                        // position: "top-end",
                        icon: "success",
                        title: "Eliminado!",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    };

    const handleDetalle = (item: ItemInterface) => {
        if (tipo_usuario === 3 || tipo_usuario === 4) {
            navigate(`/dashboard/crm/${item.id}`);
        }

        if (tipo_usuario === 6) {
            navigate(`/dashboard-corredor/crm/${item.id}`);
        }
        if (tipo_usuario === 8) {
            navigate(`/dashboard-hotel/crm/${item.id}`);
        }
    };

    const itemCrm = (item: ItemInterface) => {
        return (
            <div
                className=" border h-auto px-3  rounded-lg mt-2  text-[#3C3C3C] shadow-md relative bg-[#FAFAFA] border-[#CCCCCC] hover:scale-105"
                key={item.id}
                id={`${item.id}`}>
                <div
                    onClick={() => handleDetalle(item)}
                    className="flex items-start py-3 mt-3 cursor-pointer">
                    <img
                        className="object-cover w-8 h-8 border rounded-full"
                        src={`${
                            process.env.REACT_APP_PROXY
                        }/usuarios/getImage/${
                            item.id_cliente !== 0
                                ? item.usuario?.avatar
                                : "sinImagen.png"
                        }`}
                        alt="avatar"
                    />
                    <div className="flex mx-1">
                        <div className="flex flex-col">
                            <div className="text-sm font-semibold">
                                {item.id_cliente !== 0
                                    ? `${item.usuario.nombre} ${item.usuario.apellido}`
                                    : `${item.chat.chats_usuarios[0].cliente}`}
                            </div>
                            <p>
                                <span className="text-xs text-gray-500">
                                    Tipo : # {item.tipo}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="border-[#179BD8] " />
                <div className="flex justify-center gap-1 mt-1 ">
                    {tipo_usuario === 8 ? (
                        item.hotel_habitacione ? (
                            <div className="h-3 w-3 rounded-full bg-[#179BD8]"></div>
                        ) : (
                            <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                        )
                    ) : (
                        <div className="h-3 w-3 rounded-full bg-[#179BD8]"></div>
                    )}

                    <span className="mb-1 text-xs text-gray-700">
                        {tipo_usuario === 8 ? (
                            item.hotel_habitacione ? (
                                <b>{item.hotel_habitacione?.nombre}</b>
                            ) : (
                                <b>Sin asignar</b>
                            )
                        ) : (
                            <b>Casa : {item.casa.nombre}</b>
                        )}
                    </span>
                </div>
                <div
                    onClick={() => handleEnable(item.id)}
                    className="absolute px-2 py-1 bg-red-100 rounded-md cursor-pointer top-1 right-2 hover:bg-red-200">
                    <div>
                        <p className="text-xs font-semibold text-white">X</p>
                    </div>
                </div>
            </div>
        );
    };

    const itemCrmMobile = (item: ItemInterface) => {
        return (
            <div
                className=" border  h-auto px-3  rounded-lg mt-2  text-[#3C3C3C] shadow-md relative  "
                key={item.id}
                id={`${item.id}`}>
                <div
                    onClick={() => handleDetalle(item)}
                    className="flex items-start pt-3 pb-1 cursor-pointer">
                    <img
                        className="object-cover w-8 h-8 border rounded-full"
                        src={`${
                            process.env.REACT_APP_PROXY
                        }/usuarios/getImage/${
                            item.id_cliente !== 0
                                ? item.usuario?.avatar
                                : "sinImagen.png"
                        }`}
                        alt="avatar"
                    />
                    <div className="flex mx-1">
                        <div className="flex flex-col">
                            <h1 className="">
                                {item.id_cliente !== 0
                                    ? `${item.usuario.nombre} ${item.usuario.apellido}`
                                    : `${item.chat.chats_usuarios[0].cliente}`}
                            </h1>
                            <p>
                                <span className="text-xs text-gray-400">
                                    Tipo : # {item.tipo}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center gap-1 mt-1 mb-3 ">
                    <div className="h-3 w-3 rounded-full bg-[#179BD8]"></div>
                    <span className="text-xs text-gray-700">
                        <b>Casa :</b> # {item.casa.nombre}
                    </span>
                </div>
                <div>
                    <span
                        onClick={() =>
                            setModalEliminar({
                                visible: true,
                                index:
                                    item.id === modalEliminar.index
                                        ? 0
                                        : item.id,
                            })
                        }
                        className={`absolute right-3 top-5  cursor-pointer `}
                        aria-label="close">
                        <svg
                            className="w-5 h-5 text-black"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            {" "}
                            <circle cx="12" cy="12" r="1" />{" "}
                            <circle cx="12" cy="5" r="1" />{" "}
                            <circle cx="12" cy="19" r="1" />
                        </svg>
                    </span>
                    <div
                        className={
                            modalEliminar.visible === true &&
                            modalEliminar.index === item.id
                                ? "absolute bg-white  right-1 top-10 border rounded-lg  p-2 z-10"
                                : "hidden"
                        }>
                        <p
                            onClick={() => (
                                setModalEliminar({
                                    visible: false,
                                    index: 0,
                                }),
                                setEstadoEdit({
                                    id: item.id,
                                    nombre: "Primer seguimiento",
                                })
                            )}
                            className="p-1 hover:bg-[#029BFC] hover:text-white w-full rounded-lg cursor-pointer">
                            Actualizar
                        </p>

                        <p
                            onClick={() => (
                                setModalEliminar({
                                    visible: false,
                                    index: 0,
                                }),
                                handleEnable(item.id)
                            )}
                            className="p-1 hover:bg-[#029BFC] hover:text-white w-full rounded-lg cursor-pointer">
                            Eliminar
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <div className="w-full mt-12">
                {estadoEdit && (
                    <CRMModalEditEstado
                        estado={estadoEdit}
                        setEstado={setEstadoEdit}
                    />
                )}
                {modaPerdido ? (
                    <ModalPerdido
                        modalVisibleNota={modaPerdido}
                        setModalVisibleNota={setModalPerdido}
                    />
                ) : null}

                <div className="flex flex-col items-center justify-between gap-2 px-3 mx-auto xs:flex-row lg:px-6">
                    <div className="flex items-center">
                        <div className="flex  p-1 mr-2 border-2 border-[#CCCCCC] rounded-lg appearance-none w-40 xs:w-52 lg:w-72 left-2 text-stone-900">
                            <button className="flex items-center justify-center px-2">
                                <svg
                                    className="w-6 h-6 text-[#808080]"
                                    fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                                </svg>
                            </button>{" "}
                            <input
                                onBlur={() => setInput("")}
                                value={input}
                                type="text"
                                onChange={handleInputChange}
                                className="w-full py-1 bg-transparent outline-none appearance-none"
                                placeholder="Buscar"
                            />
                        </div>
                        <div className="hidden gap-2 mr-6 lg:flex">
                            <span
                                onClick={() => setVista("cuadrada")}
                                className={`bg-[#E6E6E6] p-2 rounded-md cursor-pointer ${
                                    vista === "cuadrada" ? "scale-95" : ""
                                }`}>
                                <svg
                                    className="w-6 h-6"
                                    id="Capa_2"
                                    data-name="Capa 2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 100 99.97">
                                    <g id="Testimonios">
                                        <g>
                                            <path
                                                fill="#808080"
                                                d="m64.78,99.97c-3.78-.84-6.64-2.87-8.33-6.41-.71-1.49-1.07-3.07-1.07-4.71-.01-7.38-.04-14.76,0-22.14.03-6.29,5.03-11.23,11.34-11.25,7.31-.03,14.63-.03,21.94,0,6.38.02,11.29,4.98,11.3,11.34,0,7.22-.1,14.44.04,21.65.1,5.28-3.48,10.14-8.72,11.35-.19.04-.37.12-.55.18h-25.95Z"
                                            />
                                            <path
                                                fill="#808080"
                                                d="m0,64.85c.83-3.78,2.87-6.64,6.41-8.33,1.49-.71,3.07-1.07,4.71-1.07,7.38,0,14.76-.03,22.14,0,6.23.03,11.22,5.02,11.25,11.25.04,7.28.02,14.57,0,21.85,0,5.62-3.53,10.02-9.04,11.3-.13.03-.25.08-.37.12H9.17c-1.57-.44-3.09-.96-4.45-1.91C2.17,96.27.72,93.78,0,90.8v-25.95Z"
                                            />
                                            <path
                                                fill="#808080"
                                                d="m0,9.24c.64-2.62,1.82-4.93,3.95-6.67C5.96.94,8.24.02,10.84.01c7.54,0,15.08-.02,22.62,0,5.97.02,10.98,4.92,11.04,10.9.07,7.54.07,15.08,0,22.62-.05,6.05-5.08,11.01-11.14,11.05-7.44.04-14.89.03-22.33,0-4.91-.02-9.28-3.36-10.67-8.08-.13-.44-.24-.88-.36-1.31,0-8.65,0-17.3,0-25.95Z"
                                            />
                                            <path
                                                fill="#808080"
                                                d="m77.71,0c3.77,0,7.54-.02,11.31,0,5.97.04,10.92,4.99,10.93,10.97.02,7.51.02,15.02,0,22.53-.01,5.97-4.9,11.01-10.87,11.07-7.57.08-15.15.08-22.72,0-5.96-.06-10.92-5.04-10.97-11.01-.07-7.51-.07-15.02,0-22.53C55.44,4.9,60.44.04,66.6,0c3.71-.02,7.41,0,11.12,0Z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span
                                onClick={() => setVista("linea")}
                                className={`bg-[#E6E6E6] p-2 rounded-md cursor-pointer ${
                                    vista !== "cuadrada" ? "scale-95" : ""
                                }`}>
                                <svg
                                    className="w-6 h-6"
                                    id="Capa_2"
                                    data-name="Capa 2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 100 75.96">
                                    <g id="Testimonios">
                                        <g>
                                            <path
                                                fill="#808080"
                                                d="m0,8.51C.66,6,1.87,3.86,3.95,2.2c4.09-3.27,10.3-2.86,13.89,1,2.97,3.19,3.76,7.68,2.02,11.48-1.8,3.92-5.65,6.3-10,6.17-4.49-.13-8.48-3.42-9.68-7.97C.13,12.66.06,12.44,0,12.22,0,10.98,0,9.75,0,8.51Z"
                                            />
                                            <path
                                                fill="#808080"
                                                d="m0,63.72c.71-3.17,2.41-5.65,5.23-7.29,4.13-2.41,9.75-1.48,12.8,2.09,3.45,4.04,3.65,9.51.5,13.4-2.63,3.24-6.04,4.6-10.1,3.84-4.19-.78-6.88-3.42-8.15-7.5-.09-.28-.18-.55-.27-.83C0,66.19,0,64.96,0,63.72Z"
                                            />
                                            <path
                                                fill="#808080"
                                                d="m0,36.02c.67-2.43,1.82-4.54,3.83-6.17,5.85-4.75,14.85-1.79,16.6,5.57,1.07,4.48-.32,8.26-4.04,10.98-3.46,2.53-7.24,2.69-11.02.71-2.48-1.3-4.15-3.38-5.01-6.08-.12-.37-.24-.74-.36-1.11,0-1.3,0-2.6,0-3.9Z"
                                            />
                                            <path
                                                fill="#808080"
                                                d="m65.35,45.01c-8.87,0-17.75,0-26.62,0-1.96,0-3.79-.41-5.35-1.72-2.05-1.72-2.96-4.66-2.19-7.24.83-2.76,3.11-4.68,5.88-4.99.91-.1,1.81-.13,2.72-.13,17.42,0,34.84,0,52.26,0,2.56,0,4.83.66,6.45,2.79,1.61,2.12,1.93,4.47.92,6.9-1,2.44-2.89,3.85-5.52,4.25-.9.14-1.81.15-2.72.15-8.61,0-17.23,0-25.84,0Z"
                                            />
                                            <path
                                                fill="#808080"
                                                d="m65.35,17.36c-9.2,0-18.39.02-27.59,0-2.94,0-5.49-1.87-6.42-4.55-1.61-4.66,1.57-9.18,6.6-9.4.16,0,.32,0,.49,0,18,0,36.01,0,54.01,0,2.99,0,5.37,1.15,6.78,3.91,2.3,4.51-.93,9.99-5.99,10.03-9.29.06-18.59.02-27.88.02Z"
                                            />
                                            <path
                                                fill="#808080"
                                                d="m65.55,58.58c9.23,0,18.46-.02,27.69.01,2.88,0,5.46,1.97,6.34,4.65,1.53,4.68-1.72,9.16-6.76,9.29-1.36.04-2.73,0-4.09,0-16.74,0-33.47,0-50.21,0-2.99,0-5.36-1.11-6.83-3.84-2.34-4.35.85-10.05,5.78-10.09,9.36-.08,18.72-.02,28.08-.02h0Z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>

                        <button className=" relative w-11 h-11 flex items-center justify-center px-1 py-1  border-2 border-[#CCCCCC] rounded-lg group hover:bg-[#CCCCCC]  lg:hidden">
                            <svg
                                onClick={() => setShowButtons((prev) => !prev)}
                                className="w-6 h-6 text-[#808080] group-hover:text-white"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round">
                                {" "}
                                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                                <circle cx="14" cy="6" r="2" />{" "}
                                <line x1="4" y1="6" x2="12" y2="6" />{" "}
                                <line x1="16" y1="6" x2="20" y2="6" />{" "}
                                <circle cx="8" cy="12" r="2" />{" "}
                                <line x1="4" y1="12" x2="6" y2="12" />{" "}
                                <line x1="10" y1="12" x2="20" y2="12" />{" "}
                                <circle cx="17" cy="18" r="2" />{" "}
                                <line x1="4" y1="18" x2="15" y2="18" />{" "}
                                <line x1="19" y1="18" x2="20" y2="18" />
                            </svg>

                            {showButtons && (
                                <div className="absolute top-8 xs:top-[50px]  w-56 right-1  pt-2 border rounded-lg bg-white z-30 2xl:text-base text-sm">
                                    <div
                                        onClick={() => {
                                            setShowButtons((prev) => !prev);
                                            setEstado("Primer seguimiento");
                                        }}
                                        className="flex items-center justify-start w-48 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#029BFC]
                                hover:text-white w-full rounded-lg text-sm">
                                            Primer seguimiento
                                        </p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setShowButtons((prev) => !prev);
                                            setEstado("Segundo seguimiento");
                                        }}
                                        className="flex items-center justify-start w-48 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#029BFC]
                                hover:text-white w-full rounded-lg">
                                            Segundo seguimiento
                                        </p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setShowButtons((prev) => !prev);
                                            setEstado("Propuesta enviada");
                                        }}
                                        className="flex items-center justify-start w-48 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#029BFC]
                                hover:text-white w-full rounded-lg">
                                            Propuesta enviada
                                        </p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setShowButtons((prev) => !prev);
                                            setEstado("Negociación");
                                        }}
                                        className="flex items-center justify-start w-48 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#029BFC]
                                hover:text-white w-full rounded-lg">
                                            Negociación
                                        </p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setShowButtons((prev) => !prev);
                                            setEstado("Ganados");
                                        }}
                                        className="flex items-center justify-start w-48 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#029BFC]
                                hover:text-white w-full rounded-lg">
                                            Ganados
                                        </p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setShowButtons((prev) => !prev);
                                            setEstado("Perdidos");
                                        }}
                                        className="flex items-center justify-start w-48 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#029BFC]
                                hover:text-white w-full rounded-lg">
                                            Perdidos
                                        </p>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setShowButtons((prev) => !prev);
                                            setEstado("Seguimiento a futuro");
                                        }}
                                        className="flex items-center justify-start w-48 p-1 mx-auto rounded-lg cursor-pointer h-11">
                                        <p
                                            className="p-2 hover:bg-[#029BFC]
                                hover:text-white w-full rounded-lg">
                                            Seguimiento a futuro
                                        </p>
                                    </div>
                                </div>
                            )}
                        </button>
                    </div>

                    <Export
                        colum1={colum1}
                        colum2={colum2}
                        colum3={colum3}
                        colum4={colum4}
                        colum5={colum5}
                        colum6={colum6}
                        colum7={colum7}
                    />
                </div>
                {vista === "linea" && (
                    <Accordion
                        column1={colum1}
                        column2={colum2}
                        column3={colum3}
                        column4={colum4}
                        column5={colum5}
                        column6={colum6}
                        column7={colum7}
                        handleEnable={handleEnable}
                        modalEliminar={modalEliminar}
                        setModalEliminar={setModalEliminar}
                        setEstadoEdit={setEstadoEdit}
                    />
                )}
                <div className="w-11/12 mx-auto lg:hidden ">
                    <div className="pb-2 mt-3 border-b ">
                        <div className="relative z-10 flex items-center justify-center mx-auto w-52">
                            <p className="mr-2 font-bold">{estado}</p>

                            <BotonInformacion
                                text={
                                    estado === "Primer seguimiento"
                                        ? procesoText.primero
                                        : estado === "Segundo seguimiento"
                                        ? procesoText.segundo
                                        : estado === "Propuesta enviada"
                                        ? procesoText.tercero
                                        : estado === "Negociación"
                                        ? procesoText.cuarto
                                        : estado === "Ganados"
                                        ? procesoText.quinto
                                        : estado === "Perdidos"
                                        ? procesoText.sexto
                                        : ""
                                }
                            />
                        </div>
                    </div>
                    {estado === "Primer seguimiento"
                        ? colum1.map((item, i) => itemCrmMobile(item))
                        : estado === "Segundo seguimiento"
                        ? colum2.map((item, i) => itemCrmMobile(item))
                        : estado === "Propuesta enviada"
                        ? colum3.map((item, i) => itemCrmMobile(item))
                        : estado === "Negociación"
                        ? colum4.map((item, i) => itemCrmMobile(item))
                        : estado === "Ganados"
                        ? colum5.map((item, i) => itemCrmMobile(item))
                        : estado === "Perdidos"
                        ? colum6.map((item, i) => itemCrmMobile(item))
                        : colum7.map((item, i) => itemCrmMobile(item))}
                </div>

                {vista === "cuadrada" && (
                    <div className={"mt-6 "}>
                        <div className="hidden w-full pl-2 mt-2 mr-2 text-base text-center max-w-7xl lg:flex justify-evenly 2xl:text-lg ">
                            <div className="w-2/6 font-bold ">
                                <div className="relative z-10 flex items-center justify-center text-sm">
                                    <p>Primer seguimiento</p>
                                    <BotonInformacion
                                        widthHeight={"w-4 h-4"}
                                        text="Esta es la primera Interacción que tienes con el cliente"
                                    />
                                </div>
                            </div>
                            <div className="w-2/6 font-bold">
                                <div className="relative z-10 flex items-center justify-center text-sm">
                                    <p>Segundo seguimiento</p>
                                    <BotonInformacion
                                        widthHeight={"w-4 h-4"}
                                        text="Llevalo a esta lista cuando el cliente decidió que se lo contacte en otro momento"
                                    />
                                </div>
                            </div>
                            <div className="w-2/6 font-bold">
                                <div className="relative z-10 flex items-center justify-center text-sm">
                                    <p>Propuesta enviada</p>
                                    <BotonInformacion
                                        widthHeight={"w-4 h-4"}
                                        text="Llevalo a esta lista cuando le enviaste al cliente una propuesta de reserva."
                                    />
                                </div>
                            </div>
                            <div className="w-2/6 font-bold">
                                <div className="relative z-10 flex items-center justify-center text-sm">
                                    <p>Negociación</p>
                                    <BotonInformacion
                                        widthHeight={"w-4 h-4"}
                                        text="Llevalo a esta lista cuando el cliente esta interesado en la propuesta , esta contestando los mensajes o llamadas"
                                    />
                                </div>
                            </div>
                            <div className="w-2/6 font-bold">
                                <div className="relative z-10 flex items-center justify-center text-sm">
                                    <p>Ganados</p>
                                    <BotonInformacion
                                        widthHeight={"w-4 h-4"}
                                        text="Llevalo a esta lista cuando la reserva se realizo con exito!"
                                    />
                                </div>
                            </div>
                            <div className="w-2/6 font-bold">
                                <div className="relative z-10 flex items-center justify-center text-sm">
                                    <p>Perdidos</p>
                                    <BotonInformacion
                                        widthHeight={"w-4 h-4"}
                                        text="Llevalo a esta lista cuando el cliente no esta interesado, o dejo de contestar llamadas o mensajes"
                                    />
                                </div>
                            </div>
                            <div className="w-2/6 font-bold">
                                <div className="relative z-10 flex items-center justify-center text-sm">
                                    <p>Seguimiento a futuro</p>
                                    <BotonInformacion
                                        widthHeight={"w-4 h-4"}
                                        text="Llevalo a esta lista cuando el cliente no esta interesado por el momento mas quieras volver a intentarlo luego"
                                    />
                                </div>
                            </div>
                        </div>
                        <hr className="border-b-2 border-[#CCCCCC] my-2 mx-4"></hr>
                    </div>
                )}

                {vista === "cuadrada" && (
                    <div className="justify-between hidden w-full pb-10 mr-2 max-w-7xl lg:flex">
                        <ReactSortable
                            id="1"
                            className="w-1/6 min-h-[73vh] px-2"
                            list={colum1}
                            setList={setColum1}
                            group={"empresas"}
                            dragClass="draggable"
                            animation={100}
                            multiDrag={true}
                            onSort={handleSort}>
                            {colum1?.map((item) => itemCrm(item))}
                        </ReactSortable>
                        <ReactSortable
                            className="w-1/6 min-h-[73vh] px-2 border-r-2 border-[#CCCCCC]"
                            id="2"
                            list={colum2}
                            setList={setColum2}
                            group={"empresas"}
                            dragClass="draggable"
                            animation={100}
                            onSort={handleSort}
                            multiDrag={true}>
                            {colum2?.map((item) => itemCrm(item))}
                        </ReactSortable>
                        <ReactSortable
                            id="3"
                            className="w-1/6 min-h-[73vh] px-2"
                            list={colum3}
                            setList={setColum3}
                            group={"empresas"}
                            dragClass="draggable"
                            animation={100}
                            onSort={handleSort}
                            multiDrag={true}>
                            {colum3?.map((item) => itemCrm(item))}
                        </ReactSortable>
                        <ReactSortable
                            id="4"
                            className="w-1/6 min-h-[73vh] px-2  border-r-2 border-[#CCCCCC]"
                            list={colum4}
                            setList={setColum4}
                            group={"empresas"}
                            dragClass="draggable"
                            animation={100}
                            onSort={handleSort}
                            multiDrag={true}>
                            {colum4?.map((item) => itemCrm(item))}
                        </ReactSortable>
                        <ReactSortable
                            id="5"
                            className="w-1/6 min-h-[73vh] px-2 "
                            list={colum5}
                            setList={setColum5}
                            group={"empresas"}
                            dragClass="draggable"
                            animation={100}
                            onSort={handleSort}
                            multiDrag={true}>
                            {colum5?.map((item) => itemCrm(item))}
                        </ReactSortable>
                        <ReactSortable
                            id="6"
                            className="w-1/6 min-h-[73vh] px-2 "
                            list={colum6}
                            setList={setColum6}
                            group={"empresas"}
                            dragClass="draggable"
                            animation={100}
                            onSort={handleSort}
                            multiDrag={true}>
                            {colum6?.map((item) => itemCrm(item))}
                        </ReactSortable>
                        <ReactSortable
                            id="7"
                            className="w-1/6 min-h-[73vh] px-2  border-r-2 border-[#CCCCCC]"
                            list={colum7}
                            setList={setColum7}
                            group={"empresas"}
                            dragClass="draggable"
                            animation={100}
                            onSort={handleSort}
                            multiDrag={true}>
                            {colum7?.map((item) => itemCrm(item))}
                        </ReactSortable>
                    </div>
                )}
            </div>
        );
    };
    return (
        <div>
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="CRM">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="CRM">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : tipo_usuario === 8 ? (
                <NavbarHotel titulo="CRM">
                    {render()}
                    <NavbarMobileHotel />
                </NavbarHotel>
            ) : null}
        </div>
    );
};
