import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import imagen1 from "./../../img/editar_landing/editar 1.webp";
import imagen2 from "./../../img/editar_landing/editar 2.webp";
import imagen3 from "./../../img/editar_landing/editar-3.webp";

import Swal from "sweetalert2";
import { useAppSelector } from "../../hooks/hooks";
import { Navbar } from "../dashboardPropietarios/Navbar";
import BotonInformacion from "../../components/helpers/BotonInformacion";
import { NavbarMobile } from "../dashboardPropietarios/NavbarMobile";
import { ButtonRegresar } from "../../components/ButtonRegresar";
import { NavbarBroker } from "../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../dashboardHotel/NavbarMobileHotel";

interface INITAL_STATE_TYPE {
    [key: string]: string;
}

export const ComoEditarLanding = () => {
    const usuarioLogeado = useAppSelector((state) => state.usuario_logeado);
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);
    const casa = useAppSelector((state) => state.landing_house);
    console.log(casa);

    const [saving, setSaving] = useState<boolean>(false);
    const [toggleButton, setToggleButton] = useState<boolean | null>(null);
    const [userInfo, setUserInfo] = useState<INITAL_STATE_TYPE>({
        id_casa: "",
        publica: "",
        privada: "",
    });

    const casas = [
        {
            tipo: "Casa del mes",
            id: 1,
            img: imagen1,
            url: "/alojamientos/38746/casa-reflejo",
            txt: ``,
        },
        {
            tipo: "Casa del mes",
            id: 2,
            img: imagen2,
            url: "/alojamientos/38746/casa-reflejo",
            txt: "Al hacerlo te aparceran los botones para editar asi como se muestra en la imagen",
        },
        {
            tipo: "Casa del mes",
            id: 3,
            img: imagen3,
            url: "/alojamientos/38746/casa-reflejo",
            txt: "Todo esto puedes editar desde la landing",
        },
    ];

    const INPUTS = [
        {
            input_id: 0,
            label: "Public Key",
            nombre: "publica",
            type: "text",
        },
        {
            input_id: 1,
            label: "Access Token",
            nombre: "privada",
            type: "text",
        },
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const render = () => {
        return (
            <div>
                <div className="w-full">
                    <div>
                        {true && (
                            <div
                                className={`mx-auto    mt-10 w-80 sm:w-[500px] lg:w-[820px] md818:w-[780px] xl:w-[1100px]`}>
                                <ButtonRegresar
                                    // url={`/dashboard/onboarding/alojamiento`}
                                    className=""
                                    arrowColor="black"
                                    textColor="black"
                                />
                                <p className="mb-4 font-serif font-bold text-[#4D4D4D] text-center">
                                    ¿Cómo editar mi página?
                                </p>
                                <div className="mx-auto ">
                                    <Swiper
                                        className="max-w-3xl mx-auto xs:w-11/12 rounded-2xl"
                                        modules={[
                                            Navigation,
                                            Pagination,
                                            Autoplay,
                                        ]}
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        rewind={true}
                                        // autoplay={{ delay: 10000 }}
                                        loop={true}
                                        // navigation
                                        pagination={{ clickable: true }}>
                                        {casas.map((casa) => (
                                            <SwiperSlide
                                                key={casa.id}
                                                // className="flex flex-col w-full md:px-1 lg:px-1 rounded-xl "
                                                className="flex flex-col items-center justify-center select-none rounded-xl bg-gradient-to-bl from-white to-blue-bluecorner ">
                                                <div
                                                    className=""
                                                    // onClick={() => {
                                                    //     window.open(`${casa.url}`, "_blank");
                                                    // }}
                                                >
                                                    <div className="flex justify-center text-center mb-2 text-[#4D4D4D]">
                                                        {casa.id === 1 ? (
                                                            <p
                                                                onClick={() => {
                                                                    window.open(
                                                                        `/`,
                                                                        "_blank"
                                                                    );
                                                                }}
                                                                className="">
                                                                1. Para editarla
                                                                debes ir a la
                                                                landing, puedes
                                                                hacerlo desde{" "}
                                                                <span className="text-[#029BFC] font-bold cursor-pointer ">
                                                                    AQUI
                                                                </span>{" "}
                                                                y selecciona
                                                                vista
                                                                administrador{" "}
                                                            </p>
                                                        ) : (
                                                            <p className="mx-4 mb-2">
                                                                {casa.id}.{" "}
                                                                {casa.txt}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <img
                                                        width="276px"
                                                        height="326px"
                                                        alt="Imagen no encontrada"
                                                        src={casa.img}
                                                        // className="object-cover w-full sm:h-[600px] h-[270px]  rounded-xl"
                                                        className="object-contain w-full mx-auto transition-all duration-200 ease-out h-60 rounded-xl"
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Editar landing">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Editar landing">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : tipo_usuario === 8 ? (
        <NavbarHotel titulo="Editar landing">
            {render()}
            <NavbarMobileHotel />
        </NavbarHotel>
    ) : null;
};
