import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { getHouse } from "../../../redux/reducers/onboardingReducer";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { useParams } from "react-router-dom";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";
import { NavbarHotel } from "../../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../../dashboardHotel/NavbarMobileHotel";

export const MensajeDeBienvenida = () => {
    const { idCasa } = useParams();
    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );
    const { tipo_usuario } = useAppSelector((state) => state.usuario_logeado);

    const usuario = useAppSelector((state) => state.usuario_logeado);
    const casa = useAppSelector((state) => state.landing_house.house);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    console.log(casa);
    const [mensaje, setMensaje] = useState("");

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    useEffect(() => {
        setMensaje(casa.mensaje_bienvenida);
    }, [casa]);

    const putMensaje = async () => {
        setLoading(true);
        if (mensaje === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });
            return;
        }
        try {
            const data = await fetchAxiosToken({
                url: `/casa/putMensajeBienvenida/${casa.id}`,
                method: "put",
                body: { mensaje_bienvenida: mensaje },
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Actualizado con exito",

                icon: "success",
                confirmButtonText: "Ok",
            });

            dispatchDominio();

            setLoading(false);
        } catch (error: any) {
            console.log(error.response);
        }
    };

    const render = () => {
        return (
            <div className="flex">
                <div className="w-full mt-2 2xl:mt-10">
                    <div className="grid w-full max-w-5xl gap-6 mx-auto md:grid-cols-2 right-2 ">
                        <div className=" 2xl:mt-10 mt-5 text-[#4D4D4D]">
                            <ButtonRegresar
                                // url={`/dashboard/onboarding/alojamiento`}
                                className="-ml-2 "
                                arrowColor="black"
                                textColor="black"
                            />
                            <p className="text-xl font-bold 2xl:text-2xl ">
                                Mensaje de bienvenida
                            </p>
                            <p className="mt-6">
                                Brinde una experiencia acogedora desde el primer
                                contacto.
                            </p>
                        </div>
                        <div>
                            <div className="mt-10 border rounded-lg shadow-lg">
                                <div className="flex flex-col items-center justify-end w-full max-w-3xl rounded-lg">
                                    <div className="bg-[#1C4CBB] w-full 2xl:p-9 p-5 mb-4 rounded-t-lg">
                                        <p className="text-2xl font-bold text-center text-white 2xl:text-3xl">
                                            Bienvenid@
                                        </p>
                                    </div>
                                    <div className="flex flex-col justify-center w-full h-64 gap-2">
                                        <div className="w-full">
                                            <img
                                                className="max-w-[280px] w-36 mx-auto  sm:w-1/3"
                                                src="https://ci5.googleusercontent.com/proxy/D0rye-gZ2XqOTw93u_L-4l_ISPJ6sQLlGWn8W2QdgXigs70jpWIhl_J-MzBtb_tgVS2g40DB0DAF_QX48nMw2z2TWMDecmAN1pxOjjpnqwo4esmIeSwtkzhBMIMTL5mq8BX0ZO5bi-FnS_jLJoe-E9QUZ13hMJUpX3zQv34xV93QNQ=s0-d-e1-ft#https://hpy.stripocdn.email/content/guids/CABINET_9ca3362f6e3ce2c3ea60da8fe4f8a850/images/78491618321704551.png"
                                                alt="Imagen"
                                            />
                                        </div>
                                        <div className="px-4 mb-4 text-center">
                                            <p className="text-[#444746] font-bold 2xl:text-xl text-lg  ">
                                                ¡Hola {usuario.nombre}!, gracias
                                                por registrarte.
                                            </p>
                                            <p className="text-[#777777] font-medium   mt-2 2xl:text-base text-sm">
                                                {mensaje}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 border rounded-lg shadow-lg 2xl:mt-8 ">
                                <div className="flex flex-col items-center justify-end w-full max-w-3xl px-4 rounded-lg">
                                    <div className="w-full max-w-3xl mt-2 mb-4 ">
                                        <p className="2xl:mt-4 mt-2 2xl:text-base text-sm font-sans font-semibold text-[#4D4D4D] ml-2">
                                            Edita tu mensaje
                                        </p>
                                        <textarea
                                            className="w-full px-2 mt-1 border-2  border-[#CCCCCC] rounded-md 2xl:text-base text-sm"
                                            value={mensaje}
                                            onChange={(e) =>
                                                setMensaje(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="flex justify-center mb-6">
                                        <button
                                            disabled={loading}
                                            onClick={() => putMensaje()}
                                            className="py-2 px-6 ml-2 uppercase font-medium text-white rounded-lg bg-[#029BFC] active:translate-y-1 disabled:translate-y-0 disabled:from-slate-400 disabled:to-gray-400 disabled:cursor-default 2xl:text-base text-sm">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return tipo_usuario === 3 || tipo_usuario === 4 ? (
        <Navbar titulo="Mensaje de bienvenida">
            {render()}
            <NavbarMobile />
        </Navbar>
    ) : tipo_usuario === 6 ? (
        <NavbarBroker titulo="Mensaje de bienvenida">
            {render()}
            <NavbarMobileBroker />
        </NavbarBroker>
    ) : tipo_usuario === 8 ? (
        <NavbarHotel titulo="Mensaje de bienvenida">
            {render()}
            <NavbarMobileHotel />
        </NavbarHotel>
    ) : null;
};
