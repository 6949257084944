import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Spinner from "../../../components/helpers/Spinner";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import { Navbar } from "../Navbar";
import { NavbarMobile } from "../NavbarMobile";
import { ChatBody } from "./ChatBody";
import { NavbarHotel } from "../../dashboardHotel/NavbarHotel";
import { NavbarMobileHotel } from "../../dashboardHotel/NavbarMobileHotel";
import { NavbarBroker } from "../../dashboardBroker/NavbarBroker";
import { NavbarMobileBroker } from "../../dashboardBroker/NavbarMobileBroker";

export interface Chat {
    id: number;
    title: string;
    id_respuestas_bot: string;
    last_message: LastMessage;
    messageNotSeen: number;
    estado: string;
    usersChat: UsersChat[];
    id_cliente: number;
}

export interface LastMessage {
    time: string;
    message: string;
    usuario: string;
    avatar: string;
}

export interface UsersChat {
    id: number;
    id_chat: number;
    id_usuario: number;
    tipo: string;
    cliente: string;
    correo: string;
    usuario: Usuario;
}

export interface Usuario {
    id: number;
    nombre: string;
    apellido: string;
    avatar: string;
}

interface Props {
    hotel?: boolean;
}

export const ChatPropietario = ({ hotel = false }: Props) => {
    const { idChat } = useParams();
    const navigate = useNavigate();
    const [chats, setChats] = useState<Chat[]>([]);
    const [chatSelected, setChatSelected] = useState<Chat | null>(null);
    const { id: id_usuario, tipo_usuario } = useAppSelector(
        (state) => state.usuario_logeado
    );
    const casa = useAppSelector((state) => state.landing_house.house);
    const [loading, setLoading] = useState<boolean>(false);

    const getChats = () => {
        fetchAxiosToken({
            url: `/chat/getAll/${id_usuario}`,
        })
            .then((res) => {
                console.log("chats", res);
                if (chatSelected === null) {
                    res.map((r: any) => {
                        if (r.id === Number(idChat)) {
                            setChatSelected(r);
                            return;
                        }
                    });
                }
                setChats(res);
                setLoading(true);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getChats();
        window.scrollTo(0, 0);
    }, []);

    const createNewChat = async (titulo: string) => {
        try {
            const response = await fetchAxiosToken({
                url: `/chat`,
                method: "post",
                body: {
                    id_cliente: id_usuario,
                    titulo: titulo,
                },
            });

            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `Chat iniciado correctamente`,
                icon: "success",
                showConfirmButton: true,
                confirmButtonText: "Aceptar",
            });
        } catch (error) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: `Hubo un error al iniciar el chat`,
                icon: "error",
                showConfirmButton: true,
                confirmButtonText: "Aceptar",
            });
        }
    };

    const handleNewChat = () => {
        Swal.fire({
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            title: "Ingresa el motivo de la conversación",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Crear chat",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                createNewChat(result.value);
            }
        });
    };
    const fechaUltimoMensaje = (ultimoMensaje: string) => {
        let nuevoFormato =
            ultimoMensaje &&
            new Date(ultimoMensaje).toLocaleDateString("es-ES", {
                month: "long",
                day: "numeric",
            });
        return nuevoFormato;
    };

    const render = () => {
        return (
            <div className="flex w-full px-4 m-2 mt-12 ">
                {chats.length > 0 || loading ? (
                    <div
                        className={`${
                            chatSelected ? "hidden" : ""
                        }  h-[85vh] overflow-auto lg:flex min-w-[19rem] flex flex-col justify-between  border-2 rounded-l-lg border-[#CCCCCC]`}
                    >
                        <div>
                            <div className="flex items-center justify-between p-2 px-3 ">
                                <span className="font-medium">Chats</span>

                                <button
                                    title={
                                        casa.plan !== 3
                                            ? "Disponible para el plan premium"
                                            : "Configurar chatbot"
                                    }
                                    disabled={casa.plan !== 3}
                                    onClick={() =>
                                        navigate(`/dashboard/chatbot`)
                                    }
                                    className="font-medium text-sm px-3 py-3 relative rounded-md text-white bg-[#029BFC] select-none cursor-pointer hover:bg-blue-400 disabled:cursor-not-allowed disabled:bg-slate-400"
                                >
                                    <p className="flex items-center gap-1">
                                        <span>
                                            <svg
                                                className="w-5 h-5 text-white"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                {" "}
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                />{" "}
                                                <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />{" "}
                                                <circle cx="12" cy="12" r="3" />
                                            </svg>
                                        </span>{" "}
                                        Chatbot
                                    </p>
                                    {casa.plan !== 3 && (
                                        <span className="text-[10px]  -top-[2px] right-3  text-yellow-300  rounded-md absolute">
                                            Premium
                                        </span>
                                    )}
                                </button>
                            </div>
                            {chats?.map((chat) => (
                                <div key={chat.id}>
                                    <div
                                        onClick={() => setChatSelected(chat)}
                                        className={`${
                                            chatSelected?.id === chat.id
                                                ? "bg-slate-100"
                                                : ""
                                        } cursor-pointer flex flex-col p-2`}
                                    >
                                        <div className="flex items-center gap-2">
                                            <img
                                                className="w-8 h-8 border rounded-full"
                                                src={`${process.env.REACT_APP_PROXY}/usuarios/getImage/${chat.last_message.avatar}`}
                                                alt="avatar del usuario"
                                            />
                                            <div className="w-full">
                                                <div className="flex justify-between ">
                                                    <span className="text-sm font-medium 2xl:text-base">
                                                        {chat.title}
                                                    </span>
                                                    {chat.messageNotSeen ? (
                                                        <div className="h-5 w-5 pb-0.5  text-white flex justify-center items-center rounded-md bg-[#029BFC]">
                                                            <span className="text-xs font-medium">
                                                                {
                                                                    chat.messageNotSeen
                                                                }
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <p className="text-sm text-gray-500">{`${chat.last_message.usuario}`}</p>
                                            </div>
                                        </div>
                                        {chat.last_message.message ? (
                                            <span className="flex justify-between mt-1 text-sm text-gray-500">
                                                <span>
                                                    Mensaje:{" "}
                                                    {chat.last_message.message
                                                        .length > 14
                                                        ? `${chat.last_message.message.slice(
                                                              0,
                                                              12
                                                          )}...`
                                                        : chat.last_message
                                                              .message}
                                                </span>
                                                <span>
                                                    {/* {fechaUltimoMensaje(chat.last_message.time)} */}
                                                    {chat.last_message.time}
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="mt-1 text-sm text-gray-500 ">
                                                Aún no has iniciado esta
                                                conversación
                                            </span>
                                        )}
                                        <div className="flex gap-1 text-xs">
                                            <span className="text-gray-500">
                                                Estado:
                                            </span>
                                            <span
                                                className={`${
                                                    chat.estado === "Abierto"
                                                        ? "text-green-500"
                                                        : "text-red-500"
                                                } w-max font-medium`}
                                            >
                                                {chat.estado}
                                            </span>
                                        </div>
                                    </div>
                                    <hr className=" border border-t-[#CCCCCC] w-11/12 mx-auto" />
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center justify-center gap-2 p-3 mt-2 text-sm text-center text-[#029BFC]">
                            <svg
                                className="w-4 h-4"
                                id="Capa_2"
                                data-name="Capa 2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 75.12 100"
                            >
                                <g id="Testimonios">
                                    <path
                                        fill="#029BFC"
                                        d="m64.7,100H10.46c-.25-.07-.49-.16-.74-.22C4.11,98.5-.06,93.13,0,87.21c.13-12.39.04-24.78.04-37.16,0-4.16,1.72-7.5,5.02-9.96.97-.72,1.24-1.45,1.25-2.59.01-3.54-.28-7.09.24-10.62C9.23,8.96,26.12-2.92,43.87.63c14.34,2.86,24.95,15.73,25,30.34,0,2.34.04,4.68-.02,7.02-.02.84.25,1.35.94,1.85,3.49,2.53,5.33,5.95,5.32,10.31,0,12.52,0,25.04,0,37.56,0,2.66-.82,5.05-2.37,7.19-2,2.77-4.76,4.35-8.05,5.11Zm-27.19-62.51c3.77,0,7.54,0,11.31,0,2.15,0,4.29,0,6.44,0,.55,0,.97-.09.97-.78-.01-2.7.16-5.4-.24-8.08-1.44-9.77-10.29-16.73-20.05-15.83-7.88.72-14.58,6.39-16.45,14.08-.75,3.07-.55,6.2-.58,9.31,0,1.28.02,1.3,1.32,1.3,5.75,0,11.51,0,17.26,0Zm0,46.66c2.13,0,4.27-.04,6.4.02,1,.02,1.35-.35,1.33-1.34-.04-2.74,0-5.48-.02-8.21,0-.77.18-1.38.72-1.96,2.46-2.68,3.46-5.83,2.95-9.45-.72-5.11-5.04-9.84-11.45-9.72-4.83.09-8.48,2.49-10.36,7.03-1.83,4.42-1.03,8.52,2.16,12.1.52.59.75,1.18.74,1.95-.03,2.74.02,5.48-.03,8.21-.02,1.07.38,1.42,1.42,1.39,2.05-.06,4.11-.02,6.16-.02Z"
                                    />
                                </g>
                            </svg>
                            <span className="">
                                Solo tú y el cliente pueden ver esto
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-center w-full h-96">
                        <Spinner />
                    </div>
                )}
                <ChatBody
                    chat={chatSelected}
                    setChat={setChatSelected}
                    getChats={getChats}
                />
            </div>
        );
    };

    return (
        <div className="w-full">
            {tipo_usuario === 3 || tipo_usuario === 4 ? (
                <Navbar titulo="Chat">
                    {render()}
                    <NavbarMobile />
                </Navbar>
            ) : tipo_usuario === 6 ? (
                <NavbarBroker titulo="Chat">
                    {render()}
                    <NavbarMobileBroker />
                </NavbarBroker>
            ) : tipo_usuario === 8 ? (
                <NavbarHotel titulo="Chat">
                    {render()}
                    <NavbarMobileHotel />
                </NavbarHotel>
            ) : null}
        </div>
    );
};
