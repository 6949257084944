import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ButtonRegresar } from "../../../components/ButtonRegresar";
import { fetchAxiosToken } from "../../../helpers/fetchAxiosToken";
import { useAppSelector } from "../../../hooks/hooks";
import capacidad from "../../../img/onboarding/capacidad.jpg";
import { useDispatchDominio } from "../../../hooks/useDispatchDominio";
import "../../../assets/css/styles_input_number.css";

import { loadStripe } from "@stripe/stripe-js";
import { useDispatchDominioCorredor } from "../../../hooks/useDispatchDominioCorredor";

let DATA = {
    starter: {
        una_casa: 19,
        casa_adicional: 7,
    },

    standard: {
        una_casa: 39,
        casa_adicional: 10,
    },
    premium: {
        una_casa: 79,
        casa_adicional: 15,
    },
};

interface Input {
    grupo_trabajo: string;
    token: string;

    idCasa: number | null;
}

export const PagarSuscripcionCorredor = () => {
    const { idCasa } = useParams();
    const { id: id_casa, plan } = useAppSelector(
        (state) => state.landing_house.house
    );

    const { id: id_usuario, grupo_trabajo } = useAppSelector(
        (state) => state.usuario_logeado
    );

    const stripePromise = loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
    );

    const Dos_casas = {
        plan: "2 a 4 casas",
        costo: 169,
        tipo_pago: "Mensual",
        anual: 2028,
        descuento: 406,
        price_id_mensual: process.env.REACT_APP_PLAN_DOS_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_DOS_ANUAL,
    };

    const Cinco_casas = {
        plan: "5 a 9 casas",
        costo: 299,
        tipo_pago: "Mensual",
        anual: 3588,
        descuento: 718,
        price_id_mensual: process.env.REACT_APP_PLAN_CINCO_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_CINCO_ANUAL,
    };

    const Diez_casas = {
        plan: "10 a 15 casas",
        costo: 449,
        tipo_pago: "Mensual",
        anual: 5388,
        descuento: 1078,
        price_id_mensual: process.env.REACT_APP_PLAN_DIEZ_MENSUAL,
        price_id_anual: process.env.REACT_APP_PLAN_DIEZ_ANUAL,
    };

    const PremiumEUR = {
        plan: "Premium",
        costo: 179.0,
        tipo_pago: "Anual",
        anual: 1749.0,
        descuento: 399,
    };

    const dispatchCorredor = useDispatchDominioCorredor();

    const dispatchDominio = useDispatchDominio(
        idCasa && Number(idCasa) > 0 ? idCasa : undefined
    );

    const [currency, setCurrency] = useState("USD");
    const [activeButton, setActiveButton] = useState(null);

    const [suscripcion, setSuscripcion] = useState("0");

    const [Plan, setPlan] = useState("Mensual");
    const [meses, setMeses] = useState<number>(1);
    const [numberHouse, setNumberHouse] = useState<number>(1);
    const [moneda, setMoneda] = useState("$");
    const [tipoPlan, setTipoPlan] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (idCasa && Number(idCasa) > 0) dispatchDominio();
    }, []);

    const handleButtonClick = (button: any) => {
        setActiveButton(button);
        if (button === "+") {
            setNumberHouse(Number(numberHouse) + 1);
        }

        if (button === "-") {
            if (numberHouse === 1) return;
            setNumberHouse(Number(numberHouse) - 1);
        }
    };

    const handlePrueba = async () => {
        // Mostrar alerta de confirmación
        const result = await Swal.fire({
            title: "¿Estás seguro?",
            text: "¿Deseas habilitar tu prueba gratuita de 14 días?",
            icon: "warning",
            confirmButtonColor: "#029BFC",
            iconColor: "#029BFC",
            showCancelButton: true,
            confirmButtonText: "Sí, habilitar",
            cancelButtonText: "Cancelar",
        });

        // Si el usuario confirma
        if (result.isConfirmed) {
            try {
                const response = await fetchAxiosToken({
                    url: `/suscripcion_pagos/postPrueba`,
                    method: "post",
                    body: {
                        id_casa: 0,
                        id_corredor: id_usuario,
                        unidades: numberHouse,
                    },
                });

                // Mostrar alerta de éxito
                await Swal.fire({
                    title: "¡Habilitado!",
                    text: "Tu prueba de 14 días ha sido habilitada.",
                    icon: "success",
                });
                dispatchCorredor();
                navigate(`/dashboard-corredor/onboarding/redes`);
            } catch (error) {
                // Mostrar alerta de error
                Swal.fire({
                    title: "Error",
                    text: "Ocurrió un error al habilitar la prueba.",
                    icon: "error",
                });
            }
        }
    };

    const handlePlan = (planString: string) => {
        setPlan(planString);
    };

    const getStarter = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi-Anual") {
            porcentaje = 0.7;
        }
        let price =
            numberHouse < 2
                ? DATA.starter.una_casa
                : Number(DATA.starter.una_casa) +
                  Number(DATA.starter.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (porcentaje) {
            price = Math.ceil(price * porcentaje);
        }

        return price;
    };

    const getStandard = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi-Anual") {
            porcentaje = 0.7;
        }
        let price =
            numberHouse < 2
                ? DATA.standard.una_casa
                : Number(DATA.standard.una_casa) +
                  Number(DATA.standard.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (porcentaje) {
            price = Math.ceil(price * porcentaje);
        }

        return price;
    };

    const getPremium = ({
        tipo,
        descuento = false,
    }: {
        tipo: string;
        descuento?: boolean;
    }) => {
        let porcentaje = 0;

        if (tipo === "Semestral") {
            porcentaje = 0.9;
        }

        if (tipo === "Anual") {
            porcentaje = 0.8;
        }

        if (tipo === "Bi-Anual") {
            porcentaje = 0.7;
        }

        let price =
            numberHouse < 2
                ? DATA.premium.una_casa
                : Number(DATA.premium.una_casa) +
                  Number(DATA.premium.casa_adicional) *
                      (Number(numberHouse) - 1);
        if (porcentaje) {
            price = Math.ceil(price * porcentaje);
        }

        return price;
    };

    const handleRegister = async (id_suscripcion: string) => {
        let costo =
            id_suscripcion === "1"
                ? getStarter({
                      tipo: Plan,
                  })
                : id_suscripcion === "2"
                ? getStandard({
                      tipo: Plan,
                  })
                : getPremium({
                      tipo: Plan,
                  });

        console.log("costo", costo);

        let plan =
            id_suscripcion === "1"
                ? "Starter"
                : id_suscripcion === "2"
                ? "Stándar"
                : "Premium";
        //"month", "semiannual", "year", "biannual"
        let tipo_pago_str =
            Plan === "Mensual"
                ? "month"
                : Plan === "Semestral"
                ? "semiannual"
                : Plan === "Anual"
                ? "year"
                : "biannual";

        let datos = {
            costo:
                costo *
                (Plan === "Anual"
                    ? 12
                    : Plan === "Semestral"
                    ? 6
                    : Plan === "Bi-Anual"
                    ? 24
                    : 1),
            id_corredor: id_usuario,
            plan: plan,
            nombre_plan: `BluStay ${plan} Corredor ${numberHouse} unidades`,
            tipo_moneda: currency,
            tipo_pago: tipo_pago_str,
            id_casa: 0,

            user: {
                subdomain: {
                    name: grupo_trabajo,
                },
                id: id_usuario,
            },
            cancel_url: "/dashboard-corredor/onboarding/suscripcion",
            success_url: "/dashboard-corredor/onboarding/redes",
            domain: true,
            unidades: numberHouse,
        };
        console.log("datos", datos);
        try {
            const response = await fetchAxiosToken({
                url: `/suscripcion_pagos/stripe-dynamic/`,
                method: "post",
                body: datos,
            });

            const urlStripe = response.sessionUrl;
            window.location.href = urlStripe;
        } catch (error) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                icon: "error",
                title: "Hubo un error contactarse con soporte",

                showConfirmButton: true,
            });
        }
    };

    const handleChange = (e: any) => {
        const value = e.target.value;

        // Verifica que el valor sea un número y que sea al menos 1
        if (/^\d*$/.test(value) && (value === "" || parseInt(value) >= 1)) {
            setNumberHouse(value); // Solo actualiza si pasa las validaciones
        }
    };

    return (
        <div className="flex flex-col h-screen grid-cols-2 xl:grid">
            <div className="block h-56 md:h-1/3 xl:h-auto bg-black/80">
                <div className="relative flex flex-col items-center justify-center h-full text-center xl:h-screen">
                    <img
                        src={capacidad}
                        alt="capacidad"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute top-0 w-full h-full bg-black opacity-40 "></div>
                    <div className="absolute top-0 flex flex-col items-center justify-center w-2/3 h-full 0">
                        <div className="text-2xl font-bold text-center text-white md:text-4xl 2xl:text-6xl ">
                            Elige tu plan
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col justify-center h-screen gap-2 p-3 text-center text-black bg-white lg:overflow-y-auto">
                <ButtonRegresar
                    url={`/dashboard-corredor/onboarding/datos`}
                    className="absolute top-10 left-10"
                    arrowColor="black"
                    textColor="black"
                />

                <div className="flex flex-col items-start justify-start w-10/12 mx-auto mt-96 pt-96 lg:pt-2 2xl:mt-64 ">
                    <div className="text-[#179BD8] text-center text-[15px] md:text-[20px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] font-semibold ">
                        Nuestros Planes
                    </div>
                    <div className="flex items-center justify-around w-11/12 mx-auto mt-6 ">
                        <div className="flex flex-col items-center justify-center gap-4">
                            <div className="text-lg font-semibold">
                                Elije el número de unidades
                            </div>
                            <div className="flex items-center justify-center gap-2 p-2 border-2 border-gray-300 rounded-full">
                                <div
                                    className={`border-2 ${
                                        activeButton === "-"
                                            ? "border-gray-500 bg-gray-200"
                                            : "border-gray-100"
                                    }  rounded-full h-10 w-10 flex justify-center items-center cursor-pointer`}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Evita que el clic en este botón cierre el estado activo
                                        handleButtonClick("-");
                                        // Aquí puedes manejar la lógica para restar
                                    }}>
                                    <span className="mb-1 text-2xl font-semibold leading-none">
                                        -
                                    </span>
                                </div>
                                <div className="flex items-center justify-center h-10 border-2 border-gray-300 rounded-md">
                                    <input
                                        type="text"
                                        className="w-16 text-xl text-center "
                                        value={numberHouse}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div
                                    className={`border-2 ${
                                        activeButton === "+"
                                            ? "border-gray-500 bg-gray-200"
                                            : "border-gray-100"
                                    }  rounded-full h-10 w-10 flex justify-center items-center cursor-pointer`}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Evita que el clic en este botón cierre el estado activo
                                        handleButtonClick("+");
                                        // Aquí puedes manejar la lógica para restar
                                    }}>
                                    <span className="mb-1 text-2xl font-semibold leading-none">
                                        +
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-center gap-4">
                            <div className="text-lg font-semibold">
                                Elije como quieres pagar
                            </div>
                            <div className="flex items-center justify-center px-1 py-1 border-2 w-96 rounded-3xl">
                                <div
                                    className={`${
                                        Plan === "Bi-Anual"
                                            ? "bg-[#0E1928] text-[#C0D345]"
                                            : " text-[#0E1928]"
                                    }   text-center  rounded-l-3xl cursor-pointer w-1/2 h-full `}
                                    onClick={() => {
                                        handlePlan("Bi-Anual");
                                        setMeses(24);
                                    }}>
                                    <p className="text-lg ">Bi Anual</p>
                                    <p
                                        className={`text-[8px] w-full  mb-1 ${
                                            Plan === "Bi-Anual"
                                                ? "text-white"
                                                : " text-[#0E1928]"
                                        }`}>
                                        - 30%
                                    </p>
                                </div>
                                <div
                                    className={`${
                                        Plan === "Anual"
                                            ? "bg-[#0E1928] text-[#C0D345]"
                                            : " text-[#0E1928]"
                                    }   text-center  cursor-pointer w-1/2 h-full `}
                                    onClick={() => {
                                        handlePlan("Anual");
                                        setMeses(12);
                                    }}>
                                    <p className="text-lg ">Anual</p>
                                    <p
                                        className={`text-[8px] w-full  mb-1 ${
                                            Plan === "Anual"
                                                ? "text-white"
                                                : " text-[#0E1928]"
                                        }`}>
                                        - 20%
                                    </p>
                                </div>

                                <div
                                    className={`${
                                        Plan === "Semestral"
                                            ? "bg-[#0E1928] text-[#C0D345]"
                                            : " text-[#0E1928]"
                                    }   text-center  cursor-pointer w-1/2 h-full `}
                                    onClick={() => {
                                        handlePlan("Semestral");
                                        setMeses(6);
                                    }}>
                                    <p className="text-lg ">Semestral</p>
                                    <p
                                        className={`text-[8px] w-full  mb-1 ${
                                            Plan === "Semestral"
                                                ? "text-white"
                                                : " text-[#0E1928]"
                                        }`}>
                                        - 10%
                                    </p>
                                </div>
                                <div
                                    className={` ${
                                        Plan === "Mensual"
                                            ? "bg-[#0E1928] text-[#C0D345]"
                                            : " text-[#0E1928]"
                                    }   px-4 text-lg   rounded-r-3xl cursor-pointer w-1/2 h-full py-2 text-center`}
                                    onClick={() => {
                                        handlePlan("Mensual");
                                        setMeses(1);
                                    }}>
                                    <p className="text-lg ">Mensual</p>
                                    <p
                                        className={`text-[8px] w-full  mb-1 ${
                                            Plan === "Mensual"
                                                ? "text-white"
                                                : " text-[#0E1928]"
                                        }`}></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center w-full gap-6 px-5 mx-auto mt-5 lg:flex-row ">
                        <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                            <div
                                className={`text-[#0E1928] text-md md:text-lg xl:text-xl  ${"mt-5"} `}>
                                Starter
                            </div>

                            <div className="flex flex-col gap-8 md:gap-0">
                                <div className="flex flex-col ">
                                    <div className="flex items-end text-[#0E1928] text-lg md:text-xl xl:text-3xl   mt-2">
                                        {Plan === "Mensual" ? (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl font-bold">
                                                    {numberHouse < 2
                                                        ? DATA.starter.una_casa
                                                        : Number(
                                                              DATA.starter
                                                                  .una_casa
                                                          ) +
                                                          Number(
                                                              DATA.starter
                                                                  .casa_adicional
                                                          ) *
                                                              (Number(
                                                                  numberHouse
                                                              ) -
                                                                  1)}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl text-[#179BD8] font-bold">
                                                    {" "}
                                                    {getStarter({
                                                        tipo: Plan,
                                                        descuento: true,
                                                    })}
                                                </p>
                                            </div>
                                        )}

                                        <div className="text-base font-semibold xl:text-lg">
                                            /mes
                                        </div>
                                    </div>
                                    <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                        {moneda}
                                        {Plan === "Mensual"
                                            ? getStarter({
                                                  tipo: Plan,
                                              })
                                            : getStarter({
                                                  tipo: Plan,
                                                  descuento: true,
                                              }) * 12}{" "}
                                        al año.
                                        {Plan === "Semestral" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 10%
                                            </div>
                                        )}
                                        {Plan === "Anual" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 20%
                                            </div>
                                        )}
                                        {Plan === "Bi-Anual" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 30%
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col ">
                                    <div
                                        className={`text-[#0E1928] text-sm  mt-4 mb-0`}>
                                        <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                            <li className="mb-1">
                                                Landing personalizada
                                            </li>
                                            <li className="mb-1">
                                                Dominio para tu página
                                            </li>
                                            <li className="mb-1">
                                                Reseñas de clientes
                                            </li>
                                            <li className="mb-1">
                                                Historial de reservas
                                            </li>
                                            <li className="mb-1">
                                                Pago por transferencia
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleRegister("1")}
                                    className="bg-[#179BD8] py-1 px-8 my-8 text-sm md:text-base  text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-11/12 text-center mx-auto">
                                    <p className="w-full text-center">
                                        COMPRAR
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col  rounded-2xl  bg-[#0E1928] text-white border-solid px-5   h-auto w-[330px] mb-4 ">
                            <div
                                className={` text-md md:text-lg xl:text-xl  flex items-center justify-between w-full  ${"mt-5"} `}>
                                <p>Estándar</p>{" "}
                                <p className="text-xs text-[#C0D345] bg-[#C0D345]/10 rounded-3xl px-3 py-1">
                                    El más seleccionado
                                </p>
                            </div>

                            <div className="flex flex-col gap-8 md:gap-0">
                                <div className="flex flex-col ">
                                    <div className="flex items-end text-[#0E1928] text-lg md:text-xl xl:text-3xl   mt-2">
                                        {Plan === "Mensual" ? (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl font-bold text-[#C0D345]">
                                                    {" "}
                                                    {numberHouse < 2
                                                        ? DATA.standard.una_casa
                                                        : Number(
                                                              DATA.standard
                                                                  .una_casa
                                                          ) +
                                                          Number(
                                                              DATA.standard
                                                                  .casa_adicional
                                                          ) *
                                                              (Number(
                                                                  numberHouse
                                                              ) -
                                                                  1)}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-5xl text-[#C0D345] font-bold">
                                                    {getStandard({
                                                        tipo: Plan,
                                                        descuento: true,
                                                    })}
                                                </p>
                                            </div>
                                        )}

                                        <div className="text-base font-semibold xl:text-lg">
                                            /mes
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-1 mt-2 text-sm ">
                                        {moneda}
                                        {Plan === "Mensual"
                                            ? getStandard({
                                                  tipo: Plan,
                                              })
                                            : getStandard({
                                                  tipo: Plan,
                                                  descuento: true,
                                              }) * 12}{" "}
                                        al año.
                                        {Plan === "Semestral" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 10%
                                            </div>
                                        )}
                                        {Plan === "Anual" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 20%
                                            </div>
                                        )}
                                        {Plan === "Bi-Anual" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 10%
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col ">
                                    <div className={` text-sm  mt-4 mb-0`}>
                                        <ul className="ml-6 text-xs list-disc list-outside marker:text-white md:text-base">
                                            <li className="mb-1">
                                                Módulo de CRM (Casa, whatsapp)
                                            </li>
                                            <li className="mb-1">
                                                Modificación precios / noche
                                                casas
                                            </li>
                                            <li className="mb-1">
                                                Creación de cupones
                                            </li>
                                            <li className="mb-1">
                                                Chat y gestión de clientes
                                            </li>
                                            <li className="mb-1">
                                                Resumen de estadísticas
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleRegister("2")}
                                    className="bg-[#C0D345] py-1 px-8 my-8 text-sm md:text-base text-[#0E1928] font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-10/12 text-center mx-auto">
                                    <p className="w-full text-center">
                                        COMPRAR
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col  rounded-2xl  bg-[#0E1928]/5 border-solid px-5   h-auto w-[330px] mb-4">
                            <div
                                className={`text-[#0E1928] text-md md:text-lg xl:text-xl  ${"mt-5"} `}>
                                Premium
                            </div>

                            <div className="flex flex-col gap-8 md:gap-0">
                                <div className="flex flex-col ">
                                    <div className="flex items-end text-[#0E1928] text-lg md:text-xl xl:text-3xl   mt-2">
                                        {Plan === "Mensual" ? (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl font-bold">
                                                    {" "}
                                                    {numberHouse < 2
                                                        ? DATA.premium.una_casa
                                                        : Number(
                                                              DATA.premium
                                                                  .una_casa
                                                          ) +
                                                          Number(
                                                              DATA.premium
                                                                  .casa_adicional
                                                          ) *
                                                              (Number(
                                                                  numberHouse
                                                              ) -
                                                                  1)}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="flex">
                                                <p className="text-base font-bold">
                                                    {moneda}
                                                </p>
                                                <p className="text-4xl text-[#179BD8] font-bold">
                                                    {" "}
                                                    {getPremium({
                                                        tipo: Plan,
                                                        descuento: true,
                                                    })}
                                                </p>
                                            </div>
                                        )}

                                        <div className="text-base font-semibold xl:text-lg">
                                            /mes
                                        </div>
                                    </div>
                                    <div className="text-[#0E1928]  text-sm  flex items-center gap-1 mt-2">
                                        {moneda}
                                        {Plan === "Mensual"
                                            ? getPremium({
                                                  tipo: Plan,
                                              })
                                            : getPremium({
                                                  tipo: Plan,
                                                  descuento: true,
                                              }) * 12}{" "}
                                        al año.
                                        {Plan === "Semestral" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 10%
                                            </div>
                                        )}
                                        {Plan === "Anual" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 20%
                                            </div>
                                        )}
                                        {Plan === "Bi-Anual" && (
                                            <div className="text-[#179BD8] text-xs md:text-sm  font-bold">
                                                Ahorras 10%
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-col ">
                                    <div
                                        className={`text-[#0E1928] text-sm  mt-4 mb-0`}>
                                        <ul className="marker:text-[#0E1928] text-xs md:text-base list-outside list-disc ml-6">
                                            <li className="mb-1">
                                                Chatbot interno
                                            </li>
                                            <li className="mb-1">
                                                Integración google calendar
                                            </li>
                                            <li className="mb-1">
                                                Plantillas para contratos por
                                                Alquiler
                                            </li>
                                            <li className="mb-1">
                                                Chatroom Whatsapp
                                            </li>
                                            <li className="mb-1">
                                                Integración Airbnb / Booking -
                                                Calendario
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    onClick={() => handleRegister("3")}
                                    className="bg-[#179BD8] py-1 px-8 my-8 text-sm md:text-base  text-white font-bold mt-5 md:mt-7 rounded-3xl cursor-pointer flex w-11/12 text-center mx-auto">
                                    <p className="w-full text-center">
                                        COMPRAR
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={handlePrueba}
                        className="rounded-2xl  bg-[#0E1928]/5 p-5 w-full cursor-pointer hover:scale-105">
                        <div className="flex items-center justify-between">
                            <div className="font-semibold">
                                Prueba gratuita de 14 días
                                <div className="text-xs">
                                    En tu prueba gratuita tendrás todas las
                                    características del plan Estándar
                                </div>
                            </div>
                            <div className="px-5  py-3 bg-[#0E1928] text-white rounded-2xl">
                                Estándar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
