import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import getEveryDayBetween2Dates from "../../helpers/getEveryDayBetween2Dates";
import { infoCasa } from "./typeCasa";
import "react-calendar/dist/Calendar.css";
import "../../index.css";
import { BodyReserva } from "./BodyReserva";
import { getEachDayPrice } from "../../helpers/getEachDayPrice";
import { transformDatesToSlash } from "../../helpers/transformDatesToSlash";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
    set_dates_selected,
    set_error_message,
    set_precio_alquiler,
    set_garantia,
} from "../../redux/reducers/precioReservaReducer";
import { calcularMinimaDisponibilidad } from "./calcularMinimaDisponibilidad";
import { ModalEditEstadiaMinima } from "./ModalEditEstadiaMinima";
import { getHouse } from "../../redux/reducers/onboardingReducer";
import { BodyReservaPromo } from "../../pages/dashboardPropietarios/chat/ModalReserva/BodyReservaPromo";
import { io, Socket } from "socket.io-client";
import { fetchAxiosBlueCornerNoToken } from "../../helpers/fetchAxiosBlueCornerNoToken";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";

interface Props {
    className?: string;
    toggleButton: boolean;
    promo?: boolean;
    datosPromo?: DatosPromo;
    setModalVisible?: (v: boolean) => void;
    socket?: Socket;
}

interface DatosPromo {
    id_chat: number;
    id_usuario: number;
    id_cliente: number;
}

export interface Reservas {
    id_pedido: number;
    fecha: string;
}

export const CalendarioReserva = ({
    className,
    promo,
    toggleButton,
    datosPromo,
    setModalVisible,
    socket,
}: Props) => {
    const infoCasaRedux = useAppSelector((state) => state.landing_house.house);
    const dispatch = useAppDispatch();

    const [infoCasa, setInfoCasa] = useState<infoCasa | null>(null);
    const [datesSelected, setDatesSelected] = useState<{
        fecha_inicial: Date;
        fecha_final: Date;
    } | null>(null);
    const [modalEditEstadiaMinima, setModalEditEstadiaMinima] = useState(false);
    const [arrayFechasBluecorner, setArrayFechasBluecorner] = useState<
        string[]
    >([]);
    const [arrayPedidosBluecorner, setArrayPedidosBluecorner] = useState<
        string[]
    >([]);
    const [arrayDiasBluecorner, setArrayDiasBluecorner] = useState<number[]>(
        []
    );
    const [arrayMesesBluecorner, setArrayMesesBluecorner] = useState<number[]>(
        []
    );
    const [arrayFechasAirBnb, setArrayFechasAirBnb] = useState<string[]>([]);
    const [arrayFechasBooking, setArrayFechasBooking] = useState<string[]>([]);
    const [arrayFechasTripadvisor, setArrayFechasTripadvisor] = useState<
        string[]
    >([]);

    const bluecorner = async () => {
        if (
            infoCasaRedux.clave ||
            infoCasaRedux.id_airbnb ||
            infoCasaRedux.id_booking ||
            infoCasaRedux.clave ||
            infoCasaRedux.id_tripadvisor
        ) {
            // if (false) {
            const dataExterna = await fetchAxiosToken({
                url: `/casa/getAvailabilityFromApiExternals/${infoCasaRedux.id}`,
            });

            setArrayDiasBluecorner(dataExterna.disponibilidadBC.arrayDias);
            setArrayMesesBluecorner(dataExterna.disponibilidadBC.arrayMeses);

            setArrayFechasBluecorner(dataExterna.disponibilidadBC.arrayFechas);
            setArrayPedidosBluecorner(dataExterna.disponibilidadBC.pedidos);
            setArrayFechasAirBnb(dataExterna.disponibilidadAirBnb.arrayFechas);
            setArrayFechasBooking(
                dataExterna.disponibilidadBooking.arrayFechas
            );
            setArrayFechasTripadvisor(
                dataExterna?.disponibilidadTripAdvisor?.arrayFechas
            );
            if (infoCasaRedux.estadia_minima) {
                setInfoCasa(infoCasaRedux);
            }
        } else {
            if (infoCasaRedux.estadia_minima) {
                setInfoCasa(infoCasaRedux);
            }
        }
    };

    useEffect(() => {
        bluecorner();
    }, [infoCasaRedux]);

    const onChangeCalendar = (twoDates: Date[]) => {
        dispatch(set_error_message(""));
        setDatesSelected({
            fecha_inicial: twoDates[0],
            fecha_final: twoDates[1],
        });
        const all_dates_selected = getEveryDayBetween2Dates({
            array_fechas: twoDates,
        });
        const all_dates_selectedTotal = getEveryDayBetween2Dates({
            array_fechas: twoDates,
        });
        const allDaysSelected = getEveryDayBetween2Dates({
            array_fechas: twoDates,
        }).map((date) => date.getDay());

        const diasDeLaSemana = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miercoles",
            "Jueves",
            "Viernes",
            "Sabado",
        ];

        let precioVariblePorSemana = 0;

        // Precios variables por semana

        if (
            infoCasaRedux.precios_variables_semanas.length &&
            all_dates_selectedTotal.length < 8
        ) {
            const primerDia =
                diasDeLaSemana[new Date(all_dates_selectedTotal[0]).getDay()];
            const ultimoDia =
                diasDeLaSemana[
                    new Date(
                        all_dates_selectedTotal[
                            all_dates_selectedTotal.length - 1
                        ]
                    ).getDay()
                ];

            infoCasaRedux.precios_variables_semanas.map((regla) => {
                if (
                    regla.tipo === "Siempre" &&
                    regla.dia_inicio === primerDia &&
                    regla.dia_final === ultimoDia
                ) {
                    return (precioVariblePorSemana = Number(regla.precio));
                }

                if (
                    regla.tipo === "Fechas" &&
                    regla.dia_inicio === primerDia &&
                    regla.dia_final === ultimoDia
                ) {
                    let reglaFechaInicio = new Date(
                        regla.fecha_inicio + "T00:00:00"
                    );
                    let reglaFechaFinal = new Date(
                        regla.fecha_final + "T00:00:00"
                    );

                    if (
                        all_dates_selectedTotal[0] >= reglaFechaInicio &&
                        all_dates_selectedTotal[
                            all_dates_selectedTotal.length - 1
                        ] <= reglaFechaFinal
                    ) {
                        return (precioVariblePorSemana = Number(regla.precio));
                    }
                }
            });
        }

        console.log("precioVariblePorSemana", precioVariblePorSemana);

        // Precios variables por semana

        // Pop() para eliminar el ultimo date del array y solo quedarme con las noches de reserva, solo si seleccionó mas de un dia sino me quedaria sin días

        if (infoCasa?.precio_base) {
            const total_alquiler = all_dates_selected.reduce(
                (acc, currentDate) => {
                    return (acc += getEachDayPrice({
                        current_day: currentDate,
                        house_variable_prices: infoCasa.precios_variables,
                        base_price: Number(infoCasa.precio_base),
                    }));
                },
                0
            );

            dispatch(
                set_precio_alquiler(
                    precioVariblePorSemana > 0
                        ? precioVariblePorSemana
                        : total_alquiler
                )
            );

            const isAvailable = calcularMinimaDisponibilidad({
                minimaDisponibilidad: infoCasa.casa_estadias_minimas,
                //Tomo el primer y el ultimo día reservado
                selected_dates: [
                    all_dates_selected[0],
                    all_dates_selected[all_dates_selected.length - 1],
                ],
                estadia_maxima: infoCasa.estadia_maxima,
                estadia_minima: infoCasa.estadia_minima,
            });

            if (all_dates_selected.length > 1) {
                all_dates_selected.pop();
            }

            dispatch(
                set_dates_selected({
                    initial_date: twoDates[0],
                    final_date: twoDates[1],
                    allDates: all_dates_selected,
                })
            );

            const arrayFechas = infoCasa.disponibilidades.arrayFechas.map(
                (fecha) => {
                    const transformFecha = transformDatesToSlash({
                        date: fecha.fecha,
                    });

                    return new Date(transformFecha).getTime();
                }
            );

            const fechasBluecorner = arrayFechasBluecorner.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });
            const fechasAirBnb = arrayFechasAirBnb.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });

            const fechasTripadvisor = arrayFechasTripadvisor.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });

            const fechasBooking = arrayFechasBooking.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });
            const pedidosBluecorner = arrayPedidosBluecorner.map((fecha) => {
                const transformFecha = transformDatesToSlash({
                    date: fecha,
                });

                return new Date(transformFecha).getTime();
            });

            const arrayFechasPedidos = infoCasa.pedidos.arrayFechasPedido.map(
                (fecha: Reservas) => {
                    const transformFecha = transformDatesToSlash({
                        date: fecha.fecha,
                    });

                    return new Date(transformFecha).getTime();
                }
            );

            const all_days_selected = all_dates_selected.map((date) =>
                date.getDay()
            );

            const { arrayDias } = infoCasa.disponibilidades;

            if (
                allDaysSelected.some((day) =>
                    arrayDiasBluecorner.includes(day)
                ) ||
                all_dates_selected.some((date) =>
                    pedidosBluecorner.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    fechasBluecorner.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    fechasAirBnb.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    fechasBooking.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    fechasTripadvisor.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    arrayFechas.includes(date.getTime())
                ) ||
                all_dates_selected.some((date) =>
                    arrayFechasPedidos.includes(date.getTime())
                ) ||
                all_days_selected.some((day) =>
                    arrayDias.some(
                        (dia) =>
                            dia.dia === day &&
                            twoDates[1] <= new Date(dia.fecha_final) &&
                            twoDates[0] >= new Date(dia.fecha_inicio)
                    )
                )
            ) {
                dispatch(
                    set_error_message(
                        "No puedes seleccionar fechas no disponibles"
                    )
                );
            } else {
                if (isAvailable.some(({ available }) => available === false)) {
                    const response = isAvailable.find(
                        ({ available }) => available === false
                    );
                    dispatch(set_error_message(response?.message));
                }
            }
        }

        if (infoCasa?.garantia) {
            dispatch(
                set_garantia(
                    Number(
                        infoCasa.tipo_garantia === "Efectivo"
                            ? 0
                            : infoCasa?.garantia
                    )
                )
            );
        }
    };

    const calculateDisabledTiles = React.useMemo(
        () =>
            (date: Date): boolean => {
                if (infoCasa) {
                    // Paso las fechas a formato Date
                    const mesActual = date.getMonth();
                    const disponibilidadesFechas =
                        infoCasa.disponibilidades.arrayFechas.some((fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha.fecha + "T00:00:00").getTime()
                            );
                        });

                    if (disponibilidadesFechas) return disponibilidadesFechas;

                    const disponibilidadesFechasBluecorner =
                        arrayFechasBluecorner.some((fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        });
                    if (disponibilidadesFechasBluecorner)
                        return disponibilidadesFechasBluecorner;

                    const disponibilidadesFechasAirBnb = arrayFechasAirBnb.some(
                        (fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        }
                    );

                    if (disponibilidadesFechasAirBnb)
                        return disponibilidadesFechasAirBnb;

                    const disponibilidadesFechasTripadvisor =
                        arrayFechasTripadvisor.some((fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        });
                    if (disponibilidadesFechasTripadvisor)
                        return disponibilidadesFechasTripadvisor;

                    const disponibilidadesFechasBooking =
                        arrayFechasBooking.some((fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        });
                    if (disponibilidadesFechasBooking)
                        return disponibilidadesFechasBooking;

                    const pedidosBluecorner = arrayPedidosBluecorner.some(
                        (fecha) => {
                            return (
                                date.getTime() ===
                                new Date(fecha + "T00:00:00").getTime()
                            );
                        }
                    );
                    if (pedidosBluecorner) return pedidosBluecorner;

                    const diasBluecorner = arrayDiasBluecorner.some((day) => {
                        return date.getDay() === day;
                    });

                    if (diasBluecorner) return diasBluecorner;

                    const mesBluecorner = arrayMesesBluecorner.some((mes) => {
                        return mesActual === mes;
                    });

                    if (mesBluecorner) return mesBluecorner;

                    const disponibilidadesFechasPedidos =
                        infoCasa.pedidos.arrayFechasPedido.some(
                            (fecha: Reservas) => {
                                return (
                                    date.getTime() ===
                                    new Date(
                                        fecha.fecha + "T00:00:00"
                                    ).getTime()
                                );
                            }
                        );

                    if (disponibilidadesFechasPedidos)
                        return disponibilidadesFechasPedidos;

                    const disponibilidadesDias =
                        infoCasa.disponibilidades.arrayDias;

                    if (
                        disponibilidadesDias.some(
                            (dia) =>
                                dia.dia === date.getDay() &&
                                date <= new Date(dia.fecha_final) &&
                                date >= new Date(dia.fecha_inicio)
                        )
                    )
                        return true;
                }
                return false;
            },
        [infoCasa]
    );

    if (!infoCasa) return null;
    else {
        return (
            <div
                className={`flex flex-col items-center text-center p-1.5 max-w-xl w-full mx-auto ${className}`}
            >
                <div className="flex items-start w-full gap-2">
                    <div className="w-full p-3 mb-4 font-medium text-blue-700 border border-blue-700 rounded-md">
                        Debe reservar al menos {infoCasa.estadia_minima}{" "}
                        {infoCasa.estadia_minima === 1 ? "noche" : "noches"}
                    </div>
                    {toggleButton && (
                        <div className="flex flex-col">
                            <button
                                onClick={() =>
                                    setModalEditEstadiaMinima((prev) => !prev)
                                }
                                title="Editar estadía mínima"
                                className="flex items-center px-2 py-1.5 border rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                            >
                                <svg
                                    className="w-10 h-10 text-blue-700"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                                    <line
                                        x1="13.5"
                                        y1="6.5"
                                        x2="17.5"
                                        y2="10.5"
                                    />
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
                {modalEditEstadiaMinima && infoCasa ? (
                    <ModalEditEstadiaMinima
                        infoCasa={infoCasa}
                        setModalVisible={setModalEditEstadiaMinima}
                    />
                ) : null}

                <Calendar
                    selectRange={true}
                    onChange={onChangeCalendar}
                    minDate={new Date()}
                    tileDisabled={({ activeStartDate, date, view }) =>
                        calculateDisabledTiles(date)
                    }
                />
                {datesSelected && !promo && (
                    <BodyReserva
                        infoCasa={infoCasa}
                        servicios_adicionales={infoCasa.servicios_adicionales}
                    />
                )}
                {datesSelected &&
                    promo &&
                    datosPromo &&
                    setModalVisible &&
                    socket && (
                        <BodyReservaPromo
                            infoCasa={infoCasa}
                            servicios_adicionales={
                                infoCasa.servicios_adicionales
                            }
                            datosPromo={datosPromo}
                            setModalVisible={setModalVisible}
                            socket={socket}
                        />
                    )}
            </div>
        );
    }
};
