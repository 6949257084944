import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Inicio } from "../pages/dashboardPropietarios/Inicio";
import { OwnerRegister } from "../pages/OwnerRegister";
import { Chat } from "../pages/dashboardPropietarios/Chat";
import { CRM } from "../pages/dashboardPropietarios/CRM/CRM";
import { Estadisticas } from "../pages/dashboardPropietarios/Estadisticas";
import { MediosDePago } from "../pages/dashboardPropietarios/mediosDePago/MediosDePago";
import { RedesSociales } from "../pages/dashboardPropietarios/RedesSociales";
import { Bienvenida } from "../pages/onboardingPropietario/Bienvenida";
import { EscogerTema } from "../pages/onboardingPropietario/EscogerTema";
import { NombreCasa } from "../pages/onboardingPropietario/NombreCasa";
import { Imagenes } from "../pages/onboardingPropietario/Imagenes";
import { TemaEjemplo } from "../pages/TemaEjemplo";
import { Calendario } from "../pages/dashboardPropietarios/calendario/Calendario";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { verifyToken } from "../redux/reducers/authReducer";
import { Micuenta } from "../pages/dashboardPropietarios/Micuenta";
import SpinnerVerifyToken from "./SpinnerVerifyToken";
import { Comforts } from "../pages/dashboardAdministrativo/comforts/Comforts";
import { CambiarPassword } from "../pages/dashboardPropietarios/CambiarPassword";
import { Users } from "../pages/dashboardAdministrativo/users/Users";
import { Comodidades } from "../pages/onboardingPropietario/Comodidades";
import { Habitaciones } from "../pages/onboardingPropietario/habitaciones/Habitaciones";
import { Precio } from "../pages/onboardingPropietario/Precio";
import { Ubicacion } from "../pages/onboardingPropietario/ubicacion/Ubicacion";
import { getHouse } from "../redux/reducers/onboardingReducer";
import { TarifasAdicionales } from "../pages/dashboardPropietarios/tarifas-personas/tarifas/TarifasAdicionales";
import { PersonasAdicionales } from "../pages/dashboardPropietarios/tarifas-personas/personas/PersonalesAdicionales";
import { DiscountsForTime } from "../pages/dashboardPropietarios/descuentosPorTiempo/DiscountsForTime";
import { DiasNoDisponibles } from "../pages/dashboardPropietarios/calendario/diasNoDisponibles/DiasNoDisponibles";
import { AvailabilityMinimum } from "../pages/dashboardPropietarios/minimaDisponibilidad/AvailabilityMinimum";
import { AvailabilityMaximo } from "../pages/dashboardPropietarios/maximaDisponibilidad/AvailabilityMaximo";
import { Coupon } from "../pages/dashboardPropietarios/cupones/Coupon";
import { Capacidad } from "../pages/onboardingPropietario/Capacidad";
import { Checkout } from "../pages/checkout/Checkout";
import { ConnectionsExternal } from "../pages/dashboardPropietarios/conexionesExternas/ConnectionsExternal";
import { HomeSubdominio } from "../pages/LandingSubdominio/HomeSubdominio";
import { ClientLogin } from "../pages/ClientLogin";
import { ClientRegister } from "../pages/ClientRegister";
import { fetchAxiosNoToken } from "../helpers/fetchAxiosNoToken";
import { ChatCliente } from "../pages/dashboardClientes/chat/ChatCliente";
import { MicuentaCliente } from "../pages/dashboardClientes/Micuenta";
import { CasasDashboard } from "../pages/dashboardAdministrativo/casas/CasasDashboard";
import { Reservas } from "../pages/dashboardPropietarios/reservas/Reservas";
import { ReservaDetalle } from "../pages/dashboardPropietarios/reservas/ReservaDetalle";
import { ChatPropietario } from "../pages/dashboardPropietarios/chat/ChatPropietario";
import { MicuentaBancaria } from "../pages/dashboardPropietarios/MicuentaBancaria";
import { CheckoutPago } from "../pages/checkout/CheckoutPago";
import ComprobarPago from "../pages/checkout/ComprobarPago";
import { ReservaResena } from "../pages/dashboardPropietarios/reservas/ReservaResena";
import { Resenas } from "../pages/dashboardPropietarios/reseñas/Resenas";
import { DetalleResena } from "../pages/dashboardPropietarios/reservas/DetalleResena";
import { ResenaPropietario } from "../pages/dashboardPropietarios/reseñas/ResenaPropietario";
import { CRMDetalle } from "../pages/dashboardPropietarios/CRM/CRMDetalle";
import { SubUsuarios } from "../pages/dashboardPropietarios/subUsuario/SubUsuarios";
import RecuerarContraseña from "../pages/dashboardPropietarios/contrasena/RecuperarContraseña";
import { CheckInCheckOut } from "../pages/dashboardPropietarios/CheckInCheckOut/CheckInCheckOut";
import { Garantia } from "../pages/dashboardPropietarios/Garantia/Garantia";
import ComprobarDisponibilidadPropietario from "../pages/checkout/ComprobarDisponibilidadPropietario";
import { PedidosDashboard } from "../pages/dashboardAdministrativo/pedidos/PedidosDashboard";
import { PedidosGananciasDashboard } from "../pages/dashboardAdministrativo/pedidos/PedidosGananciasDashboard";
import { CasasTop } from "../pages/dashboardAdministrativo/estadisticas/CasasTop";
import { CasasLeads } from "../pages/dashboardAdministrativo/estadisticas/CasasLeads";
import { CasasChats } from "../pages/dashboardAdministrativo/estadisticas/CasasChats";
import { CasasVistas } from "../pages/dashboardAdministrativo/estadisticas/CasasVistas";
import { Suscripciones } from "../pages/dashboardPropietarios/suscripcion/Suscripciones";
import { CheckoutSuscripcion } from "../pages/dashboardPropietarios/suscripcion/CheckoutSuscripcion";
import { SuscripcionesCasas } from "../pages/dashboardAdministrativo/suscripcion/SuscripcionesCasas";
import { CheckoutSuscripcionRechazada } from "../pages/dashboardPropietarios/suscripcion/CheckoutSuscripcionRechazada";
import { CheckoutConfirmarPropuesta } from "../pages/dashboardPropietarios/chat/ModalReserva/CheckoutConfirmarPropuesta";
import { Calculadora } from "../designs/calculadora/Calculadora";
import { PlanHome } from "../components/home/planes/Home";
import PagoConfirmado from "../pages/dashboardPropietarios/chat/ModalReserva/PagoConfirmado";
import PagoRechazado from "../pages/dashboardPropietarios/chat/ModalReserva/PagoRechazado";
import { MediosDePagoMercadoPago } from "../pages/dashboardPropietarios/mediosDePago/MediosDePagoMercadoPago";
import { ComoEditarLanding } from "../pages/LandingSubdominio/ComoEditarLanding";
import Breadcrumbs from "../components/helpers/Breadcrumbs";

import { BreadcrumbsRoute } from "react-router-breadcrumbs-hoc";
import { Contactos } from "../pages/dashboardPropietarios/contactos/Contactos";
import { MensajeDeBienvenida } from "../pages/dashboardPropietarios/MensajeDeBienvenida/MensajeDeBienvenida";
import { AyudaPropietario } from "../pages/dashboardPropietarios/ayuda/AyudaPropietario";
import { CRMAdmin } from "../pages/dashboardAdministrativo/CRM/CRMAdmin";
import { CRMDetallePropietarios } from "../pages/dashboardAdministrativo/CRM/CRMDetallePropietarios";
import { TemaLanding } from "../pages/LandingSubdominio/TemaLanding";

import { set_landing_house } from "../redux/reducers/landingReducer";
import { PreciosAvanzados } from "../pages/dashboardPropietarios/calendario/preciosAvanzados/PreciosAvanzados";
import { activar_socket } from "../redux/reducers/socketReducer";
import { OwnerRegisterPlan } from "../pages/registroConPlan/OwnerRegisterPlan";
import { RegistroConPlan } from "../pages/registroConPlan/RegistroConPlan";
import { Configuraciones } from "../pages/dashboardPropietarios/configuraciones/Configuraciones";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/effect-cards";
import { PropietarioLoginHome } from "../pages/PropietarioLoginHome";
import Impact from "../pages/impact/Impact";
import { Blogs } from "../pages/dashboardAdministrativo/blog/Blogs";
import CreateBlog from "../pages/dashboardAdministrativo/blog/CreateBlog";
import EditBlog from "../pages/dashboardAdministrativo/blog/EditBlog";
import BlogHome from "../pages/blog/BlogHome";
import DetailedBlog from "../pages/blog/DetailedBlog";
import { UrlName } from "../pages/dashboardPropietarios/urlName/UrlName";
import WatchDemo from "../pages/watchDemo/WatchDemo";
import WatchDemoThanks from "../pages/watchDemo/WatchDemoThanks";
import Home from "../components/home/Home";
import { EtiquetasBlogs } from "../pages/dashboardAdministrativo/etiquetas_blogs/EtiquetasBlog";
import useCurrency from "../hooks/useCurrency";
import Chatbot from "../pages/dashboardPropietarios/chatbot/Chatbot";
import AuthGoogle from "../pages/dashboardPropietarios/AuthGoogle";
import { OwnerBroker } from "../pages/OwnerBroker";
import { InicioBroker } from "../pages/dashboardBroker/InicioBroker";
import { set_corredor } from "../redux/reducers/corredorReducer";
import { DatosLandingCorredor } from "../pages/dashboardBroker/onboarding/DatosLandingCorredor";
import LandingCorredor from "../pages/landingCorredor/LandingCorredor";
import { RedesSocialesCorredor } from "../pages/dashboardBroker/onboarding/RedesSocialesCorredor";
import { CasasBroker } from "../pages/dashboardBroker/CasasBroker";
import CalendarioBroker from "../pages/dashboardBroker/calendario/CalendarioBroker";
import { MicuentaBroker } from "../pages/dashboardBroker/Micuenta";
import WhatsappNotificaciones from "../pages/dashboardAdministrativo/whatsapp_notificaciones/WhatsappNotificaciones";
import PoliticasDeUsoYPriv from "../components/home/PoliticasDeUsoYPriv";
import PlanesMulticasas from "../components/home/planes_multicasas/PlanesMulticasas";
import { ChatRoom } from "../pages/dashboardBroker/chatRoom/ChatRoom";
import { ServiciosAdicionales } from "../components/home/serviciosAdicionales/ServiciosAdicionales";
import { CorredorDashboard } from "../pages/dashboardAdministrativo/corredor/CorredorDashboard";
import { ServiciosAdicionalesPagosAdmin } from "../pages/dashboardAdministrativo/serviciosAdicionales/ServiciosAdicionalesPagosAdmin";
import { PagarSuscripcion } from "../pages/onboardingPropietario/PagarSuscripcion";
import PlanesHoteles from "../components/home/planes_hoteles/PlanesHoteles";
import { RegistroConPlanCorredor } from "../pages/registroConPlanCorredor/RegistroConPlanCorredor";

import { RegisterHotels } from "../pages/RegisterHotels";
import { NombreHotel } from "../pages/onboardingHotel/NombreHotel";

import { PagarSuscripcionCorredor } from "../pages/dashboardBroker/onboarding/PagarSuscripcionCorredor";
import { EscogerTemaHotel } from "../pages/onboardingHotel/EscogerTemaHotel";
import { ImagenesHotel } from "../pages/onboardingHotel/ImagenesHotel";
import { ComodidadesHotel } from "../pages/onboardingHotel/ComodidadesHotel";
import { UbicacionHotel } from "../pages/onboardingHotel/ubicacion/UbicacionHotel";
import { HotelHabitaciones } from "../pages/dashboardHotel/HotelHabitaciones";
import { NombreHabitacionHotel } from "../pages/dashboardHotel/onboarding/NombreHabitacionHotel";
import { CapacidadHabitacionHotel } from "../pages/dashboardHotel/onboarding/CapacidadHabitacionHotel";
import { ImagenesHabitacionHotel } from "../pages/dashboardHotel/onboarding/ImagenesHabitacionHotel";
import { PrecioHabitacionHotel } from "../pages/dashboardHotel/onboarding/PrecioHabitacionHotel";
import { CalendarioHotelHabitacion } from "../pages/dashboardHotel/calendario/CalendarioHotelHabitacion";
import { ConfiguracionesHabitacionHotel } from "../pages/dashboardHotel/configuraciones/ConfiguracionesHabitacionHotel";

import LandingHabitaciones from "../designs/design1New/Landing_habitaciones";

import { DiasNoDisponiblesHabitacionHotel } from "../pages/dashboardHotel/diasNoDisponibles/DiasNoDisponiblesHabitacionHotel";
import { ContactosHotel } from "../pages/dashboardHotel/contactos/ContactosHotel";
import { ChatClienteHotel } from "../pages/dashboardClientesHotel/chat/ChatClienteHotel";
import { MiCuentaClienteHotel } from "../pages/dashboardClientesHotel/MiCuentaClienteHotel";
import { MiCuentaHotel } from "../pages/dashboardHotel/MiCuentaHotel";
import { ConfiguracionesHotel } from "../pages/dashboardHotel/configuraciones/ConfiguracionesHotel";
import { ReservasHotel } from "../pages/dashboardHotel/reservas/ReservasHotel";
import { ReservaDetalleHotel } from "../pages/dashboardHotel/reservas/ReservaDetalleHotel";
import { CheckoutHotel } from "../pages/dashboardHotel/checkout/CheckoutHotel";
import { CheckoutPagoHotel } from "../pages/dashboardHotel/checkout/CheckoutPago";
import { CheckoutHotelReserva } from "../pages/checkout/CheckoutHotel";
import Planes from "../components/home/planes_multicasas/PlanesMulticasas";
import { PlanHomeCorredores } from "../components/home/planes_multicasas/HomeCorredores";
import { PlanHomeHoteles } from "../components/home/planes_hoteles/HomeHoteles";
import { RegisterHotelsConPlan } from "../pages/RegisterHotelsConPlan";
import { PagarSuscripcionHotel } from "../pages/onboardingHotel/PagarSuscripcionHotel";
import LandingHotelHome from "../pages/landingHotelHome/LandingHotelHome";

export interface IsExist {
    is_exist: string;
    tipo_usuario: number;
}

const AppRouter = () => {
    const [isTenant, setIsTenant] = useState<IsExist | null>(null);
    const dispatch = useAppDispatch();
    useCurrency();
    const {
        id: id_usuario,
        tipo_usuario,
        checking,
    } = useAppSelector((state) => state.usuario_logeado);

    console.log("tipo_usuario", tipo_usuario);
    const { plan, terminado } = useAppSelector(
        (state) => state.onboarding_house
    );

    const casa = useAppSelector((state) => state.landing_house);
    const corredor = useAppSelector((state) => state.corredor);

    const socketReducer = useAppSelector((state) => state.activarSocket);

    const dispatchDominio = () => {
        fetchAxiosNoToken({
            url: `/casa/getOne/dominio`,
        })
            .then((res) => {
                console.log(res);
                dispatch(set_landing_house(res));
            })
            .catch((err) => {
                console.log(err);
                // dispatch(set_landing_house(null));
            });
    };

    const dispatchDominioCorredor = () => {
        fetchAxiosNoToken({
            url: `/corredor/getOneCorredor/subdomain`,
        })
            .then((res) => {
                console.log(res);
                dispatch(set_corredor(res));
            })
            .catch((err) => {
                console.log(err);
                // dispatch(set_landing_house(null));
            });
    };

    useEffect(() => {
        if (checking === true) {
            dispatch(verifyToken()).then((data) => {
                dispatch(getHouse(data.payload.id));
            });
        }

        fetchAxiosNoToken({
            url: `/casa/isExist/`,
        })
            .then((res) => {
                console.log("res", res);
                setIsTenant(res);
            })
            .catch((error) => console.log(error));
    }, [dispatch]);

    useEffect(() => {
        if (
            isTenant?.is_exist === "Si" &&
            (isTenant?.tipo_usuario === 3 || isTenant?.tipo_usuario === 8)
        ) {
            dispatchDominio();
        } else if (
            isTenant?.is_exist === "Si" &&
            isTenant?.tipo_usuario === 6
        ) {
            dispatchDominioCorredor();
        }
    }, [isTenant]);

    useEffect(() => {
        console.log("useEffect");

        if (socketReducer.socket) {
            socketReducer.socket.emit("joinRoom", {
                chatroomId: id_usuario,
            });

            return;
        }

        if (true) {
            dispatch(
                activar_socket({
                    casa_id: casa.house.id,
                    id_usuario: id_usuario,
                })
            );
        }
    }, [id_usuario, casa?.house?.id]);
    // console.log({plan,isTenant,casa})

    if (
        checking ||
        (isTenant === null && casa?.house?.plan === 0) ||
        (isTenant?.tipo_usuario === 3 && !casa?.house?.terminado.length) ||
        (isTenant?.tipo_usuario === 6 && corredor?.id === 0)
    )
        return <SpinnerVerifyToken />;

    return (
        <BrowserRouter>
            {/* <Breadcrumbs /> */}
            {isTenant?.is_exist === "Si" && (
                <Helmet>
                    <title>{casa?.house?.nombre || "BluStay"}</title>
                </Helmet>
            )}
            <Routes>
                {/* {Rutas fallback dependiendo si es tenant o no} */}
                <Route
                    path="/*"
                    element={
                        isTenant?.is_exist === "Si" ? (
                            <Navigate to="/" replace />
                        ) : (
                            isTenant?.is_exist === "No" && (
                                <Navigate to="/home/6" replace />
                            )
                        )
                    }
                />

                {/* Rutas de administradores */}
                {id_usuario && tipo_usuario <= 2 && (
                    <>
                        <Route
                            path="/dashboard-admin/casas/pedidos/:id/:nombre"
                            element={<PedidosDashboard />}
                        />
                        <Route
                            path="/dashboard-admin/servicios"
                            element={<ServiciosAdicionalesPagosAdmin />}
                        />
                        <Route
                            path="/dashboard-admin/pedidos-ganancias/:id/:nombre"
                            element={<PedidosGananciasDashboard />}
                        />
                        <Route
                            path="/dashboard-admin/suscripcion/:id_casa/:nombre/:tipo"
                            element={<SuscripcionesCasas />}
                        />
                        <Route
                            path="/dashboard-admin/pedido-detalle/:reservaID"
                            element={<ReservaDetalle />}
                        />
                        <Route
                            path="/dashboard-admin/casas-top"
                            element={<CasasTop />}
                        />
                        <Route
                            path="/dashboard-admin/casas-leads"
                            element={<CasasLeads />}
                        />
                        <Route
                            path="/dashboard-admin/casas-chats"
                            element={<CasasChats />}
                        />
                        <Route
                            path="/dashboard-admin/casas-vistas"
                            element={<CasasVistas />}
                        />
                        <Route
                            path="/dashboard-admin/casas"
                            element={<CasasDashboard />}
                        />
                        <Route
                            path="/dashboard-admin/corredores"
                            element={<CorredorDashboard />}
                        />

                        <Route
                            path="/dashboard-admin/comodidades"
                            element={<Comforts />}
                        />

                        <Route
                            path="/dashboard-admin/etiquetas-blog"
                            element={<EtiquetasBlogs />}
                        />
                        <Route
                            path="/dashboard-admin/administradores"
                            element={<Users />}
                        />
                        <Route
                            path="/dashboard-admin/comodidades"
                            element={<Comforts />}
                        />
                        <Route
                            path="/dashboard-admin/blogs"
                            element={<Blogs />}
                        />
                        <Route
                            path="/dashboard-admin/blogs-create"
                            element={<CreateBlog />}
                        />
                        <Route
                            path="/dashboard-admin/blogs-edit/:id"
                            element={<EditBlog />}
                        />
                        <Route
                            path="/dashboard-admin/ayuda"
                            element={<AyudaPropietario />}
                        />
                        <Route
                            path="/dashboard-admin/crm"
                            element={<CRMAdmin />}
                        />
                        <Route
                            path="/dashboard-admin/crm/:id"
                            element={<CRMDetallePropietarios />}
                        />
                        <Route
                            path="/dashboard-admin/cambiar-contraseña"
                            element={<CambiarPassword />}
                        />
                        <Route
                            path="/dashboard-admin/whatsapp"
                            element={<WhatsappNotificaciones />}
                        />
                    </>
                )}
                {/* Rutas de administradores */}

                {/* Rutas de propietarios */}
                {id_usuario &&
                    (tipo_usuario === 3 ||
                        tipo_usuario === 4 ||
                        tipo_usuario === 6 ||
                        tipo_usuario === 8) &&
                    isTenant?.is_exist === "Si" && (
                        <>
                            <Route
                                path="/dashboard/configuraciones/personas-adicionales"
                                element={<PersonasAdicionales />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/personas-adicionales/:idCasa"
                                element={<PersonasAdicionales />}
                            />
                            <Route
                                path="/dashboard/configuraciones/descuento-por-estadia"
                                element={<DiscountsForTime />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/descuento-por-estadia/:idCasa"
                                element={<DiscountsForTime />}
                            />
                            <Route
                                path="/dashboard/precios-avanzados"
                                element={<PreciosAvanzados />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/cupones/:idCasa"
                                element={<Coupon />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/maxima-disponibilidad/:idCasa"
                                element={<AvailabilityMaximo />}
                            />

                            <Route
                                path="/dashboard-corredor/chat"
                                element={<ChatPropietario />}
                            />
                            {casa?.house?.plan && casa.house.plan > 1 && (
                                <>
                                    <Route
                                        path="/dashboard/configuraciones/maxima-disponibilidad"
                                        element={<AvailabilityMaximo />}
                                    />
                                    <Route
                                        path="/dashboard/configuraciones/cupones"
                                        element={<Coupon />}
                                    />

                                    <Route
                                        path="/dashboard/chat/:idChat"
                                        element={<ChatPropietario />}
                                    />
                                    <Route
                                        path="/dashboard/chat"
                                        element={<ChatPropietario />}
                                    />

                                    <Route
                                        path="/dashboard/crm"
                                        element={<CRM />}
                                    />

                                    <Route
                                        path="/dashboard/crm/:id"
                                        element={<CRMDetalle />}
                                    />
                                    <Route
                                        path="/dashboard/estadisticas"
                                        element={<Estadisticas />}
                                    />

                                    <Route
                                        path="/dashboard/mercado-pago"
                                        element={<MediosDePagoMercadoPago />}
                                    />
                                    <Route
                                        path="/dashboard/sub-usuarios"
                                        element={<SubUsuarios />}
                                    />
                                </>
                            )}
                            <Route
                                path="/comprobante-en-revicion"
                                element={
                                    <ComprobarPago
                                        tenant={isTenant?.tipo_usuario}
                                    />
                                }
                            />
                            <Route
                                path="/dashboard/bienvenidalogin/:token"
                                element={
                                    casa.house.terminado === "Si" ||
                                    terminado === "Si" ? (
                                        <Calendario />
                                    ) : (
                                        <Inicio />
                                    )
                                }
                            />

                            <Route
                                path="/dashboard/bienvenidalogin/:token"
                                element={
                                    casa.house.terminado === "Si" ||
                                    terminado === "Si" ? (
                                        <Calendario />
                                    ) : (
                                        <Inicio />
                                    )
                                }
                            />
                            <Route
                                path="/dashboard/"
                                element={
                                    casa.house.terminado === "Si" ||
                                    terminado === "Si" ? (
                                        <Calendario />
                                    ) : (
                                        <Inicio />
                                    )
                                }
                            />
                            <Route
                                path="/dashboard/calendario"
                                element={<Calendario />}
                            />

                            <Route
                                path="/dashboard-corredor/calendario/:id_casa"
                                element={<Calendario />}
                            />
                            <Route
                                path="/dashboard-corredor/chatroom"
                                element={<ChatRoom />}
                            />
                            <Route
                                path="/dashboard-corredor/crm"
                                element={<CRM />}
                            />

                            <Route
                                path="/dashboard-corredor/crm/:id"
                                element={<CRMDetalle />}
                            />
                            <Route
                                path="/dashboard/reservas"
                                element={<Reservas />}
                            />
                            <Route
                                path="/dashboard-corredor/reservas/:id_casa"
                                element={<Reservas />}
                            />
                            <Route
                                path="/dashboard/reservas/:reservaID"
                                element={<ReservaDetalle />}
                            />

                            <Route
                                path="/dashboard-corredor/reservas/detalle/:reservaID"
                                element={<ReservaDetalle />}
                            />

                            <Route
                                path="/dashboard-corredor/suscripciones"
                                element={<Suscripciones />}
                            />

                            <Route
                                path="/dashboard/contactos"
                                element={<Contactos />}
                            />

                            <Route
                                path="/dashboard-corredor/contactos"
                                element={<Contactos />}
                            />

                            <Route
                                path="/dashboard/ayuda"
                                element={<AyudaPropietario />}
                            />

                            <Route
                                path="/dashboard/suscripciones"
                                element={<Suscripciones />}
                            />

                            <Route
                                path="/dashboard/transferencia"
                                element={<MediosDePago />}
                            />

                            <Route
                                path="/dashboard/configuraciones"
                                element={<Configuraciones />}
                            />

                            <Route
                                path="/dashboard/configuraciones/editar-landing"
                                element={<ComoEditarLanding />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/editar-landing/:idCasa"
                                element={<ComoEditarLanding />}
                            />

                            <Route
                                path="/dashboard-corredor/transferencia"
                                element={<MediosDePago />}
                            />

                            <Route
                                path="/dashboard-corredor/mercado-pago"
                                element={<MediosDePagoMercadoPago />}
                            />
                            <Route
                                path="/dashboard/configuraciones/tema-landing"
                                element={<TemaLanding />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/tema-landing/:idCasa"
                                element={<TemaLanding />}
                            />
                            <Route
                                path="/dashboard/redes-sociales"
                                element={<RedesSociales />}
                            />
                            <Route
                                path="/dashboard/mi-cuenta-bancaria"
                                element={<MicuentaBancaria />}
                            />
                            <Route
                                path="/dashboard/mi-cuenta"
                                element={<Micuenta />}
                            />
                            <Route
                                path="/dashboard/cambiar-contraseña"
                                element={<CambiarPassword />}
                            />
                            <Route
                                path="/dashboard/configuraciones/tarifas-adicionales"
                                element={<TarifasAdicionales />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/tarifas-adicionales/:idCasa"
                                element={<TarifasAdicionales />}
                            />

                            <Route
                                path="/dashboard/reviews"
                                element={<Resenas />}
                            />

                            <Route
                                path="/dashboard-corredor/reviews/:id_casa"
                                element={<Resenas />}
                            />

                            <Route
                                path="/dashboard/noche-no-disponibles"
                                element={<DiasNoDisponibles />}
                            />

                            <Route
                                path="/dashboard/configuraciones/minima-disponibilidad"
                                element={<AvailabilityMinimum />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/minima-disponibilidad/:idCasa"
                                element={<AvailabilityMinimum />}
                            />

                            <Route
                                path="/dashboard/configuraciones/check"
                                element={<CheckInCheckOut />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/check/:idCasa"
                                element={<CheckInCheckOut />}
                            />
                            <Route
                                path="/dashboard/configuraciones/mensaje-bienvenida"
                                element={<MensajeDeBienvenida />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/mensaje-bienvenida/:idCasa"
                                element={<MensajeDeBienvenida />}
                            />
                            <Route
                                path="/dashboard/configuraciones/garantia"
                                element={<Garantia />}
                            />

                            <Route
                                path="/dashboard-corredor/configuraciones/garantia/:idCasa"
                                element={<Garantia />}
                            />
                            <Route
                                path="/dashboard/onboarding/bienvenidalogin/:token"
                                element={<Bienvenida corredor={"casa"} />}
                            />
                            <Route
                                path="/dashboard/onboarding/bienvenida"
                                element={<Bienvenida corredor={"casa"} />}
                            />

                            <Route
                                path="/dashboard-corredor/onboarding/bienvenida"
                                element={<Bienvenida corredor={"corredor"} />}
                            />
                            <Route
                                path="/dashboard-corredor/onboarding/bienvenidalogin/:token"
                                element={<Bienvenida corredor={"corredor"} />}
                            />

                            <Route
                                path="/dashboard-hotel/onboarding/bienvenida"
                                element={<Bienvenida corredor={"hotel"} />}
                            />
                            <Route
                                path="/dashboard-hotel/onboarding/bienvenidalogin/:token"
                                element={<Bienvenida corredor={"hotel"} />}
                            />
                            <Route
                                path="/dashboard/onboarding/suscripcion"
                                element={<PagarSuscripcion />}
                            />
                            <Route
                                path="/dashboard/onboarding/alojamiento"
                                element={<NombreCasa />}
                            />
                            <Route
                                path="/dashboard/onboarding/capacidad"
                                element={<Capacidad />}
                            />
                            <Route
                                path="/dashboard/onboarding/tema"
                                element={<EscogerTema />}
                            />
                            <Route
                                path="/dashboard/onboarding/imagenes"
                                element={<Imagenes />}
                            />
                            <Route
                                path="/dashboard/onboarding/comodidades"
                                element={<Comodidades />}
                            />
                            <Route
                                path="/dashboard/onboarding/habitaciones"
                                element={<Habitaciones />}
                            />
                            <Route
                                path="/dashboard/onboarding/ubicacion"
                                element={<Ubicacion />}
                            />
                            <Route
                                path="/dashboard/onboarding/precio"
                                element={<Precio />}
                            />
                            <Route
                                path="/dashboard/configuraciones/conexiones-externas"
                                element={<ConnectionsExternal />}
                            />
                            <Route
                                path="/dashboard-corredor/configuraciones/conexiones-externas/:idCasa"
                                element={<ConnectionsExternal />}
                            />
                            <Route
                                path="/dashboard/configuraciones/url"
                                element={<UrlName />}
                            />

                            <Route
                                path="/dashboard-corredor/configuraciones/url/:idCasa"
                                element={<UrlName />}
                            />

                            <Route
                                path="/dashboard/onboarding/:temaEjemplo/:number"
                                element={
                                    <TemaEjemplo
                                        tipo_usuario={isTenant.tipo_usuario}
                                    />
                                }
                            />
                            <Route
                                path="/dashboard/reviews/:resenaID"
                                element={<DetalleResena />}
                            />
                            <Route
                                path="/dashboard/reviews-propietario/:reservaID"
                                element={<ResenaPropietario />}
                            />

                            <Route
                                path="/dashboard-corredor/reviews-propietario/:reservaID"
                                element={<ResenaPropietario />}
                            />
                            <Route
                                path="/dashboard/chatbot"
                                element={<Chatbot />}
                            />
                        </>
                    )}
                {/* Rutas de propietarios */}

                {/* Rutas prohibidas para tenants  */}
                {isTenant?.is_exist === "No" && (
                    <>
                        <Route path="/home/:tags?" element={<Home />} />
                        <Route path="/home/:tags?/:view?" element={<Home />} />
                        <Route
                            path="/home/:tags?/calculadora/"
                            element={<Calculadora />}
                        />
                        <Route
                            path="/home/:tags?/servicios-adicionales/"
                            element={<ServiciosAdicionales />}
                        />
                        <Route
                            path="/home/calculadora/"
                            element={<Calculadora />}
                        />
                        <Route
                            path="/home/:tags?/planes/"
                            element={<Planes />}
                        />

                        <Route
                            path="/home/:tags?/planes-hoteles/"
                            element={<PlanesHoteles />}
                        />

                        <Route
                            path="/home/:tags?/funcionalidades-propietarios"
                            element={<PlanHome />}
                        />

                        <Route
                            path="/home/:tags?/funcionalidades-corredores"
                            element={<PlanHomeCorredores />}
                        />

                        <Route
                            path="/home/:tags?/funcionalidades-hoteles"
                            element={<PlanHomeHoteles />}
                        />
                        <Route path="/impact/:tags?" element={<Impact />} />
                        <Route
                            path="/watchDemo/:tags?"
                            element={<WatchDemo />}
                        />
                        <Route
                            path="/watchDemoThanks"
                            element={<WatchDemoThanks />}
                        />
                        <Route path="/blog/" element={<BlogHome />} />
                        <Route
                            path="/blog/:idBlog"
                            element={<DetailedBlog />}
                        />

                        {!id_usuario && (
                            <>
                                <Route
                                    path="/registro-propietario"
                                    element={<OwnerRegister />}
                                />
                                <Route
                                    path="/registro-corredor"
                                    element={<OwnerBroker />}
                                />

                                <Route
                                    path="/registro-hotel"
                                    element={<RegisterHotels />}
                                />

                                <Route
                                    path="/registro-hotel-plan"
                                    element={<RegisterHotelsConPlan />}
                                />
                                <Route
                                    path="/registro-propietario-plan"
                                    element={<RegistroConPlan />}
                                />

                                <Route
                                    path="/registro-corredor-plan"
                                    element={<RegistroConPlanCorredor />}
                                />
                            </>
                        )}
                    </>
                )}
                {/* Rutas prohibidas para tenants  */}

                {/* Rutas de tenants only  */}
                {isTenant?.is_exist === "Si" && (
                    <>
                        {(isTenant?.tipo_usuario === 3 ||
                            isTenant?.tipo_usuario === 8) && (
                            <Route
                                path="/:view?"
                                element={
                                    <HomeSubdominio
                                        tipo_usuario={isTenant.tipo_usuario}
                                    />
                                }
                            />
                        )}
                        {isTenant?.tipo_usuario === 6 && (
                            <Route
                                path="/:view?"
                                element={<LandingCorredor />}
                            />
                        )}

                        {isTenant?.tipo_usuario === 6 && (
                            <Route
                                path="/:id?/:nombre?"
                                element={
                                    <HomeSubdominio
                                        tipo_usuario={isTenant.tipo_usuario}
                                    />
                                }
                            />
                        )}

                        {isTenant?.tipo_usuario === 8 && (
                            <Route
                                path="/landing-habitaciones"
                                element={<LandingHabitaciones />}
                            />
                        )}

                        {isTenant?.tipo_usuario === 6 && (
                            <Route
                                path="/:id?/:nombre?/:view?"
                                element={
                                    <HomeSubdominio
                                        tipo_usuario={isTenant.tipo_usuario}
                                    />
                                }
                            />
                        )}

                        <Route path="/checkout/" element={<Checkout />} />
                        <Route
                            path="/checkout-hotel/"
                            element={<CheckoutHotelReserva />}
                        />
                        <Route
                            path="/checkout/:id_reserva"
                            element={<CheckoutPago />}
                        />
                        <Route
                            path="/checkout/propuesta/:id_reserva"
                            element={<CheckoutConfirmarPropuesta />}
                        />
                        <Route
                            path="/checkout/suscripcion/:id_suscripcion/:tipo/:id_corredor"
                            element={<CheckoutSuscripcion />}
                        />
                        <Route
                            path="/checkout-hotel/:id_reserva"
                            element={<CheckoutPagoHotel />}
                        />
                        {!id_usuario && (
                            <>
                                <Route
                                    path="/registro"
                                    element={
                                        <ClientRegister
                                            tipo_usuario={
                                                isTenant?.tipo_usuario
                                            }
                                        />
                                    }
                                />
                            </>
                        )}
                        {id_usuario && tipo_usuario === 8 && (
                            <>
                                <Route
                                    path="/dashboard-hotel/onboarding/alojamiento"
                                    element={<NombreHotel />}
                                />

                                <Route
                                    path="/dashboard-hotel/onboarding/tema"
                                    element={<EscogerTemaHotel />}
                                />

                                <Route
                                    path="/dashboard-hotel/onboarding/imagenes"
                                    element={<ImagenesHotel />}
                                />
                                <Route
                                    path="/dashboard-hotel/onboarding/comodidades"
                                    element={<ComodidadesHotel />}
                                />

                                <Route
                                    path="/dashboard-hotel/onboarding/ubicacion"
                                    element={<UbicacionHotel />}
                                />
                                <Route
                                    path="/dashboard-hotel/habitaciones"
                                    element={<HotelHabitaciones />}
                                />
                                <Route
                                    path="/dashboard-hotel/onboarding/habitacion/:idCasa"
                                    element={<NombreHabitacionHotel />}
                                />
                                <Route
                                    path="/dashboard-hotel/onboarding/capacidad-habitacion/:idCasa"
                                    element={<CapacidadHabitacionHotel />}
                                />
                                <Route
                                    path="/dashboard-hotel/onboarding/imagenes-habitacion/:idCasa"
                                    element={<ImagenesHabitacionHotel />}
                                />
                                <Route
                                    path="/dashboard-hotel/onboarding/precio-habitacion/:idCasa"
                                    element={<PrecioHabitacionHotel />}
                                />

                                <Route
                                    path="/dashboard-hotel/calendario/:id_casa"
                                    element={<CalendarioHotelHabitacion />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones-habitacion/:idCasa"
                                    element={<ConfiguracionesHabitacionHotel />}
                                />

                                <Route
                                    path="/dashboard-hotel/noche-no-disponibles/:idCasa"
                                    element={
                                        <DiasNoDisponiblesHabitacionHotel />
                                    }
                                />

                                <Route
                                    path="/dashboard-hotel/contactos"
                                    element={<ContactosHotel />}
                                />

                                <Route
                                    path="/dashboard-hotel/chat/:idChat"
                                    element={<ChatPropietario hotel={true} />}
                                />
                                <Route
                                    path="/dashboard-hotel/chat"
                                    element={<ChatPropietario hotel={true} />}
                                />

                                <Route
                                    path="/dashboard-hotel/mi-cuenta"
                                    element={<MiCuentaHotel />}
                                />
                                <Route
                                    path="/dashboard-hotel/cambiar-contraseña"
                                    element={<CambiarPassword />}
                                />

                                <Route
                                    path="/dashboard-hotel/crm"
                                    element={<CRM />}
                                />

                                <Route
                                    path="/dashboard-hotel/crm/:id"
                                    element={<CRMDetalle />}
                                />
                                <Route
                                    path="/dashboard-hotel/configuraciones-habitacion/garantia/:idCasa"
                                    element={<Garantia />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones-habitacion/check/:idCasa"
                                    element={<CheckInCheckOut />}
                                />

                                <Route
                                    path="/dashboard-hotel/mercado-pago"
                                    element={<MediosDePagoMercadoPago />}
                                />
                                <Route
                                    path="/dashboard-hotel/transferencia"
                                    element={<MediosDePago />}
                                />
                                <Route
                                    path="/dashboard-hotel/ayuda"
                                    element={<AyudaPropietario />}
                                />
                                <Route
                                    path="/dashboard-hotel/reviews/:resenaID"
                                    element={<DetalleResena />}
                                />
                                <Route
                                    path="/dashboard-hotel/reviews"
                                    element={<Resenas />}
                                />

                                <Route
                                    path="/dashboard-hotel/reviews-propietario/:reservaID"
                                    element={<ResenaPropietario />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones"
                                    element={<ConfiguracionesHotel />}
                                />
                                <Route
                                    path="/dashboard-hotel/configuraciones/editar-landing"
                                    element={<ComoEditarLanding />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones/tema-landing"
                                    element={<TemaLanding />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones/mensaje-bienvenida"
                                    element={<MensajeDeBienvenida />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones/cupones"
                                    element={<Coupon />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones/url"
                                    element={<UrlName />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones-habitacion/tarifas-adicionales/:idCasa"
                                    element={<TarifasAdicionales />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones-habitacion/personas-adicionales/:idCasa"
                                    element={<PersonasAdicionales />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones-habitacion/descuento-por-estadia/:idCasa"
                                    element={<DiscountsForTime />}
                                />

                                <Route
                                    path="/dashboard-hotel/configuraciones-habitacion/minima-disponibilidad/:idCasa"
                                    element={<AvailabilityMinimum />}
                                />
                                <Route
                                    path="/dashboard-hotel/reservas"
                                    element={<ReservasHotel />}
                                />

                                <Route
                                    path="/dashboard-hotel/reservas/:reservaID"
                                    element={<ReservaDetalleHotel />}
                                />
                                <Route
                                    path="/dashboard-hotel/suscripciones"
                                    element={<Suscripciones />}
                                />

                                <Route
                                    path="/dashboard-hotel/onboarding/suscripcion"
                                    element={<PagarSuscripcionHotel />}
                                />
                            </>
                        )}

                        {id_usuario && tipo_usuario === 6 && (
                            <>
                                <Route
                                    path="/dashboard-corredor"
                                    element={<InicioBroker />}
                                />
                                <Route
                                    path="/dashboard-corredor/configuraciones/:idCasa"
                                    element={<Configuraciones />}
                                />
                                <Route
                                    path="/dashboard-corredor/mi-cuenta"
                                    element={<MicuentaBroker />}
                                />
                                <Route
                                    path="/dashboard-corredor/casas"
                                    element={<CasasBroker />}
                                />
                                <Route
                                    path="/dashboard-corredor/onboarding/datos"
                                    element={<DatosLandingCorredor />}
                                />
                                <Route
                                    path="/dashboard-corredor/calendario"
                                    element={<CalendarioBroker />}
                                />

                                <Route
                                    path="/dashboard-corredor/onboarding/redes"
                                    element={<RedesSocialesCorredor />}
                                />

                                <Route
                                    path="/dashboard-corredor/onboarding/suscripcion"
                                    element={<PagarSuscripcionCorredor />}
                                />

                                <Route
                                    path="/dashboard-corredor/onboarding/alojamiento/:idCasa"
                                    element={<NombreCasa />}
                                />
                                <Route
                                    path="/dashboard-corredor/onboarding/capacidad/:idCasa"
                                    element={<Capacidad />}
                                />
                                <Route
                                    path="/dashboard-corredor/onboarding/tema/:idCasa"
                                    element={<EscogerTema />}
                                />
                                <Route
                                    path="/dashboard-corredor/onboarding/imagenes/:idCasa"
                                    element={<Imagenes />}
                                />
                                <Route
                                    path="/dashboard-corredor/onboarding/comodidades/:idCasa"
                                    element={<Comodidades />}
                                />
                                <Route
                                    path="/dashboard-corredor/onboarding/habitaciones/:idCasa"
                                    element={<Habitaciones />}
                                />
                                <Route
                                    path="/dashboard-corredor/onboarding/ubicacion/:idCasa"
                                    element={<Ubicacion />}
                                />
                                <Route
                                    path="/dashboard-corredor/onboarding/precio/:idCasa"
                                    element={<Precio />}
                                />
                                <Route
                                    path="/dashboard-corredor/cambiar-contraseña"
                                    element={<CambiarPassword />}
                                />
                            </>
                        )}

                        {id_usuario &&
                            (tipo_usuario === 5 ||
                                tipo_usuario === 7 ||
                                tipo_usuario === 8 ||
                                tipo_usuario === 9) && (
                                <>
                                    <Route
                                        path="/dashboard-cliente"
                                        element={<Reservas />}
                                    />
                                    <Route
                                        path="/dashboard-cliente/reservas"
                                        // element={<ReservasCliente />}
                                        element={<Reservas />}
                                    />
                                    <Route
                                        path="/dashboard-cliente/reservas/:reservaID"
                                        element={<ReservaDetalle />}
                                    />
                                    <Route
                                        path="/dashboard-cliente/reviews/:reservaID"
                                        element={<ReservaResena />}
                                    />
                                    <Route
                                        path="/dashboard-cliente/chat/:idChat"
                                        element={<ChatCliente />}
                                    />
                                    <Route
                                        path="/dashboard-cliente/chat"
                                        element={<ChatCliente />}
                                    />

                                    <Route
                                        path="/comprobante-en-revicion"
                                        element={
                                            <ComprobarPago
                                                tenant={isTenant?.tipo_usuario}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/pago-confirmado"
                                        element={<PagoConfirmado />}
                                    />
                                    <Route
                                        path="/pago-rechazado"
                                        element={<PagoRechazado />}
                                    />
                                    <Route
                                        path="/dashboard-cliente/mi-cuenta"
                                        element={<MicuentaCliente />}
                                    />
                                    <Route
                                        path="/dashboard-cliente/cambiar-contraseña"
                                        element={<CambiarPassword />}
                                    />
                                </>
                            )}

                        {id_usuario && tipo_usuario === 9 && (
                            <>
                                {/* <Route
                                    path="/dashboard-cliente-hotel"
                                    element={<Reservas />}
                                /> */}
                                <Route
                                    path="/dashboard-cliente-hotel/reservas"
                                    element={<ReservasHotel />}
                                />
                                <Route
                                    path="/dashboard-cliente-hotel/reservas/:reservaID"
                                    element={<ReservaDetalleHotel />}
                                />
                                <Route
                                    path="/dashboard-cliente-hotel/reviews/:reservaID"
                                    element={<ReservaResena />}
                                />
                                <Route
                                    path="/dashboard-cliente-hotel/chat/:idChat"
                                    element={<ChatClienteHotel />}
                                />
                                <Route
                                    path="/dashboard-cliente-hotel/chat"
                                    element={<ChatClienteHotel />}
                                />

                                <Route
                                    path="/comprobante-en-revicion"
                                    element={
                                        <ComprobarPago
                                            tenant={isTenant?.tipo_usuario}
                                        />
                                    }
                                />
                                <Route
                                    path="/pago-confirmado"
                                    element={<PagoConfirmado />}
                                />
                                <Route
                                    path="/pago-rechazado"
                                    element={<PagoRechazado />}
                                />
                                <Route
                                    path="/dashboard-cliente-hotel/mi-cuenta"
                                    element={<MiCuentaClienteHotel />}
                                />
                                <Route
                                    path="/dashboard-cliente-hotel/cambiar-contraseña"
                                    element={<CambiarPassword />}
                                />

                                <Route
                                    path="/dashboard-cliente-hotel/reviews/:reservaID"
                                    element={<ReservaResena />}
                                />
                            </>
                        )}
                    </>
                )}
                {/* Rutas de tenants only  */}

                {/* Rutas públicas */}
                <Route
                    path="/comprobando-disponibilidad/:disponible/:idReserva"
                    element={<ComprobarDisponibilidadPropietario />}
                />
                <Route path="/login" element={<ClientLogin />} />
                <Route path="/google/auth" element={<AuthGoogle />} />
                <Route
                    path="/home/:tags?/politicas-de-uso-y-privacidad"
                    element={<PoliticasDeUsoYPriv />}
                />
                <Route path="/home/login" element={<PropietarioLoginHome />} />
                <Route
                    path="/home/landing-hotel"
                    element={<LandingHotelHome />}
                />

                <Route
                    path="/login-admin"
                    element={<ClientLogin loginAdmin={true} />}
                />
                <Route
                    path="/recuperar_cuenta/:token/:id"
                    element={<RecuerarContraseña />}
                />
                <Route
                    path="/dashboard-admin/mi-cuenta"
                    element={<Micuenta isAdmin={true} />}
                />
                {/* Rutas públicas */}
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;
