import { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { ModalEditComforts } from "../modalEdit/ModalEditComforts";
import ModalReserva from "./ModalReserva/ModalReserva";
import { useNavigate } from "react-router-dom";
interface Props {
    toggleButton: boolean;
    tipo_landing: number;
}
const Servicios = ({ toggleButton, tipo_landing }: Props) => {
    const { casa_comodidades, id: casa_id } = useAppSelector(
        (state) => state.landing_house.house
    );

    const navigate = useNavigate();
    const [modalVisibleComodidades, setModalVisibleComodidades] =
        useState<boolean>(false);

    const [modalVisibleReserva, setModalVisibleReserva] =
        useState<boolean>(false);

    const [input, setInput] = useState({
        huespedes: 0,
        fecha_inicio: "",
        fecha_final: "",
    });

    const handleModalReserva = () => {
        if (tipo_landing === 8) {
            navigate(`/landing-habitaciones`);
        } else {
            setModalVisibleReserva(true);
        }
        setModalVisibleReserva(true);
    };
    return (
        <div className="w-full ">
            {modalVisibleComodidades && (
                <ModalEditComforts
                    setModalVisibleComodidades={setModalVisibleComodidades}
                    modalVisibleComodidades={modalVisibleComodidades}
                />
            )}

            {modalVisibleReserva && (
                <ModalReserva
                    modalVisible={modalVisibleReserva}
                    setModalVisible={setModalVisibleReserva}
                    toggleButton={toggleButton}
                    input={input}
                />
            )}

            <div
                className={
                    toggleButton
                        ? "flex justify-center px-12"
                        : "flex justify-center px-5"
                }
            >
                <h2 className="font-bold text-[#1C4CBB] text-xl lg:text-3xl 2xl:text-4xl text-center mb-6 ">
                    Nuestros servicios
                </h2>
                {toggleButton && (
                    <div
                        onClick={() => setModalVisibleComodidades(true)}
                        title="editar servicios"
                        className="flex flex-col items-center px-2 py-1 mr-6 rounded-lg cursor-pointer opacity-80 bg-slate-200 hover:opacity-100"
                    >
                        <svg
                            className="w-8 h-8 text-[#1C4CBB]"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" d="M0 0h24v24H0z" />{" "}
                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />{" "}
                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                        </svg>
                        <div className="text-xs text-[#1C4CBB]">
                            Editar Servicios
                        </div>
                    </div>
                )}
            </div>

            <div className="grid w-full grid-cols-2 gap-3 2xl:gap-6 2xl:mt-10">
                {casa_comodidades.map((service) => (
                    <div
                        className="p-1 md:p-2  text-center gap-1 flex flex-col justify-center items-center min-w-[205px] 2xl:mt-3 mt-1"
                        key={service.id}
                    >
                        <img
                            className="md:w-10 md:h-10 w-8 h-8 object-contain"
                            src={
                                `${process.env.REACT_APP_PROXY_ARCHIVOS}/blu/v1/comodidad/getImage/` +
                                service.comodidade.icono
                            }
                            alt=""
                        />
                        <div className="font-semibold text-[#666666] md:text-sm text-xs">
                            {service.comodidade.nombre}
                        </div>
                    </div>
                ))}
            </div>

            <button
                onClick={() => handleModalReserva()}
                className="flex gap-1 px-2 py-4 text-white  rounded-md cursor-pointer bg-[#1E90FF] hover:scale-105 text-sm 2xl:text-base select-none disabled:bg-slate-300 disabled:scale-100 mt-5 2xl:mt-14 w-1/2 mx-auto"
            >
                <p className="mx-auto font-bold"> RESERVAR AHORA</p>
            </button>
        </div>
    );
};

export default Servicios;
